import { shippersTypes } from '../types/shippersType';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';


export const getShipperData = (params: any) => {
    return async (dispatch: Dispatch) => {
         const res = await getShipperDataReq(params);
         if (res?.data?.hits?.length > 0) {
            dispatch(setShippers(res?.data?.hits));
         }else{
            dispatch(setShippers([]));
         }
       
        return res;
    }
}
export const deleteShipper = (LocationNumber: any,ShipperName:any) => {
    return async (dispatch: Dispatch) => {
         const res = await deleteShipperReq(LocationNumber,ShipperName);
         dispatch(setShippers(res));
    
        return res;
    }
}
export const deleteShipperContact = (LocationNumber: any,ShipperName:any,Seq:any) => {
    return async (dispatch: Dispatch) => {
         const res = await deleteShipperContactReq(LocationNumber,ShipperName,Seq);
         dispatch(setShippers(res));
    
        return res;
    }
}
export const saveShipper = (LocationNumber: any,params:any,type:any) => {
    return async (dispatch: Dispatch) => {
         const res = await saveShipperReq(LocationNumber,params,type);
        dispatch(setShippers(res));
        return res;
    }
}
export const saveShipperHours = (LocationNumber: any,params:any,type:any,shipperCode:any) => {
    return async (dispatch: Dispatch) => {
         const res = await saveShipperHoursReq (LocationNumber,params,type,shipperCode);
        dispatch(setShippers(res));
        return res;
    }
}

export const searchShipper = (params: any,query:any) => {
    

    return async (dispatch: Dispatch) => {

        let url = `/elastic/shipperconsignee/_search`
        const res = await getSearch(url,params,query);
    
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(setShippers(res.data.hits))
            // dispatch(setShippers(res))
            // dispatch(setShippers({
            //     isLoading: false,
            //     data: res.data.length === 0 ? [] : res.data.hits.map((item: any) => item._source),
            //     totalRows: res.data.length === 0 ? 0 : res.data.total.value
            // }))
        }
        else {
           
        }
        return res;
    }
}


const setShippers = (apiData: any) => {
    return {
        type: shippersTypes.SHIPPERS_DATA,
        payload: apiData,
    };
};
const setShippersContact = (apiData: any) => {
    return {
        type: shippersTypes.SHIPPERS_CONTACT_DATA,
        payload: apiData,
    };
};
const setShippersHours = (apiData: any) => {
    return {
        type: shippersTypes.SHIPPERS_HOURS_DATA,
        payload: apiData,
    };
};


const SaveShippers = (apiData: any) => {
    return {
        type: shippersTypes.SHIPPERS_SAVE,
        payload: apiData,
    };
};


export const getShipperDataReq = async (params: any) => {
    console.log("params--sort",params)
    // let url = `/shipperconsignee/${params.locationNumber}`
    let url = `/elastic/shipperconsignee/${params.locationNumber}/_search`
    const queryString = `?pageNumber=${params?.query?.page}&pageSize=${params?.query?.pageSize}&sortBy=${params?.sortData?.field}&sortOrder=${params?.sortData?.sort}`;
    
    // const queryString =""
    try {
        let res = await POST(url + queryString,params.postParams);
        return res;
    } catch (err) {
        return [];
    }
   
}
export const getSearch = async (url: any,params:any,query:any) => {
    
    // if (typeof query?.page !== 'undefined' && query?.page !== 0) {
    //     url += `?page=${query?.page}&size=${query?.pageSize}`;
    // } else {
    //     url += '?page=1&size=100';
    // }
    try {
        let res = await POST(url, params);
        return res;
    } catch (err) {
        return [];
    }
}
export const saveShipperReq = async (locationNumber: number, params: any, type: any) => {
    try {
        let res;
        if (type === 'new') {
            // Perform POST request
           
            res = await POST(`/shipperconsignee/${locationNumber}`, params);
        } else {
            // Perform PUT request
            res = await PUT(`/shipperconsignee/${locationNumber}`, params); // Assuming you have a PUT function
        }
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}
export const saveShipperHoursReq = async (locationNumber: number, params: any, type: any,shipperCode:any) => {
    console.log("ship",shipperCode)
    try {
        let res;
        if (type === 'new') {
            // Perform POST request
            res = await POST(`shipperconsignee/${locationNumber}/${shipperCode}/hours`, params);
        } else {
            // Perform PUT request
            res = await PUT(`shipperconsignee/${locationNumber}/${shipperCode}/hours`, params); // Assuming you have a PUT function
        }
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}
export const deleteShipperReq = async (locationNumber: number,shipperCode:any) => {
    try {
        let res = await DELETE(`/shipperconsignee/${locationNumber}/${shipperCode}`);
        return res;
    } catch (err) {
        return [];
    }
}
export const deleteShipperContactReq = async (locationNumber: number,shipperCode:any,Seq:any) => {
    try {
        let res = await DELETE(`/shipperconsignee/${locationNumber}/${shipperCode}/contacts/${Seq}`);
        return res;
    } catch (err) {
        return [];
    }
}

//contacts
// /shipperconsignee/800/TESTSHP/contacts

export const getShipperContactData = (params: any) => {
    return async (dispatch: Dispatch) => {
         const res = await getShipperContactDataReq(params);
         if (res?.data?.hits?.length > 0) {
            dispatch(setShippersContact(res?.data?.hits));
         }else{
            dispatch(setShippersContact([]));
         }
       
        return res;
    }
}

export const getShipperContactDataReq = async (params: any) => {
    console.log("params",params)
    let url = `/shipperconsignee/${params.locationNumber}/${params.shipperCode}/contacts`
   
    // const queryString = `?page=${params.query.page}&size=${params.query.pageSize}&sortBy=${params.sortData.field}&sortOrder=${params.sortData.sort}`;
    const queryString =""
    try {
        let res = await GET(url + queryString);
        console.log("contact-res",res)
        return res;
    } catch (err) {
        return [];
    }
   
}

export const getShipperHoursData = (params: any) => {
    return async (dispatch: Dispatch) => {
         const res = await getShipperHoursDataReq(params);
         if (res?.data?.hits?.length > 0) {
            dispatch(setShippersHours(res?.data?.hits));
         }else{
            dispatch(setShippersHours([]));
         }
       
        return res;
    }
}

export const getShipperHoursDataReq = async (params: any) => {
    console.log("params",params)
    let url = `/shipperconsignee/${params.locationNumber}/${params.shipperCode}/hours`
   
    // const queryString = `?page=${params.query.page}&size=${params.query.pageSize}&sortBy=${params.sortData.field}&sortOrder=${params.sortData.sort}`;
    const queryString =""
    try {
        let res = await GET(url + queryString);
        console.log("contact-res",res)
        return res;
    } catch (err) {
        return [];
    }
   
}


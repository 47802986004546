import { locationTypes } from "../types/locationTypes"
import { loadsTypes } from "../types/loadsTypes"
import { Dispatch } from 'redux';
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import { setSerialNumber, setFetchSerialNumber } from '../../scenes/Location/response-modify';

// interface
interface pageNationInfo {
    limitNumber: number,
    page: number,
    status: string
}

interface locationDetail {
    type: string,
    payload: any
}

interface addLocationParams {
    email: string,
    locationNumber: [],
    status: number
}

interface editdata {
    currentEmail: string,
    newEmail: string,
    locationNumber: [],
    status: number
}

interface deleteValue {
    locationNumber: number,
    email: string
}

const setLoad = (isLoading: boolean) => {
    return {
        type: loadsTypes.SET_LOAD_LOADING,
        payload: isLoading
    }
}

export const getLocationGridDetail = (pageNationInfo: pageNationInfo) => {

    return async (dispatch: Dispatch) => {
        dispatch(setLoad(true));
        let res = await getLocationData(pageNationInfo);
        dispatch(getLocationAction(res))
        dispatch(setLoad(false));
    }
}

const getLocationData = async (pageNationInfo: any) => {
    const { page, limitNumber, status } = pageNationInfo;
    let res;
    if (status == '') {
        res = await GET(`/locations/all?page=${page}&limit=${limitNumber}`)
    }
    if (status !== '') {
        res = await GET(`/locations/all?page=${page}&limit=${limitNumber}&status=${status}`)
    }
    let modifyRes;
    if (res.data.data.length > 0) {
        let sNo = limitNumber * (page - 1);
        modifyRes = setSerialNumber(res, sNo)
    }

    return modifyRes?.data || []
}

const getLocationAction = (locationDetail: locationDetail) => {
    return {
        type: locationTypes.GET_LOCATION_DATA,
        payload: locationDetail
    }
}

export const addNewLocation = (addLocationParams: addLocationParams) => {
    return async (dispatch: Dispatch) => {
        let res = await addLocationData(addLocationParams);
        return res
    }
}

const addLocationData = async (addLocationParams: addLocationParams) => {
    let res = await POST('/locations', { locationNumber: addLocationParams.locationNumber, cur_email: addLocationParams.email, new_email: addLocationParams.email, status: addLocationParams.status });
    return res;

}


const addLocationAction = (locationDetail: locationDetail) => {
    return {
        type: locationTypes.ADD_NEW_LOCATION,
        payload: locationDetail
    }
}

// Edit location
export const editNewLocation = (editdata: editdata) => {
    return async (dispatch: Dispatch) => {
        let res = await editLocationData(editdata);
        return res;
    }
}

const editLocationData = async (addLocationParams: editdata) => {
    let res = await PUT('/locations', { cur_email: addLocationParams.currentEmail, new_email: addLocationParams.newEmail, locationNumber: addLocationParams.locationNumber, status: addLocationParams.status });
    return res;
}


const editLocationAction = (locationDetail: locationDetail) => {
    return {
        type: locationTypes.ADD_NEW_LOCATION,
        payload: locationDetail
    }
}

// Search location
export const searchEmailString = (searchEmail: String) => {
    return async (dispatch: Dispatch) => {
        let res = await searchEmailData(searchEmail);
        dispatch(searchLocationAction(res))
    }
}

const searchEmailData = async (addEmailParams: String) => {
    let res = await GET(`/locations/all?email=${addEmailParams}`);
    let modifyRes;
    if (res.message == "Records fetched successfully!") {
        res.data.message = 'Records fetched successfully!'
        modifyRes = setSerialNumber(res)
    } if (res.message == "There is no record for selected filter!") {
        res.data.message = 'There is no record'
        modifyRes = res
    } if (res.message == "Something went wrong, Please try again!") {
        res.data.message = "Something went wrong, Please try again!"
        modifyRes = res
    }
    return modifyRes.data;
}

const searchLocationAction = (emailString: locationDetail) => {

    return {
        type: locationTypes.GET_LOCATION_DATA,
        payload: emailString
    }
}

//Delete Location

export const deleteLoc = (deleteValue: deleteValue) => {

    return async (dispatch: Dispatch) => {
        let res = await deleteLocData(deleteValue);
        dispatch(deleteLocAction(res))
    }
}

const deleteLocData = async (addDeleteParams: deleteValue) => {
    let res = await DELETE('/locations', { locationNumber: addDeleteParams.locationNumber, email: addDeleteParams.email });
    return res;
}

const deleteLocAction = (deletString: locationDetail) => {
    return {
        type: locationTypes.DELETE_LOCATION_NUMBER,
        payload: deletString
    }
}

//Fetch Location Data 

export const fetchLocation = () => {
    return async (dispatch: Dispatch) => {
        let res = await fetchLocationData();
        dispatch(fetchLocationAction(res))
    }
}

const fetchLocationData = async () => {
    let res;
    if (!res) {
        res = await GET("/locations/master")
    }
    let modifyRes = setFetchSerialNumber(res)
    return modifyRes;

}

const fetchLocationAction = (fetchLocData: locationDetail) => {
    return {
        type: locationTypes.FETCH_LOCATION_DATA,
        payload: fetchLocData
    }
}

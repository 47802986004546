import config from '../config/config';
import Axios from 'axios';
import { getToken, logoutInternal, getRefreshToken, setToken } from './cookie';
import { setErrorData } from '../store/actions/loadsActions';
import { setErrorData1 } from '../store/actions/orderActions';
import { refreshAccessToken } from './auth-axios';
import reduxStore from '../store';
// import { load } from 'recaptcha-v3';
const URL = config.api.URL;

// direct access to redux store.
const { dispatch } = reduxStore;

// doc - https://github.com/axios/axios
const axiosInstance = Axios.create({
    baseURL: URL,
    headers: {
        'Cache-control': 'no-cache, no-store',
        'Pragma': 'no-cache',
        'Content-Type': 'application/json',
    },
    // timeout: 10000,
    transformRequest: [
        function (data, headers) {
            headers['Expires'] = '0';
            if (getToken()) headers['Authorization'] = `Bearer ` + getToken();
            if(isStringified(data)){
                return data
            } else {
                return JSON.stringify(data);
            }
        },
    ],
    transformResponse: [
        function (data, headers) {

            if (headers['content-type'].indexOf('application/json') > -1) {
                const json = JSON.parse(data);
                return json;
            }
            return data;
        },
    ],
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    // return logoutInternal();
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {

        // Logout user if refresh token is not set
        if (getRefreshToken() === null) {
            return logoutInternal();
        }

        config.sent = true;
        const newAccessToken = await memoizedRefreshToken();

        if (newAccessToken !== null) {
            error.config.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return axiosInstance(error.config);
        }

        return logoutInternal();

    }
    if (error?.response?.status === 403) {
        return logoutInternal();
    }
    // const errObj = { status: 'ErrorCode: ' + error.response.status, statusText: ', ErrorMessage: ' + error.response.statusText + ', ApiUrl: ' + error.response.config.url };
    const errObj = { status: 'ErrorCode: ' + 500, statusText: ', ErrorMessage: ' + 'Internal Server Error!!!' };
    dispatch(setErrorData(errObj));
    dispatch(setErrorData1(errObj));
    // if (error?.response?.status === 500) return;
    // throw new Error();
})

export const GET = async (url) => {
    const res = await axiosInstance.get(url);
    return res?.data;
};

export const POST = async (url, data) => {
    const res = await axiosInstance.post(url, data);
    return res?.data;
};

export const PUT = async (url, data) => {
    const res = await axiosInstance.put(url, data)
    return res?.data;
};

export const DELETE = async (url, data) => {

    const res = await axiosInstance.delete(url, {
        data: data,
        headers: {
            "Authorization": localStorage.getItem("access_token") !== null ? `Bearer ` + localStorage.getItem("access_token") : null,
            "Content-Type": "application/json"
        }
    });
    return res?.data;
}

const getNewAccessToken = async () => {
    const newAccessToken = await refreshAccessToken();

    if (newAccessToken !== false) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        return newAccessToken;
    }
    return null;
}

// Function to memoize the result of a given function for a certain duration
function memoizeWithDuration(func, duration) {
    let cachedResult = null;
    let cacheExpiry = null;

    return (...args) => {
        if (cacheExpiry && cacheExpiry > Date.now()) {
            return cachedResult;
        } else {
            const result = func(...args);
            cachedResult = result;
            cacheExpiry = Date.now() + duration;
            return result;
        }
    };
}

//chcek if the obj is a string or not
function isStringified(obj) {
    if (typeof obj !== 'string') {
        return false; // It's not a string, so it's not stringified
    }

    try {
        JSON.parse(obj);
        return true; // Successfully parsed, so it's a stringified object
    } catch (e) {
        return false; // Parsing failed, so it's not valid JSON
    }
}

const memoizedRefreshToken = memoizeWithDuration(getNewAccessToken, 10000);
import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { truckDriverStatus } from '../../scenes/TenderDetails/truck-driver-utils';
import Tooltip from '@mui/material/Tooltip';

function DriverInfo({ truckDriverInfo, driverStatusFunc }) {
    const [driverSafetyStatus, setDriverSafetyStatus] = useState('')
    const [driverSafetyMessage, setDriverSafetyMessage] = useState('')
    const history = useHistory();

    useEffect(() => {
        const status = truckDriverStatus(dateConverter(truckDriverInfo.licenseExpiry), dateConverter(truckDriverInfo.medicalExamExpiry));
        setDriverSafetyStatus(status)
    }, [truckDriverInfo.licenseExpiry && truckDriverInfo.medicalExamExpiry]);

    useEffect(() => {
        driverStatusFunc(driverSafetyStatus)
        if (driverSafetyStatus === "OK") {
            setDriverSafetyMessage("OK")
        }
        if (driverSafetyStatus === "NA") {
            setDriverSafetyMessage("License/Medical Expired!")
        }
    }, [driverSafetyStatus])

    return (
        <div>
            <h3 className="truck-driver-subheading">Driver Information</h3>
            <div className="truck-info-input">
                <label>Driver Number</label>
                <Tooltip title={truckDriverInfo?.driverNumber} arrow>
                <TextField
                    value={truckDriverInfo?.driverNumber}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="driver-number-field"
                    
                /></Tooltip>
            </div>
            <div className="truck-info-multi-input3">
                <label>Driver Name</label>
                <Tooltip title={truckDriverInfo?.driverName} arrow>
                <TextField
                    value={truckDriverInfo?.driverName}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="driver-name-text"
                    // style={{ width: "150px" }}
                /></Tooltip>
                <Tooltip title={truckDriverInfo?.driverMiddleName} arrow>
                <TextField
                    value={truckDriverInfo?.driverMiddleName}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    // style={{ width: "50px" }}
                    className="driver-name-middlename"
                /></Tooltip>
                <Tooltip title={truckDriverInfo?.driverLastName} arrow>
                <TextField
                    value={truckDriverInfo?.driverLastName}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="driver-name-lastname"
                    // style={{ width: "180px" }}
                /></Tooltip>
            </div>
            <div className="truck-info-multi-input">
                <label>Safety Driver Status</label>
                <Tooltip title={driverSafetyStatus} arrow>
                <TextField
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    style={{ width: "100%" }}
                    value={driverSafetyStatus}  
                />
                </Tooltip>
                <Tooltip title={driverSafetyMessage} arrow>
                <TextField
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="driverinfo-text2"
                    value={driverSafetyMessage}
                   />
                </Tooltip>
            </div>
            <div className="truck-info-multi-input">
                <label>License Expires/No</label>
                <Tooltip title={dateConverter(truckDriverInfo?.licenseExpiry)} arrow>
                <TextField
                    value={dateConverter(truckDriverInfo?.licenseExpiry)}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-liscense-no"
                    
                /></Tooltip>
                 <Tooltip title={truckDriverInfo?.licenseNumber} arrow>
                <TextField
                    value={truckDriverInfo?.licenseNumber}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="driverinfo-text2"
                /></Tooltip>
            </div>

            <div className="truck-info-input">
                <label>Medical Exam Expires</label>
                <TextField
                    value={dateConverter(truckDriverInfo?.medicalExamExpiry)}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal"
                />
            </div>

            <div className="truck-info-input">
                <label>Annual Review Expires</label>
                <TextField
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal"
                />
            </div>

            <div className="truck-info-input">
                <label>Termination Date</label>
                <TextField
                    value={dateConverter(truckDriverInfo?.terminationDate)}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal"
                />
            </div>

            <div className="truck-info-input">
                <label>Phone Number</label>
                <TextField
                    value={truckDriverInfo?.phone2}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal"
                />
            </div>


            <div className="truck-info-input">
                <label>Fax Number</label>
                <TextField
                    value={truckDriverInfo?.faxNumber}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal"
                />
            </div>

            <div className="truck-info-input">
                <label>Email Address</label>
                <TextField
                    value={truckDriverInfo?.email}
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal"
                />
            </div>

            <div className="truck-info-input">
                <label>Comdata Card #</label>
                <TextField
                    id="outlined-size-small"
                    data-test="driverinfofields"
                    size="small"
                    variant="outlined"
                    className="truck-width-normal" 
                />
            </div>
        </div>
    )
}

export default DriverInfo;

export const dateConverter = (val) => {

    if (val) {
        let dateFormat = `${val}`.split('');
        let year = `${dateFormat[0]}${dateFormat[1]}${dateFormat[2]}${dateFormat[3]}`;
        let month = `${dateFormat[4]}${dateFormat[5]}`;
        let date = `${dateFormat[6]}${dateFormat[7]}`
        return `${month}/${date}/${year}`;
    }else{
        return ''
    }

}

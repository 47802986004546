import { Grid } from "@material-ui/core";
import {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useLayoutEffect,
} from "react";
import ShipperConsigneeTableContainer from "./ShipperConsigneeTableContainer";
import { OrdersWrapper } from "../../components/styledComponents";
import ShipperConsigneeFormContainer from "./ShipperConsigneeFormContainer";
import { useSelector, useDispatch } from "react-redux";
import { getShipperData } from "../../store/actions/shipperActions";
import { maptoShipperTable } from "./components/commonFn";
import useUnsavedChangesWarning from '../../components/Alerts/useUnsavedChangesWarning';

export default function ShipperConsignee() {
  const [addNewShipperCons, setAddNewShipperCons] = useState(false);
  const[editShipperClicked,setEditShipperClicked]=useState(false)
  const [selectedRow, setSelectedRow] = useState();
  // const memoizedSelectedRow = useMemo(() => selectedRow, [selectedRow]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [rowsCount, setRowsCount] = useState(1);
  const dispatch = useDispatch();
  const [formKey, setFormKey] = useState(0);

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );


  const condition = editShipperClicked || addNewShipperCons;

  useUnsavedChangesWarning(condition);

  useEffect(() => {
    setSelectedRow("");

    if (typeof ordersLocation?.locationNumber !== "undefined") {
      setIsLoading(true);
      shipperTableApiCall();
    }
  }, [ordersLocation]);
  // shipperTableApiCall(ordersLocation.locationNumber,{page: params.page, pageSize: params.pageSize  })
  const shipperTableApiCall = (
    query = { page: 1, pageSize: 50 },
    sortData = { field: "name", sort: "asc" },
    code=""
  ) => {
  

    setIsLoading(true);
  let postParams = [
  {
      clause: "AND",
      field: "locationNumber",
      operand: "IS",
      value: ordersLocation?.locationNumber,
      format: "Number"
  }]
    dispatch(
      getShipperData({
        postParams,
        locationNumber: ordersLocation?.locationNumber,
        query: query,
        sortData,
      })
    ).then((res) => {
      console.log("res-d", res.data.hits);

      if (res?.data?.hits?.length > 0) {
        setRowsData(res?.data.hits)
        let data = maptoShipperTable(res?.data.hits);
        setRows(data);
        setRowsCount(res?.data.total.value);
        if(code){
          console.log("res?.data.hits",res?.data.hits)
          const savedDataRes=res?.data.hits.filter((item)=>item?._source.shipperCode===code)
          setSelectedRow(savedDataRes[0])
        }
      } else {
       
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    setFormKey((prevKey) => (prevKey === 0 ? 1 : 0)); // Toggle between 0 and 1
  }, [selectedRow, addNewShipperCons]);
 
  return (
    <OrdersWrapper>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: "10px",
            }}
          >
            <ShipperConsigneeTableContainer
              setSelectedRow={setSelectedRow}
              setAddNewShipperCons={setAddNewShipperCons}
              setEditShipperClicked={ setEditShipperClicked}
              setRows={setRows}
              rows={rows}
              rowsData={rowsData}
              shipperTableApiCall={shipperTableApiCall}
              isLoading={isLoading}
              rowsCount={rowsCount}
              setRowsCount={setRowsCount}
              setRowsData={setRowsData}
              setIsLoading={setIsLoading}
              selectedRow={selectedRow}
              addNewShipperCons={addNewShipperCons}
              editShipperClicked={editShipperClicked}

            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: !addNewShipperCons ? "0px" : "10px",
            }}
          >
            {addNewShipperCons || selectedRow ? (
             
              <ShipperConsigneeFormContainer
                key={formKey}
                formKey={formKey}
                selectedRow={selectedRow}
                setRows={setRows}
                shipperTableApiCall={shipperTableApiCall}
                addNewShipperCons={addNewShipperCons}
                setAddNewShipperCons={setAddNewShipperCons}
                setSelectedRow={ setSelectedRow}
                onEditShipper={setEditShipperClicked}
              />
            
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ fontSize: "22px", color: "#707070" }}>
                  {" "}
                  Open any Shipper/Consignee
                </h3>
                <h3 style={{ fontSize: "22px", color: "#707070" }}>or</h3>
                <h3 style={{ fontSize: "22px", color: "#707070" }}>
                  Create new Shipper/Consignee
                </h3>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </OrdersWrapper>
  );
}

import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@material-ui/core';
import {
  Button,
} from '@material-ui/core'
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import KeyboardDoubleArrowDownSharpIcon from '@mui/icons-material/KeyboardDoubleArrowDownSharp';
import KeyboardDoubleArrowUpSharpIcon from '@mui/icons-material/KeyboardDoubleArrowUpSharp';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { getLocationGridDetail, searchEmailString } from '../../store/actions/locationActions';
import Loader from '../../components/Loader/Lodaer';
import { fetchLocationData } from '../../scenes/Location/fetch-location'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  tableContainer: {
    overflowX: "initial"
  }
})

export default function CustomizedTables({ isSearch, getSearchEmail, refreshEmail }) {

  const [statusChange, setStatusChange] = useState('');
  const [selectOpen, setSelectOpen] = React.useState(false);
  const [page1, setPage] = React.useState(1);
  const [loadMyData, setLoadMyData] = useState([]);
  let isDataLoad = false;
  const dispatch = useDispatch();
  let [newLocationNumbers, setNewLocationNumbers] = useState([]);
  let [editEmail, setEditEmail] = useState('');
  const [open, setOpen] = React.useState(false);
  let [totalLocations, setTotalLocations] = useState(0);
  let [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

 

  // status set default
  const [defaultStatus, setDefaultStatus] = useState('');

  //Pagination value
  let getPaginationNum = Math.ceil(totalLocations / 5) || 0;

  // Fetch Location Data
  const getFetchLocLocalStorage = localStorage.getItem('fetLocationNumber');

  // search server error message
  let [searchServerErr, setSearchServerErr] = useState('');

  useEffect(() => {
    setLoadMyData([]);
    setIsLoading(true)
  }, [isSearch == true])

  useEffect(() => {
    const fetchLoc = fetchLocationData(dispatch);
  }, [getFetchLocLocalStorage == null])
  
  if (loadMyData.length === 0) {
    isDataLoad = true;
  } else {
    isDataLoad = false;
  }

  //load initial Data to Table Grid
  useEffect(() => {
    let paginationData = { page: page1, limitNumber: 5, status: defaultStatus };
    dispatch(getLocationGridDetail(paginationData));
  }, [page1]);

  const popupNumber = newLocationNumbers.join(', ')

  function editLocation(email) {
    return function () {
      history.push(`/addlocation/${email}`)
    }
  }

  const handleChange = (event, value) => {

    setDefaultStatus(defaultStatus)
    setPage(value);
  };

  const handleClickOpen = (arr, email) => {
    return function () {
      let arrNumber = arr.map(Number);
      setNewLocationNumbers(arrNumber)
      setEditEmail(email);
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [hide, setHide] = useState(false);

  function statusHandlerUp(statusVal) {

    return function () {

      let value = statusVal;
      if (value === 1) {
        setHide(true);
      } else {
        setHide(false);
      }

      //Status filter
      setDefaultStatus(value)
      if (defaultStatus !== value) {
        let paginationData = { page: 1, limitNumber: 5, status: value };
        setStatusChange(value);
        setLoadMyData([]);
        setIsLoading(true)
        dispatch(getLocationGridDetail(paginationData))
      } else {
        let paginationData = { page: page1, limitNumber: 5, status: value };
        setStatusChange(value);
        dispatch(getLocationGridDetail(paginationData))
      }
    }

  }

  //get Status Filter value
  const statusHandleChange = (event) => {
    let value = event.target.value;
    setDefaultStatus(value)
    if (defaultStatus !== value) {
      let paginationData = { page: 1, limitNumber: 5, status: value };
      setStatusChange(event.target.value);
      setLoadMyData([]);
      setIsLoading(true)
      dispatch(getLocationGridDetail(paginationData))
    } else {
      let paginationData = { page: page1, limitNumber: 5, status: value };
      setStatusChange(event.target.value);
      dispatch(getLocationGridDetail(paginationData))
    }
  };
  const statusHandleClose = () => {
    setSelectOpen(false);
  };

  const statusHandleOpen = () => {
    setSelectOpen(true);
  };

// load data to const
  const pageLoadData = useSelector(({ locationReducer }) => {
    return locationReducer.allLocationData.data
  });

  const totalPageData = useSelector(({ locationReducer }) => {

    return locationReducer?.allLocationData?.totalRows

  });

  const searchServerErrText = useSelector(({ locationReducer }) => {

    return locationReducer?.allLocationData?.message

  });

  useEffect(() => {
    setSearchServerErr(searchServerErrText)
  }, [searchServerErrText])

  useEffect(() => {
    setIsLoading(true)
    if (pageLoadData?.length > 0) {
      setIsLoading(true)
      setLoadMyData(pageLoadData);
    } else {
      setLoadMyData([]);
      setIsLoading(true)
    }
  }, [pageLoadData])

  useEffect(() => {

    setTotalLocations(totalPageData);

  }, [pageLoadData])

  function refreshLocation() {
    setDefaultStatus('')
    setLoadMyData([]);
    setIsLoading(true)
    refreshEmail('')

    let paginationData = { page: 1, limitNumber: 5, status:''};
    dispatch(getLocationGridDetail(paginationData));

  }

  const classes = useStyles();


  return (
    <>
      {
        (loadMyData.length > 0 || isLoading == true) && searchServerErr !== "Something went wrong, Please try again!" ? <TableContainer classes={{root: classes.tableContainer}} style={{ MarginTop: '20px', width: "100%" }}>
          <Table aria-label="collapsible table">
            <TableHead
              style={{
                justifyContent: 'space-between',
                marginTop: '2px',
                height: '6px',
                background: "rgba(24,0,255,255)",
                color: "white"
              }}>
              <TableRow >
                <TableCell style={{ fontSize: '16px', fontWeight: 'bold', columnWidth: "auto" }}><span style={{ color: 'white' }}>#</span></TableCell>
                <TableCell style={{ fontSize: '16px', fontWeight: 'bold', align: "left" }}><span style={{ color: 'white' }}>Email</span></TableCell>
                <TableCell style={{ fontSize: '16px', fontWeight: 'bold' }}><span style={{ color: 'white' }}>Location</span></TableCell>
                <TableCell style={{ fontSize: '16px', fontWeight: 'bold', align: "left" }}><span style={{ color: 'white' }}>Default</span></TableCell>
                <TableCell style={{ fontSize: '16px', fontWeight: 'bold', align: "left", display: "flex" }}><span style={{ color: 'white' }}>Status {hide ? <IconButton onClick={statusHandlerUp(0)}><KeyboardDoubleArrowUpSharpIcon /></IconButton> : <IconButton onClick={statusHandlerUp(1)}><KeyboardDoubleArrowDownSharpIcon /></IconButton>}</span></TableCell>
                <TableCell style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}><span style={{ color: 'white' }}>Action</span></TableCell>
              </TableRow>
            </TableHead>
            {
              isLoading ? <TableBody>

                {
                  loadMyData.length > 0 ? loadMyData.map((val, key) => {
                    const isLocalArray = Array.isArray(val.locationNumber);
                    let greaterThreeNumber = '';
                    let locationNumber = '';
                    if (isLocalArray) {
                      if (val.locationNumber.length === 3) {
                        greaterThreeNumber = `${val.locationNumber[0]}, ${val.locationNumber[1]}, ${val.locationNumber[2]}`;
                      }
                      if (val.locationNumber.length > 3) {
                        greaterThreeNumber = `${val.locationNumber[0]}, ${val.locationNumber[1]}, ${val.locationNumber[2]}, ...`;
                      } else {
                        if (locationNumber == '') {
                          locationNumber = `${val.locationNumber}`

                        } else {
                          locationNumber = `${locationNumber}, ${val.locationNumber}`
                        }
                      }
                    } else {
                      locationNumber = val.locationNumber
                    }
                    return (
                      <tr key={key}>

                        <TableCell>{val.sNo}</TableCell>
                        <TableCell>{val.email}</TableCell>
                        <TableCell onClick={handleClickOpen(val.locationNumber, val.email)}>
                          {
                            greaterThreeNumber ? greaterThreeNumber : locationNumber
                          }
                        </TableCell>
                        <TableCell>{val.default}</TableCell>
                        <TableCell>{val.status}</TableCell>
                        <TableCell style={{ textAlign: 'center' }}><Button onClick={editLocation(val.email)} size="small"><EditIcon style={{ fontSize: '15px' }} /></Button></TableCell>
                      </tr>
                    )
                  }) : <tr style={totalLocations == 0 && isSearch ? { display: "none" } : {}}><td colspan="6"><Loader style={{ marginLeft: "100px" }} /></td></tr>
                }
              </TableBody> : null
            }

          </Table>
          <div ></div>
          <Stack spacing={2}>
            {
              loadMyData.length > 0 ?
                <div style={{ display: "grid", margin: "2%", gridTemplateColumns: "1fr 2fr auto" }}>
                  <Typography>Total Records: {totalLocations}</Typography>
                  <Pagination count={getPaginationNum} showFirstButton showLastButton onChange={handleChange} />
                  <Typography><IconButton onClick={refreshLocation}><RefreshIcon /></IconButton></Typography>
                </div> : null}
          </Stack>
        </TableContainer> : <h2 style={{ textAlign: 'center' }}> {searchServerErr}</h2>
      }
      {
        totalLocations == 0 ? <h2 style={{ textAlign: 'center' }}> {searchServerErr}</h2> : null
      }

      {totalLocations == 0 ? <Typography style={{ textAlign: "right" }}><IconButton onClick={refreshLocation}><RefreshIcon /></IconButton></Typography> : null}
      {searchServerErr == "Something went wrong, Please try again!" ? <Typography style={{ textAlign: "right" }}><IconButton onClick={refreshLocation}><RefreshIcon /></IconButton></Typography> : null}
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {popupNumber}
            <DialogActions>
              <Button size="small" style={{ background: "blue", padding: '5px', marginRight: '10px' }} onClick={editLocation(editEmail)}>
                <span style={{ color: 'white' }}>Edit</span>
              </Button><Button size="small" style={{ background: "red", padding: '5px', marginRight: '10px' }} onClick={handleClose}>
                <span style={{ color: 'white' }}>Cancel</span>
              </Button>
            </DialogActions>
          </DialogTitle>

        </Dialog>
      </div>
    </>
  )
}

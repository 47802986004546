import { useState } from 'react';
import {
    Paper, Tabs, Tab, Box, Stack, Grid, Checkbox, FormControl, InputLabel, FormControlLabel, Divider, Chip,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Backdrop,
    CircularProgress,
    IconButton,
    Autocomplete
} from "@mui/material";

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
// import custom date picker
import CustomDatePicker from '../../../../components/CustomDatePicker';

import RatesAutoComplete from './RatesAutoComplete';
import GLCodesAutoComplete from './GLCodesAutoComplete';
import TrucksAutoComplete from './TrucksAutoComplete';
import { getPayableGlCode, getParentPayableRate, calAmount, roundOfNumber, findMaxLineNumber, getParentPayableUnit } from '../CommonFn';

export default function TableNewRowForm(props) {
    const { accessorialEditRef, linehaulEditRef, onChildNodeChange, tableType, orderType, ordersLocation,
        hideNewRow, handleNewRow, isReceivableDisabled, isPayableDisabled, unPayableGLCodes, isTemplateScreen, edi210 } = props;
    // const maxLineNumber = findMaxLineNumber([...linehaulEditRef.current, ...accessorialEditRef.current]) + 1;

    const [receivablesRow, setRecievablesRow] = useState(!isReceivableDisabled ? {
        lineNumber: 0, //maxLineNumber,
        accountLine: tableType,
        description: '',
        destinationCity: '',
        destinationStatePrefix: '',
        glCode: '',
        glDescription: '',
        notes: "",
        loadType: "DP",
        originCity: '',
        originName: '',
        originPrefix: '',
        parentLineNumber: '',
        payRec: '',
        payType: '',
        payableReceivable: 'R',
        subPer: '',
        transmitted: 'N',
        truckNumber: '',
        unitRate: 0,
        units: 1,
        amount: 0,
    } : {});
    const [payableRow, setPayableRow] = useState(!isPayableDisabled?
        {
            lineNumber: 0, //maxLineNumber + Number(1),
            accountLine: tableType,
            description: '',
            destinationCity: '',
            destinationStatePrefix: '',
            glCode: '',
            glDescription: '',
            notes: "",
            loadType: "DP",
            originCity: '',
            originName: '',
            originPrefix: '',
            parentLineNumber: '',
            payRec: 0, //maxLineNumber,
            payType: '',
            payableReceivable: 'P',
            subPer: '',
            transmitted: 'N',
            truckNumber: '',
            unitRate: 0,
            units: 1,
            amount: 0,
        }:{}
    );

    const handleSave = () => {
        const maxLineNumber = findMaxLineNumber([...linehaulEditRef.current, ...accessorialEditRef.current]) + Number(1);

        hideNewRow(false);
        handleNewRow({ receivable: !isReceivableDisabled?{...receivablesRow, lineNumber: maxLineNumber}:{}, payable: !isPayableDisabled?{...payableRow, lineNumber: maxLineNumber + Number(1), payRec: !isReceivableDisabled?maxLineNumber:0 }:{} });
    }

    return (
        <TableRow>
            <TableCell>
                <Stack direction="row">
                    <IconButton size={`small`} sx={{ width: '20px' }} onClick={() => handleSave()}>
                        <DoneIcon titleAccess='Save Changes' fontSize="small" color="success" />
                    </IconButton>
                    <IconButton size={`small`} sx={{ width: '20px' }} onClick={() => hideNewRow(false)}>
                        <CloseIcon titleAccess='Cancel Changes' fontSize="small" sx={{ color: 'red' }} />
                    </IconButton>
                </Stack>
            </TableCell>
            <TableCell>
                <RatesAutoComplete
                    valueChange={(val) => {
                        if (val !== null) {
                            setRecievablesRow(!isReceivableDisabled?{
                                ...receivablesRow,
                                description: val.invoiceDesc,
                                destinationCity: val.destinationCity,
                                destinationStatePrefix: val.destinationState,
                                glCode: val.GLCode,
                                glDescription: val.GLDesc,
                                notes: val.notes,
                                originCity: val.originCity,
                                originName: val.originCity,
                                originPrefix: val.originState,
                                unitRate: val.receivableRates,
                                amount: val.receivableRates
                            }:{})
                            if (unPayableGLCodes.includes(val.GLCode)) {
                                setPayableRow({})
                            } else {
                                setPayableRow(!isPayableDisabled?{
                                    ...payableRow,
                                    description: val.invoiceDesc,
                                    destinationCity: val.destinationCity,
                                    destinationStatePrefix: val.destinationState,
                                    glCode: getPayableGlCode(orderType, val.GLCode),
                                    glDescription: val.GLDesc,
                                    notes: val.notes,
                                    originCity: val.originCity,
                                    originName: val.originCity,
                                    originPrefix: val.originState,
                                    unitRate: val.receivableRates,
                                    units: getParentPayableUnit(val.GLCode, val.payableRatioBroker, val.payableRatioContract),
                                    amount: getParentPayableRate(val.GLCode, val.payableRateBroker, val.payableRateContract)
                                }:{})
                            }
                        }
                    }
                    }
                    existingValue={receivablesRow.notes}
                    lineAccount={tableType}
                    isDisabled={isReceivableDisabled && isPayableDisabled?true:false}
                    orderType={orderType}
                    ordersLocation={ordersLocation}
                    edi210={edi210}
                />
            </TableCell>
            <TableCell>
                <GLCodesAutoComplete
                    valueChange={(val) => {
                        if (val !== '') {
                            setRecievablesRow({
                                ...receivablesRow,
                                glCode: val
                            });
                            if (unPayableGLCodes.includes(val)) {
                                setPayableRow({})
                            } else {
                                !isPayableDisabled?
                                setPayableRow({
                                    ...payableRow,
                                    glCode: getPayableGlCode(orderType, val),
                                })
                                :setPayableRow({})
                            }

                        } else if (val === '') {
                            setPayableRow({
                                ...payableRow,
                                glCode: '',
                            })
                        }
                    }
                    }
                    existingValue={receivablesRow.glCode}
                    isDisabled={isReceivableDisabled}
                    lineAccount={tableType}
                    orderType={orderType}
                    edi210={edi210}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type={`text`}
                    variant="standard"
                    value={receivablesRow.description}
                    onChange={(e, val) => setRecievablesRow({
                        ...receivablesRow,
                        description: e.target.value
                    })}
                    disabled={isReceivableDisabled}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type={`number`}
                    variant="standard"
                    value={receivablesRow.unitRate}
                    onChange={(e, val) => setRecievablesRow({
                        ...receivablesRow,
                        unitRate: e.target.value,
                        amount: calAmount(e.target.value, receivablesRow.units)
                    })}
                    disabled={isReceivableDisabled}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type={`number`}
                    variant="standard"
                    value={receivablesRow.units}
                    onChange={(e, val) => setRecievablesRow({
                        ...receivablesRow,
                        units: e.target.value,
                        amount: calAmount(receivablesRow.unitRate, e.target.value)
                    })}
                    disabled={isReceivableDisabled}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type={`number`}
                    variant="standard"
                    value={receivablesRow.amount}
                    // onChange={(e, val) => setRecievablesRow({
                    //     ...receivablesRow,
                    //     amount: e.target.value
                    // })}
                    disabled={true}
                />
            </TableCell>
            <TableCell>
                <TextField
                    type={`text`}
                    variant="standard"
                    value={payableRow.description}
                    onChange={(e, val) => setPayableRow({
                        ...payableRow,
                        description: e.target.value
                    })}
                    disabled={isPayableDisabled}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                />
            </TableCell>
            <TableCell>
                {isReceivableDisabled?
                (
                    <GLCodesAutoComplete
                    valueChange={(val) => {
                        if (val !== '') {
                            
                            if (unPayableGLCodes.includes(val)) {
                                setPayableRow({})
                            } else {
                                setPayableRow({
                                    ...payableRow,
                                    glCode: val
                                })
                            }

                        } else if (val === '') {
                            setPayableRow({
                                ...payableRow,
                                glCode: '',
                            })
                        }
                    }
                    }
                    existingValue={payableRow.glCode}
                    isDisabled={isPayableDisabled}
                    lineAccount={tableType}
                    orderType={orderType}
                    edi210={edi210}
                    payOrRec='P'
                />
                ):(
                <TextField
                    type={`text`}
                    variant="standard"
                    value={payableRow.glCode}
                    onChange={(e, val) => setPayableRow({
                        ...payableRow,
                        glCode: e.target.value
                    })}
                    disabled={true}
                />
                )}
            </TableCell>
            <TableCell>
                <TextField
                    type={`number`}
                    variant="standard"
                    value={payableRow.unitRate}
                    onChange={(e, val) => setPayableRow({
                        ...payableRow,
                        unitRate: e.target.value,
                        amount: calAmount(e.target.value, payableRow.units)
                    })}
                    disabled={isPayableDisabled}
                ></TextField>
            </TableCell>
            <TableCell>
                <TextField
                    type={`number`}
                    variant="standard"
                    value={payableRow.units}
                    onChange={(e, val) => setPayableRow({
                        ...payableRow,
                        units: e.target.value,
                        amount: calAmount(payableRow.unitRate, e.target.value)
                    })}
                    disabled={isPayableDisabled}
                ></TextField>
            </TableCell>
            <TableCell>
                <TextField
                    type={`number`}
                    variant="standard"
                    value={payableRow.amount}
                    onChange={(e, val) => setPayableRow({
                        ...payableRow,
                        amount: e.target.value
                    })}
                    disabled={isPayableDisabled}
                ></TextField>
            </TableCell>
            <TableCell>
                {/* <TextField
                    type={`text`}
                    variant="standard"
                    value={payableRow.truckNumber}
                    onChange={(e, val) => setPayableRow({
                        ...payableRow,
                        truckNumber: e.target.value
                    })}
                    disabled={false}
                    inputProps={{ maxLength: 5 }}
                ></TextField> */}
                <TrucksAutoComplete
                    valueChange={(val) => {
                        if (val !== '') {
                            setPayableRow({
                                ...payableRow,
                                truckNumber: val,
                            })

                        } else if (val === '') {
                            setPayableRow({
                                ...payableRow,
                                truckNumber: '',
                            })
                        }
                    }
                    }
                    existingValue={payableRow.truckNumber}
                    isDisabled={isTemplateScreen || isPayableDisabled ? true : false}
                />
            </TableCell>
            <TableCell>
                <CustomDatePicker
                    variant="standard"
                    value={payableRow?.processedDate || ''}
                    onValueChange={(val) => setPayableRow({
                        ...payableRow,
                        processedDate: val
                    })}
                    disabled={isTemplateScreen || isPayableDisabled ? true : false}
                />
            </TableCell>

            <TableCell>

            </TableCell>
        </TableRow>
    )
}
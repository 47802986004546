import { Provider } from 'react-redux';
import { MainRouter } from './scenes/MainRouter';
import store from './store/index';
import { BrowserRouter as Router } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-license-pro';

/**
 * Expired license keys:
 * @returns 
 * - 14 October, 2024
 * - c0981cce56b374bf661c85d6fc240e04Tz03NDYzMSxFPTE3MjYyNjY0MTQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y 
 */
LicenseInfo.setLicenseKey('eb3557e56cdf87276dd8cd19a1c9de9cTz05ODQxNixFPTE3NTgzMDk4MTMwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');

function App() {
  return (
    <Provider store={store}>
      <Router>
        <MainRouter />
      </Router>
    </Provider>
  );
}

export default App;

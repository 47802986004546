import { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import { useParams } from "react-router-dom";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import CopyOrderModal from '../../scenes/Orders/components/CopyOrderModal';
import CopyTemplateModel from './CopyTemplateModel';

export default function CopyOrderMenu(props) {


  const { anchorEl, open, setAnchorEl, currentOrderData,currentOrderDataTemplate, handleModalSubmitButton, reqParams ,templateTableApiCall,isTemplateScreenReload} = props;


  const [showCopyOrderModal, setShowCopyOrderModal] = useState(false);
  const [isCopyOrderLoading, setIsCopyOrderLoading] = useState(false);
  const [showCopyTemplateModal, setShowCopyTemplateModal] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopyOrder = () => {
    handleClose();
    setShowCopyOrderModal(true)

  }
  const handleCopyTemplate = () => {
    handleClose();
    setShowCopyTemplateModal(true)
  }

  return (
    <>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCopyOrder}>Copy to Order</MenuItem>
        <MenuItem onClick={handleCopyTemplate}>Copy to Template</MenuItem>
      </Menu>

      {
        showCopyOrderModal == true ?
          <CopyOrderModal
            openCO={showCopyOrderModal}
            closeCO={() => setShowCopyOrderModal(false)}
            currentOrderData={currentOrderData}
            handleModalSubmitButton={handleModalSubmitButton}
            isCopyOrderLoading={isCopyOrderLoading}
            templateTableApiCall={templateTableApiCall}
            isTemplateCopyOrder={true}
          />
          : null

      }

      {
        showCopyTemplateModal === true ?
          <CopyTemplateModel
            openCO={showCopyTemplateModal}
            reqParams={reqParams}
            currentOrderData={currentOrderDataTemplate}
            closeCO={() => setShowCopyTemplateModal(false)}
            templateTableApiCall={templateTableApiCall}
            isTemplateScreenReload={isTemplateScreenReload}
          />
          : null

      }

    </>
  )
}

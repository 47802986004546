import {reportTypes } from "../types/reportTypes"
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';
import {mapToPTReportTable } from "../../scenes/Reports/PTReport/component/commonFn";



export const getPTReportData = (params:any) => {
    return async (dispatch: Dispatch) => {
        const res = await PTReportApiReq( params);
        dispatch(setReport(res));
        return res;
    };
}

const setReport = (apiData: any) => {
    return {
        type: reportTypes.REPORT_DATA,
        payload: apiData,
    };
};

export const PTReportApiReq = async (params: any) => {
    const {locationNumber,payType,transmitted,isTruckNull,isPayDateNull,truckNumber,vendor,sortBy,sortOrder,page,size,fromProcessDate,toProcessDate}=params
    try {
        // let url=`/reports/pt-report/${locationNumber}?payType=${payType}&isTruckNull=${isTruckNull}&isPayDateNull=${isPayDateNull}&sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&size=${size}`
        let url=`/reports/pt-report/${locationNumber}?payType=${payType}&isTruckNull=${isTruckNull}&isPayDateNull=${isPayDateNull}&sortBy=${sortBy}&sortOrder=${sortOrder}`
        if(transmitted==="N"){
            url+=`&transmitted=${transmitted}`
        }
        if(truckNumber){
            url+=`&truckNumber=${truckNumber}`
        }
        if(vendor){
            url+=`&vendor=${vendor}`
        }
        if(fromProcessDate &&toProcessDate){
            url+=`&fromProcessDate=${fromProcessDate}&toProcessDate=${toProcessDate}`
        }
        let res = await GET(url)
    
      
        if (res) {
            let data = mapToPTReportTable(res?.data);
            return {data:data,totalRows:res?.data?.totalRows};
        }
        return [];
    } catch (err) {
        return [];
    }
}

export const getTruckList = (locationNumber: any) => {
    return async (dispatch: Dispatch) => {
        try {
            let res = await GET(`/trucks?location=${locationNumber}`);
            if (res ) {
                return res;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };
};



export const getVendorList = (locationNumber: any) => {
    return async (dispatch: Dispatch) => {
        try {
            let res = await GET(`/vendors/${locationNumber}`);
            if (res && res.httpCode===200 &&res.data ) {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };
};
export const getVendorListSearch = (params: any) => {
    return async (dispatch: Dispatch) => {
        try {
            let res = await GET(`/vendors/${params.locationNumber}?vendorNumber=${params.searchVal}`);
            if (res && res.httpCode===200 &&res.data ) {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };
};

export const getBillingReportData = (locationNumber: number, query:any) => {
    return async (dispatch: Dispatch) => {
        try {
            let url = `reports/billing-summary-report/${locationNumber}`
            
            if (typeof query?.sortOrder !== 'undefined' && typeof query?.sortBy !== 'undefined') {
                url += `&sortOrder=${query.sortOrder}&sortBy=${query.sortBy}`;
            }else{
                url += `?sortOrder=desc&sortBy=control`;
            }

            if (typeof query?.page !== 'undefined' && query?.pageSize !== 0) {
                url += `&page=${Number(query.page) + Number(1)}&pageSize=${query.pageSize}`;
            } 

            if( query.dateType !== '' && query.dateRange.length === 2 && query.dateRange[0] !== null && query.dateRange[1] !== null){
                url+=`&dateType=${query.dateType}&fromDate=${query.dateRange[0]}&toDate=${query.dateRange[1]}`
            }

            if (typeof query?.billTo !== 'undefined' && query?.billTo !== '') {
                url += `&billTo=${query.billTo}`;
            }

            if (typeof query?.orderBy !== 'undefined' && query?.orderBy !== '') {
                url += `&orderbyCode=${query.orderBy}`;
            }

            let res = await GET(url);
            if (res && res.httpCode === 200 && res.data ) {
                return res.data;
            } else {
                return [];
            }
        } catch (err) {
            return [];
        }
    };
};


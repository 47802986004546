import { loadsTypes } from "../types/loadsTypes";

const initialState = {
    selectedLoad: undefined,
    loadsList: [],
    loadsMap: {},
    loadDetails: undefined,
    isLoading: false,
    driverList: [],
    driverInfo: undefined,
    locationList: [],
    loadSearchValues: { container: '', driverId: '', unassignedDate: '', available: '', locationId: '' },
    errorHandlerData: { status: '', statusText: '' },
    truckDriverInfoDetail: {},
    toggleStatus: '',
    submitSearchStatus:'',
    screenStatus:true,
    tenderTypeStatus:'',
    }

export const loadsReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case loadsTypes.SET_LOAD_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case loadsTypes.SELECT_LOAD:
            return {
                ...state,
                selectedLoad: state.loadsMap[payload]
            }
        case loadsTypes.SET_DRIVERS:
            return {
                ...state,
                driverList: payload
            }
        case loadsTypes.SET_DRIVER_INFO:
            return {
                ...state,
                driverInfo: payload
            }
        case loadsTypes.SET_LOCATIONS:
            return {
                ...state,
                locationList: payload
            }
        case loadsTypes.SET_LOAD_DETAILS:
            return {
                ...state,
                loadDetails: payload
            }
        case loadsTypes.SET_LOAD:
            return {
                ...state,
                loadsList: payload,
                loadsMap: payload.reduce((acc, load) => {
                    acc[load.controlNumber] = load;
                    return acc;
                }, {})
            }
        case loadsTypes.SET_SEARCH_VALUES:
            return {
                ...state,
                loadSearchValues: payload
            }
        case loadsTypes.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorHandlerData: payload
            }
        case loadsTypes.GET_TRUCK_DRIVER_INFO:
            return {
                ...state,
                truckDriverInfoDetail: payload
            }
        case loadsTypes.DRIVER_TOGGLE:
            return {
                ...state,
                toggleStatus: payload
            }
        case loadsTypes.SUBMIT_SEARCH:
            return {
                ...state,
                submitSearchStatus: payload
            }
            case loadsTypes.SCREEN_CHANGE:
                return {
                    ...state,
                    screenStatus: payload
                }
                case loadsTypes.REMOVE_TENDER_TYPE:
                return {
                    ...state,
                    tenderTypeStatus: payload.message
                    }
                case loadsTypes.ORDER_BY_TYPE:
                return {
                    ...state,
                    orderByLookup: payload.message
                    }
        default:
            return state;
    }
};
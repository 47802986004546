import config from '../config/config';
import Axios from 'axios';
import { getRefreshToken, setToken, deleteItem } from './cookie';
const URL2 = config.api.URL2;

const axiosInstance = Axios.create({
    baseURL: URL2,
    headers: {
        'Cache-control': 'no-cache, no-store',
        'Pragma': 'no-cache',
        'Content-Type': 'application/json',
    },
    // timeout: 10000,
    transformRequest: [
        function (data, headers) {
            headers['Expires'] = '0';
            return JSON.stringify(data);
        },
    ],
    transformResponse: [
        function (data, headers) {

            if (headers['content-type'].indexOf('application/json') > -1) {
                const json = JSON.parse(data);
                return json;
            }
            return data;
        },
    ],
});

axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // if (error?.response?.status === 401 || error?.response?.status === 403) {
    //     return logoutInternal();
    // }
    // // const errObj = { status: 'ErrorCode: ' + error.response.status, statusText: ', ErrorMessage: ' + error.response.statusText + ', ApiUrl: ' + error.response.config.url };
    // const errObj = { status: 'ErrorCode: ' + 500, statusText: ', ErrorMessage: ' + 'Internal Server Error!!!' };
    // dispatch(setErrorData(errObj));
    // dispatch(setErrorData1(errObj));
    if (error?.response?.status === 500) return;
    throw new Error();
})

export const GET = async (url) => {
    const res = await axiosInstance.get(url);
    return res?.data;
};

export const POST = async (url, data) => {
    const res = await axiosInstance.post(url, data);
    return res?.data;
};

export const PUT = async (url, data) => {
    const res = await axiosInstance.put(url, data)
    return res?.data;
};

export async function refreshAccessToken() {
    try {
        const response = await POST('/getNewAccessToken', { refreshToken: getRefreshToken() });
        if (response) {
            //deleteItem('auth');
            setToken(response.accessToken, response.refreshToken);
            return response.accessToken;
        }
    } catch (error) {
        return false;
    }
}
export const getToken = () => {
    const expiredToken = localStorage.getItem('auth');
    if (!expiredToken) return null;
    const item = JSON.parse(expiredToken);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem('auth')
        return null
    }
    return item.token;
    // return cookie.load('auth');
}

export const getRefreshToken = () => {
    const expiredToken = localStorage.getItem('auth');
    const item = JSON.parse(expiredToken);
    if (item?.refreshToken) {
        return item.refreshToken;
    }
    return null
}

export const getUserFromCookie = () => {
    try {
        if (!getToken()) return;
        const user = JSON.parse(atob(getToken().split('.')[1]));
        return user;
    }
    catch (err) {
        console.error(err);
    }
}

export const setToken = (token, refreshToken) => {
    const TTL = 1000 * 60 * 60 * 24;
    const now = new Date();
    const expiredToken = {
        token,
        refreshToken: refreshToken,
        expiry: now.getTime() + TTL
    }
    localStorage.setItem('auth', JSON.stringify(expiredToken));
    // cookie.save('auth', token, { path: '/' });
}

export const logoutInternal = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('userSelectedLocation')
    localStorage.removeItem('AppliedElasticFilter')
    // cookie.remove('auth');
    window.location = '/';
}

export const setItem = (key, item, ttl) => {
    const TTL = 1000 * 60 * 60 * 24 * ttl
    const payload = {
        payload: item,
        expiry: (new Date().getTime + TTL)
    }
    localStorage.setItem(key, JSON.stringify(payload))
}

export const getItem = (key) => {
    const item = localStorage.getItem(key);
    console.log('item', item)
    if (!item) return null;
    const payload = JSON.parse(item);
    const now = new Date();

    if (now.getTime() > payload.expiry) {
        // If the item is expired, delete the item from storage
        localStorage.removeItem(key)
        console.log('item expired')
        return null
    }
    console.log('item payload', payload.payload)

    return payload.payload;
}

export const deleteItem = (key) => {
    localStorage.removeItem(key)
}
import { useRef, useLayoutEffect } from 'react';
import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

export const PhoneNumberMask = ({ value, onChange, hasFocus, className, size, label }) => {
    const ref = useRef();
    
    useLayoutEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <InputMask
            mask="999-999-9999"
            value={value}
            onChange={onChange}
        >
            {inputProps => (
                <TextField
                    {...inputProps}
                    inputRef={ref}
                    className={className}
                    size={size}
                    label={label}
                />
            )}
        </InputMask>
    );
};

export const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2") || '';
};

import { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormControlLabel,
    Button,
    Box,
    Tabs,
    Tab,
    Paper,
    Grid,
    Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

import templatedefaultoption from './templatedefaultoption.json'
import { TextField } from '@material-ui/core';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useHistory } from 'react-router-dom';
import { getTemplateData, saveTemplate ,searchTemplate} from '../../store/actions/templateAction';
import { isConstructorDeclaration } from 'typescript';

import {  mapOrderToTemplateToServer } from "../../scenes/Templates/components/commonFn";
import { useParams } from "react-router-dom";
export default function CopyTemplateModel(props) {
    const { openCO, closeCO, currentOrderData, reqParams,templateTableApiCall ,isTemplateScreenReload} = props;

    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);
    const [textInput, setTextInput] = useState('');
    const [errorText, setErrorText] = useState(false);
    const [checked, setChecked] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('');
    const [templateNameError, setTemplateNameError] = useState('');

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );


    const handleSubmit = () => {

    }


    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const handleClose = (event) => {
        setSnackOpen(false);
    };

    // const mapTemplateToServer = (item) => {


    //     let postObj = {
    //         templateName: textInput,
    //         orderCreateDate: "",
    //         locationNumber:Number(reqParams.locationNumber)  || "",
    //         controlNumber:Number(reqParams.controlNumber)  || "",
    //         sequenceNumber: "",
    //         unitNumber: item?.equipmentDetails?.unitNumber || "",
    //         chassisNumber: item?.equipmentDetails?.chassisNumber || "",
    //         shipperAgentCode: item?.orderBy?.shipperAgentCode || "",
    //         shipperAgent: item?.orderBy?.shipperAgent || "",
    //         shipperOrAgentLoc: item?.orderBy?.shipperOrAgentLoc || "",
    //         shipperPhone: item?.shipper?.shipperPhone || "",
    //         shipperFax: item?.shipper?.shipperFax || "",
    //         releaseNumber: "",
    //         referenceNumber: "",
    //         customerNumber: item?.orderBy?.customerNumber || "",
    //         shipperOrConsShipper: item?.shipper?.shipperOrConsShipper || "",
    //         shipper: item?.shipper?.shipper || "",
    //         shipperPONumber: "",
    //         shipperAddress: item?.shipper?.shipperAddress || "",
    //         shipperCity: item?.shipper?.shipperCity || "",
    //         shipperState: item?.shipper?.shipperState || "",
    //         shipperZip: item?.shipper?.shipperZip || "",
    //         shipperAgentPhone: item?.orderBy?.shipperAgentPhone || "",
    //         shipperAgentFax: item?.orderBy?.shipperAgentFax || "",
    //         status: item?.orderBy?.status || "",
    //         hazMat: item?.equipmentDetails?.hazMat || "",
    //         shipperOrConsignee: "",
    //         consignee: item?.consignee?.consignee || "",
    //         consigneeAddress: item?.consignee?.consigneeAddress || "",
    //         consigneeCity: item?.consignee?.consigneeCity || "",
    //         consigneeState: item?.consignee?.consigneeState || "",
    //         consigneeZip: item?.consignee?.consigneeZip || "",
    //         consigneePhone: item?.consignee?.consigneePhone || "",
    //         consigneeFax: item?.consignee?.consigneeFax || "",
    //         createdDate: "",
    //         changeUser: "",
    //         changedDate: "",
    //         createUser: "",
    //         preNoteDate: "",
    //         preNoteTime: "",
    //         consigneePONumber: "",
    //         notifyDate: item?.pickup?.notifyDate || "",
    //         notifyTime: item?.pickup?.notifyTime || "",
    //         pickDate: item?.pickup?.pickDate || "",
    //         poruDate: item?.pickup?.poruDate || "",
    //         poruTime: item?.pickup?.poruTime || "",
    //         poruPoint: item?.pickup?.poruPoint || "",
    //         returnDate: item?.pickup?.returnDate || "",
    //         returnTime: item?.pickup?.returnTime || "",
    //         vessel: item?.equipmentDetails?.vessel || "",
    //         voyageNumber: item?.equipmentDetails?.voyageNumber || "",
    //         equipSize: item?.equipmentDetails?.equipSize || "",
    //         remarks1: "",
    //         remarks2: "",
    //         disposition: item?.pickup?.disposition || "",
    //         cuttOffDate: item?.pickup?.cuttOffDate || "",
    //         cutOffTime: item?.pickup?.cutOffTime || "",
    //         etaDate: item?.pickup?.etaDate || "",
    //         etaTime: item?.pickup?.etaTime || "",
    //         secondaryUnit: item?.equipmentDetails?.secondaryUnit || "",
    //         stops: item?.stops || [],
    //         orderSequenceNumber: "",
    //         orderType: item?.orderType || "",
    //         billingExtSeq: "",
    //         billingRelease: " ",
    //         billingReference: item?.orderBy?.billingReference || "",
    //         billingBooking: item?.equipmentDetails?.billingBooking || "",
    //         billingShipperPO: item?.shipper.billingShipperPO || "",
    //         billingConsigneePO: item?.consignee?.billingConsigneePO || "",
    //         billingDate: item?.orderBy?.billingDate || " ",
    //         rates:
    //         {
    //             "linehaul": (item?.ratesCharges || []).filter(item => item?.accountLine === "L") || [],
    //             "accessorial": (item?.ratesCharges || []).filter(item => item?.accountLine === "A") || [],
    //         },
    //         dispositionCSeq: "",
    //         dispositionCChassisPickupPoint: item?.pickup?.dispositionCChassisPickupPoint || "",
    //         dispositionCChassisDisposition: item?.pickup?.dispositionCChassisDisposition || "",
    //         dispositionCDestinationPort: item?.equipmentDetails?.dispositionCDestinationPort || ""
    //     }

    //     return postObj;

    // }
    function clearPayablesProcessedDateAndTruckNumber(item) {
        // Create a copy of the item object
        const newItem = { ...item };
    
        // Check if linehaul exists and iterate through its charges
        if (newItem.ratesCharges.linehaul) {
            newItem.ratesCharges.linehaul.forEach(charge => {
                // Check if payable object exists
                if (charge.payable) {
                    // Clear processedDate and truckNumber
                    charge.payable.processedDate = '';
                    charge.payable.truckNumber = '';
                    if (charge.payable.subItems) {
                        charge.payable.subItems.forEach(subItemCharge => {
                            // Check if payable object exists
                            subItemCharge.processedDate = '';
                            subItemCharge.truckNumber = '';
                          
                           subItemCharge.splitItems.forEach(subItemSplitCharge => {
                            subItemSplitCharge.processedDate = '';
                            subItemSplitCharge.truckNumber = '';
                           })
                        })}
                        if (charge.payable.splitItems) {
                            charge.payable.splitItems.forEach(splitItemCharge => {
                                // Check if payable object exists
                                splitItemCharge.processedDate = '';
                                splitItemCharge.truckNumber = '';
                              
                            })}
                    
                }
            });
        }
    
        // Check if accessorial exists and iterate through its charges
        if (newItem.ratesCharges.accessorial) {
            newItem.ratesCharges.accessorial.forEach(charge => {
                // Check if payable object exists
                if (charge.payable) {
                    // Clear processedDate and truckNumber
                    charge.payable.processedDate = '';
                    charge.payable.truckNumber = '';
                    if (charge.payable.subItems) {
                        charge.payable.subItems.forEach(subItemCharge => {
                            // Check if payable object exists
                            subItemCharge.processedDate = '';
                            subItemCharge.truckNumber = '';
                           
                           subItemCharge.splitItems.forEach(subItemSplitCharge => {
                            subItemSplitCharge.processedDate = '';
                            subItemSplitCharge.truckNumber = '';
                           })
                        })}
                        if (charge.payable.splitItems) {
                            charge.payable.splitItems.forEach(splitItemCharge => {
                                // Check if payable object exists
                                splitItemCharge.processedDate = '';
                                splitItemCharge.truckNumber = '';
                               
                            })}
                    
                }
            });
        }
    
        // Return the modified item
        return newItem;
    }

    const handleSaveTemplate = () => {
        if (textInput.trim().length !== 0) {
            const encodedTemplateName=encodeURIComponent(textInput.trim())
            setSnackOpen(true)
            setErrorText(false)
            setSnackContent({ severity: "info", message: "Saving Template ..." })
            let reqParams = {
                locationNumber: params.locationNumber === undefined ? ordersLocation?.locationNumber : params.locationNumber,
                controlNumber: params.controlNumber === undefined ? currentOrderData?.controlNumber : params.controlNumber,
            }
            console.log("currentOrderData",currentOrderData)
        const clearPtDateTruckNo=clearPayablesProcessedDateAndTruckNumber(currentOrderData)

            let postObj = mapOrderToTemplateToServer(clearPtDateTruckNo, reqParams, encodedTemplateName)
        
           checkExistingorNot(reqParams.locationNumber,postObj,encodedTemplateName)

        } else {
            setErrorText(true)
        }

    }
    

    const saveAPICall = (locationNumber,postObj,encodedTemplateName) => {
        
        dispatch(saveTemplate(locationNumber, encodedTemplateName, postObj)).then((res) => {
           
            if (res?.httpCode === 200 && res?.message === 'Order Created successfully.') {
                setSnackOpen(true)
                setSnackContent({ severity: "success", message: res?.message })
                if(isTemplateScreenReload){
                    templateTableApiCall()
                };
                if (checked !== false) {
                    closeCO();
                    // history.replace("/templates");
                    history.replace({ pathname: "/templates", state: { templateName: encodedTemplateName } });

                } else {
                    closeCO();
                }
              

            } else {
                
                setSnackOpen(true)
                setSnackContent({ severity: "error", message: res?.message })
                closeCO();
            }
        });
    }

    // const checkExistingorNot = (locationNumber,postObj,encodedTemplateName) => {

    //     dispatch(getTemplateData(locationNumber)).then((res) => {
    //         console.log("res----",res)

    //         if (res?.data?.hits?.length > 0) {
    //             let data = res?.data?.hits 
    //             let checkTemplate=data.filter(item=> item?._source?.templateName === encodedTemplateName )
    //             setSnackOpen(false)
    //             if(checkTemplate?.length !== 0){
    //                 let confirmAlert = window.confirm( textInput +' already existing');
    //                 if(confirmAlert){
    //                     setTextInput('')
    //                 }
    //             }else{
    //                 //  saveAPICall(postObj,encodedTemplateName)
    //             }


    //         } else {
        
    //         }
    //     });
    // }
    const checkExistingorNot = async (locationNumber,post,encodedTemplateName, query = { page: 1, pageSize: 50 }) => {
        try {
            let postParams = [
                {
                    clause: "AND",
                    field: "locationNumber",
                    operand: "IS",
                    value: locationNumber
                },
                {
                    clause: "OR",
                    field: "templateName",
                    operand: "IS",
                    value: encodedTemplateName
                }
            ];
    
            const response = await dispatch(searchTemplate({
                postParams, 
                query
            }));
    
            if (response?.data?.hits?.length > 0) {
                let confirmAlert = window.confirm(textInput + ' already exists. Do you want to proceed?');
                if (!confirmAlert) {
                    setTextInput('')
                    setSnackOpen(false)
           setSnackContent("")
                } else {
                    saveAPICall(locationNumber,post,encodedTemplateName)
                }
            } else {
                saveAPICall(locationNumber,post,encodedTemplateName)// No existing template found, so it's safe to proceed
            }
        } catch (error) {
            console.log("error",error)
            setSnackContent({ severity: "error", message: "Error checking template existence" })
            // console.error("Error checking template existence:", error);
            
        }
    };

    const handleTextInputChange = event => {
        const inputValue = event.target.value;
        // eslint-disable-next-line
        const forbiddenCharacters = /[\!\(\)\[\]\*\"\/\\\#\?]/;
    
        if (forbiddenCharacters.test(inputValue)) {
            const forbiddenCharacter = inputValue.match(forbiddenCharacters)[0];
            setTemplateNameError(`Input contains forbidden character: ${forbiddenCharacter}` );
         
        } else {
            setTemplateNameError('');
            setTextInput(inputValue);
        }
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={`sm`}
                open={openCO} onClose={closeCO}>
                <DialogTitle sx={{ textAlign: 'center', color: '#fff', backgroundColor: '#0911A7' }}>Copy Template</DialogTitle>
                <DialogContent>
                    <Box>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={(e, newVal) => setValue(newVal)} aria-label="basic tabs example">
                                <Tab label="Template Details" {...a11yProps(0)} />
                                <Tab label="Default Fields" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                    </Box>
                    <TabPanel value={value} index={0}>

                        <div style={{ width: '100%', height: '70px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <label>Template Name</label>
                            <div style={{ width: '70%', height: '40px', display: 'flex', alignItems: 'center' }}>
                                <TextField variant="outlined" size="small" style={{ width: '100%', height: '35px' }}
                                    value={textInput}
                                    onChange={handleTextInputChange} />
                                     {templateNameError && <div style={{ color: 'red' }}>{templateNameError}</div>}

                            </div>

                        </div>
                        <div style={{ width: '100%', justifyContent: 'center', alignItems: 'flex-end', display: 'flex', paddingLeft: '40px' }}>
                            {
                                errorText === true ?
                                    <label style={{ fontSize: '13px', color: 'red' }}>Please Enter Template Name</label>
                                    : null
                            }

                        </div>

                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <FormControlLabel control={<Checkbox onChange={handleChange} />} label="Take me to Templates" />
                        </div>

                    </TabPanel>


                    <TabPanel value={value} index={1} >
                        <Paper elevation={2}>
                            <Grid container sx={{ m: 1 }}>
                                {templatedefaultoption.defaultFields.map((d_val, d_ind) => {
                                    return (
                                        <Grid item xs={6} key={d_ind}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={true} disabled />
                                                }
                                                label={d_val.label}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Paper>
                    </TabPanel>


                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCO} variant="outlined" size="small" color="primary">Cancel</Button>
                    <Button onClick={handleSaveTemplate} variant="contained" size="small" color="primary">Save</Button>
                </DialogActions>
                
                < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
                <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>

            </Dialog>

        
        </>

    )
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}
import React, { useCallback, useState, useEffect } from "react";
import {
  Grid,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Paper,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core";
import { TenderWrapper } from "../styledComponents";
import { Option } from "../Option/Option";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import FlagIcon from "@material-ui/icons/Flag";
import PropTypes from "prop-types";
import TenderModal from "./Modal/TenderModal";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoadReq,
  getPOD,
  unTenderType,
  searchLoads,
} from "../../store/actions/loadsActions";
import { DateTime } from "luxon";
import Draggable from "react-draggable";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const getFullAdress = (location) => {
  if (!location) return "";
  const {
    code = "",
    name = "",
    address1 = "",
    city = "",
    state = "",
    zip = "",
  } = location;
  const address = [name, address1, city, state]
    .filter((word) => word)
    .join(", ");
  return address;
};

const replaceNullWithHypen = (fieldValue) => {
  return fieldValue !== 0 && fieldValue !== null ? fieldValue : "-";
};
const getMoreDetails = (details) => {
  if (!details) return [];
  return [
    {
      options: [
        {
          description: "chassis",
          label: details["chassis"] || "-",
        },
        {
          description: "reference",
          label: details["reference"] || "-",
        },
      ],
    },
    {
      options: [
        {
          description: "release",
          label: details["release"] || "-",
        },
        {
          description: "booking",
          label: details["booking"] || "-",
        },
      ],
    },
    {
      options: [
        {
          description: "vessel",
          label: details["vessel"] || "-",
        },
        {
          description: "weight",
          label: details["weight"] || "-",
        },
      ],
    },
  ];
};

export const friendlyLoadStatus = (s) =>
  s === "N"
    ? "New"
    : s === "A"
    ? "Active"
    : s === "B"
    ? "Billed"
    : s === "M"
    ? "Manifest Billed"
    : s === "P"
    ? "P"
    : s === "V"
    ? "Void"
    : s === "C"
    ? "Complete"
    : `INVALID STATUS: ${s}`;

const friendlyTenderType = (t) =>
  t === ""
    ? "None"
    : t === null
    ? "None"
    : t === undefined
    ? "None"
    : t === "RT"
    ? "Round Trip"
    : t === "RP"
    ? "Round Trip"
    : t === "DE"
    ? "Drop/Pull"
    : t === "PE"
    ? "Drop/Pull"
    : t === "DL"
    ? "Drop/Pull"
    : t === "PL"
    ? "Drop/Pull"
    : `INVALID TENDER TYPE: ${t}`;

const friendlyStopStatus = (s) =>
  s === ""
    ? "None"
    : s === undefined
    ? "None"
    : s === null
    ? "None"
    : s === "A"
    ? "Assigned"
    : s === "D"
    ? "Dispatched"
    : s === "T"
    ? "Tendered"
    : s === "C"
    ? "Complete"
    : `INVALID STATUS: ${s}`;

const friendlyTenderStatus = (s) =>
  s === ""
    ? "None"
    : s === undefined
    ? "None"
    : s === null
    ? "None"
    : s === "O"
    ? "Open"
    : s === "A"
    ? "Accepted"
    : s === "C"
    ? "Complete"
    : s === "T"
    ? "Timed Out"
    : `INVALID TENDER STATUS: ${s}`;

const Problem = ({ message }) => (
  <Paper
    style={{ margin: 8, padding: 5, alignContent: "center" }}
    elevation={3}
  >
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={6}>
        <ErrorIcon color="error" />
      </Grid>
      <Grid item xs={6}>
        <Typography align="center" color="error">
          {message}
        </Typography>
      </Grid>
    </Grid>
  </Paper>
);

const RoundTripDriver = ({ stop }) =>
  stop.spotDriverNumber !== stop.returnDriverNumber ||
  stop.spotTruckNumber !== stop.returnTruckNumber ? (
    <Problem message="Stop & Return Drivers/Trucks Do Not Match" />
  ) : (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Option
        size={4}
        description="Driver/Truck"
        label={`${stop.spotDriverNumber}/${stop.spotTruckNumber}`}
      />
      <Option
        size={4}
        description="Status"
        label={friendlyStopStatus(stop.spotStopStatus)}
      />
      {stop.spotTenderStatus && (
        <Option
          size={4}
          description="Tender Status"
          label={friendlyTenderStatus(stop.spotTenderStatus)}
        />
      )}
    </Grid>
  );

const DropPullDriver = ({ stop }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-around"
    alignItems="center"
  >
    <Option
      size={4}
      description="Drop Driver/Truck"
      label={`${stop.spotDriverNumber}/${stop.spotTruckNumber}`}
    />
    <Option
      size={4}
      description="Status"
      label={friendlyStopStatus(stop.spotStopStatus)}
    />
    <Option
      size={4}
      description="Tender Status"
      label={friendlyTenderStatus(stop.spotTenderStatus)}
    />

    <Option
      size={4}
      description="Pull Driver/Truck"
      label={`${stop.returnDriverNumber}/${stop.returnTruckNumber}`}
    />
    <Option
      size={4}
      description="Status"
      label={friendlyStopStatus(stop.returnStopStatus)}
    />
    <Option
      size={4}
      description="Tender Status"
      label={friendlyTenderStatus(stop.returnTenderStatus)}
    />
  </Grid>
);

function DriverTender({ stop }) {
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {stop.tenderType && (
          <Option
            size={12}
            description={"Tender Type"}
            label={friendlyTenderType(stop.tenderType)}
          />
        )}
      </Grid>
      {stop.tenderType === "RT" || stop.tenderType === "RP" ? (
        <RoundTripDriver stop={stop} />
      ) : (
        <DropPullDriver stop={stop} />
      )}
    </div>
  );
}

DriverTender.propTypes = {
  stop: PropTypes.object.isRequired,
};

function Tender({ load, showMoreDetailsBar = false, showLoadStops = false }) {
  const {
    stops = [],
    controlNumber,
    pickup,
    container,
    locationNumber,
    appointmentDate,
    appointmentTime,
    io,
  } = load;
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedStops, setSelectedStops] = useState(stops);
  const [unTenderControlNumber, setunTenderControlNumber] = useState("");
  const [unTenderLocationNumber, setunTenderLocationNumber] = useState("");
  const [unTenderStopNumber, setunTenderStopNumber] = useState("");
  const [unTenderErrMsg, setUnTenderErrMsg] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const select = useCallback(
    (controlNum) => dispatch(selectLoadReq(controlNum, locationNumber)),
    [dispatch]
  );

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const tenderSelectStops = useCallback(
    (stopNumber) => {
      setSelectedStops(
        stopNumber ? [stopNumber] : stops.map((stop) => stop.stopNumber)
      );
      setModalOpen(true);
    },
    [setSelectedStops, stops]
  );

  const dateFormatter = useCallback((tenderDate) => {
    if (!tenderDate) return "-";
    const date = DateTime.fromISO(tenderDate);
    return `${date.month}/${date.day}/${date.year}`;
  }, []);

  const apptDateTime = (date, time) => {
    return date !== undefined
      ? date
      : "" + " " + (time !== undefined)
      ? time
      : "";
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (location, cntrlNum, stop) => {
    setunTenderControlNumber(cntrlNum);
    setunTenderLocationNumber(location);
    setunTenderStopNumber(stop);
    setOpen(true);
  };

  const handleClose = () => {
    setunTenderControlNumber("");
    setunTenderLocationNumber("");
    setunTenderStopNumber("");
    setOpen(false);
  };
  let counter = 0;
  const removeTenderType = async () => {
    counter = counter + 1;
    if (counter == 1) {
      setUnTenderErrMsg("");
      const resMsg = await dispatch(
        unTenderType({
          unTenderLoc: unTenderLocationNumber,
          unTenderCntrlNum: unTenderControlNumber,
          unTenderStopNum: unTenderStopNumber,
        })
      );
        setOpen(false);
      if (resMsg?.message == "Load Untendered successfully!") {
        dispatch(searchLoads(`driverId=${getDriverId}`));
      } else {
        setOpen(false);
        setUnTenderErrMsg(resMsg?.message);
      }
    }
    };

  const getDriverId = useSelector(
    ({ loadsReducer }) => loadsReducer.loadSearchValues.driverId
  );

  return (
    <TenderWrapper
      style={{
        border:
          io === "inbound"
            ? "2px solid rgba(24,0,255,255)"
            : "2px solid rgba(163,116,30,255)",
      }}
    >
      {unTenderErrMsg ? <Problem message={unTenderErrMsg} /> : null}
      <Grid container>
        <Option size={4} description={`Control Number`} label={controlNumber} />
        <Option size={4} description={`Container`} label={container} />
        <Option size={4} description={`Reference`} label={load.reference} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          justifyContent: "space-between",
          background:
            io === "inbound" ? "rgba(24,0,255,255)" : "rgba(163,116,30,255)",
          color: "white",
        }}
      >
        <Option
          size={6}
          labelColor={`white`}
          label={io === "inbound" ? "Delivery" : "Pickup"}
        />
        {load.hazmat === "Y" && (
          <Grid item xs={4}>
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Typography style={{ fontSize: 14 }}>Hazmat</Typography>
              </Grid>
              <Grid item xs={6}>
                <FlagIcon color="error" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid container>
        <Option
          size={6}
          description={"Pickup Point/Origin"}
          label={getFullAdress(pickup)}
        />
        <Option
          size={6}
          description={"Disposition"}
          label={getFullAdress(load.disposition)}
        />
        <Option size={6} description={"Remarks 1"} label={load.remarks1} />
        <Option size={6} description={"Remarks 2"} label={load.remarks2} />
        <Option
          size={6}
          description={"Equipment Size"}
          label={load.equipmentSize}
        />
        {/* // only if present */}
        <Option
          size={6}
          description={"Reloaded Control Number"}
          label={
            load.reloadedControlNumber > 0 ? (
              <a
                href={`details/${load.reloadedControlNumber}/${load.locationNumber}`}
              >
                {load.reloadedControlNumber}
              </a>
            ) : (
              "-"
            )
          }
        />
      </Grid>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="body2" color="textSecondary">
            See more Load details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Option
              size={12}
              description={"Status"}
              label={friendlyLoadStatus(load.status)}
            />
            <Option size={6} description={`Chassis`} label={load.chassis} />
            <Option
              size={6}
              description={`Secondary Container`}
              label={load.secondaryContainer}
            />
            <Option size={6} description={`Release`} label={load.release} />
            <Option size={6} description={`P/U#`} label={load.pu} />
            <Option
              size={6}
              description={"Chassis Pickup"}
              label={getFullAdress(load.chassisPickup)}
            />
            <Option
              size={6}
              description={"Chassis Disposition"}
              label={getFullAdress(load.chassisDisposition)}
            />
            <Option
              size={6}
              description={"Order By"}
              label={
                <span>
                  {load.orderBy.code}, {load.orderBy.name},{" "}
                  {load.orderBy.location}{" "}
                  <a href={`tel:${load.orderBy.phone}`}>{load.orderBy.phone}</a>
                </span>
              }
            />
            {load.io === "inbound" ? (
              <Option
                size={6}
                description={"Shipper"}
                label={getFullAdress({
                  ...load.shipper.location,
                  code: load.shipper.code,
                  name: load.shipper.name,
                })}
              />
            ) : (
              <Option
                size={6}
                description={"Consignee"}
                label={getFullAdress({
                  ...load.consignee.location,
                  code: load.consignee.code,
                  name: load.consignee.name,
                })}
              />
            )}
            <Option size={6} description={"Bill To"} label={load.billTo.code} />
            <Option size={6} description={"Bill To Name"} label={"-"} />
            <Option
              size={6}
              description={"Last Storage Day"}
              label={load.equipmentSize}
            />
            <Option
              size={6}
              description={"Last Per Diem Day"}
              label={load.equipmentSize}
            />
            {/* if international */}
            <Option size={6} description={"Vessel"} label={load.vessel} />
            <Option size={6} description={"Booking"} label={load.booking} />
            <Option size={6} description={"Voyage"} label={load.voyage} />
            <Option
              size={6}
              description={"Destination Port"}
              label={load.destinationPort}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
      {stops && stops.length > 0 && (
        <>
          <Divider />
          {stops.map((stop, i) => (
            <Card style={{ margin: 10 }} key={i} variant="outlined">
              <CardContent>
                <Typography
                  style={{ paddingBottom: 8 }}
                  align="center"
                  variant="subtitle2"
                  color="textPrimary"
                >
                  {" "}
                  Stop {stop.stopNumber}
                </Typography>
                <Divider />
                <Grid
                  container
                  direction="row"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Option
                    size={4}
                    description={`Appt date Time`}
                    label={apptDateTime(
                      dateFormatter(appointmentDate),
                      appointmentTime
                    )}
                  />
                  <Option
                    size={6}
                    description={io === "inbound" ? "Consignee" : "Shipper"}
                    label={`${
                      stop.location.name ? stop.location.name : "N/A"
                    }, ${getFullAdress(stop.location)}`}
                  />
                  <Grid
                    item
                    xs={4}
                    style={{ padding: "10px", display: "flex" }}
                  >
                    {friendlyTenderStatus(stop.returnTenderStatus) ===
                    "None" ? (
                      <Button
                        variant="contained"
                        onClick={() => tenderSelectStops(stop.stopNumber)}
                      >
                        Tender{" "}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() =>
                          handleClickOpen(
                            locationNumber,
                            controlNumber,
                            stop.stopNumber
                          )
                        }
                      >
                        UnTender
                      </Button>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{ padding: "5px", display: "flex" }}
                  ></Grid>
                </Grid>
                <DriverTender stop={stop} />
                <Dialog
                  open={open}
                  onClose={handleClose}
                  PaperComponent={PaperComponent}
                  aria-labelledby="draggable-dialog-title"
                >
                  <DialogTitle
                    style={{ cursor: "move" }}
                    id="draggable-dialog-title"
                  >
                    Alert !!!
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Do you want to remove Tender type?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button autoFocus onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button onClick={removeTenderType}>Yes</Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
              <CardActions>
                <Accordion style={{ width: "100%" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography variant="body2" color="textSecondary">
                        See more Stop {stop.stopNumber} details
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() =>
                          dispatch(
                            getPOD(
                              locationNumber,
                              controlNumber,
                              stop.stopNumber
                            )
                          )
                        }
                      >
                        Download POD
                      </Button>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                      alignItems="baseline"
                    >
                      <Option
                        size={4}
                        description={"PO/PU"}
                        label={stop.popu}
                      />
                      <Option
                        size={4}
                        description={"Release"}
                        label={stop.contact}
                      />
                      <Option
                        size={4}
                        description={"Shipper B/L"}
                        label={stop.shipperBL}
                      />
                      <Option
                        size={4}
                        description={"Pieces"}
                        label={stop.pieces}
                      />
                      <Option
                        size={4}
                        description={"Weight"}
                        label={stop.weight}
                      />
                      <Option size={4} description={"Seal"} label={stop.seal} />
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Option
                        size={4}
                        description={"Estimated Pickup/Delivery"}
                        label={`${replaceNullWithHypen(
                          stop.etaDate
                        )} ${replaceNullWithHypen(stop.etaTime)}`}
                      />
                      {/* <Option size={4} description={'Temp'} label={stop.temp} /> */}
                      {/* <Option size={4} description={'Pulp'} label={stop.pulp} /> */}
                      <Option
                        size={4}
                        description={"Note 1"}
                        label={stop.notes1}
                      />
                      <Option
                        size={4}
                        description={"Note 2"}
                        label={stop.notes2}
                      />
                      <Option
                        size={4}
                        description={"Commodity"}
                        label={stop.commodity}
                      />
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Option
                        size={4}
                        description={"Arrive"}
                        label={`${replaceNullWithHypen(
                          stop.arriveDate
                        )} ${replaceNullWithHypen(stop.arriveTime)}`}
                      />
                      <Option
                        size={4}
                        description={"Spotted"}
                        label={`${replaceNullWithHypen(
                          stop.spottedDate
                        )} ${replaceNullWithHypen(stop.spottedTime)}`}
                      />
                      <Option
                        size={4}
                        description={"Stop UL"}
                        label={`${replaceNullWithHypen(
                          stop.stopUnloadDate
                        )} ${replaceNullWithHypen(stop.stopUnloadTime)}`}
                      />
                      <Option
                        size={4}
                        description={"Available"}
                        label={`${replaceNullWithHypen(
                          stop.availableDate
                        )} ${replaceNullWithHypen(stop.availableTime)}`}
                      />
                      <Option
                        size={4}
                        description={"Depart"}
                        label={`${replaceNullWithHypen(
                          stop.departedDate
                        )} ${replaceNullWithHypen(stop.departedTime)}`}
                      />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </CardActions>
            </Card>
          ))}
        </>
      )}
      {showMoreDetailsBar && (
        <>
          <br />
          <Grid container>
            <Grid item xs={12}>
              <Button
                variant="contained"
                style={{ float: "right" }}
                onClick={() => {
                  select(controlNumber);
                  history.push(`/details/${controlNumber}/${locationNumber}`);
                }}
              >
                <span>Load Details</span>
                <ChevronRightIcon />
              </Button>
            </Grid>
          </Grid>
          {isModalOpen && (
            <TenderModal
              closeModal={closeModal}
              selectedStops={selectedStops}
              controlNumber={controlNumber}
            />
          )}
        </>
      )}
    </TenderWrapper>
  );
}

Tender.propTypes = {
  load: PropTypes.object.isRequired,
};

export default Tender;

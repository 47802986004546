import {GridColumnMenu } from '@mui/x-data-grid-pro';

export default function CustomColumnMenu(props) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          // Hide `columnMenuColumnsItem`
        //   columnMenuColumnsItem: null,
          columnMenuSortItem:null,
          columnMenuFilterItem:null,
          columnMenuPinningItem:null
        }}
      />
    );
  }

import { useEffect, useState } from "react";

import {
  Paper,
  TableContainer,
  Box,
  TextField,
  Button,
  InputAdornment,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector, useDispatch } from "react-redux";

import ShipperConsigneeTable from "./components/ShipperConsigneeTable";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchShipper,getShipperData } from "../../store/actions/shipperActions";
import { maptoShipperTable } from "./components/commonFn";


export default function ShipperConsigneeTableContainer(props) {
  const {
    setSelectedRow,
    setRows,
    setRowsData,
    rows,
    rowsData,
    isLoading,
    shipperTableApiCall,
    setRowsCount,
    rowsCount,
    setAddNewShipperCons,
    setEditShipperClicked,
    setIsLoading,
    selectedRow,
    addNewShipperCons,
    editShipperClicked

 
  } = props;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [shipperSearchError, setShipperSearchError] = useState("");
  const open = Boolean(anchorEl);
  // const popOverID = open ? "new-order-popover" : undefined;

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  // const handleClearSearch=()=>{
  //     setSearchText("")
  //     if (typeof ordersLocation?.locationNumber !== 'undefined') {
  //         shipperTableApiCall(ordersLocation?.locationNumber);
  //     }
  // }
  const handleSearchText = (event) => {
    setSearchText(event);
    if (event === "") {
      handleSearch("");
    }
  };
  const handleSearch = (searchName,query = { page: 1, pageSize: 50 }) => {
    console.log("searchName",searchName)

    if (searchName !== "") {
      setIsLoading(true)
    
        let postParams = [
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation?.locationNumber
            },
            {
                clause: "OR",
                field: "shipperCode",
                operand: "IS",
                value:`${searchName}*`
            },
            {
                clause: "OR",
                field: "yard",
                operand: "IS",
                value: `${searchName}*`
            },
            {
                clause: "OR",
                field: "name",
                operand: "IS",
                value: `*${searchName}*`
            },
            {
                clause: "OR",
                field: "city",
                operand: "IS",
                value: `${searchName}*`
            },
            {
                clause: "OR",
                field: "state",
                operand: "IS",
                value: `${searchName}*`
            },
            {
              clause: "OR",
              field: "address",
              operand: "IS",
              value: `${searchName}*`
          }
           
        ]

        dispatch(
          getShipperData({
            postParams,
            locationNumber: ordersLocation?.locationNumber,
            query: query,
            sortData:{field:"name",sort:"asc"}
          })
        ).then((res) => {
          console.log("res-d", res.data.hits);
    
          if (res?.data?.hits?.length > 0) {
            setShipperSearchError("")
            setRowsData(res?.data.hits)
            let data = maptoShipperTable(res?.data.hits);
            setRows(data);
            setRowsCount(res?.data.length);
          } else {
            setShipperSearchError("No Data Found")
          }
          setIsLoading(false);
        });
      }else {

        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            setShipperSearchError("")
            shipperTableApiCall();
        }
    }


   


}
  useEffect(() => {}, [rows]);

  const handleQueryPageChange = (
    params,
    sortData = {
      field: "name",
      sort: "asc",
    }
  ) => {
    setSelectedRow("");
    if (searchText) {
      handleSearch(searchText, {
        page: params.page + 1,
        pageSize: params.pageSize,
      });
    } else {
      shipperTableApiCall(
        { page: params?.page + 1, pageSize: params?.pageSize },
        sortData
      );
    }
  };
  const handleSortChange = (
    params,
    sortData = {
      field: "shipperAgent",
      sort: "asc",
    }
  ) => {
    // setSelected("")
    setSelectedRow("");
    if (searchText) {
      handleSearch(searchText, {
        page: params.page + 1,
        pageSize: params.pageSize,
      });
    } else {
      shipperTableApiCall(
        { page: params?.page + 1, pageSize: params?.pageSize },
        sortData
      );
    }
  };
  const isNewOrderClicked=()=>{

    if(editShipperClicked || addNewShipperCons){
      let confirmAlert = window.confirm("Please save your changes or click 'OK' to open a new shipper consignee form ");
      if (confirmAlert) {
        setAddNewShipperCons(true);
      setSelectedRow("");
      
       
    }
   
     
    } else {
      setAddNewShipperCons(true);
      setSelectedRow("");
        // return true;
    }
  
  }
console.log("rowsData-contable",rowsData)
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <label style={{ fontSize: "14px", color: "#091DA3" }}>
          Shipper/Consignee
        </label>
      </div>

      <div
        style={{
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "70%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            paddingRight: "5px",
          }}
        >
          <TextField
            label="Search"
            margin="dense"
            style={{ height: "40px", width: "100%", fontSize: "10px" }}
            size="small"
            inputProps={{ style: { fontSize: 10 } }}
            InputLabelProps={{ style: { fontSize: 14, height: 40 } }}
            value={searchText}
            // onChange={(event) => { setSearchText(event.target.value) }}
            onChange={(event) => {
              handleSearchText(event.target.value);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && handleSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      searchText ? handleSearchText("") : handleSearch();
                    }}
                  >
                    {searchText ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                  {/* <IconButton onClick={() => { handleSearch() }}>
                                        <SearchIcon />
                                    </IconButton> */}
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div
          style={{
            width: "30%",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            style={{ width: "100%", height: "35px", fontSize: "10px" }}
            onClick={isNewOrderClicked}
            // onClick={() => {
            //   setAddNewShipperCons(true);
            //   setSelectedRow("");
            // }}
            endIcon={
              <AddCircleOutlineIcon style={{ width: "15px", height: "15px" }} />
            }
          >
            New Ship/Consignee
          </Button>
        </div>
      </div>

      <div style={{ width: "100%", height: `calc(100vh - 200px)` }}>
        {shipperSearchError ? (
          <div style={{ color: "red", textAlign: "center" }}>
            {shipperSearchError}
          </div>
        ) : (
          <ShipperConsigneeTable
            rows={rows}
            rowsData={rowsData}
            isLoading={isLoading}
            // setSelected={setSelected}
            setSelectedRow={setSelectedRow}
            handleQueryPageChange={handleQueryPageChange}
            handleSortChangeData={handleSortChange}
            rowsCount={rowsCount}
            setAddNewShipperCons={setAddNewShipperCons}
            shipperTableApiCall={shipperTableApiCall}
            setEditShipperClicked={setEditShipperClicked}
            editShipperClicked={editShipperClicked}
            addNewShipperCons={addNewShipperCons}
          />
        )}
      </div>

    </Paper>
  );
}

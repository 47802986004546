import { useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from "react";
import { Paper } from "@mui/material";
import './DispatchStyle.css'
import TabsComponent from "./components/TabsComponent";
import TableHeadingStrip from './components/TableHeadingStrip';
import CommonTable from './components/CommonTable';

import { spottedTableColumns } from './commonFn';

const tabname = [
    { id: 'dropavailable', label: 'Drop Available' },
    { id: 'dropunavailable', label: 'Drop Unavailable' },
]

const tableColumns = spottedTableColumns;

export default function SpottedTable({ stopsDataParent, isLoading }) {
    //const [isLoading, setIsLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [groupRows, setGroupRows] = useState({});
    const [tabNames, setTabNames] = useState(tabname);
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(async () => {
        await groupStopsData(stopsDataParent);
    }, [stopsDataParent])

    const groupStopsData = useCallback(async (stops) => {
        try {
            let finalData = {};
            let tabNameWithLoadCount = [];
            tabname.map((tabData) => {
                let filterData = stops.filter((val) => {
                    if (tabData['id'] === 'dropunavailable') {
                        if (
                            val.arriveDriver !== 0 &&
                            val.arriveTruck !== 0 &&
                            val.spotDate !== 0 &&
                            val.availDate === 0 &&
                            val.departTruck === 0 &&
                            val.departDriver === 0
                        ) {
                            return val
                        }
                    } else if (tabData['id'] === 'dropavailable') {
                        if (
                            val.arriveDriver !== 0 &&
                            val.arriveTruck !== 0 &&
                            val.spotDate !== 0 &&
                            val.availDate !== 0 &&
                            val.departTruck === 0 &&
                            val.departDriver === 0
                        ) {
                            return val
                        }
                    }
                })
                // Sort data by apptDate
                let sortedData;
                if (tabData['id'] === 'dropunavailable') {
                    sortedData = filterData.sort((a, b) => {
                        let dateB = new Date(b.outageDateTime);
                        let dateA = new Date(a.outageDateTime);
                        return dateA - dateB;
                    });
                } else {
                    sortedData = filterData.sort((a, b) => {
                        let dateB = new Date(b.availDate);
                        let dateA = new Date(a.availDate);
                        return dateA - dateB;
                    });
                }

                Object.assign(finalData, { [tabData['id']]: sortedData });
                tabNameWithLoadCount.push({ ...tabData, loadCount: filterData?.length || 0 })
            })

            setTabNames(tabNameWithLoadCount);
            if (typeof finalData !== 'undefined' && Object.keys(finalData || {}).length > 0) {
                setGroupRows(finalData);
                setRows(finalData[tabname[selectedTab]['id']])
            }
        } catch (error) {
            console.log('Error:', error);
        }
    }, [stopsDataParent])

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
        let tabData = tabname[newValue];
        let data = groupRows[tabData['id']];
        if (typeof data !== 'undefined' && data.length > 0) {
            return setRows(data);
        }
        return setRows([]);
    }

    return (
        <div style={{ height: 'auto', width: '100%', flexDirection: 'column', display: 'flex', padding: '10px' }}>
            <TableHeadingStrip title={'Spotted'} />
            <TabsComponent
                tabname={tabNames}
                tabletype={'Spotted'}
                rowData={rows}
                handleTabChange={(event, newValue) => handleTabChange(event, newValue)}
                tableColumns={tableColumns}
                isLoading={isLoading}
            />
            <CommonTable rowData={rows} tableColumns={tableColumns} isLoading={isLoading} tabletype={'spotted'} />
        </div>
    )
}
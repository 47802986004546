import {maintenanceTypes } from "../types/maintenanceTypes"
import { Dispatch } from 'redux';
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import {mapOrderByFormResponseToClient,maptoOrderByTable} from "../../scenes/Maintenance/OrderBy/components/commonFn"


export const getOrderByData = ( params: any) => {
    return async (dispatch: Dispatch) => {
        const res = await getOrderByDataApiReq( params);
        dispatch(setMaintenance(res));
        return res;
    };
}

const setMaintenance = (apiData: any) => {
    return {
        type: maintenanceTypes.MAINTENANCE_DATA,
        payload: apiData,
    };
};


export const getOrderByDataApiReq = async (params: any) => {
    console.log("params--sort",params)
    // let url = `/shipperconsignee/${params.locationNumber}`
    let url = "/order-by/list"
    
    let queryString = `?page=${params?.pageData?.page}&size=${params?.pageData?.pageSize}&sortBy=${params?.sortData?.field}&sortOrder=${params?.sortData?.sort}`;
    if(params?.orderByStatus==="A"&& params?.search){
         queryString  +=`&orderByStatus=A&search=${params?.search}`
    }else{
    if(params?.orderByStatus==="A"){
        queryString  +=`&orderByStatus=A`
   }
   if(params?.search){
        queryString  +=`&search=${params?.search}`
        //  queryString  +=`&search=A&searchBy=status`
    }
}

    try {
        let res = await GET(url + queryString);
        if (res && res.httpCode === 200 && res.status === 'success')  {
            console.log("res.data",res)
                let data = {data:maptoOrderByTable(res?.data?.data),count:res?.data?.totCount}
                console.log("----data",data)
              
                return data;
            }
            return [];
       
    } catch (err) {
        return [];
    }
   
}

export const getSingleOrderByData = (req:any) => {
    return async (dispatch: Dispatch) => {
        const res = await singleOrderByDataApiReq(req);
        dispatch(setMaintenance(res));
        return res;
    };
}

export const singleOrderByDataApiReq = async (req:any) => {
    try {
        
        if(req.code){
            
              let res = await GET(`/order-by/code/${req.code}`)
            console.log("res",res)
            if (res && res.httpCode === 200 && res.status === 'success')  {
            console.log("res.data",res)
            let data = mapOrderByFormResponseToClient(res?.data);
            console.log("----data",data)
          
            return data;
               
            }

        }else{
            let data = mapOrderByFormResponseToClient();
            console.log(">>>>>data",data)
          
            return data;

        }
  
    } catch (err) {
        return [];
    }
}
export const saveOrderByContactData = (LocationNumber: any,params:any,type:any) => {
    return async (dispatch: Dispatch) => {
         const res = await saveOrderByContactReq(LocationNumber,params,type);
        dispatch(setMaintenance(res));
        return res;
    }
}

export const saveOrderByContactReq = async (locationNumber: number, params: any, type: any) => {
    console.log(">>>>postDAta",locationNumber,params,type)
    try {
        const res =await POST("/contacts/bulk/add", params); 
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}





export const deleteOrderByContact = (seq:any) => {
    return async (dispatch: Dispatch) => {
         const res = await deleteOrderByContactReq(seq);
         dispatch(setMaintenance(res));
    
        return res;
    }
}

export const deleteOrderByContactReq = async (seq:any) => {
    try {
        let res = await DELETE(`/contacts/800/${seq}`);
        return res;
    } catch (err) {
        return [];
    }
}


export const getOrderByContact = (code:any) => {
    return async (dispatch: Dispatch) => {
         const res = await getOrderByContactReq(code);
         dispatch(setMaintenance(res));
    
        return res;
    }
}

export const getOrderByContactReq = async (code:any) => {
    try {
        let res = await GET(`/contacts/list/code/${code}`);
        return res;
    } catch (err) {
        return [];
    }
}

import Dialog from '@mui/material/Dialog';
import { Button, Typography } from "@mui/material";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react";
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import addicon from '../../assets/icons/add_icon.png'


import './SaveStyle.css'
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserViewsExtendedSearchAction, saveViewElastic } from '../../store/actions/orderActions';


function SaveView(props) {
    const { onClose, open, searchClause, view } = props;
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [viewName, setViewName] = useState('')
    const [alert, setAlert] = useState({
        open: false,
        message: ``,
        severity: 'error'
    })

    const userViews = useSelector(
        ({ orderReducer }) => orderReducer.allExtendedSearchViews
    );

    const handleClose = () => {
        onClose(false);
        setShow(false)
    };

    const handleshowhide = () => {
        setShow(true)
    }
    const handleAlertClose = () => {
        setAlert({
            open: false,
            message: ``,
            severity: 'error'
        })
    };

    const saveView = () => {
        const newView = {
            name: encodeURIComponent(viewName),
            view,
            searchClause
        }
        console.log('Saving View', viewName, newView)
        dispatch(saveViewElastic(newView))
            .then((res) => {
                console.log('RESPONSE IS', res)
                if (res.httpCode === 200) {
                    console.log('success')
                    setAlert({
                        open: true,
                        message: `View: ${viewName} has been saved`,
                        severity: 'success'
                    })
                    const isUpdate = userViews.data.filter(item => item.name !== newView.name)
                    dispatch(getAllUserViewsExtendedSearchAction({
                        isLoading: false,
                        data: [...isUpdate, newView]
                    }))
                    handleClose()
                }
                else {
                    console.log('Error Occurred Try again')
                    setAlert({
                        open: false,
                        message: `Error Saving View, Please Try again`,
                        severity: 'error'
                    })
                }
            })
    }

    return (
        <>
            <Dialog onClose={handleClose} open={open}>
                <div style={{ display: 'flex', flexDirection: 'column', minWidth: '500px', minHeight: '200px', borderRadius: '10px', padding: '30px' }}>

                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                        <div style={{ width: '50%' }}>
                            <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px' }} > {`Save View`} </Typography>
                        </div>
                        <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                            <Button
                                variant="text"
                                onClick={handleClose}
                                style={{ textDecorationLine: 'underline', textTransform: 'capitalize', color: '#102254' }}>
                                Cancel
                            </Button>
                            <Button
                                variant="text"
                                onClick={saveView}
                                style={{ textDecorationLine: 'underline', textTransform: 'capitalize', color: '#102254', display: (show == true ? 'none' : 'flex') }}>
                                Save
                            </Button>
                            <Button
                                variant="text"
                                onClick={saveView}
                                style={{ textDecorationLine: 'underline', textTransform: 'capitalize', display: (show == true ? 'flex' : 'none'), color: '#102254' }}>
                                Save As New View
                            </Button>
                        </div>

                    </div>

                    <div style={{ width: '100%', backgroundColor: '#707070', height: '0.5px', opacity: '40%' }}></div>

                    <div aria-disabled={true} style={{ display: 'flex', flexDirection: 'row', padding: '1rem', alignItems: 'center', justifyContent: 'space-between' }}>
                        <label style={{ opacity: (show == true ? '40%' : '100%') }}>Your View :</label>
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            className="autocomplestyl"
                            options={userViews.data.map(item => decodeURIComponent(item.name)) || []}
                            value={viewName || ''}
                            onChange={(event, value) => setViewName(value)}
                            disabled={show == true ? true : false}
                            defaultValue={'--Select the View--'}
                            sx={{ width: 300 }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    <div style={{ flexDirection: 'row', padding: '1rem', alignItems: 'center', justifyContent: 'space-between', display: (show == true ? 'flex' : 'none') }}>
                        <label>View Name :</label>
                        <TextField
                            disablePortal
                            value={viewName}
                            onChange={(event) => setViewName(event.target.value)}
                            className="autocomplestyl"
                            id="combo-box-demo"
                            sx={{ width: 300 }}
                        />
                    </div>

                    <div style={{ width: '100%', backgroundColor: '#707070', height: '0.5px', opacity: '40%' }}></div>

                    <div style={{ paddingTop: '1rem', display: 'flex', alignItems: 'center', display: (show == false ? 'flex' : 'none') }} onClick={handleshowhide}>
                        <img src={addicon} style={{ width: '15px', height: '15px' }} />
                        <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', marginLeft: '0.5rem' }} > {`Add New Save`} </Typography>
                    </div>

                </div>
            </Dialog>
            < Snackbar open={alert.open} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={5000} onClose={handleAlertClose} >
                <Alert onClose={handleAlertClose} severity={alert.severity} sx={{ width: '100%' }}>
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    )
}
export default SaveView;
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';

import AutoComplete from '../../components/AutoComplete/AutoComplete'
import { addNewLocation, editNewLocation } from '../../store/actions/locationActions';
import { TenderListWrapper } from '../../components/styledComponents'

export default function AddLocation() {
    // create variable 
    const [emailEditDefaultValue, setEmailEditDefaultValue] = useState("")
    const [addLocEmail, setAddLocEmail] = useState('');
    const [addLocNumber, setAddLocNumber] = useState([]);
    const [status, setStatus] = useState(1)
    let [pageName, setPageName] = useState('ADD User Details');
    const [isEmailRegistered, setIsEmailRegistered] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const [emailError, setEmailError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    // Variable sent to autocomplete component
    const [editCount, setEditCount] = useState(0)
    
   // Check whether the page is Add or Edit
    useEffect(() => {
        setPageName('ADD User Details')
        if (pageName == "ADD User Details") {
            setStatus(1)
        }
    }, [])

    //Edit Location 
    const [searchRecord, SetsearchRecord] = useState([]);
    const getUserValueLocal = localStorage.getItem("getAllLocationData")
    const editUserValue = JSON.parse(getUserValueLocal);

    useEffect(() => {
        SetsearchRecord(editUserValue)
    }, [pageName == 'Edit Details'])

    const { email } = useParams();
    let idNum = email || '';

    let getSelectedRecord = [];
    if (idNum) {
        getSelectedRecord = searchRecord && searchRecord.filter(ele =>
            ele.email == idNum
        )
    }
    useEffect(() => {
        if (email) {
            setPageName('Edit Details')
        }

    }, [email])

    useEffect(() => {

        setEmailEditDefaultValue(getSelectedRecord[0]?.email);
        setAddLocEmail(getSelectedRecord[0]?.email)
        setAddLocNumber(getSelectedRecord[0]?.locationNumber)
        if (getSelectedRecord[0]?.status == "Active" || getSelectedRecord[0]?.status == 1) {
            setStatus(1);
        }
        if (getSelectedRecord[0]?.status == "InActive" || getSelectedRecord[0]?.status == 0) {
            setStatus(0);

        }
    }, [getSelectedRecord?.length > 0])

    // get and set the email value
    const handlerGetEmail = (e) => {
        setIsEmailRegistered(false);
        setAddLocEmail(e.target.value);
        setEmailError(false)
        setIsSubmitClicked(false)
    }

   //function called from Autocomplete
    const editHandler = (loc) => {
        setAddLocNumber(loc)
    }

    // Submit Location for Add Location
    const submitAddLocationDetail = async () => {
        setIsSubmitClicked(true)
        if (addLocNumber.length > -1) {
            setLocationError(true);
        }
        if (pageName === 'ADD User Details') {
            if (addLocEmail && addLocNumber.length > 0) {
                setLocationError(false)
                let emailValidation = isValidEmail(addLocEmail)
                if (emailValidation == false) {
                    setEditCount(0)
                    setEmailError(true);
                    editHandler('')
                }
                else {
                    const res = await dispatch(addNewLocation({
                        email: addLocEmail,
                        locationNumber: addLocNumber,
                        status
                    }))
                    if (res.message === "Record has been inserted successfully!") {
                        setIsSubmitClicked(false);
                        setEditCount(0)
                        setAddLocNumber([])
                        history.push('/location')
                    }

                    if (res.message == "Email Id already exist in database!") {
                        setEditCount(0)
                        setIsSubmitClicked(false)
                        setIsEmailRegistered(true);
                    }
                    setEditCount(1)
                    setEmailError(false);
                }

            } else {
                if (!addLocEmail) {
                    setEmailError(true);
                }
                if (addLocNumber.length > 0) {
                    setLocationError(true);
                }
            }
            if (editCount == 0) {
                setEditCount(1)
            }
        } else {
            setLocationError(false);
            if (addLocEmail && addLocNumber) {
                console.log(emailEditDefaultValue, addLocEmail, addLocNumber)

                let emailValidation = isValidEmail(addLocEmail)
                if (emailValidation == false) {
                    setEmailError(true);
                }
                else {
                    let res = await dispatch(editNewLocation({
                        currentEmail: emailEditDefaultValue,
                        newEmail: addLocEmail,
                        locationNumber: addLocNumber,
                        status
                    }))
                    setEmailError(false);
                    if (res?.message == "Record has been updated successfully!") {
                        setIsSubmitClicked(false);
                        history.push('/location')
                    }
                    if (res?.message == "Modified email Id already associated with other account default location!") {
                        setIsSubmitClicked(false)
                        setIsEmailRegistered(true);
                    }
                }

            } else {
                if (!addLocEmail) {
                    setEmailError(true);
                }
                if (!addLocNumber) {
                    setLocationError(true);
                }
            }
        }
        setAddLocEmail('');
        setAddLocNumber([])
    }

    function exitData() {
        history.push('/location')
    }

    const addHandler = (value) => {
        setLocationError(value);
    }

    const statusChange = (e) => {
        if (status == 1) {
            setStatus(0)
        } else {
            setStatus(1)
        }
    }
    let autoComDefValue = [];
    return (

        <TenderListWrapper>
            {
                isEmailRegistered ? <Alert variant="filled" severity="warning">
                    This Email ID Already Available!
                </Alert> : null
            }

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'left', marginBottom: '10px', marginTop: '70px' }}>
                <h2 style={{ marginBottom: "30px", fontWeight: 'bold', fontSize: '20px' }}>{pageName}</h2>
                <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr',
                        alignItems: 'center',
                        columnGap: '23%'
                    }}>
                        <label>
                            Email id:
                        </label>
                        <TextField style={{ width: "137%" }} id="outlined-basic" label="xyz@eaglegroup.com" variant="outlined" value={addLocEmail} onChange={handlerGetEmail} />

                    </div>
                    {
                        emailError == false ? null : <label style={{ marginLeft: "41%", marginTop: '3%', color: "red" }}>Please enter eaglegroup.com email</label>
                    }

                    <br />
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr',
                        alignItems: 'center',
                        columnGap: '5%'
                    }}>
                        <label>
                            Location:
                        </label>
                        <AutoComplete props={getSelectedRecord} editHandler={editHandler} addHandler={addHandler} autoComDefValue={autoComDefValue} editCount={editCount} />
                    </div>
                    {
                        locationError == false ? null : <label style={{ marginLeft: "41%", marginTop: '3%', color: "red" }}>Please Select a Location Number</label>
                    }

                    <br />
                    <div style={{
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr',
                        alignItems: 'center',
                        columnGap: '25%'
                    }}>
                        <label>
                            Status:
                        </label>
                        <FormControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group" checked="Active"
                                onChange={statusChange}
                            >
                                {
                                    status == 1 ? <> <FormControlLabel value="Active" control={<Radio />} label="Active" checked="checked" />
                                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" /></> : <> <FormControlLabel value="Active" control={<Radio />} label="Active" />
                                        <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" checked="checked" /></>
                                }
                            </RadioGroup>
                        </FormControl>
                    </div>
                </form>
                <div style={{
                    marginTop: '3%', marginLeft: '25%', display: 'grid',
                    gridTemplateColumns: 'auto 1fr 1fr',
                    alignItems: 'center',
                    columnGap: '5%'
                }}>

                    <Button disabled={isSubmitClicked} type="submit" value="Submit" size="small" style={isSubmitClicked ? { background: "grey", padding: '5px', marginRight: '10px' } : { background: "blue", padding: '5px', marginRight: '10px' }} onClick={submitAddLocationDetail}><span style={{ color: 'white' }}>Submit</span></Button>
                    <Button type="submit" value="Submit" size="small" onClick={exitData} style={{ background: "red", padding: '5px', marginLeft: '10px' }}><span style={{ color: 'white' }}>Back</span></Button>

                </div>
            </div>
        </TenderListWrapper >
    )
}

// Email Error Validation using Regex
function isValidEmail(value) {
    let splitVal = value.split('');
    let checkValitEmail = splitVal.includes('@');
    if(checkValitEmail == true){
        let splitEmailAt = value.split('@')
        let splitDot = splitEmailAt[1].split('.')
        if (splitDot[0] == 'eaglegroup') {
            return /^[\w\-\.\+]+\@[eaglegroup]+\.[com]{2,5}$/.test(value);
        } else {
            return false
        }  
}
else {
    return false
}

}



import { OrdersWrapper, StyledSceneContent } from "../../components/styledComponents";
import SceneHeader from "../../components/SceneHeader/SceneHeader";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import BillingControl from "./BillingControl/BillingControlForm";
import StopsList from './Stops/StopsList';
import StopsTable from './Stops/StopsTable';
import RatesAndCharges from './RatesAndCharges';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BlockIcon from '@mui/icons-material/Block';

import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Button, Typography, Select, Box, MenuItem } from '@mui/material';

import { useEffect, useRef, useState, createContext, useCallback, useLayoutEffect } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { POST, PUT } from '../../utils/axios';

import {
  getSingleOrder,
  copyOrderReq
} from "../../store/actions/orderActions";
import { getGLCodes } from "../../store/actions/rateBookActions";

import { useParams } from "react-router-dom";
import Loader from '../../components/Loader/Lodaer';
import CopyOrderModal from './components/CopyOrderModal';

import orderContext from './orderContext';
import CopyOrderMenu from '../../components/CopyOrderDropDown/CopyOrderMenu';
import { gridColumnsTotalWidthSelector } from '@mui/x-data-grid';
import { mapOrderToTemplateServer } from "../../scenes/Templates/components/commonFn";
import Notes from "./OrderNotes"
import OrderNotes from "./OrderNotes";
// "../../scenes/Templates/components/commonFn";


const actionStyles = { display: 'flex', width: '100%', padding: '2em', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }

const OrderValuesChangedContext = createContext()

const orderTypeOptions = [
  {
    id: 1,
    value: "INBOUND",
    value2: "DOMINB"
  },
  {
    id: 2,
    value: "OUTBOUND",
    value2: "DOMOUT"
  },
  {
    id: 3,
    value: "IMPORT",
    value2: ""
  },
  {
    id: 4,
    value: "EXPORT",
    value2: ""
  },
];

export default function Order(props) {


  const history = useHistory();

  const [orderValuesChanged, setOrderValuesChanged] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [isStateChanged, setIsStateChanged] = useState(false);
  const [isEdi210, setIsEdi210] = useState(false);
  const [stopsGridView, setStopsGridView] = useState(false)
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState('')
  const [anchorEl, setAnchorEl] = useState(null);
  const [stopsUpdate, setStopsUpdate] = useState(0); 
  const [stopsMasterLoad, setStopsMasterLoad] = useState([]); 
  const open = Boolean(anchorEl);

  const handleClose = (event) => {
    setSnackOpen(false);
  };

  // 30-03-2023: load single row data from API
  const dispatch = useDispatch();
  const params = useParams();


  // START: On location change reload URL with new location
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  useEffect(() => {
    // console.log('Header Location:', ordersLocation);
    // console.log('URL Param Location:', params.locationNumber);
    if (ordersLocation && Number(ordersLocation.locationNumber) !== Number(params.locationNumber) && params.controlNumber === 'new') {
      history.push(`/orders/${ordersLocation.locationNumber}/${params.controlNumber}/${params.orderType}`)
    }
  }, [ordersLocation]);
  // END: Reload page


  let [currentOrder, setCurrentOrder] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState((params.controlNumber === 'new') ? false : true);

  const [orderType, setOrderType] = useState(params.controlNumber === 'new' ? params.orderType : 'INBOUND');
  let billingControlRef = useRef(getDefaultBillingControl());
  let stopsRef = useRef([]);
  // let receivablesRef = useRef([]);
  // let payablesRef = useRef([]);
  let linehaulRef = useRef([]);
  let accessorialRef = useRef([]);

  let reqParams = {
    locationNumber: params.locationNumber,
    controlNumber: params.controlNumber,
  }

  const fetchOrderDetails = (params, cb) => {
    console.log('Fetching Order Details')
    dispatch(getSingleOrder(reqParams)).then((res) => {
      if (res?.status === 'success') {
        setApiData(res.data);

        setCurrentOrder(res.data);
        if (cb)
          cb()
      }
      setIsLoading(false);
    });
  }
  const fetchGlCodes = async () => {
    dispatch(getGLCodes());
  }
  // Watch for changes in current order.
  useEffect(() => {
    if (params.controlNumber !== 'new') {
      fetchOrderDetails(reqParams)
    }
    fetchGlCodes()
  }, [params.controlNumber])

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.location.reload();
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // Force update useRef state
  useEffect(() => {
    if (params.controlNumber !== 'new') {
      billingControlRef.current = currentOrder.billingControl;
      stopsRef.current = currentOrder.stops;
      // receivablesRef.current = currentOrder.receivable;
      // payablesRef.current = currentOrder.payables;
      linehaulRef.current = currentOrder.linehaul;
      accessorialRef.current = currentOrder.accessorial;
      setOrderType(currentOrder.billingControl.orderType);
      setStopsMasterLoad(currentOrder.stops);
    }
  }, [currentOrder])


  currentOrder = mapOrderToClient(currentOrder)

  /**
   * Modified on: 27-09-2023
   * Basic validation for stops, order by & chasis
   */
  const validateOrderFields = (item) => {
    console.log('validation object', item)
    if (Object.keys(item?.orderBy || {}).length > 0) {
      if (item?.orderBy?.orderBy === '') {
        return {
          status: true,
          error: 'Order By field is mandatory!'
        }
      } else if (item?.orderBy?.billingDate !== '' && item?.equipmentDetails?.chassis === '') {
        return {
          status: true,
          error: 'Chasis field is mandatory!!'
        }
      } else if (item?.orderBy?.billingDate !== '' && item?.pickup?.returnDate === '') {
        const confirm = window.confirm('Are you sure? Unit has not been returned');
        if (!confirm) {
          return {
            status: true,
            error: 'Chasis field is mandatory!!'
          }
        }
      }
    }
    if (item.stops[0]?.name === '' || item.stops[0]?.address === '' || item.stops[0]?.city === '') {
      return {
        status: true,
        error: 'Stops - Name, Address & City fields are mandatory!'
      }
    }

    let stopTruckErrMsg = '';
    item.stops.map((e) => {
      if(!isZeroOrEmpty(e.arriveTruck) && e.arriveTruckStatus === ''){
        stopTruckErrMsg = `Stops [${e.stop}] - Arriver Truck Status field is mandatory!!`;
        return false;
      } else if(isZeroOrEmpty(e.arriveTruck) && e.arriveTruckStatus !== ''){
        stopTruckErrMsg = `Stops [${e.stop}] - Arrive Truck field is mandatory!!`
        return false;
      } else if(!isZeroOrEmpty(e.departTruck) && e.departTruckStatus === ''){
        stopTruckErrMsg = `Stops [${e.stop}] - Depart Truck Status field is mandatory!!`
        return false;
      } else if(isZeroOrEmpty(e.departTruck) && e.departTruckStatus !== '') {
        stopTruckErrMsg = `Stops [${e.stop}] - Depart Truck field is mandatory!!`
        return false;
      } else if (!isZeroOrEmpty(e.arriveTruck) && e.arriveTruckStatus === 'C' && isZeroOrEmpty(e.arriveDate)){
        stopTruckErrMsg = `Stops [${e.stop}] - Arrive Date field is mandatory!!`
        return false;
      } else if (!isZeroOrEmpty(e.departTruck) && e.departTruckStatus === 'C' && isZeroOrEmpty(e.departDate)){
        stopTruckErrMsg = `Stops [${e.stop}] - Depart Date field is mandatory!!`
        return false;
      }else if ((!isZeroOrEmpty(e.departTruck)  || !isZeroOrEmpty(e.arriveTruck) ) && isZeroOrEmpty(e.appointmentDate)){
        stopTruckErrMsg = `Stops [${e.stop}] - Appointment Date field is mandatory!!`
        return false;
      }
    })

    if(stopTruckErrMsg !== ''){
      return {
        status: true,
        error: stopTruckErrMsg
      }
    }

    let rates = normalizeRates(item)
    let ratesErrMsg = '';
    rates.map((e) => {
      const accountLine = {L: 'Linehaul', A:'Accessorial'}
      const payableRecievable  = {P: 'Payables', R:'Receivables'}
      if(isZeroOrEmpty(e.glCode)){
        ratesErrMsg = `Rates&Charges [${accountLine[e.accountLine]}][${payableRecievable[e.payableRecievable]}] - GL Code is mandatory!!`;
        return false;
      } else if(item?.orderBy?.billingDate !== '' && e.amount == 0){
        ratesErrMsg = `Rates&Charges [${accountLine[e.accountLine]}][${payableRecievable[e.payableRecievable]}] - Receivables line amount should not be zero!!`;
        return false;
      }
    });

    if(ratesErrMsg !== ''){
      return {
        status: true,
        error: ratesErrMsg
      }
    }

    return {
      status: false,
      error: ""
    }
  }

  const validateTruckDriverFieldsInStop=(stops)=>{
    const allTruckDriverError = [];
    let firstStopHandled = false;
stops.forEach(currentStop => {
  //due to legacy i handle stop 0 here for this firstStopHandled is check if stop start from 0  
    if (!firstStopHandled && (currentStop.newStop === 1 || currentStop.newStop === 0)) {
        // Check departure truck status for the first stop
        if (currentStop.departTruckStatus !== "T") {
            return; // Continue to the next stop
        } else {
            // Check if arrival truck details are incomplete
            if (!currentStop.arriveTruck  && !currentStop.arriveTruckStatus) {
                const truckDriverError = {
                    stopNo: currentStop.newStop,
                    stopError: "Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop."
                };
                allTruckDriverError.push(truckDriverError);
            }
        }
        firstStopHandled = true;
    } else {
        // For stops after the first one
        if ((currentStop.arriveTruckStatus === "A" || !currentStop.arriveTruckStatus) &&
         (currentStop.departTruckStatus === "A" || !currentStop.departTruckStatus) ){
            return; // Continue to the next stop
        } else {
            // Find the previous stop
            const previousStopId = currentStop.newStop - 1;
            const previousStop = stops.find(stop => stop.newStop === previousStopId);

                        // Check if arrival truck details are incomplete for the current stop
                        if (!currentStop.arriveTruck && !currentStop.arriveTruckStatus && currentStop.departTruck && currentStop.departTruckStatus){
                            const truckDriverError = {
                                stopNo: currentStop.newStop,
                                stopError: "Cannot enter departure truck details at this stop. Please complete arrival truck details for this stop."
                            };
                            allTruckDriverError.push(truckDriverError);
                        }

            // Check if previous stop's departure truck details are incomplete
            if (previousStop && previousStop.departTruck && previousStop.departTruckStatus ) {
                return; // Continue to the next stop
            } else {
                const truckDriverError = {
                    stopNo: currentStop.newStop,
                    stopError: "Cannot enter arrival truck details at this stop. Please complete previous stop departure truck details."
                };
                allTruckDriverError.push(truckDriverError);
            }
        }
    }
});

return allTruckDriverError
  }
  
  const isZeroOrEmpty = (value) => {
    return value === 0 || value === '';
  }
  /**
   * TODO : Monitor child component state change & accordingly update cancel button alert
   * Monitor child component state changes 
   * Handled with the Global Context
   * */
  // 20-03-2024 : Commenting out this code its not needed anymore
  // useEffect(() => {
  //   if (isStateChanged) {
  //     // Add Window Listener 
  //     // Enable navigation prompt
  //     window.onbeforeunload = function () {
  //       return true;
  //     };
  //   }
  //   else {
  //     // Remove Window Listener
  //     window.onbeforeunload = null;
  //   }
  // }, [isStateChanged]);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (isStateChanged) {
        const confirm = window.confirm('Your changes will not be saved. would you like to proceed?');
        if (confirm) {
          return true;
        }
        return false; // Block navigation
      } else {
        return true; // Allow navigation
      }
    });
    return () => {
      unblock();
    };
  }, [history, isStateChanged]);

  const handleSave = () => {

    // if (validateOrderBy(billingControlRef.current)) {
    //   setSnackOpen(true)
    //   setSnackContent({ severity: "error", message: "Order By field is mandatory!" })
    //   return
    // }
    // if (validateStops(stopsRef.current)) {
    //   setSnackOpen(true)
    //   setSnackContent({ severity: "error", message: "Stops - Name, Address & City fields are mandatory!" })
    //   return
    // }
    let validationRes = validateOrderFields({ ...billingControlRef.current, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] });
    if (validationRes.status) {
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: validationRes.error })
      return
    }
    let validationTruckDriverRes=validateTruckDriverFieldsInStop(stopsRef.current)
    if (validationTruckDriverRes.length > 0) {
      // Create a string of all errors
      const allErrors = validationTruckDriverRes.map(error => `Stop ${error.stopNo}: ${error.stopError}`).join('<br>');

    
      setSnackOpen(true);
      setSnackContent({
        severity: "error",
        message: <span dangerouslySetInnerHTML={{ __html: allErrors }} /> // Display all errors
        // message: allErrors // Display all errors
      });
    
      return;
    }
    let postObj = mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })
    if (!postObj) {
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: "Data invalid, Request failed" })
      return
    }

    setSnackOpen(true)
    setSnackContent({ severity: "info", message: "Saving Order ..." })
    if (params.controlNumber !== 'new') {
      // We want to update the order
      console.log('Update Order', postObj)
      PUT(`/orders/order/${props.match.params.locationNumber}/${props.match.params.controlNumber}`, postObj)
        .then((res) => {
          if (res.status === "success") {
            setSnackOpen(true)
            setSnackContent({ severity: "success", message: res.message })
            history.push(`/orders/${props.match.params.locationNumber}/${props.match.params.controlNumber}`)
          }
          else if (res.status === "error") {
            setSnackOpen(true)
            setSnackContent({ severity: "error", message: res.message })
          }
        })
        .catch(e => {
          setSnackOpen(true)
          setSnackContent({ severity: "error", message: e })
          console.log(e)
        })
    }
    else {
      // WE will create a new one
      console.log('Create A new order', postObj)
      POST(`/orders/order/${props.match.params.locationNumber}`, postObj)
        .then((res) => {
          if (res.status === "success") {
            setSnackOpen(true)
            setSnackContent({ severity: "success", message: res.message })
            // fetchOrderDetails({
            //   locationNumber: params.locationNumber,
            //   controlNumber: res.data.orderNumber
            // })
            history.push(`/orders/${props.match.params.locationNumber}/${res.data.orderNumber}`)
          }
          else if (res.status === "error") {
            setSnackOpen(true)
            setSnackContent({ severity: "error", message: res.message })
          }
        })
        .catch(e => {
          setSnackOpen(true)
          setSnackContent({ severity: "error", message: e.message })
          console.log(e)
        })

    }
    setIsStateChanged(false);
  }


  const [showCopyOrderModal, setShowCopyOrderModal] = useState(false);
  const [isCopyOrderLoading, setIsCopyOrderLoading] = useState(false);

  const handleCopyOrder = async (copies, postObj) => {
    // console.log('copy order copies: ', copies);
    // console.log('copy order post data: ', postObj);
    setIsCopyOrderLoading(true);
    if (!postObj) {
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: "Unable to copy, Request failed" })
      setIsCopyOrderLoading(false);
      return;
    }

    if (copies > 0 && copies <= 25) {
      setSnackOpen(true)
      setSnackContent({ severity: "info", message: "Copying Order ..." })

      //let grabFirstOrder = '';
      // Create new orders based on copies
      //for (let index = 0; index < copies; index++) {
      let apiRes = await copyOrderReq(props.match.params.locationNumber, postObj, copies);
      if (apiRes.status === 'success') {
        //grabFirstOrder = apiRes.data.orderNumber;
        setShowCopyOrderModal(false);
        setIsCopyOrderLoading(false);
        setSnackOpen(true)
        //setSnackContent({ severity: "success", message: `New orders has been created from ${grabFirstOrder} to ${apiRes.data.orderNumber}` })
        setSnackContent({ severity: "success", message: apiRes.message })
        setTimeout(() => {
          history.replace("/orders");
          history.replace(`/orders/${props.match.params.locationNumber}/${apiRes.data.orderNumber}`)
        }, 2000)

      } else if (apiRes.status === 'error') {
        setIsCopyOrderLoading(false);
        setSnackOpen(true)
        setSnackContent({ severity: "error", message: apiRes.message })
      }
      //}
    } else {
      setIsCopyOrderLoading(false);
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: "Copies must be greater than 0 & less than 25" })
    }
  }
  const handleCopyClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleVoidOrder = () => {
    let postObj = mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })
    postObj.orderBy.status = 'V'
    if (!postObj) {
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: "Unable to Void, Request failed" })
      return
    }

    setSnackOpen(true)
    setSnackContent({ severity: "info", message: "Voiding Order ..." })

    console.log('Void order', postObj)

    PUT(`/orders/order/${props.match.params.locationNumber}/${props.match.params.controlNumber}`, postObj)
      .then((res) => {
        if (res.status === "success") {
          setSnackOpen(true)
          setSnackContent({ severity: "success", message: res.message })
          fetchOrderDetails(reqParams)
          // setCurrentOrder({
          //   billingControl: {...currentOrder.billingControl, orderBy: {...currentOrder.billingControl, billToStatus: "V"}},
          //   payables:{...currentOrder.payables},
          //   recievables:{...currentOrder.recievables},
          //   stops:[...currentOrder.stops]
          // })
          // history.push(`/orders/${props.match.params.locationNumber}/${res.data.orderNumber}`)
        }
        else if (res.status === "error") {
          setSnackOpen(true)
          setSnackContent({ severity: "error", message: res.message })
        }
      })
      .catch(e => {
        setSnackOpen(true)
        setSnackContent({ severity: "error", message: e })
        console.log(e)
      })

  }

  const handleUnVoidOrder = () => {
    let postObj = mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })
    postObj.orderBy.status = 'V'
    if (!postObj) {
      setSnackOpen(true)
      setSnackContent({ severity: "error", message: "Unable to Void, Request failed" })
      return
    }

    setSnackOpen(true)
    setSnackContent({ severity: "info", message: "Un-Voiding Order ..." })
    const spotDateArray = postObj.stops.map(stop => ({ spotDate: stop.spotDate }));


    const postData={
      currentStatus:postObj.orderBy.status,
      billingDate:postObj.orderBy.billingDate,
      returnDate:postObj.pickup.returnDate,
      notifyDate:postObj.pickup.notifyDate,
      stops:spotDateArray
    }
    console.log("postData",postData)

    PUT(`/orders/order/unvoid/${props.match.params.locationNumber}/${props.match.params.controlNumber}`, postData)
      .then((res) => {
        if (res.status === "success") {
          setSnackOpen(true)
          setSnackContent({ severity: "success", message: res.message })
          fetchOrderDetails(reqParams)
        }
        else if (res.status === "error") {
          setSnackOpen(true)
          setSnackContent({ severity: "error", message: res.message })
        }
      })
      .catch(e => {
        setSnackOpen(true)
        setSnackContent({ severity: "error", message: e })
        console.log(e)
      })

  }

  /**
   * Added: 20-June-2024
   * show prompt to user if user tries to close the tab without saving the changes.
   */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isStateChanged) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome to show the confirmation dialog
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isStateChanged]);

  useEffect(() => {
    setStopsMasterLoad(stopsRef.current)
    // console.log('stopsUpdate useEffect', stopsUpdate);
  } , [stopsUpdate] );

  return (
    <OrdersWrapper>

      <orderContext.Provider value={{ isStateChanged, setIsStateChanged, isEdi210, setIsEdi210 }}>
        {
          props.match.template === true ? null :

            <SceneHeader >
              <div style={actionStyles}>
                <div>
                  <Select
                    id="order-type-select"
                    value={orderType}
                    label="Order Type"
                    size="small"
                    onChange={(event, value) => { console.log(event.target.value); setOrderType(event.target.value) }}
                    disabled
                  >
                    {orderTypeOptions.length > 0
                      ? orderTypeOptions.map((menu) => {
                        return (
                          <MenuItem value={menu.value}>
                            {menu.value}
                          </MenuItem>
                        );
                      })
                      : null}
                  </Select>
                </div>
                <Typography variant="h6" style={{ color: 'black', fontWeight: 'bold' }} > {`ORDER # ${props.match.params.controlNumber.toString().toUpperCase()}`} </Typography>
                <div>
                {(params.controlNumber !== 'new' && currentOrder.billingControl.orderBy.billToStatus === 'V') && <Button onClick={handleUnVoidOrder} variant="outlined" startIcon={<BlockIcon />}> UN-Void Order </Button>}
                  {(params.controlNumber !== 'new' && currentOrder.billingControl.orderBy.billToStatus !== 'V') && <Button onClick={handleVoidOrder} variant="outlined" startIcon={<BlockIcon />}> Void Order </Button>}
                  {' '}
                  {params.controlNumber !== 'new' && <Button onClick={handleCopyClick} variant="outlined" startIcon={<ContentCopyIcon />}> Copy Order </Button>}
                  <CopyOrderMenu
                    anchorEl={anchorEl}
                    open={open}
                    reqParams={reqParams}
                    setAnchorEl={setAnchorEl}
                    currentOrderData={mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })}
                    currentOrderDataTemplate={mapOrderToTemplateServer({ billingControl: { ...billingControlRef.current, orderType: orderType, }, stops: stopsRef.current, ratecharges: { linehaul: linehaulRef.current, accessorial: accessorialRef.current } })}
                    handleModalSubmitButton={(copies, postData) => handleCopyOrder(copies, postData)}
                  />
                  {' '}
                  <Button onClick={handleSave} variant='outlined' disabled={!isStateChanged} startIcon={<SaveIcon />}> Save </Button>
                  {' '}
                  <Button onClick={() => {
                    if (!isStateChanged) {
                      //history.goBack();
                      history.push('/orders')
                    } else {
                      setIsConfirmDialogOpen(true)
                    }
                  }} variant="outlined" startIcon={<CancelIcon />}> Cancel </Button>

                  <ConfirmationDialog
                    isConfirmDialogOpen={isConfirmDialogOpen}
                    title={`Are you sure?`}
                    description="Your changes will not be saved. would you like to proceed?"
                    acceptTxt="Yes"
                    rejectTxt="No"
                    handleNo={() => setIsConfirmDialogOpen(false)}
                    //handleYes={() => history.goBack()}
                    handleYes={() => history.push('/orders')}
                  />
                </div>
              </div>
            </SceneHeader>
        }
        <StyledSceneContent style={{ marginTop: props.match.template === true ? '-20px' : null }}>
          {isLoading ? <Loader /> : (
            (apiData && Object.keys(apiData).length === 0 && params.controlNumber !== 'new') ? (
              <div style={{ textAlign: "center" }}>No record found for this control number!</div>
            ) : (
              <div>
                <div>
                  <Divider
                    textAlign="left"
                    style={{ marginTop: "1%" }}
                  >
                    <Chip label="BILLING CONTROL" size="large" variant="filled" />
                  </Divider>
                  <BillingControl billingControlRef={billingControlRef} stopsMasterLoad={stopsMasterLoad} orderType={orderType} orderTypeOptions={orderTypeOptions} disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'} />
                </div>
                <div>
                  <Box display="flex" alignItems="center" width="100%">
                        <Divider sx={{ flex: 1 }} />
                          <Typography variant="body1" sx={{ mx: 2 }}>
                            <Chip label="STOPS" size="large" variant="filled"/>
                          </Typography>
                        <Divider sx={{ flex: 1 }} />
                        <Divider sx={{ flex: 1 }} />
                        <Divider sx={{ flex: 1 }} />
                        <Divider sx={{ flex: 1 }} />
                        <Divider sx={{ flex: 1 }} />
                          <Typography variant="body1" sx={{ mx: 2 }}>
                            <FormControlLabel
                              label="Grid View"
                              style={{ fontSize: 50,  }}
                              control={
                                <Switch
                                  checked={stopsGridView}
                                  onChange={(event) => setStopsGridView(event.target.checked)}
                                />
                              }
                          />
                          </Typography>
                    </Box>

                  {!stopsGridView && <StopsList stopsRef={stopsRef} setStopsUpdate={()=>setStopsUpdate( stopsUpdate + 1 )} orderType={orderType} orderTypeOptions={orderTypeOptions} disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'} />}
                  {stopsGridView && <StopsTable stopsRef={stopsRef} disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'} />}
                </div>
                <div>
                  <Divider
                    textAlign="left"
                    style={{ marginTop: "2%", marginBottom: "1%" }}
                  >
                    <Chip label="RATES & CHARGES" size="large" variant="filled" />
                  </Divider>
                  <RatesAndCharges
                    // receivablesRef={receivablesRef}
                    // payablesRef={payablesRef}
                    accessorialRef={accessorialRef}
                    linehaulRef={linehaulRef}
                    orderType={orderType}
                    snackBarHandler={(content) => { setSnackOpen(content.status); setSnackContent({ severity: content.type, message: content.message }) }}
                    disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'}
                    billingControlRef={billingControlRef}
                    ordersLocation={params?.locationNumber}
                  />
                </div>
                <div>
                <Divider
                  textAlign="left"
                  style={{ marginTop: "2%", marginBottom: "1%" }}
                >
                  <Chip label="Notes" size="large" variant="filled" />
                </Divider>
                <OrderNotes
                  controlNumber={params.controlNumber}
                  locationNumber={params.locationNumber}
                  
                />
              </div>
              </div>
            )
          )}
        </StyledSceneContent>

        < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
          <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
            {snackContent.message}
          </Alert>
        </Snackbar>
        {/* {showCopyOrderModal &&
          <CopyOrderModal
            openCO={showCopyOrderModal}
            closeCO={() => setShowCopyOrderModal(false)}
            currentOrderData={mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })}
            handleModalSubmitButton={(copies, postData) => handleCopyOrder(copies, postData)}
            isCopyOrderLoading={isCopyOrderLoading}
          />} */}
      </orderContext.Provider>
    </OrdersWrapper>
  );
}

const getDate = (dateVal) => {
  if (dateVal?.toString().length < 7 || !dateVal)
    return ""
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  if (moment(date, 'YYYY-MM-DD', true).isValid()) {
    return date;
  }
  return "";
}

const getTime = (timeVal) => {
  // Check if timeVal is falsy or 0
  if (!timeVal || timeVal === 0)
    return "00:00";

  // Convert timeVal to string for manipulation
  let timeString = timeVal.toString();

  // Pad with zeros if the length is less than 4
  while (timeString.length < 4) {
    timeString = "0" + timeString;
  }

  // Extract hours and minutes parts
  const hours = timeString.substring(0, 2);
  const minutes = timeString.substring(2);

  // Return formatted time
  return `${hours}:${minutes}`;
}

const validateTimeField = (timeVal) => {
  return timeVal;
  // if (timeVal === '00:00') {
  //   return 0;
  // } else {
  //   return timeVal
  // }
}

const validateDateField = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return dateVal;
  }
  if (dateVal?.toString().length === 7) {
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    return date;
  }
  return "";

}

function replaceSpecialCharacters(str, replacement) {
  var specialCharPattern = /[^\u0000-\u007F]/g;
  var result = str.replace(specialCharPattern, replacement);
  return result;
}

const checkingBillingDateLocked = (item) => {
  return item?.receivable?.isBillingDateLocked ||
    (item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems[0]?.isBillingDateLocked);
}



const mapOrderToClient = (item) => {
  const checkIsBillingDateLocked = item?.rates?.linehaul?.some(row => checkingBillingDateLocked(row));

  let billingControl = {
    orderBy: {
      orderBy: item?.shipperAgentCode || "",
      name: item?.shipperAgent || "",
      location: item?.shipperOrAgentLoc || "",
      phone: "",
      fax: item?.shipperAgentFax || "",
      email: "",
      contact: "",
      billTo: item?.customerNumber || "",
      reference: item?.billingReference || "",
      billToStatus: item?.status || "",
      billingDate: getDate(item?.billingDate) || "",
      isBillingDateLocked: checkIsBillingDateLocked || false,
      orderContactSeq: item?.dispositionCOrderByContactSeq || ''
    },
    equipmentDetails: {
      unitNum: item?.unitNumber || "",
      chassis: item?.chassisNumber || "",
      equipmentSize: item?.equipSize || "",
      release: item?.releaseNumber || "",
      ssl: item?.ssl || "",
      booking: item?.billingBooking || "",
      voyage: item?.voyageNumber || "",
      vessel: item?.vessel || "",
      destinationPort: item?.dispositionCDestinationPort || "",
      equipmentType: item?.equipmentType || '',
      secondaryUnit: item?.secondaryUnit || '',
      secondaryChassis: item?.secondaryChassis || '',
      chassisProvider: item?.chassisProvider || '',
      chassisRelease: item?.chassisRelease || '',
      hazMat: item?.hazMat || 'N',
      tanker: item?.tanker || 'N',
      overWeight: item?.overWeight || 'N',
      reefer: item?.reefer || 'N',
      residential: item?.residential || 'N',
    },

    customerNotes: {
      internalNotes: item?.internalNotes || ""
    },

    // Shipper value or consignee value - defaul to shipper
    shipper: {
      shipper: item?.shipperOrConsShipper || "",
      name: item?.shipper || "",
      pickupNumber: item?.billingShipperPO || "",
      address: item?.shipperAddress || "",
      city: item?.shipperCity || "",
      state: item?.shipperState || "",
      zip: item?.shipperZip || "",
      phone: item?.shipperPhone || "",
      fax: item?.shipperFax || "",
    },
    pickup: {
      pickupPoint: item?.poruPoint || "",
      pickupDate: getDate(item?.poruDate) || "",
      pickupTime: getTime(item?.poruTime) || "",
      disposition: item?.disposition || "",
      returnDate: getDate(item?.returnDate) || "",
      returnTime: getTime(item?.returnTime) || "",
      chassisPickup: item?.dispositionCChassisPickupPoint || "",
      chassisPickupDate: '',
      chassisPickupTime: '',
      chasisDisposition: item?.dispositionCChassisDisposition || "",
      chassisReturnDate: '',
      chassisReturnTime: '',
      notifyDate: getDate(item?.notifyDate) || "",
      notifyTime: getTime(item?.notifyTime) || "",
      lastFreeDay: getDate(item?.cuttOffDate) || "",
      lastFreeTime: getTime(item?.cutOffTime) || "",
      lastPerDiemDays: getDate(item?.pickDate) || "",
      etaDate: getDate(item?.etaDate) || "",
      etaTime: getTime(item?.etaTime) || ""
    },
    driverNotes: {
      driverNotes: item?.remarks1,
      driverNotes2: item?.remarks2,
    },
    masterLoadDetails: {
      billOfLading: item?.billOfLading || "",
      purchaseOrder: item?.purchaseOrder || "",
    },

    orderType: orderTypeOptions?.filter(option => item?.orderType === option.value || item?.orderType === option.value2)[0]?.value,
  }

  // Order type is OUTBOUND OR EXPORT
  if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
    // Shipper becomes consignee in above object
    billingControl.shipper = {
      shipper: item?.shipperOrConsignee,
      name: item?.consignee,
      pickupNumber: item?.billingConsigneePO,
      address: item?.consigneeAddress,
      city: item?.consigneeCity,
      state: item?.consigneeState,
      zip: item?.consigneeZip,
      phone: item?.consigneePhone,
      fax: item?.consigneeFax,
    }
  }

  let stops = (item?.stops) ? item?.stops?.map(stop => {
    if (stop?.lineNumber !== null) {
      return {
        stop: stop?.lineNumber,
        newStop:stop?.lineNumber,
        consignee: stop?.code,
        pickupNumber: stop?.pickupNumber,
        name: stop?.name,
        address: stop?.address,
        city: stop?.city,
        state: stop?.state,
        zip: stop?.zip,
        phone: stop?.contactPhone,//stop?.phone,
        fax: stop?.fax,
        shipperBillOfLadding: stop?.shipperBillOfLadding,
        appointmentDate: getDate(stop?.apptDate),
        appointmentTime: stop?.apptTime, //getTime(stop.apptTime),
        totalPieces: stop?.pieces,
        totalWeight: stop?.weight,
        commodity: stop?.commodity,
        doorSeal: stop?.doorSeal,
        etaDate: getDate(stop?.etaDate),
        etaTime: getTime(stop?.etaTime),
        arriveDate: getDate(stop?.arriveDate),
        arriveTime: getTime(stop?.arriveTime),
        spotDate: getDate(stop?.spotDate),
        spotTime: getTime(stop?.spotTime),
        unloadDate: getDate(stop?.unloadDate),
        unloadTime: getTime(stop?.unloadTime),
        departDate: getDate(stop?.departDate),
        departTime: getTime(stop?.departTime),
        availDate: getDate(stop?.availDate),
        availTime: getTime(stop?.availTime),
        arriveTruck: stop?.arriveTruck,
        arriveDriver: stop?.arriveDriver,
        departTruck: stop?.departTruck,
        departDriver: stop?.departDriver,
        arriveTruckStatus: stop?.arriveTruckStatus,
        departTruckStatus: stop?.departTruckStatus,
        arriveDriverStatus: (stop?.arriveTruckStatus==='C')?'C':stop?.arriveTenderStatus,
        departDriverStatus: (stop?.departTruckStatus==='C')?'C':stop?.departTenderStatus,
        driverNotes: stop?.driverNotes,
        driverNotes2: stop?.driverNotes2,
        isServerRecord: true,
        internalNotes: stop?.internalNotes,
        contactName: stop?.contactName,
        contactPhone: stop?.contactPhone,
        contactEmail: stop?.contactEmail,

        arriveTenderStatus: stop?.arriveTenderStatus,
        departTenderStatus: stop?.departTenderStatus,
        defaultContact:stop?.defaultContact
      }
    }
  }) : []

  /**
   * 19-sep-2023
   */
  let linehaul = (item?.rates) ? item?.rates?.linehaul : [];
  let accessorial = (item?.rates) ? item?.rates?.accessorial : [];

  return { billingControl, stops, linehaul, accessorial }
}

const mapOrderToServer = (item) => {
  try {
    let billingControl = {
      orderBy: {
        shipperAgentCode: item.billingControl.orderBy.orderBy || "",
        shipperAgent: item.billingControl.orderBy.name || "",
        shipperOrAgentLoc: item.billingControl.orderBy.location || "",
        shipperAgentPhone: item.billingControl.orderBy.phone || "",
        shipperAgentFax: item.billingControl.orderBy.fax || "",
        email: item.billingControl.orderBy.email || "",
        contact: item.billingControl.orderBy.contact || "",
        customerNumber: item.billingControl.orderBy.billTo || "",
        billingReference: item.billingControl.orderBy.reference || "",
        status: item.billingControl.orderBy.billToStatus || "",
        billingDate: validateDateField(item.billingControl.orderBy.billingDate) || "",
        dispositionCOrderByContactSeq: item.billingControl.orderBy.orderContactSeq || 0
      },
      equipmentDetails: {
        unitNumber: item.billingControl.equipmentDetails.unitNum || "",
        chassisNumber: item.billingControl.equipmentDetails.chassis || "",
        equipSize: item.billingControl.equipmentDetails.equipmentSize || "",
        realeaseNumber: item.billingControl.equipmentDetails.release || "",
        ssl: item.billingControl.equipmentDetails.ssl || "",
        billingBooking: item.billingControl.equipmentDetails.booking || "",
        voyageNumber: item.billingControl.equipmentDetails.voyage || "",
        vessel: item.billingControl.equipmentDetails.vessel || "",
        dispositionCDestinationPort: item.billingControl.equipmentDetails.destinationPort || "",
        equipmentType: item.billingControl.equipmentDetails.equipmentType || '',
        secondaryUnit: item.billingControl.equipmentDetails.secondaryUnit || '',
        secondaryChassis: item.billingControl.equipmentDetails.secondaryChassis || '',
        chassisProvider: item.billingControl.equipmentDetails.chassisProvider || '',
        chassisRelease: item.billingControl.equipmentDetails.chassisRelease || '',
        hazMat: item.billingControl.equipmentDetails.hazMat || 'N',
        tanker: item.billingControl.equipmentDetails.tanker || 'N',
        overWeight: item.billingControl.equipmentDetails.overWeight || 'N',
        reefer: item.billingControl.equipmentDetails.reefer || 'N',
        residential: item.billingControl.equipmentDetails.residential || 'N',
      },
      customerNotes: {
        internalNotes: item.billingControl.customerNotes.internalNotes
      },
      pickup: {
        poruPoint: item.billingControl.pickup.pickupPoint || "",
        poruDate: validateDateField(item.billingControl.pickup.pickupDate) || "",
        poruTime: validateTimeField(item.billingControl.pickup.pickupTime) || "",
        disposition: item.billingControl.pickup.disposition || "",
        returnDate: validateDateField(item.billingControl.pickup.returnDate) || "",
        returnTime: validateTimeField(item.billingControl.pickup.returnTime) || "",
        dispositionCChassisPickupPoint: item.billingControl.pickup.chassisPickup || "",
        chassisPickupDate: '',
        chassisPickupTime: '',
        dispositionCChassisDisposition: item.billingControl.pickup.chassisDisposition || "",
        chassisReturnDate: '',
        chassisReturnTime: '',
        notifyDate: validateDateField(item.billingControl.pickup.notifyDate) || "",
        notifyTime: validateTimeField(item.billingControl.pickup.notifyTime) || "",
        cuttOffDate: validateDateField(item.billingControl.pickup.lastFreeDay) || "",
        cutOffTime: validateTimeField(item.billingControl.pickup.lastFreeTime) || "",
        pickDate: validateDateField(item.billingControl.pickup.lastPerDiemDays) || "",
        etaDate: validateDateField(item.billingControl.pickup.etaDate) || "",
        etaTime: validateTimeField(item.billingControl.pickup.etaTime) || ""
      },
      // Shipper value or consignee value - defaul to shipper
      shipper: {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        shipper: item.billingControl.shipper.name || "",
        billingShipperPO: item.billingControl.shipper.pickupNumber?.substring(0, 10) || "",
        shipperAddress: item.billingControl.shipper.address || "",
        shipperCity: item.billingControl.shipper.city || "",
        shipperState: item.billingControl.shipper.state || "",
        shipperZip: item.billingControl.shipper.zip || "",
        shipperPhone: item.billingControl.shipper.phone || "",
        shipperFax: item.billingControl.shipper.fax || "",
      },
      customerNotes: {
        internalNotes: item.billingControl.customerNotes.internalNotes || "",
      },
      driverNotes: {
        driverNotes: item.billingControl.driverNotes.driverNotes || "",
        driverNotes2: item.billingControl.driverNotes.driverNotes2 || "",
      },
      masterDetails: {
        billOfLading: item.billingControl.masterLoadDetails.billOfLading || "",
        purchaseOrder: item.billingControl.masterLoadDetails.purchaseOrder || "",
      },
      orderType: item.billingControl.orderType || "",
    }


    let stops = item.stops.map(stop => {
      return {
        lineNumber: stop.newStop || stop.stop || "",
        newLineNumber: stop.newStop || stop.stop,
        code: stop.consignee || "",
        pickupNumber: replaceSpecialCharacters(stop.pickupNumber, '-') || "",
        name: stop.name || "",
        address: stop.address || "",
        city: stop.city || "",
        state: stop.state || "",
        zip: stop.zip || "",
        phone: stop.Phone || "",
        fax: stop.fax || "",
        contact: stop.contact || "",
        contactEmail: stop.contactEmail || "",
        poPU: stop.contactEmail || "",
        shipperBillOfLadding: stop.shipperBillOfLadding || "",
        apptDate: validateDateField(stop.appointmentDate) || "",
        apptTime: stop.appointmentTime || "",
        pieces: stop.totalPieces || "",
        weight: stop.totalWeight || "",
        commodity: stop.commodity || "",
        doorSeal: stop.doorSeal || "",
        arriveDate: validateDateField(stop.arriveDate) || "",
        arriveTime: validateTimeField(stop.arriveTime) || "",
        spotDate: validateDateField(stop.spotDate) || "",
        spotTime: validateTimeField(stop.spotTime) || "",
        unloadDate: validateDateField(stop.unloadDate) || "",
        unloadTime: validateTimeField(stop.unloadTime) || "",
        departDate: validateDateField(stop.departDate) || "",
        departTime: validateTimeField(stop.departTime) || "",
        availDate: validateDateField(stop.availDate) || "",
        availTime: validateTimeField(stop.availTime) || "",
        etaDate: validateDateField(stop.etaDate) || "",
        etaTime: validateTimeField(stop.etaTime) || "",
        arriveTruck: stop.arriveTruck || "",
        arriveDriver: stop.arriveDriver || "",
        departTruck: stop.departTruck || "",
        departDriver: stop.departDriver || "",
        arriveTruckStatus: stop.arriveTruckStatus || "",
        departTruckStatus: stop.departTruckStatus || "",
        arriveDriverStatus: stop.arriveDriverStatus || "",
        departDriverStatus: stop.departDriverStatus || "",
        tempPulp: stop.tempPulp || "",
        internalNotes: stop.internalNotes || "",
        driverNotes: stop.driverNotes || "",
        driverNotes2: stop.driverNotes2 || "",
      }
    })

    // Order type is OUTBOUND OR EXPORT
    if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
      // Shipper becomes the first Stop

      billingControl.shipper = {
        shipperOrConsShipper: stops[0]?.code || "",
        shipper: stops[0]?.name || "",
        billingShipperPO: stops[0]?.pickupNumber?.substring(0, 10) || "",
        shipperAddress: stops[0]?.address || "",
        shipperCity: stops[0]?.city || "",
        shipperState: stops[0]?.state || "",
        shipperZip: stops[0]?.zip || "",
        shipperPhone: stops[0]?.phone || "",
        shipperFax: stops[0]?.fax || "",
      }
      // Consignee Becomes the Shipper
      billingControl.consignee = {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        consignee: item.billingControl.shipper.name || "",
        billingConsigneePO: item.billingControl.shipper.pickupNumber?.substring(0, 10) || "",
        consigneeAddress: item.billingControl.shipper.address || "",
        consigneeCity: item.billingControl.shipper.city || "",
        consigneeState: item.billingControl.shipper.state || "",
        consigneeZip: item.billingControl.shipper.zip || "",
        consigneePhone: item.billingControl.shipper.phone || "",
        consigneeFax: item.billingControl.shipper.fax || "",
      }
      billingControl.orderType = billingControl.orderType === "OUTBOUND" ? "DOMOUT" : "EXPORT"
    }
    else if (billingControl.orderType === "INBOUND" || billingControl.orderType === "IMPORT") {
      // Consignee is First Stop
      billingControl.consignee = {
        shipperOrConsShipper: stops[0]?.code || "",
        consignee: stops[0]?.name || "",
        billingConsigneePO: stops[0]?.pickupNumber?.substring(0, 10) || "",
        consigneeAddress: stops[0]?.address || "",
        consigneeCity: stops[0]?.city || "",
        consigneeState: stops[0]?.state || "",
        consigneeZip: stops[0]?.zip || "",
        consigneePhone: stops[0]?.phone || "",
        consigneeFax: stops[0]?.fax || "",
      }

      // Shipper is Shipper Already Assigned by Default
      billingControl.orderType = billingControl.orderType === "INBOUND" ? "DOMINB" : "IMPORT"
    }


    // let ratesChargesarray = [];
    // console.log('rates charge saving', item.ratecharges);
    // item.ratecharges.map((item, indx) => {
    //   let rowIndex = Number(indx) + 1;
    //   if (item.payable.payableReceivable === 'P') {
    //     const { subItems: _, splitItems: __, ...newObj } = item?.payable;

    //     ratesChargesarray.push(ratesChargesObjsForServer(newObj));

    //     if (Object.keys(item?.payable).length !== 0) {
    //       item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.map(subitem => {
    //         //if (subitem?.splitItems?.length === 0) {
    //         ratesChargesarray.push(ratesChargesObjsForServer(subitem));
    //         //}
    //         subitem?.splitItems?.map(splititem => {
    //           ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
    //         })
    //       })

    //       item?.payable?.splitItems?.map(splitItems => {
    //         ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
    //       })

    //     }
    //   }

    //   if (typeof item.payable.payableReceivable === 'undefined' && item.payable?.splitItems?.length > 0) {

    //     item?.payable?.splitItems?.map(splitItems => {
    //       ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
    //     })

    //     if (item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.[0]?.splitItems?.length > 0) {
    //       item.payable?.subItems?.[0]?.splitItems?.map(splititem => {
    //         ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
    //       })
    //     }

    //   }

    //   if (item.receivable.payableReceivable === 'R') {

    //     const { subItems: _, splitItems: __, ...newObj } = item?.receivable;
    //     ratesChargesarray.push(ratesChargesObjsForServer(newObj));

    //     if (Object.keys(item?.receivable).length !== 0) {

    //       item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems?.map(subitem => {
    //         ratesChargesarray.push(ratesChargesObjsForServer(subitem));
    //       })

    //     }
    //   }
    // });

    // const ratesCharges = ratesChargesarray.sort((a, b) => a.lineNumber - b.lineNumber).filter(item => item.lineNumber !== '');
    // return { ...billingControl, stops }
    const ratesCharges = normalizeRates(item)
    return { ...billingControl, stops, ratesCharges }

  }
  catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

const normalizeRates = (item) => {
  let ratesChargesarray = [];
    console.log('rates charge saving', item);
    item.ratecharges.map((item, indx) => {
      let rowIndex = Number(indx) + 1;
      if (item.payable.payableReceivable === 'P') {
        const { subItems: _, splitItems: __, ...newObj } = item?.payable;

        ratesChargesarray.push(ratesChargesObjsForServer(newObj));

        if (Object.keys(item?.payable).length !== 0) {
          item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.map(subitem => {
            //if (subitem?.splitItems?.length === 0) {
            ratesChargesarray.push(ratesChargesObjsForServer(subitem));
            //}
            subitem?.splitItems?.map(splititem => {
              ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
            })
          })

          item?.payable?.splitItems?.map(splitItems => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
          })

        }
      }

      if (typeof item.payable.payableReceivable === 'undefined' && item.payable?.splitItems?.length > 0) {

        item?.payable?.splitItems?.map(splitItems => {
          ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
        })

        if (item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.[0]?.splitItems?.length > 0) {
          item.payable?.subItems?.[0]?.splitItems?.map(splititem => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
          })
        }

      }

      if (item.receivable.payableReceivable === 'R') {

        const { subItems: _, splitItems: __, ...newObj } = item?.receivable;
        ratesChargesarray.push(ratesChargesObjsForServer(newObj));

        if (Object.keys(item?.receivable).length !== 0) {

          item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems?.map(subitem => {
            ratesChargesarray.push(ratesChargesObjsForServer(subitem));
          })

        }
      }
    });

    const ratesCharges = ratesChargesarray.sort((a, b) => a.lineNumber - b.lineNumber).filter(item => item.lineNumber !== '');
    return ratesCharges;
}
const recAgainstLineNumber = (obj) => {
  if (typeof obj?.lineNumber !== 'undefined' && obj?.lineNumber !== 0) {
    return obj.lineNumber;
  }
  return 0;
}
const ratesChargesObjsForServer = (obj) => {
  const res = {
    lineNumber: obj.lineNumber,
    description: obj.description || "",
    notes: obj.notes || "",
    glDescription: obj.glDescription || "",
    glCode: obj.glCode || "",
    originName: obj.originName || "",
    originCity: obj.originCity || "",
    originPrefix: obj.originPrefix || "",
    destinationCity: obj.destinationCity || "",
    destinationStatePrefix: obj.destinationStatePrefix || "",
    accountLine: obj.accountLine || "",
    unitRate: obj.unitRate || "",
    units: obj.units || "",
    amount: obj.amount || "",
    transmitted: obj.transmitted || "",
    truckNumber: obj.truckNumber || "",
    parentLineNumber: obj.parentLineNumber || "",
    splitLineNumber: obj.splitLineNumber || "",
    loadType: obj.loadType || "",
    subPer: obj.subPer || "",
    payRec: obj.payRec || "",
    payType: obj.payType || "",
    payableRecievable: obj.payableReceivable || "",
    processedDate: validateDateField(obj.processedDate),
    department: getRCDepartment(obj.glCode),
    recAgainstSplitLine: obj.recAgainstSplitLine || 0,
    splitType: obj.splitType || '',
    commonSplitLine: obj.commonSplitLine || 0,
  }
  return res
}
const convertDateToCentury = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return "1" + moment(dateVal).format('YYMMDD');
  }

}

const getRCDepartment = (glCode) => {
  const glCodeArr = [2026000, 2035000, 2175000, 2177000];
  if (glCodeArr.includes(glCode)) {
    return 0;
  }
  return 310;
}
const getDefaultBillingControl = () => {
  return {
    orderBy: {
      orderBy: '',
      name: '',
      location: '',
      phone: '',
      fax: '',
      email: '',
      billTo: '',
      billToName: '',
      reference: '',
      contact: '',
      billingDate: '',
      orderContactSeq: 0
    },
    equipmentDetails: {
      unitNum: '',
      chassis: '',
      equipmentSize: '',
      release: '',
      ssl: '',
      booking: '',
      voyage: '',
      vessel: '',
      destinationPort: '',
      equipmentType: '',
      secondaryUnit: '',
      secondaryChassis: '',
      chassisProvider: '',
      chassisRelease: '',
      hazMat: 'N',
      tanker: 'N',
      overWeight: 'N',
      reefer: 'N',
      residential: 'N',
    },
    customerNotes: {
      internalNotes: ''
    },
    pickup: {
      // pickupNumber: '',
      pickupPoint: '',
      pickupDate: '',
      pickupTime: '',
      disposition: '',
      returnDate: '',
      returnTime: '',
      chassisPickup: '',
      chassisPickupDate: '',
      chassisPickupTime: '',
      chasisDisposition: '',
      chassisReturnDate: '',
      chassisReturnTime: '',
      notifyDate: '',
      notifyTime: '',
      lastFreeDay: '',
      lastFreeTime: '',
      lastPerDiemDays: '',
      etaDate: '',
      etaTime: ''
    },

    shipper: {
      shipper: '',
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      fax: '',
    },

    driverNotes: {
      driverNotes: '',
      driverNotes2: '',
    },

    masterLoadDetails: {
      billOfLading: '',
      purchaseOrder: '',
    }
  }
}
const getDefaultBillingControlold = () => {
  return {
    orderBy: {
      orderBy: '',
      name: '',
      location: '',
      phone: '',
      fax: '',
      billTo: '',
      billToName: '',
      unitNum: '',
      reference: '',
      equipmentSize: '',
      release: '',
      chassis: '',
      billingDate: ''
    },
    shipper: {
      shipper: '',
      name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      fax: '',
    },
    pickup: {
      pickupNumber: '',
      pickupPoint: '',
      disposition: '',
      chassisPickup: '',
      chasisDisposition: '',
      pickupDate: '',
      pickupTime: '',
      returnDate: '',
      returnTime: '',
      notifyDate: '',
      notifyTime: '',
      etaDate: '',
      etaTime: '',
    },
    notes: {
      secondaryUnit: '',
      lastFreeDay: '',
      lastPerDiemDays: '',
      remarks1: '',
      remarks2: '',
      hazMat: '',
    },
    importExport: {
      booking: '',
      voyage: '',
      vessel: '',
      destinationPort: ''
    }
  }
}
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography, Paper, Box, Button,
  CircularProgress, TablePagination, IconButton
} from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import { getRatesCalculations, billingReportProperties, FSCCodes } from '../CommonFn';
import { convertMilitaryDateToDDMMYY, sanitizeString } from '../../../utils/global-functions';

export default function DetailedSummary(props) {
  const { activeTab, setTotalRecords, rawData, locationNumber, isLoading, filterParams } = props;
  const [rows, setRows] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  useEffect(() => {
    if (rawData) {
      handleFilter(filterParams);
    }
  }, [rawData])

  const handleFilter = (filterParams) => {
    // Extract filters
    const {
      dateType,
      revenuePercentage,
      excludeFSC,
      orderBy,
      billTo,
      dateRange,
      startingPercentage,
      endingPercentage
    } = filterParams;

    // Make a deep copy of the data to avoid mutating the original
    const clonedData = JSON.parse(JSON.stringify(rawData));

    // Helper function to check if a value falls in a range
    const isInRange = (value, start, end) => value >= start && value <= end;

    const filteredData = clonedData.filter((item, index) => {
      const source = item;

      // Filter by dateRange using dateType (e.g., billingDate)
      if (dateType && dateRange && dateRange.length === 2) {
        const dateValue = source[dateType];
        if (!isInRange(dateValue, dateRange[0], dateRange[1])) {
          return false;
        }
      }

      // Filter by billTo (customerNumber)
      if (billTo && source.billTo !== parseInt(billTo)) {
        return false;
      }

      // Filter by order By (shipperAgentCode)
      if (orderBy && source.shipperAgentCode !== orderBy) {
        return false;
      }

      // Filter by excludeFSC (exclude entries with FUEL SURCHARGE in glDescription)
      if (excludeFSC) {
        source.rates = source.rates.filter(rate => !FSCCodes.includes(rate.glCode));
      }

      // Filter by revenuePercentage (based on startingPercentage and endingPercentage)
      if (revenuePercentage && startingPercentage && endingPercentage) {

        const { totalRevenue, totalExpense, totalNet, totalPercent, ptNet, ptPercent, receivables, payables } = getRatesCalculations(source.rates, excludeFSC);

        if (revenuePercentage === 'totalPercent' && !isInRange(totalPercent, parseFloat(startingPercentage), parseFloat(endingPercentage))) {
          return false;
        }

        if (revenuePercentage === 'ptPercent' && !isInRange(ptPercent, parseFloat(startingPercentage), parseFloat(endingPercentage))) {
          return false;
        }
      }

      return true;
    });

    // Sort by controlNumber if required
    // if (sortByControl) {
    //   filteredData.sort((a, b) => a._source.controlNumber - b._source.controlNumber);
    // }

    setTotalRecords([filteredData.length, rawData.length])
    setRows(filteredData);
  }


  const PrintSummaryByDay = () => {
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
        <html>
          <head>
            <title>Detail By Control #</title>
            <style>
              body {
                font-family: Arial, sans-serif;
                margin: 0;
                padding: 20px;
              }
              table {
                width: 100%;
                border-collapse: collapse;
                margin-bottom: 20px;
              }
              th, td {
                border: 1px solid rgb(165, 165, 165);
                padding: 8px;
                text-align: left;
              }
              th {
                background-color: #f2f2f2;
                font-weight: bold;
              }
              .bold {
                font-weight: bold;
              }
              .align-right {
                text-align: right;
              }
              .mt-2 {
                margin-top: 16px;
              }
              @media print {
                body {
                  margin: 0;
                  padding: 0;
                }
                table {
                  page-break-inside: auto;
                }
                tr {
                  page-break-inside: avoid;
                  page-break-after: auto;
                }
                @page {
                  margin: 0.5in;
                }
              }
            </style>
          </head>
          <body>
            <table style="border-collapse: collapse; width: 100%; border: none;">
              <tbody>
                ${rows?.length > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
      const e = billingReportProperties(item, index);
      return `
                    <tr key="${index}">
                      <td style="vertical-align: top; padding: 0; width: 44%; border: 1px solid rgb(165, 165, 165);">
                        <table style="border-collapse: collapse; width: 100%;">
                          <tbody>
                            <tr>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Control#</span><br>
                                <a href="/orders/${locationNumber}/${e.controlNumber}" target="_blank" style="text-decoration: underline;">${e.controlNumber}</a>
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Billing Date</span><br>
                                ${convertMilitaryDateToDDMMYY(e.billingDate)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Shipper</span><br>
                                ${sanitizeString(e.shipperName)}
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Customer</span><br>
                                ${sanitizeString(e.customerName)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Return Date</span><br>
                                ${convertMilitaryDateToDDMMYY(e.returnDate)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Shipper City, ST</span><br>
                                ${sanitizeString(e.shipperAddress)}
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Bill To Code</span><br>
                                ${sanitizeString(e.billToCode)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Unit#</span><br>
                                ${sanitizeString(e.unitNumber)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Consignee</span><br>
                                ${sanitizeString(e.consigneeName)}
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Reference#</span><br>
                                ${sanitizeString(e.reference)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">PU/Disp Loc</span><br>
                                ${[e.PULoc, e.dispositionLoc].filter(Boolean).join('/')}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Consignee City, ST</span><br>
                                ${sanitizeString(e.consigneeAddress)}
                              </td>
                            </tr>
                            <tr>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">I/O</span><br>
                                ${sanitizeString(e.io)}
                              </td>
                              <td style="border: none; padding: 4px 8px;">
                                <span class="bold">Appt Date</span><br>
                                ${convertMilitaryDateToDDMMYY(e.apptDate)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td style="vertical-align: top; padding: 2; width: 28%; border: 1px solid rgb(165, 165, 165);">
                        <table style="border-collapse: collapse; width: 100%;">
                          <tbody>
                            <tr>
                              <td class="bold">Receivable</td>
                              <td class="bold">Description</td>
                              <td class="bold" align="right">Revenue</td>
                            </tr>
                            ${e.receivables.length > 0 ? e.receivables.map((row) => `
                              <tr>
                                <td>${row.receivable}</td>
                                <td>${row.description}</td>
                                <td align="right">${row.amount}</td>
                              </tr>
                            `).join('') : ''}
                          </tbody>
                        </table>
                        <div class="mt-2">
                          <span class="bold" style="float: right;">Total Revenue: ${e.totalRevenue}</span><br>
                          <span class="bold" style="float: right;">Total Net: ${e.totalNet}</span><br>
                          <span class="bold" style="float: right;">Total %: ${e.totalPercent}</span>
                        </div>
                      </td>
                      <td style="vertical-align: top; padding: 2; width: 28%; border: 1px solid rgb(165, 165, 165);">
                        <table style="border-collapse: collapse; width: 100%;">
                          <tbody>
                            <tr>
                              <td class="bold">Payables</td>
                              <td class="bold">Description</td>
                              <td class="bold">Truck</td>
                              <td class="bold" align="right">Amount</td>
                            </tr>
                            ${e.payables.length > 0 ? e.payables.map((row) => `
                              <tr>
                                <td>${row.payable}</td>
                                <td>${row.description}</td>
                                <td>${row.truck}</td>
                                <td align="right">${row.amount}</td>
                              </tr>
                            `).join('') : ''}
                          </tbody>
                        </table>
                        <div class="mt-2">
                          <span class="bold" style="float: right;">Total Expense: ${e.totalExpense}</span><br>
                          <span class="bold" style="float: right;">PT %: ${e.ptPercent}</span><br>
                          <span class="bold" style="float: right;">Total PT Net: ${e.ptNet}</span>
                        </div>
                      </td>
                    </tr>
                  `;
    }).join('') : ''}
              </tbody>
            </table>
          </body>
        </html>
      `);

    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  }

  return (
    <ComponentWrapper>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'absolute',
          //bottom:0,
          right: "1%",
          zIndex: 9999,
          marginTop: "-2%"
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => PrintSummaryByDay(rows)}
          startIcon={<PictureAsPdfIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          PDF
        </Button>
      </Stack>

      <Stack
        sx={{
          height: window.innerHeight - 310,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(11, 67, 135, 1)",
            color: "white",
          },
          "& .isSubtotal": {
            fontWeight: "bold",
            // color: "blue",
            backgroundColor: "#E8E8E8 !important",
            // backgroundColor: "#F0F0F0 !important",
          },

        }}
      >
        {/* <Button
                variant="text"
                size="small"
                onClick={PrintSummaryByDay}
                startIcon={<SaveAltIcon color="primary" />}
                color={`primary`}
                sx={{
                  display:'flex',
                  justifyContent:'flex-end'
                }}
            >
                EXPORT
            </Button> */}
        <TableContainer component={Paper} sx={{}} id="table-to-print">
          {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
              <CircularProgress />
              <Typography variant="h6" style={{ marginLeft: '20px' }}>
                Loading data...
              </Typography>
            </div>
          ) : (
            <Table sx={{ borderCollapse: 'collapse', width: '100%', border: 'none' }}>
              <TableBody>
                {rows.length > 0 && rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                  const source = item;
                  let e = billingReportProperties(source, index)
                  return (
                    <TableRow key={index}>
                      {/* First TD: Vertical Table for Header Information */}
                      <TableCell sx={{ verticalAlign: 'top', padding: 0, width: '44%', border: '1px solid rgb(165 165 165)' }}>
                        <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Control#</Typography>
                                <Typography><a href={`/orders/${locationNumber}/${e.controlNumber}`} target="_blank" style={{ textDecoration: 'underline' }}>{e.controlNumber}</a></Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Billing Date</Typography>
                                <Typography>{convertMilitaryDateToDDMMYY(e.billingDate)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Shipper</Typography>
                                <Typography>{sanitizeString(e.shipperName)}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Customer</Typography>
                                <Typography>{sanitizeString(e.customerName)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Return Date</Typography>
                                <Typography>{convertMilitaryDateToDDMMYY(e.returnDate)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Shipper City, ST</Typography>
                                <Typography>{sanitizeString(e.shipperAddress)}</Typography>
                              </TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Bill To Code</Typography>
                                <Typography>{sanitizeString(e.billToCode)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Unit#</Typography>
                                <Typography>{sanitizeString(e.unitNumber)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Consginee</Typography>
                                <Typography>{sanitizeString(e.consigneeName)}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Reference#</Typography>
                                <Typography>{sanitizeString(e.reference)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>PU/Disp Loc</Typography>
                                <Typography>{[e.PULoc, e.dispositionLoc].filter(Boolean).join('/')}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Consignee City, ST</Typography>
                                <Typography>{sanitizeString(e.consigneeAddress)}</Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>I/O</Typography>
                                <Typography>{sanitizeString(e.io)}</Typography>
                              </TableCell>
                              <TableCell sx={{ border: 'none', padding: '4px 8px' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Appt Date</Typography>
                                <Typography>{convertMilitaryDateToDDMMYY(e.apptDate)}</Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableCell>

                      <TableCell sx={{ verticalAlign: 'top', padding: 2, width: '28%', border: '1px solid rgb(165 165 165)' }}>
                        <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
                          <TableBody>
                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Receivable</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="right">Revenue</TableCell>
                            </TableRow>
                            {e.receivables.length > 0 && e.receivables.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.receivable}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell align="right">{row.amount}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>

                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>Total Revenue: {e.totalRevenue}</Typography>
                          <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>Total Net: {e.totalNet}</Typography>
                          <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>Total %: {e.totalPercent}</Typography>
                        </Box>
                      </TableCell>

                      <TableCell sx={{ verticalAlign: 'top', padding: 2, width: '28%', border: '1px solid rgb(165 165 165)' }}>
                        <Table sx={{ borderCollapse: 'collapse', width: '100%' }}>
                          <TableBody>

                            <TableRow>
                              <TableCell sx={{ fontWeight: 'bold' }}>Payables</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }}>Truck</TableCell>
                              <TableCell sx={{ fontWeight: 'bold' }} align="right">Amount</TableCell>
                            </TableRow>
                            {e.payables.length > 0 && e.payables.map((row, index) => (
                              <TableRow key={index}>
                                <TableCell>{row.payable}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                <TableCell>{row.truck}</TableCell>
                                <TableCell align="right">{row.amount}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Box sx={{ mt: 2 }}>
                          <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>Total Expense: {e.totalExpense}</Typography>
                          <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>PT %: {e.ptPercent}</Typography>
                          <Typography variant="body2" align="right" sx={{ fontWeight: 'bold' }}>Total PT Net: {e.ptNet}</Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          )}

        </TableContainer>
      </Stack>
      {/* Pagination Controls */}
      <TablePagination
        component="div"
        count={rows.length}
        page={page}
        onPageChange={(event, newPage) => {
          setPage(newPage);
        }}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
      />
    </ComponentWrapper>
  );
}

import React, { useState } from "react";
import { Autocomplete, TextField, } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';

export default function DriverAutoComplete(props) {
    const { valueChange } = props;
    const [value, setValue] = useState({ driverId: "", driverName: "" });
    const options = useSelector(
        ({ dispatchReducer }) => dispatchReducer.activeTrucks
    );

    return (
        <Autocomplete
            disableClearable
            size={`small`}
            options={options.filter(e => e.isAvailable === 'Y')}
            getOptionLabel={(option) => `${(option.driverId) ? option.driverName : ''}`}
            value={value}
            onChange={(event, value, reason) => {
                if (typeof (value) === 'string') {
                    setValue({ driverId: value, driverName: "" });
                } else {
                    setValue(value);
                }

                if (typeof (value) === 'object') {
                    valueChange(value)
                }
            }}
            style={{ width: '50%' }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="glCode"
                    //label="GL Desc"
                    variant="standard"
                    sx={{
                        input: {
                            textTransform: 'uppercase'
                        }
                    }}
                />
            )}
        />
    )
}
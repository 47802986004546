import { Popover, List, ListItem, ListItemButton, ListItemText } from "@mui/material";

export default function PopOver(props){
    

    const {anchorEl,handleClose,popOverID,ordersLocation,open,setSelected,setTemplateObj,setSelectedRow,setOrderType,setEditTemplate,setAddNewTemplate, addNewTemplate,editTemplate}=props;

    const handleClick=(event)=>{

        if(editTemplate||addNewTemplate){
            let confirmAlert = window.confirm("Please save your changes or click 'OK' to open a new Template ");
            if (confirmAlert) {
                setSelected(event);
                setOrderType(event)
                let row={
                    id:'',
                    orderby:'',
                    stop:'',
                    tamplatename:'',
                    type:event,
        
                }
                setTemplateObj(row)
                setSelectedRow('');
                handleClose();
                setAddNewTemplate(true)
                setEditTemplate(false)
          }
           
          } else {
            setSelected(event);
        setOrderType(event)
        let row={
            id:'',
            orderby:'',
            stop:'',
            tamplatename:'',
            type:event,

        }
        setTemplateObj(row)
        setSelectedRow('');
        handleClose();
        setAddNewTemplate(true)
        setEditTemplate(false)
        
            
          }  
      
    }

    return(
        <Popover
        id={popOverID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
    >
        <List>
            <ListItem disablePadding divider>
                <ListItemButton component="a" onClick={(event)=>{handleClick(event?.target?.innerText)}} dense>
                    <ListItemText primary="INBOUND" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding divider>
                <ListItemButton component="a" onClick={(event)=>{handleClick(event?.target?.innerText)}} dense>
                    <ListItemText primary="OUTBOUND" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding divider>
                <ListItemButton component="a" onClick={(event)=>{handleClick(event?.target?.innerText)}} dense>
                    <ListItemText primary="IMPORT" />
                </ListItemButton>
            </ListItem>
            <ListItem disablePadding divider>
                <ListItemButton component="a" onClick={(event)=>{handleClick(event?.target?.innerText)}} dense>
                    <ListItemText primary="EXPORT" />
                </ListItemButton>
            </ListItem>

        </List>
    </Popover>
    )
}
import { useEffect, useState } from "react";

import {
  Paper,
  TableContainer,
  Box,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  reqQuery
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector, useDispatch } from "react-redux";

import PickupDispositionTable from "./components/PickupDispositionTable";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { searchShipper,getShipperData } from "../../../store/actions/shipperActions";
import { maptopickUpDispositionTable } from "./components/commonFn";
import Chip from "@mui/material/Chip";



export default function PickupDispositionTableContainer(props) {
  const {
    setSelectedRow,
    setRows,
    // setRowsData,
    rows,
    // rowsData,
    isLoading,
    pickUpDispositionTableApiCall,
    setRowsCount,
    rowsCount,
    setAddNewPickUpDispo,
    setEditShipperClicked,
    setIsLoading,
    selectedRow,
    addNewPickUpDispo,
    editShipperClicked

 
  } = props;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [shipperSearchError, setShipperSearchError] = useState("");
  const open = Boolean(anchorEl);
  const[selectedLocationList,setSelectedLocationList]=useState([])
  // const popOverID = open ? "new-order-popover" : undefined;
  const locationList = useSelector(({ loadsReducer }) => loadsReducer.locationList);
  const stringAllLocationList = locationList?.map(item => String(item.locationNumber)) || [];
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const handleSearchText = (event) => {
    setSearchText(event);
    if (event === "") {
      handleSearch("");
    }
  };
  const handleSearch = (searchName) => {
    console.log("searchName",searchName)


      setIsLoading(true)
      pickUpDispositionTableApiCall(
        { page: 1, pageSize: 50 },
       "",
        searchName,
        selectedLocationList,
      );
    
}
  useEffect(() => {}, [rows]);

  const handleQueryPageChange = (
    params,
    sortData = {
      field: "name",
      sort: "asc",
    },
   
  ) => {
    setSelectedRow("");
      pickUpDispositionTableApiCall(
        { page: params?.page + 1, pageSize: params?.pageSize },
        sortData,
        searchText,
        selectedLocationList,
      );
    // }
  };
  const handleSortChange = (
    params,
    sortData = {
      field: "name",
      sort: "asc",
    }
  ) => {
    setSelectedRow("");
      pickUpDispositionTableApiCall(
        { page: params?.page + 1, pageSize: params?.pageSize },
        sortData,
        searchText,
        selectedLocationList,
      );
  };
  const isNewOrderClicked=()=>{

    console.log("addNewPickUpDispo:",addNewPickUpDispo,"editShipperClicked:",editShipperClicked)
    if(editShipperClicked || addNewPickUpDispo){
      let confirmAlert = window.confirm("Please save your changes or click 'OK' to open a new Pickup/Disposition form ");
      if (confirmAlert) {
        setAddNewPickUpDispo(true);
      setSelectedRow(""); 
       
    }
   
     
    } else {
      setAddNewPickUpDispo(true);
      setSelectedRow("");
    }
  
  }
  const handleChangeLocationData = (event, value) => {
    console.log("value",value)
    const locationNumbers = value;
   
    setSelectedLocationList(locationNumbers);
    pickUpDispositionTableApiCall(
      { page: 1, pageSize: 50 },
      "",
      searchText,
      locationNumbers,
    );
  
  }
// console.log("rowsData-contable",rowsData)
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <label style={{ fontSize: "14px", color: "#091DA3" }}>
          Equip/Pickup/Disposition
        </label>
      </div>

      <div
        style={{
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "40%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            paddingRight: "5px",
          }}
        >
          <TextField
            label="Search"
            margin="dense"
            style={{ height: "40px", width: "100%", fontSize: "10px" }}
            size="small"
            inputProps={{ style: { fontSize: 10 } }}
            InputLabelProps={{ style: { fontSize: 14, height: 40 } }}
            value={searchText}
            
            // onChange={(event) => { setSearchText(event.target.value) }}
            onChange={(event) => {
              handleSearchText(event.target.value);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && handleSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      searchText ? handleSearchText("") : handleSearch();
                    }}
                  >
                    {searchText ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                  {/* <IconButton onClick={() => { handleSearch() }}>
                                        <SearchIcon />
                                    </IconButton> */}
                </InputAdornment>
              ),
            }}
          />
        </div>
      
       <div
        style={{
          width: "30%",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Autocomplete
        limitTags={1}
          multiple
          options={stringAllLocationList}
          getOptionLabel={(option) => option}
          value={selectedLocationList}
          onChange={handleChangeLocationData}
          freeSolo={false}
          disableClearable
         
          renderTags={(value) => (
            <Box
              style={{
                display: 'flex',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedLocationList.length <= 2
                ? value.join(', ')
                : `${selectedLocationList.slice(0, 2).join(', ')}...`}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Location"
              // placeholder={selectedLocationList}
              size="small"
            />
          )}
          // style={{ width: "100%" }}
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            height: '40px',
          }}
  
        />
  
      </div>
        <div
          style={{
            width: "30%",
            height: "40px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant="outlined"
            style={{ width: "100%", height: "35px", fontSize: "10px" }}
            onClick={isNewOrderClicked}
            // onClick={() => {
            //   setAddNewPickUpDispo(true);
            //   setSelectedRow("");
            // }}
            endIcon={
              <AddCircleOutlineIcon style={{ width: "15px", height: "15px" }} />
            }
          >
            New Pick/Disp
          </Button>
        </div>
      </div>

      <div style={{ width: "100%", height: `calc(100vh - 200px)` }}>
        {shipperSearchError ? (
          <div style={{ color: "red", textAlign: "center" }}>
            {shipperSearchError}
          </div>
        ) : (
          <PickupDispositionTable
            rows={rows}
            // rowsData={rowsData}
            isLoading={isLoading}
            // setSelected={setSelected}
            setSelectedRow={setSelectedRow}
            handleQueryPageChange={handleQueryPageChange}
            handleSortChangeData={handleSortChange}
            rowsCount={rowsCount}
            setAddNewPickUpDispo={setAddNewPickUpDispo}
            pickUpDispositionTableApiCall={pickUpDispositionTableApiCall}
            setEditShipperClicked={setEditShipperClicked}
            editShipperClicked={editShipperClicked}
            addNewPickUpDispo={addNewPickUpDispo}
          />
        )}
      </div>

    </Paper>
  );
}

import { Paper, Button } from "@mui/material";
import { Grid } from "@material-ui/core";

import "./Styles.css";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/Loader/Lodaer";
import { StyledSceneContent } from "../../../components/styledComponents";
import {getSingleOrderByData,saveOrderByContactData,getOrderByContact} from "../../../store/actions/maintenanceOrderByActions";
import { useHistory } from "react-router-dom";
import { OrdersWrapper } from "../../../components/styledComponents";
import ContactTable from "./components/allOrderByForms/ContactTable";
import PickupDispoForm from "./components/allOrderByForms/OrderByForm";
import {
  mapShipperContactToServer,
  maptoClientContactTable
} from "./components/commonFn";

export default function OrderByFormContainer(props) {
  const {
    selectedRow,
    addNewPickUpDispo,
    orderByTableApiCall,
    formKey,
    setAddNewPickUpDispo,
    setSelectedRow,
    onClose,
    isOrderScreen,
    shipperSelect,
    shipperOptions,
    stopState,
    newShipper,
    handleAddPickupDispo,
    isEditScreen,
    onEditShipper,
    //new
    editDataSendToModal
  } = props;

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState("");

  const [editShipper, setEditShipper] = useState(isEditScreen?true:false);

  const [isLoading, setIsLoading] = useState(
    params.controlNumber === "new" ? false : true
  );
  const contactRowsRef = useRef([]);
  const orderByFormRef = useRef([]);


  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );


// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(() => {
  setIsLoading(true);
  orderByFormRef.current = [];
  contactRowsRef.current = [];

  const fetchData = async () => {
    const code= selectedRow ||editDataSendToModal?.code||""
      try {
        const res = await dispatch(getSingleOrderByData({
                  locationNumber: ordersLocation?.locationNumber,
                  code:code
              }));
              setFormValues(res);
         
      } catch (error) {
          console.error("Error fetching data:", error);
      } finally {
          setIsLoading(false);
      }
  };

  fetchData();
}, [formKey, selectedRow, addNewPickUpDispo, shipperSelect]);
const setFormValues = (mapShipperForm) => {
  if (mapShipperForm) {
      orderByFormRef.current = mapShipperForm.shipperForm;
      contactRowsRef.current = mapShipperForm.shipperContacts || [];
  } else {
      // If mapShipperForm is not provided or invalid, reset form values
      orderByFormRef.current = [];
      contactRowsRef.current = [];
  }
};

  const handleCancelClick = () => {
    // setAddNewPickUpDispo(false)
    if(isOrderScreen){
      onClose()}else{
        setAddNewPickUpDispo(false);
  
      }
    // setEditShipper(false);
  
  };

  const handleCancelEdit = () => {
    if(isOrderScreen){
      onClose()}else{
        setAddNewPickUpDispo(false);
  
      }
      setEditShipper(false);
            
  };

  const handleSave = async (type = "new") => {

let shipperCode=orderByFormRef.current.code
    if (shipperCode) {
      setSnackOpen(true);
      setSnackContent({
        severity: "info",
        message: `Pickup/Disposition Data ${type === "new" ? "Saving" : "Updating"} ...`,
      });
      const mapShipContactToServer=mapShipperContactToServer(contactRowsRef.current,orderByFormRef.current,ordersLocation.locationNumber)
const postObj=mapShipContactToServer

saveContactAPICall(postObj)

    }

    else {
        setSnackOpen(true)
        if(!shipperCode){

        setSnackContent({ severity: "error", message: "shipperCode is mandatory!" })
        }else{
            setSnackContent({ severity: "error", message: "something went wrong!" })
        }
    }
  };
 

  const saveContactAPICall = (postObj) => {
    dispatch(saveOrderByContactData(ordersLocation.locationNumber, postObj))
    .then(
      (res) => {
        console.log("---res-shipperForm", res);
        if (
          res?.httpCode === 200
          
        ) {
          console.log("")
          if(postObj?.[0]?.agentCode){
            dispatch(getOrderByContact(postObj?.[0]?.agentCode))
            .then(
              (res) => {
                const mapContactToclient=maptoClientContactTable(res?.data,orderByFormRef.current)
                contactRowsRef.current = mapContactToclient  || [];
              })

          }
         
          // http://localhost:3002/api/contacts/list/code/EXEL447
          setSnackOpen(true);
          setSnackContent({
            severity: "success",
            message:res?.message 
             
          });
          
            setEditShipper(false)
            onEditShipper(false)
       
         
        } else{


          setSnackOpen(true);
          setSnackContent({
            severity: "error",
            message: res?.message
               
          });
        }
      }
    );
  };
  
  const handleClose = (event) => {
            setSnackOpen(false);
  };

  const handleContactData = (contactData, isAddContact) => {
    console.log("data", contactData, isAddContact);
  };
  
//  console.log("orderByFormRef.current",orderByFormRef.current)
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          {editDataSendToModal?.type?<label style={{ fontSize: "14px", color: "#091DA3" }}>
          {editDataSendToModal?.type} {editDataSendToModal?.field} Point
          </label>:<label style={{ fontSize: "14px", color: "#091DA3" }}>
          {/* Equip Pickup Disposition Point */}
          </label>}
        </div>
        {addNewPickUpDispo || editShipper ? (
          <div>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={<SaveIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                handleSave(editShipper ? "edit" : "new");
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={editShipper ? handleCancelEdit : handleCancelClick}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            {/* <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={
                <DeleteIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={handleDeleteClick}
              disabled={true}
            >
              Delete
            </Button> */}
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={<EditIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                setEditShipper(true);
                onEditShipper(true)
              }}
            >
              Edit
            </Button>
            {/* {isOrderScreen?(<Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
                marginLeft:10
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={ handleCancelClick}
            >
              Cancel
            </Button>):null} */}
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div >
        <StyledSceneContent>
          <OrdersWrapper >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{pointerEvents: "none", opacity: "0.8" }}>
                <PickupDispoForm orderByFormRef={orderByFormRef}/>
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div style={{ height: "100%" }}>
                  <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={editShipper || addNewPickUpDispo? {}: {pointerEvents: "none", opacity: "0.8" }}>
                      <ContactTable
                        contactRowsRef={contactRowsRef}
                        onContactData={handleContactData}
                      />
                    </Grid>
                  
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </OrdersWrapper>
        </StyledSceneContent>
        </div>
      )}
      <Snackbar
        open={snackOpen}
        style={{ marginTop: "5%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackContent.severity}
          sx={{ width: "100%" }}
        >
          {snackContent.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export const capitalize = text => {
    if(!text) return '-';
    try {
        return text
            .toLowerCase()
            .split(' ')
            .map(word => {
                return word[0].toUpperCase() + word.slice(1);
            })
            .join(' ');
    } catch (e) {
        return '';
    }
};
import React, { useContext } from "react";
import { TextField, Paper, FormControlLabel, FormGroup } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Checkbox from '@mui/material/Checkbox'
import './Styles.css'
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { GET } from '../../../utils/axios'
import orderContext from '../orderContext';

export default function CustomerNotes(props) {

    const { billingControlRef, isTemplateScreen } = props
    const dispatch = useDispatch();

    // Local State for Select Box of Order By
    const [customerNotesState, setCustomerNotes] = useState(billingControlRef.current.customerNotes)
    // const [customerNotesState, setCustomerNotes] = useState('')
    const { setIsStateChanged } = useContext(orderContext);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('customer notes fields are changes')
        setIsStateChanged(true);
        /** END */
    }

    return (
        (isTemplateScreen ? ( 
        <div className="mainContainer customernotes" >
            <Divider
                textAlign="left"
                className="dividersty"
            >
                <Chip label="Customer Notes" size="small" variant="outlined" />
            </Divider>
            <Paper className="paperstyl" variant="outlined">
                <TextField
                    label="Internal Notes"
                    size="small"
                    fullWidth
                    value={customerNotesState?.internalNotes || ''}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    multiline
                    rows={10}
                    onChange={(event) => {
                        // console.log(event.target.value)
                        setCustomerNotes({
                            ...customerNotesState,
                            internalNotes: event.target.value
                        })
                        billingControlRef.current = {
                            ...billingControlRef.current,
                            customerNotes: {
                                ...customerNotesState,
                                internalNotes: event.target.value
                            }
                        }
                        handleFieldChanges();
                    }}
                    disabled
                />

            </Paper>
        </div>) : (
        <div className="mainContainer customernotes" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Divider style={{ flex: 1, marginRight: '8px' }} />
                <Chip label="Customer Notes" size="small" variant="outlined" />
                <Divider style={{ flex: 1, marginLeft: '8px' }} />
            </div>
            <Paper className="paperstyl1" variant="outlined" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <TextField
                    label="Internal Notes"
                    size="small"
                    fullWidth
                    value={customerNotesState?.internalNotes || ''}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    multiline
                    rows={10}
                    onChange={(event) => {
                        // console.log(event.target.value)
                        setCustomerNotes({
                            ...customerNotesState,
                            internalNotes: event.target.value
                        })
                        billingControlRef.current = {
                            ...billingControlRef.current,
                            customerNotes: {
                                ...customerNotesState,
                                internalNotes: event.target.value
                            }
                        }
                        handleFieldChanges();
                    }}
                    disabled
                />

            </Paper>
        </div>
        ))
    );
}
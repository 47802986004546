import { dispatchTypes } from "../types/dispatchTypes";
const initialState = {
  stopsData: [],
  activeTrucks: [],
  subItemData: [],
  loadsData: [],
  spottedData: [],
  reloadTablesOnTender: false
};

export const dispatchReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case dispatchTypes.ALL_STOPS:
      return {
        ...state,
        stopsData: payload
      }
    case dispatchTypes.ACTIVE_TRUCKS:
      return {
        ...state,
        activeTrucks: payload
      }
    case dispatchTypes.SUBITEM_DATA:
      return {
        ...state,
        subItemData: payload
      }
    case dispatchTypes.LOADS_DATA:
      return {
        ...state,
        loadsData: payload
      }
    case dispatchTypes.SPOTTED_DATA:
      return {
        ...state,
        spottedData: payload
      }
    case dispatchTypes.RELOAD_TABLES_ON_TENDER:
      return {
        ...state,
        reloadTablesOnTender: payload
      }

    default:
      return state;
  }
}
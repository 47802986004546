import React, { useState, useEffect } from 'react'
import { ListWrapper, TenderListWrapper } from '../../components/styledComponents'
import Tender from '../../components/Tender/Tender'
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader/Lodaer';
import LoadSearch from './LoadsSearch/LoadSearch';
import DriverInfo from '../../components/DriverInfo/DriverInfo';
import TenderDataTable from '../../components/Tender/TenderDataTable'

function TenderList() {
    let [isToggle, setIsToggle] = useState(false);
    let [isSearch, setIsSearch] = useState(false);
    const loadsList = useSelector(({ loadsReducer }) => loadsReducer.loadsList);
    const isLoading = useSelector(({ loadsReducer }) => loadsReducer.isLoading);
    const driverInfo = useSelector(({ loadsReducer }) => loadsReducer.driverInfo);
    const changeToggleStatus = useSelector(({ loadsReducer }) => loadsReducer.toggleStatus);
    const searchStatus = useSelector(({ loadsReducer }) => loadsReducer.submitSearchStatus);
    useEffect(() => {
        setIsToggle(changeToggleStatus);
    }, [changeToggleStatus]);

    useEffect(() => {
        setIsSearch(searchStatus);
    }, [searchStatus]);

    return (
        <TenderListWrapper>
            <LoadSearch />
            <ListWrapper>
                <DriverInfo data={driverInfo} />
                {isLoading ? <Loader /> : <>{
                    isToggle && isSearch ? <TenderDataTable loadsList={loadsList} /> : <>{
                        loadsList.map((load, i) => <Tender key={i} load={load} showMoreDetailsBar={true} showLoadStops={true} data-test="tenderItems" />)}
                    </>}</>
                }
                {(!isLoading && loadsList.length === 0) && <div>No loads</div>}
            </ListWrapper>
        </TenderListWrapper>
    )
}

export default TenderList;
import  _ from 'loadsh';

export const truckDriverStatus = (firstDate, secondDate) => {

    let changeFirstDate = new Date(firstDate);
    let changeSecondDate = new Date(secondDate);

    // let currentDate = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
    let currentDate = new Date();
    let checkEmptyFirstDate = _.isEmpty(changeFirstDate);
    let checkEmptySecondDate = _.isEmpty(changeSecondDate);
    // if(checkEmptyFirstDate == true && checkEmptySecondDate == true){
    //     return 'NA'
    // }
    if (firstDate && secondDate) {

        if ((currentDate < changeFirstDate) && (currentDate < changeSecondDate)) {
            return 'OK'
        }
        else {
            return 'NA'
        }

    } else {
       
            if (currentDate > changeFirstDate) {
                return 'NA'
            }
            else {
                return 'OK'
            }
        

    }
}
import { traceTypes } from "../types/traceTypes";
const initialState = {
  traceDate: []
};

export const traceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case traceTypes.TRACE_DATA:
      return {
        ...state,
        traceDate: payload
      }

    default:
      return state;
  }
}
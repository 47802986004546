import { CodeRounded } from '@material-ui/icons';
import moment from 'moment';


export const mapOrderByFormToClient = (item) => {
  console.log("mapOrderByToClient",item)
 const data= {
  code: item?.code || "",
  customerHash:item.billToHash || "",
  billTo :item.billTo || "",
  status: item.status || "",
  COD: item.COD  || "",
  name:item?.name||"",
  address: item?.address || "",
  city: item?.city || "",
  state: item?.state || "",
  zip: item?.zipCode|| "",
  phone: item?.phone || "",
  fax: item?.fax || "",
  location:item?.location ||[],
  note1: item?.notes1 || "",
  note2: item?.notes2 || "",
  note3: item?.notes3 || "",
  edi210:item?.edi210 || "",
  edi214: item?.edi214 || "",
  internalNote: `${item?.notes1?item?.notes1+" ":""}${item?.notes2?item.notes2+" ":""}${item?.notes3?item?.notes3+" ":""}`, 
  }
  return data
  
}
//new
export const  mapOrderByFormResponseToClient = (item) => {
  console.log("mapOrderByFormToClient",item)
 const shipperForm= mapOrderByFormToClient(item)
  const shipperContacts=maptoClientContactTable(item?.contacts,item|| "")
  return { shipperForm,shipperContacts}
  
}





export const mapShipperFormToServer = (item,contact) => {
  console.log("mapShipperFormToServer",item,"---contact",contact)
  const splitNote =item?.internalNote?.match(/.{1,25}/g)
  console.log("splitNote",splitNote)
 const data= {
    address: item?.address || "",
    city: item?.city || "",
    code: item?.code || "",
    fax: item?.fax || "",
    description: item?.description || "",
    description2: item?.description2 || "",
    scac: item?.scac || "",
    hubid: item?.hubid || "",
    name: item?.name || "",
    notes1: splitNote && splitNote[0] ? splitNote[0] : "",
    notes2: splitNote && splitNote[1] ? splitNote[1] : "",
    notes3:splitNote && splitNote[2] ? splitNote[2] : "",
    phone: (contact[0] &&contact[0].id===-1)?contact[0].phone_number: "",
    state: item?.state || "",
    zip: item?.zip || ""
  }
  return data
  
}




//above
export const maptoOrderByTable = (shipperRes) => {
 
  try {
    let shipper = shipperRes?.map((item,index) => {
      return {id: index,
      code: item?.code || "",
      billTo:item?.billTo || "",
      billToHash:item?.billToHash || "",
      city: item?.city || "",
      state: item?.st || "",
      location: item?.location || "",
      name:item?.name ||"",
      status:item?.status=== "A"?"ACTIVE":"INACTIVE"||"",
      }
    })
    return shipper;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

export const maptoClientContactTable = (shipperRes,shipperData) => {


  try {
   
    let shipperContacts = shipperRes?.map((item,index) => {
      return {id: index,
        email: item?.email|| "",
        contact_name: `${item?.fisrtName?item?.fisrtName :item?.fname?item?.fname:""} ${item?.lastName?item?.lastName:item?.lname?item?.lname:""}`,
        title:item?.title|| "",
        phone_number: item?.primaryPhone|| "",
        secondary_phone_number:item?.secondaryPhone || "",
        seq: item?.seq|| "",
        primary:item?.isPrimary ||"N"  
      }
    })
    const additionalContact={
      id: -1,
      contact_name: shipperData.name|| "",
        email: shipperData?.email|| "",
        title:"",
        phone_number: shipperData?.phone|| "",
        secondary_phone_number: "",
        seq:"",
        code:"",
        primary:"N",
        isDisabled:true
        
        
      
    }
    if(shipperData?.phone){
      shipperContacts.unshift(additionalContact)
    }
    
    return shipperContacts;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}



export const getDefaultShipperContact = () => {

  return {
      id: "",
      email: "",
      fax: "",
      contact_name: "",
      phone_number: "",
      seq: "",
      code: "",
      primary:"N",
      isNew: true

  }
}


const splitName = (contact_name) => {
  // Trim any leading/trailing whitespace and split the name by space
  const [fname, ...lnameParts] = contact_name.trim().split(' ');
  
  // Join remaining parts to form the last name
  const lname = lnameParts.join(' ');
  
  return { fname: fname || "", lname: lname || "" };
};
export const mapShipperContactToServer = (data,orderBydata,locationNumber) => {
  console.log("data",data,"orderBydata",orderBydata)
 
  return data
      .filter(item => item.isNew === false ||item.hasChanged===true)
      // .filter(item => item.id!== -1 )
      .map(item => {
        const { fname, lname } = splitName(item.contact_name);
        return {
        locationNumber:locationNumber||"",
        company:1,
        fname:fname||"",
        lname:lname||"",
        title:item.title||"",
        city:item.city ||orderBydata.city,
        state:item.state || orderBydata.state,
        country:"",
        zip:item.zip || orderBydata.zip,
        primaryPhone:item.phone_number ||"",
        secondaryPhone:item.secondary_phone_number ||"",
        email: item.email ||"",
        agentCode: orderBydata.code ||"",       
        isPrimary :item.isPrimary ||"N" ,
          seq: item?.seq?item?.seq:-1,
          // isPrimary: item?.primary || "N"
      }});
};

export const mapOrderByScreenData= (item) => {


const orderByScreenData={
  code: item?.code ||"",
  name: item?.name ||"",
  billTo: item?.customerHash ||"",
  billToName: item?.billTo ||"",
  billToStatus: item?.status ||"",
  billingComment: item?.billingComment ||"",
  orderByLocation: item?.location ||"",
  city: item?.city ||"",
  state: item?.state ||"",
  zip: item?.zip ||"",
  phone: item?.phone ||"",
  fax: item?.fax ||"",
  edi210: item?.edi210 ||"N",
  edi214: item?.edi214 ||"N"
}
return orderByScreenData
}
import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams, GridToolbar } from '@mui/x-data-grid';
import { TenderDetailsWrapper } from './../../components/styledComponents/index';
import { getFullAdress, friendlyLoadStatus } from './Tender'

export default function DataGridDemo({ loadsList }) {
 
  const columns = [
    {
      field: 'controlNumber',
      headerName: 'Control Number',
      width: 150,
      hideable: false,
      editable: false,
      valueGetter: (params) => {

        return `${params.row.controlNumber || ''}`
      }
    },
    {
      field: 'container',
      headerName: 'Container',
      width: 150,
      editable: false,
      valueGetter: (params) => {

        return `${params.row.container || ''}`
      }
    },
    {
      field: 'reference',
      headerName: 'Reference',
      width: 150,
      sortable: false,
      editable: false,
      valueGetter: (params) => {
        return `${params.row.reference || ''}`
      }
    },
    {
      field: 'pickup',
      headerName: 'Pickup Point/Origin',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
      valueGetter: (params) => {
        return `${getFullAdress(params.row.pickup) || ''}`
      }
    },

    {
      field: 'disposition',
      headerName: 'Disposition',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
      valueGetter: (params) => {
        return `${getFullAdress(params.row.disposition) || ''}`
      }
    },

    {
      field: 'remarks1',
      headerName: 'Remarks 1',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 260,
      valueGetter: (params) => {
        return `${params.row.remarks1 || ''}`
      }
    },
    {
      field: 'remarks2',
      headerName: 'Remarks 2',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.remarks2 || ''}`
      }
    },
    {
      field: 'equipmentsize',
      headerName: 'Equipment Size',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.equipmentsize || ''}`
      }
    },
    {
      field: 'reloadedcontrolnumber',
      headerName: 'Reloaded Control Number',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.reloadedControlNumber || ''}`
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      width: 160,
      valueGetter: (params) => {
       return `${friendlyLoadStatus(params.row.status) || ''}`
      }
    },

    {
      field: 'chassis',
      headerName: 'Chassis',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.chassis || ''}`
      }
    },
    {
      field: 'secondarycontainer',
      headerName: 'Secondary Container',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.secondaryContainer || ''}`
      }
    },
    {
      field: 'release',
      headerName: 'Release',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.release || ''}`
      }
    },
    {
      field: 'P/U#',
      headerName: 'P/U#',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.pu || ''}`
      }
    },
    {
      field: 'chassisPickup',
      headerName: 'Chassis Pickup',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${getFullAdress(params.row.chassisPickup) || ''}`
      }
    },
    
    {
      field: 'chassisDisposition',
      headerName: 'Chassis Disposition',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${getFullAdress(params.row.chassisDisposition) || ''}`
      }
    },
     {
      field: 'orderBy',
      headerName: 'Order By',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 460,
      valueGetter: (params) => {
        return `${params.row.orderBy.code}, ${params.row.orderBy.name}, ${params.row.orderBy.location}, ${params.row.orderBy.phone}`
      }
    },

    {
      field: 'shipper',
      headerName: 'Shipper',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 460,
      valueGetter: (params) => {
        return `${params.row.shipper.code}, ${params.row.shipper.name}`
      }
    },
    {
      field: 'billTo',
      headerName: 'Bill To',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.billTo || ''}`
      }
    },

    {
      field: 'billToName',
      headerName: 'Bill To Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${'-' || ''}`
      }
    },

    {
      field: 'lastStorageDayDate',
      headerName: 'Last Storage Day',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.lastStorageDayDate || ''}`
      }
    },
    {
      field: 'lastPerDiemDay',
      headerName: 'Last Per Diem Day',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.lastPerDiemDay || ''}`
      }
    },

    {
      field: 'vessel',
      headerName: 'Vessel',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.vessel || ''}`
      }
    },
    
    {
      field: 'booking',
      headerName: 'Booking',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.booking || ''}`
      }
    },

    {
      field: 'voyage',
      headerName: 'Voyage',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.voyage || ''}`
      }
    },
    {
      field: 'destinationPort',
      headerName: 'Destination Port',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params) => {
        return `${params.row.destinationPort || ''}`
      }
    },
  ];

   let rows = loadsList.length > 0 && loadsList.map((val, i) => {
    return {
      id: i,
      controlNumber: val.controlNumber,
      container: val.container,
      reference: val.reference,
      pickup: val.pickup,
      disposition: val.disposition,
      remarks1:val.remarks1,
      remarks2:val.remarks2,
      equipmentsize:val.equipmentSize,
      reloadedControlNumber:val.reloadedControlNumber,
      status:val.status,
      chassis:val.chassis,
      secondaryContainer:val.secondaryContainer,
      release:val.release,
      pu:val.pu,
      chassisPickup:val.chassisPickup,
      chassisDisposition:val.chassisDisposition,
      orderBy:{
        code:val.orderBy.code,
        name:val.orderBy.name,
        location:val.orderBy.location,
        phone:val.orderBy.phone
      },
      shipper:{
        code:val.orderBy.code,
        name:val.orderBy.name
      },
      billTo:val.billTo.code,
      billToName:'-',
      lastStorageDayDate:val.lastStorageDayDate,
      lastPerDiemDay:val.equipmentSize,
      vessel:val.vessel,
      booking:val.booking,
      voyage:val.voyage,
      destinationPort:val.destinationPort
    } 
  });

  return (
    <TenderDetailsWrapper>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
         initialState={{
          columns: {
            columnVisibilityModel: {
              // Hide columns status and traderName, the other columns will remain visible
            },
          },
        }}
          rows={rows}
          rowHeight={47}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          experimentalFeatures={{ newEditingApi: true }}
          components={{
            Toolbar: GridToolbar,
          }}
        />
      </Box>
    </TenderDetailsWrapper>
  );
}

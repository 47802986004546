import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import { Button, DetailsButton, TenderWrapper } from '../styledComponents'
import { Option } from '../Option/Option'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getDriverTruckInfoAction, driverToggle } from '../../store/actions/loadsActions';

export default function DriverInfo(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false);
  let [isSearch, setIsSearch] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    dispatch(driverToggle(event.target.checked));
  };

  const searchStatus = useSelector(({ loadsReducer }) => loadsReducer.submitSearchStatus);

  useEffect(() => {
    setIsSearch(searchStatus);
  }, [searchStatus]);

  function loadDetails(driverID, locNum, truckId) {


    return () => {
      dispatch(getDriverTruckInfoAction({}))
      history.push(`/tenderList/details/${driverID}/${locNum}/${truckId}`)
    }
  }

  const { data } = props;
  if (data === undefined) {
    return (null);
  }
  
  return (
    <>
      {
        isSearch ? <div style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "center", justifyItems: "end" }}>
          <Switch
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <h4>Data Grid View</h4>
        </div> : <></>
      }
      <TenderWrapper style={{ minHeight: 0 }}>
        <Grid style={{ display: 'flex' }}>
          <Option size={3} description={`Id`} label={data.id} />
          <Option size={3} description={`Name`} label={data.name} />
          <Option size={3} description={`Truck Number`} label={data.truck} />
          <Option size={3} description={`Location Number`} label={data.locationNumber} />
          <Button style={{ height: "40%", display: "grid", alignSelf: "center", marginRight: "1%" }} onClick={loadDetails(data.id, data.locationNumber, data.truck)}>Details</Button>
        </Grid>
      </TenderWrapper>
    
      </>
  );
}

import * as React from "react";
import { useState, useEffect } from "react";
import {
    Stack,
    Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { getRatesCalculations, billingReportProperties, FSCCodes } from '../CommonFn';
import { convertMilitaryDateToDDMMYY } from '../../../utils/global-functions';
import ExportSummaryByControl from './ExportSummaryByControl';

export default function SummaryByControl(props) {
    const { activeTab, setTotalRecords, rawData, locationNumber, isLoading, filterParams } = props;
    const [rows, setRows] = useState([]);
    const apiRef = useGridApiRef()

    useEffect(() => {
        if (locationNumber !== 0) {
            handleFilter(filterParams);
        }
    }, [activeTab, rawData])

    const transformData = (data) => {
        return data.map((item, index) => {
            const source = item;
            return billingReportProperties(source, index)
        });
    }

    const handleFilter = (filterParams) => {
        // Extract filters
        const {
            dateType,
            revenuePercentage,
            excludeFSC,
            orderBy,
            billTo,
            dateRange,
            startingPercentage,
            endingPercentage
        } = filterParams;

        // Make a deep copy of the data to avoid mutating the original
        const clonedData = JSON.parse(JSON.stringify(rawData));

        // Helper function to check if a value falls in a range
        const isInRange = (value, start, end) => value >= start && value <= end;

        const filteredData = clonedData.filter((item, index) => {
            const source = item;

            // Filter by dateRange using dateType (e.g., billingDate)
            if (dateType && dateRange && dateRange.length === 2) {
                const dateValue = source[dateType];
                if (!isInRange(dateValue, dateRange[0], dateRange[1])) {
                    return false;
                }
            }

            // Filter by billTo (customerNumber)
            if (billTo && source.billTo !== parseInt(billTo)) {
                return false;
            }

            // Filter by order By (shipperAgentCode)
            if (orderBy && source.shipperAgentCode !== orderBy) {
                return false;
            }

            // Filter by excludeFSC (exclude entries with FUEL SURCHARGE in glDescription)
            if (excludeFSC) {
                source.rates = source.rates.filter(rate => !FSCCodes.includes(rate.glCode));
            }

            // Filter by revenuePercentage (based on startingPercentage and endingPercentage)
            if (revenuePercentage && startingPercentage && endingPercentage) {

                const { totalRevenue, totalExpense, totalNet, totalPercent, ptNet, ptPercent } = getRatesCalculations(source.rates, excludeFSC);

                if (revenuePercentage === 'totalPercent' && !isInRange(totalPercent, parseFloat(startingPercentage), parseFloat(endingPercentage))) {
                    return false;
                }

                if (revenuePercentage === 'ptPercent' && !isInRange(ptPercent, parseFloat(startingPercentage), parseFloat(endingPercentage))) {
                    return false;
                }
            }

            return true;
        });

        // Sort by controlNumber if required
        // if (sortByControl) {
        //     filteredData.sort((a, b) => a._source.controlNumber - b._source.controlNumber);
        // }

        setTotalRecords([filteredData.length, rawData.length])
        setRows(transformData(filteredData));
    }

    // Function to calculate totals for price and quantity columns
    const calculateTotals = (rows) => {
        let totalRevenue = 0;
        let totalMiscRevenue = 0;
        let totalExpense = 0;
        let totalNet = 0;
        let ptNet = 0;
        let totalLoads = 0;

        rows.forEach((row) => {
            totalRevenue += parseFloat(row.totalRevenue) || 0;
            totalMiscRevenue += parseFloat(row.totalMiscRevenue) || 0;
            totalExpense += parseFloat(row.totalExpense) || 0;
            totalNet += parseFloat(row.totalNet) || 0;
            totalLoads += Number(1)
        });

        return {
            totalRevenue,
            totalExpense,
            totalNet,
            ptNet: totalNet - totalMiscRevenue,
            totalPercent: ((totalExpense / totalRevenue) * 100).toFixed(2),
            ptPercent: ((totalExpense / (totalRevenue - totalMiscRevenue)) * 100).toFixed(2),
            totalLoads
        };
    };

    const totals = calculateTotals(rows);

    const pinnedRows = {
        bottom: [
            {
                id: 'total-rows',
                controlNumber: ``,
                customerName: 'Total Rows:',
                billToCode: totals.totalLoads,
                totalRevenue: `${totals.totalRevenue.toFixed(2)}`,
                totalNet: `${totals.totalNet.toFixed(2)}`,
                totalPercent: `${parseFloat(totals.totalPercent) || 0}`,
                totalExpense: `${totals.totalExpense.toFixed(2)}`,
                ptNet: `${totals.ptNet.toFixed(2)}`,
                ptPercent: `${parseFloat(totals.ptPercent) || 0}`
            }
        ],
    };

    const tableColumns = [
        {
            field: "controlNumber",
            headerName: "Control #",
            minWidth: 90,
            flex: 1,

            renderCell: (params) => (
                <a
                    href={`/orders/${locationNumber}/${params.value}`}
                    style={{ color: "blue", textDecoration: "underline" }}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {params.value}
                </a>
            ),
        },
        // { field: "totalLoads", headerName: "Total Loads", minWidth: 90, flex: 1 },
        { field: "customerName", headerName: "Customer", minWidth: 100, flex: 1 },
        { field: "billToCode", headerName: "Bill To Code", minWidth: 90, flex: 1 },
        {
            field: "billingDate",
            headerName: "Billing Date",
            minWidth: 90,
            flex: 0.5,
            renderCell: (params) => {
                // Check if the row is a pinned row
                if (params.row.id === 'total-rows') {
                    return params.value; // Return unformatted value for pinned row
                }
                return convertMilitaryDateToDDMMYY(params.value)
            }
        },
        {
            field: "reference",
            headerName: "Reference #",
            minWidth: 90,
            flex: 0.5,
        },
        {
            field: "unitNumber",
            headerName: "Unit#",
            minWidth: 90,
            flex: 2,
        },
        {
            field: "io",
            headerName: "I/O",
            minWidth: 90,
            flex: 1,
        },
        {
            field: "apptDate",
            headerName: "Appt Date",
            minWidth: 90,
            flex: 1,
            renderCell: (params) => {
                // Check if the row is a pinned row
                if (params.row.id === 'total-rows') {
                    return params.value; // Return unformatted value for pinned row
                }
                return convertMilitaryDateToDDMMYY(params.value)
            }
        },
        { field: "PULoc", headerName: "PU Loc", minWidth: 90, flex: 1 },
        { field: "dispositionLoc", headerName: "Disp Loc", minWidth: 90, flex: 1 },
        {
            field: "returnDate", headerName: "Return Date", minWidth: 90, flex: 1,
            renderCell: (params) => {
                // Check if the row is a pinned row
                if (params.row.id === 'total-rows') {
                    return params.value; // Return unformatted value for pinned row
                }
                return convertMilitaryDateToDDMMYY(params.value)
            }
        },
        { field: "shipperName", headerName: "Shipper", minWidth: 90, flex: 1 },
        { field: "shipperAddress", headerName: "Shipper City ST", minWidth: 90, flex: 1 },
        { field: "consigneeName", headerName: "Consignee", minWidth: 90, flex: 1 },
        { field: "consigneeAddress", headerName: "Consignee City ST", minWidth: 90, flex: 1 },
        { field: "totalRevenue", headerName: "Total Revenue", minWidth: 90, flex: 1 },
        { field: "totalNet", headerName: "Total Net", minWidth: 90, flex: 1 },
        { field: "totalPercent", headerName: "Total %", minWidth: 90, flex: 1 },
        { field: "totalExpense", headerName: "Total Expense", minWidth: 90, flex: 1 },
        { field: "ptNet", headerName: "PT Net", minWidth: 90, flex: 1 },
        { field: "ptPercent", headerName: "PT %", minWidth: 90, flex: 1 },
    ];

    return (
        <ComponentWrapper>
            <Stack
                direction="row"
                justifyContent="flex-end"
                sx={{
                    position: 'absolute',
                    //bottom:0,
                    right: "1%",
                    zIndex: 9999,
                    marginTop: "-2%"
                }}
            >
                <Button
                    variant="text"
                    size="small"
                    onClick={() => apiRef.current.exportDataAsCsv()}
                    startIcon={<TextSnippetIcon color="primary" />}
                    color={`primary`}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    CSV
                </Button>
                <Button
                    variant="text"
                    size="small"
                    onClick={ExportSummaryByControl(rows, tableColumns)}
                    startIcon={<PictureAsPdfIcon color="primary" />}
                    color={`primary`}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                    }}
                >
                    PDF
                </Button>
            </Stack>
            <Stack
                //marginTop={0.5}
                sx={{
                    height: window.innerHeight - 250,
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "rgba(11, 67, 135, 1)",
                        color: "white",
                    },
                    "& .isSubtotal": {
                        fontWeight: "bold",
                        // color: "blue",
                        backgroundColor: "#E8E8E8 !important",
                        // backgroundColor: "#F0F0F0 !important",
                    },
                    "& .pinned-row": {
                        backgroundColor: "#f0f0f0",  /* Light gray background */
                        fontWeight: "bold",          /* Bold text */
                        color: "#000000"            /* Black text */
                    },
                    "& .pinned-row .MuiDataGrid-cell": {
                        borderTop: "2px solid #000000"
                    }
                }}
            >

                <DataGridPro
                    apiRef={apiRef}
                    hideFooter={true}
                    rows={rows}
                    columns={tableColumns}
                    loading={isLoading}
                    pinnedRows={pinnedRows}
                    getRowClassName={(params) => params.id === 'total-rows' ? 'pinned-row' : ''}
                />
            </Stack>
        </ComponentWrapper>
    );
}

import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useUnsavedChangesWarning = (condition) => {
  const history = useHistory();

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (condition) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome to show the confirmation dialog
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [condition]);

  useEffect(() => {
    const unblock = history.block((location) => {
      if (condition) {
        const confirm = window.confirm('Your changes will not be saved. Would you like to proceed?');
        return confirm;
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [history, condition]);
};

export default useUnsavedChangesWarning;

import React, { useState, useRef, useEffect } from "react";
import { Autocomplete, TextField, createFilterOptions, fabClasses } from "@mui/material";
import { useSelector } from 'react-redux';

export default function GLCodesAutoComplete(props) {
    const { valueChange, existingValue, isDisabled, lineAccount, orderType, edi210, payOrRec } = props
    const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
    const [options, setOptions] = useState([]);
    const [value, setValue] = useState({ glcode: existingValue || "", glDescription: "" });
    useEffect(() => {
        if (rateBookReducer?.GLCodeData?.length > 0) {
            // let filteredGLCodes = lineAccount === 'A' ? rateBookReducer.GLCodeData.filter((e) => e.glPayRec !== 'R') : rateBookReducer.GLCodeData;
            let apiRes = rateBookReducer.GLCodeData;
            if(payOrRec === 'P'){
                switch (orderType) {
                    case 'INBOUND':
                    case 'OUTBOUND':
                        apiRes = apiRes.filter((e) => e.glPayRec === 'P' && e.glcode !== 6985000)
                        break;
                    case 'IMPORT':
                    case 'EXPORT':
                        apiRes = apiRes.filter((e) => e.glPayRec === 'P' && e.glcode !== 6980000)
                        break;
                    default:
                        apiRes = apiRes.filter((e) => e.glPayRec === 'P')
                        break;
                }
            }else{
                apiRes = apiRes.filter((e) => e.glPayRec !== 'P')
            }
            if (['DOMOUT', 'DOMINB', 'INBOUND', 'OUTBOUND'].includes(orderType) && lineAccount === 'L') {
                setOptions(apiRes.filter((e) => e.glcode !== 3320000))
            } else if (['EXPORT', 'IMPORT'].includes(orderType) && lineAccount === 'L') {
                setOptions(apiRes.filter((e) => e.glcode !== 3310000))
            } else {
                setOptions(apiRes)
            }
        }

    }, [rateBookReducer.GLCodeData])

    useEffect(async () => {
        if (existingValue !== undefined && existingValue !== "") {
            const matchedOpt = rateBookReducer.GLCodeData.filter((e) => e.glcode === existingValue);
            setValue(matchedOpt[0]);
        }
    }, [existingValue])

    return (
        <Autocomplete
            disabled={isDisabled}
            freeSolo
            autoSelect
            options={(edi210 === 'Y') ? options : options?.filter(e => e.glLineAcc === lineAccount || e.glLineAcc === '')}
            getOptionLabel={(option) => `${(option.glcode) ? option.glDescription + '  ' + option.glcode : ''}`}
            size={`small`}
            id="glcode-autocomplete"
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="glCode"
                    //label="GL Desc"
                    variant="standard"
                    sx={{
                        input: {
                            textTransform: 'uppercase'
                        }
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${(option.glcode) ? option.glDescription + '  ' + option.glcode : ''}`}
                    </li>
                )
            }}
            componentsProps={{ popper: { style: { width: 300 } } }}
            onChange={(event, value, reason) => {
                if (typeof (value) === 'string' && value !== '' && value !== null) {
                    setValue({ glcode: value, glDescription: "" });
                } else {
                    setValue(value);
                }

                if (typeof (value) === 'object' && value !== '' && value !== null) {
                    valueChange(value.glcode)
                }
            }}
            value={value}
        />
    );
}
import jsPDF from "jspdf";
import "jspdf-autotable";

// define a generatePDF function that accepts a tickets argument
const PdfGenerator = (items, container, reference) => {
  // initialize jsPDF
  const doc = new jsPDF();

  // define the columns we want and their titles
  const tableColumn = ["Description", "Rate", "Units", "Amount"];

  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  var total = 0;
  items.rows.forEach(row => {
    if (row.history.AccountTypeCode !== "P" ) {
      const itemData = [
        row.Desciption,
        "$" + Number(row.history.RateAmount).toFixed(2),
        row.history.Units,
        "$" + Number(row.Amount).toFixed(2)
      ];
      total = parseFloat(total) + parseFloat(row.Amount);
      tableRows.push(itemData);
    }
  });

  var body = [...tableRows, ["Total", "", "", "$" + Number(total).toFixed(2)]];
  // [{content: `TOTAL = ${total}`, colSpan: 4, 
  //   styles: { fillColor: [239, 154, 154] }
  // }]]
  doc.setFont("courier", "normal");
  doc.text("Greetings from Eagle Systems,", 20, 20);
  doc.setFont("courier", "normal");
  var splitLine1 = doc.splitTextToSize("To improve the accuracy of your invoice, please review and confirm the below charges within the next 48 hours.", 170);
  doc.text(splitLine1, 20, 30);
  // doc.setFont("courier", "normal");
  var splitLine2 = doc.splitTextToSize("If we do not hear from you, we will assume all is correct and submit it to our accounting team to generate your invoice without corrections.", 170);
  doc.text(splitLine2, 20, 60);
  doc.setFont("courier", "normal");
  var unitNumberText = "UNIT NUMBER " + container;
  doc.text(unitNumberText, 20, 90);
  var refNumberText = "REFERENCE NUMBER " + reference;
  doc.text(refNumberText, 20, 100);

  // startY is basically margin-top
  doc.autoTable(tableColumn, body, {
    theme: 'grid',
    startY: 110,
    headStyles: { fillColor: "#0911A7" },
    drawRow: function (row, data) {
      doc.setFontStyle('bold');
      doc.setFontSize(10);
    }
  });
  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];

  doc.save(`report_${dateStr}.pdf`);
};

export default PdfGenerator;
import { templatesTypes } from '../types/templatesType';
import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk'
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import moment from 'moment';


export const getTemplateData = (params: any) => {
    return async (dispatch: Dispatch) => {
         const res = await getTemplateDataReq(params);
         if (res?.data?.hits?.length > 0) {
            dispatch(setTemplates(res?.data?.hits));
         }else{
            dispatch(setTemplates([]));
         }
       
        return res;
    }
}
export const deleteTemplate = (LocationNumber: any,TemplateName:any) => {
    return async (dispatch: Dispatch) => {
         const res = await deleteTemplateReq(LocationNumber,TemplateName);
         dispatch(setTemplates(res));
    
        return res;
    }
}
export const saveTemplate = (LocationNumber: any,TemplateName:any,params:any) => {
    return async (dispatch: Dispatch) => {
         const res = await saveTemplateReq(LocationNumber,TemplateName,params);
        dispatch(setTemplates(res));
        return res;
    }
}

export const searchTemplate = (params: any,query:any) => {
    

    return async (dispatch: Dispatch) => {

        let url = `/elastic/templates/_search`
        const res = await getSearch(url,params,query);
    
        if (res && res.httpCode === 200 && res.status === 'success') {
            dispatch(setTemplates(res.data.hits))
            // dispatch(setTemplates(res))
            // dispatch(setTemplates({
            //     isLoading: false,
            //     data: res.data.length === 0 ? [] : res.data.hits.map((item: any) => item._source),
            //     totalRows: res.data.length === 0 ? 0 : res.data.total.value
            // }))
        }
        else {
           
        }
        return res;
    }
}


const setTemplates = (apiData: any) => {
    return {
        type: templatesTypes.TEMPLATES_DATA,
        payload: apiData,
    };
};

const SaveTemplates = (apiData: any) => {
    return {
        type: templatesTypes.TEMPLATES_SAVE,
        payload: apiData,
    };
};


export const getTemplateDataReq = async (params: any) => {
    let url = `/elastic/templates/${params.locationNumber}`;
   
    const queryString = `?page=${params.query.page}&size=${params.query.pageSize}&sortBy=${params.sortData.field}&sortOrder=${params.sortData.sort}`;

    try {
        let res = await GET(url + queryString);
        return res;
    } catch (err) {
        return [];
    }
   
}
export const getSearch = async (url: any,params:any,query:any) => {
     
    let queryString = '';
    if(params.query?.page && params.query?.pageSize && params?.sortData?.field && params?.sortData?.sort){
        queryString = `?page=${params.query?.page}&size=${params.query?.pageSize}&sortBy=${params?.sortData?.field}&sortOrder=${params?.sortData?.sort}`;
    }
    else {
        queryString = '?page=1&size=100';
    }
    try {
        let res = await POST(url+queryString, params.postParams);
        return res;
    } catch (err) {
        return [];
    }
}
export const saveTemplateReq = async (locationNumber: number,templateName:any,params:any) => {
    try {
        let res = await POST(`/elastic/templates/${locationNumber}/${templateName}`,params);
        return res;
    } catch (err) {
        return [];
    }
}
export const deleteTemplateReq = async (locationNumber: number,templateName:any) => {
    try {
        let res = await DELETE(`/elastic/templates/${locationNumber}/${templateName}`);
        return res;
    } catch (err) {
        return [];
    }
}


import moment from 'moment';

// function getDateMDY(dateString,type) {
//   // Check if the input is null or undefined and return an empty string
//   if (dateString == null) {
//     return "";
//   }

//   // Ensure dateString is a string
//   dateString = String(dateString);

//   // Check if the string is empty
//   if (dateString === "") {
//     return "";
//   }

//   if (dateString.length<8) {
//     return "";
//   }

//   // Extract the year, month, and day from the input string
//   let year = dateString.substring(0, 4);
//   let month = dateString.substring(4, 6);
//   let day = dateString.substring(6, 8);
  
//   // Create the MM/DD/YY format
//   let formattedDate = `${month}/${day}/${year.substring(2)}`;
  
//   return formattedDate;
// }
function getDateMDY(dateString, datetype) {
  // Check if the input is null or undefined and return an empty string
  if (dateString == null) {
    return "";
  }

  // Ensure dateString is a string
  dateString = String(dateString);

  // Check if the string is empty
  if (dateString === "") {
    return "";
  }

  if (dateString.length < 8) {
    return "";
  }

  // Extract the year, month, and day from the input string
  let year = parseInt(dateString.substring(0, 4));
  let month = parseInt(dateString.substring(4, 6)) - 1; // Month is zero-based in JavaScript Date
  let day = parseInt(dateString.substring(6, 8));
  
  // Create a Date object
  let date = new Date(year, month, day);
  
  // Adjust the date based on the datetype
  if (datetype === "medicalExamExpiration") {
    date.setFullYear(date.getFullYear() + 2);
  } else if (datetype === "annualReviewExpiration") {
    date.setFullYear(date.getFullYear() + 1);
  } else if (datetype === "fhdwExpiration") {
    date.setMonth(date.getMonth() + 6);
  }
  
  // Create the MM/DD/YY format
  let formattedMonth = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based in JavaScript Date
  let formattedDay = String(date.getDate()).padStart(2, '0');
  let formattedYear = String(date.getFullYear()).substring(2);
  
  let formattedDate = `${formattedMonth}/${formattedDay}/${formattedYear}`;
  
  return formattedDate;
}
function truck_status(registrationExpires, fhdwExpiration, terminationDate, available) {
  let statusDetails = {
    overallStatus: "green",
    issues:"",
    statusCode:"Okay"
  };

  if (highlightDate(registrationExpires, true) === "red") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+="Reg,";
  }else if(highlightDate(registrationExpires, true) === "#f29407"){
    statusDetails.overallStatus = "yellow";
    
  }
  if (highlightDate(fhdwExpiration, true) === "red") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+=" FWHA,";
  }else if(highlightDate(fhdwExpiration, true) === "#f29407"){
    statusDetails.overallStatus = "yellow";
    
  }
  
  if (highlightDate(terminationDate, true,true) === "red") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+="Term,";
   }else if(highlightDate(terminationDate, true,true) === "#f29407"){
    statusDetails.overallStatus = "yellow";
    
  }
  if (statusDetails.issues) {
    statusDetails.issues+="are expired or unavailable. ";
    statusDetails.statusCode="ER"
  }
  if (available === "N") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+=" Truck is not avail.";
    statusDetails.statusCode="ER"
  }
  
  // if (active === "N") {
  //   statusDetails.overallStatus = "red";
  //   statusDetails.issues+=" Truck is not active."
  //   statusDetails.statusCode="ER"
  // }
  return statusDetails;
}

function driver_status(licenceExpire,medicalExamExpire, terminationDate) {
  let statusDetails = {
    overallStatus: "green",
    issues:""
  };
 
  if (highlightDate(licenceExpire, true)  === "red") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+="License,";
  }else if(highlightDate(licenceExpire, true) === "#f29407"){
    statusDetails.overallStatus = "yellow";
    
  }
  
  if (highlightDate(medicalExamExpire, true) === "red") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+="Medical Exam,";
  }else if (highlightDate(medicalExamExpire, true) === "#f29407") {
    statusDetails.overallStatus = "yellow";
  }
  
  if (highlightDate(terminationDate, true,true) === "red") {
    statusDetails.overallStatus = "red";
    statusDetails.issues+="Termination,";
  }else if (highlightDate(terminationDate, true,true) === "#f29407") {
    statusDetails.overallStatus = "yellow";
  }
  if (statusDetails.issues) {
    
    statusDetails.issues+="are expired or unavailable. ";
  }

 
  return statusDetails;
}
function overallAvailabilityStatus(available, active) {
  let statusDetails = {
    overallStatus: "Y",
    issues:"",
    statuscode:"Okay"
  };
if( (available === "Y") &&(active === "Y")){
  return statusDetails

}else{
  if (available === "N") {
    statusDetails.overallStatus = "N";
    statusDetails.issues+="Truck is not available.";
    statusDetails.statuscode="ER"
  }
  
  if (active === "N") {
    statusDetails.overallStatus = "N";
    statusDetails.issues+="Truck is not active."
    statusDetails.statuscode="ER"
  }

}
  return statusDetails;
}
export const truckDriverStatusSendToDispatch=(item,type)=>{
  if(type==="truck"){
    const registrationExpires = getDateMDY(item.registrationExpires,"registrationExpires") || "";
    const fhdwExpiration = getDateMDY(item.fhdwExpiration,"fhdwExpiration") || "";
    const terminationDate = getDateMDY(item.terminationDate,"terminationDate") || "";
  const status=  truck_status(registrationExpires, fhdwExpiration, terminationDate, item?.available)
  return status

  }
  if(type==="driver"){
    const terminationDate = getDateMDY(item.terminationDate,"terminationDate") || "";
    const licenseExp= getDateMDY(item.licenseExpiration,"licenseExpiration") || "";
    const medicalExamExp= getDateMDY(item.medicalExamExpiration,"medicalExamExpiration") || ""
    const status= driver_status(licenseExp, medicalExamExp, terminationDate)
    return status
  }
}


export const maptoTruckDriverTable = (truckDriverRes) => {
    try {
      let truckDriver = truckDriverRes?.map((item,index) => {
        const registrationExpires = getDateMDY(item.registrationExpires,"registrationExpires") || "";
      const fhdwExpiration = getDateMDY(item.fhdwExpiration,"fhdwExpiration") || "";
      const terminationDate = getDateMDY(item.terminationDate,"terminationDate") || "";
      const licenseExp= getDateMDY(item.licenseExpiration,"licenseExpiration") || "";
      const medicalExamExp= getDateMDY(item.medicalExamExpiration,"medicalExamExpiration") || ""
      
      // const isActive=truck_status(registrationExpires, fhdwExpiration, terminationDate, item.available).overallStatus==="green"?"Y":"N"
      const isActive=(truck_status(registrationExpires, fhdwExpiration, terminationDate, item.available).overallStatus!=="red"&&driver_status(licenseExp, medicalExamExp, terminationDate).overallStatus!=="red")?"Y":"N"
       
      return {id: index,
          overall_avb: overallAvailabilityStatus( item.available, isActive).overallStatus|| "Y",
          truckPhone: item.truckPhone || "",
          comment: item.truckComment || "",  
          truck: item.truckNumber || "",
          licence:item.license||"",
          truck_status_des:truck_status(registrationExpires, fhdwExpiration, terminationDate, item.available).issues || "",
          truckDriverLabel: item.truckDriverLabel || "",
          // active: item.active || "",
          active: item.truckNumber ? item?.active === 'A' ? 'Y' : 'N' : '',
          available: item.available || "",
          registration_exp: getDateMDY(item.registrationExpires,"registrationExpires") || "",
          fhwa_insp_exp: getDateMDY(item.fhdwExpiration,"fhdwExpiration") || "",
          driver_number:item.driverNumber || "",
          truck_driver_label:item.truckDriverLabel ||"",
          
          driver_name:`${item.driverName?.firstName?item.driverName.firstName:""} ${item.driverName?.middleName?item.driverName.middleName:""} ${item.driverName?.lastName?item.driverName.lastName:""}`||"",
          license_exp: getDateMDY(item.licenseExpiration,"licenseExpiration") || "",
          medical_exam_exp: getDateMDY(item.medicalExamExpiration,"medicalExamExpiration") || "",
          annual_review_exp:  getDateMDY(item.annualReviewExpiration,"annualReviewExpiration") || "",
          termination_date:  getDateMDY(item.terminationDate,"terminationDate") || "",
          roadExam: item.roadExam || "",
          driver_phone_num: item.driverPhone || "",
          driver_email: item.driverEmail || "",
          condata_card: item.comdataCard || "",
          vendor_code: item.vendorNumber || "",
          vendor_name: item.vendorName || "",
          vendor_address: item.vendorAddress || "",
          vendor_city_st_zip:`${item.vendorCity?item.vendorCity:""}${item.vendorState?item.vendorState:""}${item.vendorZip?item.vendorZip:""}`,
          // vendorCity: item.vendorCity || "",
          // vendorState: item.vendorState || "",
          // vendorZip: item.vendorZip || "",
          company: item.company || "",
          location: item.location || "",
          //extra
          overall_avb_message:overallAvailabilityStatus( item.available, isActive).issues|| "The truck is available",
          description:"",
          truck_status: item.truckNumber ? truck_status(registrationExpires, fhdwExpiration, terminationDate, item.available).overallStatus||"" : 'none',
          truck_status_code:truck_status(registrationExpires, fhdwExpiration, terminationDate, item.available).statusCode || "",
         // equipment_termination:"",   // not required
          // ex_service_date:"",   // not required
          phone_number:"",
        
          driver_status:driver_status(licenseExp, medicalExamExp, terminationDate).overallStatus||"",
          driver_status_description:driver_status(licenseExp, medicalExamExp, terminationDate).issues||"",
          licence_no:item.license || "",
         // driver_fax_num:"", // not required
          sort:item.sort ||""


        }
      })
      return truckDriver;
  
    } catch (e) {
      console.log('Error occurred while mapping', e)
      return false;
    }
  }
  export const mapTruckDriverFormToServer = (formData) => {
     
        try {

          let truckDriver ={  truck: {
            location:formData.location || 0,
            company:formData.company || 0,
            truckNumber:formData.truck || 0,
            driverNumber:formData.driver_number || 0,
            truckAvailable:formData.available || "",
            phone:formData.truckPhone || "",
            truckLabel: formData.truck_driver_label || "",
            comment:formData.comment || "" ,
            sort:formData.sort | ""           
            },
            driver : {
              company:formData.company || 0,
              driverNumber:formData?.old_driver_number?formData?.old_driver_number:formData.driver_number || 0,
              phone:formData.driver_phone_num || "",
              email:formData.driver_email || "",
              comdataCard:formData.condata_card || "",
              newDriver:formData.driver_number,
              oldTruckNumbers:formData?.trucks || []
              }
            }
          
          return truckDriver;
      
        } catch (e) {
          console.log('Error occurred while mapping', e)
          return false;
        }
      }


  export function highlightDate(value, color = false,terminationDateField=false) {
    const currentDate = new Date();
    const oneMonthFromNow = new Date();
    oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
  
    let highlightClass = "";
    
  
    if (!value) {
      highlightClass =terminationDateField?"":"red";
    } else {
      // Correctly parse date value assuming format "DD/MM/YYYY"
      
      const [month,day,year] = value.split('/');
      const cellDate = new Date(`20${year}`, month - 1, day);
  
      if (cellDate < currentDate) {
        highlightClass = "red";
      } else if (cellDate < oneMonthFromNow) {
        highlightClass =terminationDateField?"":"#f29407";
      }
    }
  
    if (color) {
      return highlightClass;
    } else {
      return (
        <div style={{ color: highlightClass, padding: "4px 8px" ,minHeight:"25px",minWidth:"90px"}}>
          {value||""} {/* Non-breaking space */}
        </div>
      );
    }
  }
  
  export const mapDriverList = (driverRes,old_driver_no) => {
    try {
        let truckDriver = driverRes?.map((item, index) => {
          const terminationDate = getDateMDY(item?.dateOfTermination,"terminationDate") || "";
      const licenseExp= getDateMDY(item?.dateLicenseExpires,"licenseExpiration") || "";
      const medicalExamExp= getDateMDY(item?.dateMedicalExam,"medicalExamExpiration") || ""
            return {
                id: index,
                driver_number: item?.safetyId,
                old_driver_number:old_driver_no||"",
                driver_name: `${item?.firstName ? item.firstName : ""} ${item?.middleInitial ? item.middleInitial : ""} ${item?.lastName ? item.lastName : ""}` || "",
                licence_no: item?.licenseNo||"",
                license_exp: licenseExp || "",
                medical_exam_exp: medicalExamExp || "",
                termination_date: terminationDate || "",
                driver_phone_num: item?.driverPhone || "",
                driver_email: item?.driverEmail || "",
                condata_card: item?.comdataCard || "",
                driver_status:driver_status(licenseExp, medicalExamExp, terminationDate).overallStatus||"",
                driver_status_description:driver_status(licenseExp, medicalExamExp, terminationDate).issues||"",
                trucks:[]
            }
        });

        return truckDriver;
    } catch (e) {
        console.log('Error occurred while mapping', e);
        return false;
    }
}

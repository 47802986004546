import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Paper, TableContainer, Box, TextField, Button } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';

import { getTemplateData } from "../../../store/actions/templateAction";
import { maptoTemplateTable } from "./commonFn";
import { createTheme, ThemeProvider } from '@mui/material/styles';
export default function TemplateTable(props) {
                        useState('')
    const { setSelected,selected,rows, setRows, setSelectedRow ,setRowdata ,rowData , isLoading,setTemplateObj,templateTableApiCall,handleQueryPageChange,rowsCount,handleSortChangeData,setEditTemplate, setAddNewTemplate, addNewTemplate,editTemplate  } = props;

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 1,
    });
    const [sortData, setSortData] = useState({
        field:"shipperAgent", 
        sort:"asc"
        
    });
    const dispatch = useDispatch();

    const tableColumns = [
        { field: 'tamplatename', headerName: 'Template Name', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,valueFormatter: (params) => decodeURIComponent(params.value),minWidth:100 },
        { field: 'orderby', headerName: 'Order By', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        { field: 'stop', headerName: 'Shipper/Cgnee', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'type', headerName: 'Type', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        { field: 'poruPoint', headerName: 'Pickup Point', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100},
        { field: 'stop_city_state', headerName: 'City,ST', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        // { field: 'stop_city', headerName: 'stop city', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        { field: 'disposition', headerName: 'Disposition', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        { field: 'shipperOrConsShipper', headerName: 'SSL', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
    ]

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const handleRowClick = (params) => {
        if(editTemplate||addNewTemplate){
            let confirmAlert = window.confirm("Please save your changes or click 'OK' to open selected Template ");
            if (confirmAlert) {
                let selected=params?.row?.id;
                let data=rowData?.data?.hits;
                setSelected(params?.row?.tamplatename)
                setTemplateObj(params?.row)
                setSelectedRow(data.filter((item,index) => index === selected))
                setAddNewTemplate(false)
                setEditTemplate(false)
               
             
          }
           
          } else {
            let selected=params?.row?.id;
                let data=rowData?.data?.hits;
                setSelected(params?.row?.tamplatename)
                setTemplateObj(params?.row)
                setSelectedRow(data.filter((item,index) => index === selected))
                setAddNewTemplate(false)
                setAddNewTemplate(false)
                setEditTemplate(false)
          }  




       

    };
  
    const handlePageChange = (params) => {
        console.log("sortData",sortData)
        setPaginationModel({ pageSize: params.pageSize, page: params.page })
        handleQueryPageChange(params,sortData)
        // templateTableApiCall(ordersLocation.locationNumber,{page: params.page, pageSize: params.pageSize  })
    }
    const handleSortChange = (sortModel) => {
        console.log("sortmodel",sortModel)
        const sortDataToServer={
            field:"shipperAgent", 
            sort:"asc"
            
        }
        if(sortModel[0]){
            console.log("if true")
            const { field, sort } = sortModel[0];
            sortDataToServer.field=field==="tamplatename"?"templateName":field==="orderby"?"shipperAgent":field==="type"?"orderType":field==="stop"?"stops.name":field==="stop_city_state"?"stops.city":field
            sortDataToServer.sort=sort
        }

       console.log("sortDataToServer",sortDataToServer)
        setSortData(sortDataToServer)
        handleSortChangeData(paginationModel,sortDataToServer)
       
        
    };
    return (

        <TableContainer component={Paper} style={{ width: '100%', height: '100%'}}>
            <Box sx={{
                width: '100%',
                overflowX: 'auto',
                height: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .tableTH': {
                    fontWeight: 700
                },
                '& .MuiDataGrid-columnHeaders': {
                    // backgroundColor: "rgba(9, 29, 163, 1)",
                    backgroundColor: "rgb(11, 67, 135)",
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: '12px'
                },
                '& .MuiDataGrid-sortIcon': {
                    opacity: 1,
                    color: "rgba(255, 255, 255, 1)",
                },
                '& .MuiDataGrid-menuIconButton': {
                    opacity: 1,
                    color: "rgba(255, 255, 255, 1)",
                },
                '& .super-app-theme--cell': {
                    backgroundColor: 'white',
                    fontSize: '11px'

                },
                "& .selected-row": {
                    backgroundColor: "#D3D3D3 !important", // Highlight selected row
                  },


            }}>
                <DataGridPro
                    rows={rows}
                    rowCount={rowsCount}
                    columns={tableColumns}
                    getRowClassName={(params) =>
                         selected=== params?.row?.tamplatename ? "selected-row" : ""
                      }
                    editMode="row"
                    columnHeaderHeight={30}
                    rowHeight={30}
                    checkboxSelection={false}
                    disableRowSelectionOnClick {...rows}
                    // hideFooter={true}
                    // hideFooterRowCount={true}
                    onRowClick={handleRowClick}
                    loading={isLoading}
                    paginationMode="server"
                        pagination={true}
                        paginationModel={paginationModel}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={(params) => { handlePageChange(params) }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page } },
                        }}
                        sortingOrder={['desc', 'asc']}
                        sortingMode="server"
                        onSortModelChange={handleSortChange}

                />
              
            </Box>
        </TableContainer>
    )

}
import _ from 'lodash';
export const calRatio = (num1, num2) => {
  if ((num1 !== '' && typeof num1 !== 'undefined') && (num2 !== '' && typeof num2 !== 'undefined')) {
    return roundToTwo((Number(num1) / Number(num2)) * 100);
    //return ((Number(num1) / Number(num2)) * 100)?.toFixed(2); // + "%";
  } else {
    return 0;
  }
};
export const calMargin = (num1, num2) => {
  if ((num1 !== '' && typeof num1 !== 'undefined') && (num2 !== 0 && typeof num2 !== 'undefined')) {
    return roundToTwo(Number(num1) - Number(num2));
    //return (Number(num1) - Number(num2))?.toFixed(2);
  } else {
    return 0;
  }
};
export const calRevenue = (num1, num2) => {
  if ((num1 !== '' && typeof num1 !== 'undefined' && num1 !== null) && (num2 !== 0 && typeof num2 !== 'undefined' && num2 !== null)) {
    return "$" + roundToTwo(Number(num1) / Number(num2));
    //return "$" + (Number(num1) / Number(num2))?.toFixed(2);
  } else {
    return 0;
  }
};

export const calRevenueForImport = (num1, num2) => {
  if ((num1 !== '' && typeof num1 !== 'undefined' && num1 !== null) && (num2 !== 0 && typeof num2 !== 'undefined' && num2 !== null)) {
    return roundToTwo(Number(num1) / Number(num2));
    //return (Number(num1) / Number(num2))?.toFixed(2);
  } else {
    return 0;
  }
};

export const calRate = (num1, num2) => {
  if ((num1 !== '' && typeof num1 !== 'undefined') && (num2 !== '' && typeof num2 !== 'undefined')) {
    return roundToTwo((Number(num1) * Number(num2)) / 100);
    //return ((Number(num1) * Number(num2)) / 100)?.toFixed(2);
  } else {
    return 0;
  }
};

export const calRateOnMarginChange = (num1, num2) => {
  if ((num1 !== '' && typeof num1 !== 'undefined') && (num2 !== 0 && typeof num2 !== 'undefined')) {
    return roundToTwo(Number(num1) - Number(num2));
    //return (Number(num1) - Number(num2))?.toFixed(2);
  } else {
    return 0;
  }
};

export const formatRatioPercentage = (num) => {
  if (num !== '' && typeof num !== 'undefined') {
    return roundToTwo(Number(num)) + '%'
    //return Number(num)?.toFixed(2) + '%'
  }
  return 0.00;
}

export const validator = (input) => {
  const errors = {};
  if (!input.notes) {
    errors.notes = "This field is required!";
  } else {
    errors.notes = "";
  }

  if (!input.invoiceDesc) {
    errors.invoiceDesc = "This field is required!";
  } else {
    errors.invoiceDesc = "";
  }

  if (!input.GLCode) {
    errors.GLCode = "This field is required!";
  } else {
    errors.GLCode = "";
  }

  if (!input.GLDesc) {
    errors.GLDesc = "This field is required!";
  } else {
    errors.GLDesc = "";
  }

  // if (!input.originCity) {
  //   errors.originCity = "This field is required!";
  // } else {
  //   errors.originCity = "";
  // }

  // if (!input.originState) {
  //   errors.originState = "This field is required!";
  // } else {
  //   errors.originState = "";
  // }

  // if (!input.destinationCity) {
  //   errors.destinationCity = "This field is required!";
  // } else {
  //   errors.destinationCity = "";
  // }

  // if (!input.destinationState) {
  //   errors.destinationState = "This field is required!";
  // } else {
  //   errors.destinationState = "";
  // }

  // if (!input.totMiles) {
  //   errors.totMiles = "This field is required!";
  // } else {
  //   errors.totMiles = "";
  // }

  // if (!input.receivableRates) {
  //   errors.receivableRates = "This field is required!";
  // } else {
  //   errors.receivableRates = "";
  // }

  // if (!input.LorA) {
  //   errors.LorA = "This field is required!";
  // } else {
  //   errors.LorA = "";
  // }

  // if (!input.payableRateContract) {
  //   errors.payableRateContract = "This field is required!";
  // } else {
  //   errors.payableRateContract = "";
  // }

  // if (!input.payableRateBroker) {
  //   errors.payableRateBroker = "This field is required!";
  // } else {
  //   errors.payableRateBroker = "";
  // }

  return errors;
};

const fieldsMapping = {
  "autoId": "Auto ID",
  "locationNumber": "Location Number",
  "notes": "Rate Name",
  "invoiceDesc": "Invoice Description",
  "GLCode": "GL Code",
  "GLDesc": "GL Description",
  "originCity": "Ramp/Port/CY",
  "originState": "State",
  "destinationCity": "Destination City",
  "destinationState": "Destination State",
  "totMiles": "RT Miles",
  "receivableRates": "Receivable Rates",
  "LorA": "L/A",
  "payableRateContract": "Contract Rate",
  "payableRateBroker": "Broker Rate",
  "sno": "Sr No",
}

const mandatoryFields = [
  "notes",
  "invoiceDesc",
  "GLDesc",
  "originCity",
  "originState",
  "destinationCity",
  "destinationState",
  "receivableRates"
]

export async function validateField(row) {
  return new Promise((resolve, reject) => {
    let errors = {};
    for (const key in row) {
      if ((row[key] === "" || row[key] === 0) && mandatoryFields.includes(key)) {
        Object.assign(errors, { [key]: { error: true, message: `${fieldsMapping[key]} field is required!` } });
      }
    }
    if (Object.keys(errors).length > 0) {
      return reject(errors);
    }
    return resolve(errors);
  });
}

export function sanitizeString(rawStr) {
  if (typeof rawStr !== 'undefined' && rawStr !== '' && rawStr !== null) {
    var sanitizedStr = rawStr?.replace(/[^a-zA-Z0-9\-_.,()&/$ ]/g, ''); //replace(/[^a-zA-Z\d]/g, "");
    return sanitizedStr;
  }
  return rawStr;
}

export function validateRatebookData(obj) {
  let errMsg = '';
  if (!(isNaN(obj['Rate Name']) && (obj['Rate Name'].length <= 100)))
    errMsg += 'Rate Name field is not valid';
  if (!(isNaN(obj['Invoice Description']) && (obj['Invoice Description'].length <= 35)))
    errMsg += '\nInvoice Description field is not valid';
  if (!(!isNaN(obj['GL Code']) && (obj['GL Code'].toString().length === 7)))
    errMsg += '\nGL Code field is not valid';
  if (!(isNaN(obj['GL Description']) && (obj['GL Description'].length <= 40)))
    errMsg += '\nGL Description field is not valid';

  if (errMsg !== '') {
    return { status: true, message: errMsg }
  }
  return { status: false, message: '' }
}

export function validateSingleField(obj) {
  if (!(isNaN(obj['Rate Name']) && (obj['Rate Name'].length <= 100)))
    return false;
  if (!(isNaN(obj['Invoice Description']) && (obj['Invoice Description'].length <= 35)))
    return false;
  if (!(!isNaN(obj['GL Code']) && (obj['GL Code'].toString().length === 7)))
    return false;
  if (!(isNaN(obj['GL Description']) && (obj['GL Description'].length <= 40)))
    return false;
  // if (!(isNaN(obj['Ramp/Port/CY']) && (obj['Ramp/Port/CY'].length <= 20)))
  //   return false;
  // if (!(isNaN(obj['State']) && (obj['State'].length === 2)))
  //   return false;
  // if (!(isNaN(obj['Destination City']) && (obj['Destination City'].length <= 20)))
  //   return false;
  // if (!(isNaN(obj['Destination State']) && (obj['Destination State'].length === 2)))
  //   return false;
  // if (!(!isNaN(obj['Miles']) && (obj['Miles'].toString().length <= 4)))
  //   return false;
  // if (!(!isNaN(obj['Receivable Rates']) && (obj['Receivable Rates'].toString().length <= 6)))
  //   return false;
  // if (!(isNaN(obj['L/A']) && (obj['L/A'].length === 1)))
  //   return false;
  // if (!(!isNaN(obj['Payable Rate (Contractor)']) && (obj['Payable Rate (Contractor)'].toString().length <= 6)))
  //   return false;
  // if (!(!isNaN(obj['Payable Rate (Broker)']) && (obj['Payable Rate (Broker)'].toString().length <= 6)))
  //   return false;

  return true;
}

export function computeMutation(newRow, oldRow) {
  let changedFieldsMsg = [];
  for (const key in newRow) {
    if (newRow[key] !== oldRow[key]) {
      let msg = '';
      if (oldRow.autoId === -1) {
        msg = (<p key={key}>{`${fieldsMapping[key]} - '${newRow[key]}'`}</p>);
      } else {
        msg = (<p key={key}>{`${fieldsMapping[key]} - from '${oldRow[key]}' to '${newRow[key]}'`}</p>);
      }
      changedFieldsMsg.push(msg);
    }
  }
  return changedFieldsMsg;
}

function roundToTwo(num) {
  const finalNum = parseFloat(num.toPrecision(5));
  if (isNaN(finalNum)) {
    return 0;
  }
  return finalNum;
}

export function exportResultToCsv(filename, rows) {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j]?.toString() || '';
      if (row[j] instanceof Date) {
        innerValue = row[j]?.toLocaleString() || '';
      };
      var result = innerValue?.replace(/"/g, '""') || '';
      if (result?.search(/("|,|\n)/g) >= 0)
        result = '"' + result + '"';
      if (j > 0)
        finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useParams } from "react-router-dom";
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@mui/material/Divider';
// import {
//     Button
// } from '@material-ui/core';

import { TenderDetailsWrapper } from './../../components/styledComponents/index';
import { TruckDriverListWrapper } from '../../components/styledComponents'
import CompanyInfo from '../../components/TruckDriverInformation/CompanyInfo'
import TruckInfo from '../../components/TruckDriverInformation/TruckInfo'
import VendorInfo from '../../components/TruckDriverInformation/VendorInfo'
import DriverInfo from '../../components/TruckDriverInformation/DriverInfo'
import './truck-driver-info.css';
import { getTruckDriverInfo } from '../../store/actions/loadsActions';
import Loader from './../../components/Loader/Lodaer';

export default function TruckDriverinfo() {
    let [checkServerDataDriver, setCheckServerDataDriver] = useState("");
    const params = useParams();
    const { driverId, locNum, truckId } = params;
    let driverNumId = Number(driverId)
    let locNumId = Number(locNum)
    let truckNumId = Number(truckId)
    const dispatch = useDispatch();
    let [truckDriverInfo, SetTruckDriverInfo] = useState({});

    // truck driver status
    const [updatedDriverStatus, setUpdatedDriverStatus] = useState('');
    const [updatedTruckStatus, setUpdatedTruckStatus] = useState('');

    // over all status
    const [overAllStatus, setOverAllStatus] = useState('')
    const history = useHistory();

    function backDetailsPage() {
        history.push('/tenderList')
    }

    useEffect(async () => {
        let checkTruckDriverData = await dispatch(getTruckDriverInfo({ driverNumId, locNumId, truckNumId }));
        // let checkTruckDriverData = await dispatch(getTruckDriverInfo({ driverNumId: 9177, locNumId: 6, truckNumId: 1149 }));
        if (checkTruckDriverData == "There is no data for requested driver Id!") {
            setCheckServerDataDriver(checkTruckDriverData)
        }
    }, []);

    let driverInfoObj;
    const driverInfo = useSelector(({ loadsReducer }) => {
        driverInfoObj = _.isEmpty(loadsReducer.truckDriverInfoDetail);
        if (driverInfoObj == false) {
            return loadsReducer.truckDriverInfoDetail
        }
    })

    useEffect(() => {
        if (driverInfo) {
            SetTruckDriverInfo(driverInfo)
        }
    }, [driverInfo])

    let checkDriverInfoObj = _.isEmpty(truckDriverInfo);

    const truckStatusFunc = (status) => {
        if (status) {
            setUpdatedTruckStatus(status)
        }
    }

    const driverStatusFunc = (status) => {
        if (status) {
            setUpdatedDriverStatus(status)
        }
    }

    useEffect(() => {
        if ((updatedTruckStatus == 'OK') && (updatedDriverStatus == 'OK')) {
            setOverAllStatus('YES')
        } else {
            setOverAllStatus('NO')
        }
    }, [updatedTruckStatus && updatedDriverStatus])

    return (
        <TenderDetailsWrapper>
            <TruckDriverListWrapper className="truck-driverinfo-list-wrapper">
                {
                    (checkDriverInfoObj || driverInfoObj) && checkServerDataDriver == '' ? <Loader /> : <AccordionDetails>
                        {
                            checkServerDataDriver ? <div className="truck-driverinfo-empty-data-wrapper">
                                <h4>{checkServerDataDriver}</h4>
                                <button onClick={backDetailsPage} style={{
                                    display: "grid",
                                    background: "red",
                                    padding: '5px',
                                    marginTop: "3%",
                                    width: "30%",
                                    justifySelf: "end"
                                }}><span style={{ color: 'white' }}>Back</span></button>
                            </div> : <form className="driver-info-form">
                                
                                <CompanyInfo truckDriverInfo={truckDriverInfo} overAllStatus={overAllStatus} />
                                <Divider style={{ marginBottom: "3%" }} />
                                <TruckInfo truckDriverInfo={truckDriverInfo} truckStatusFunc={truckStatusFunc} />
                                <Divider style={{ marginBottom: "3%" }} />
                                <VendorInfo truckDriverInfo={truckDriverInfo} />
                                <Divider style={{ marginBottom: "3%" }} />
                                <DriverInfo truckDriverInfo={truckDriverInfo} driverStatusFunc={driverStatusFunc} />

                                <button onClick={backDetailsPage} className="truck-driver-back-button"><span style={{ color: 'white' }}>Back</span></button>
                            </form>
                        }
                    </AccordionDetails>}

            </TruckDriverListWrapper>
        </TenderDetailsWrapper >
    )

}



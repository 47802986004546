import { fetchLocation } from '../../store/actions/locationActions';
// const _ = require('loadsh')
import _ from 'lodash';


export const fetchLocationData = (dispatch) => {
 
    dispatch(fetchLocation());
  
}

const getFetchLocLocalStorage = localStorage.getItem('fetLocationNumber');

let newFetchLocNumber = [];
export let userList = [];
if (getFetchLocLocalStorage !== null) {
  
    const parseLocArray = JSON.parse(getFetchLocLocalStorage)

    let sortUserList = parseLocArray.sort(function (a, b) {

        return parseFloat(a.LOCATIONNUMBER) - parseFloat(b.LOCATIONNUMBER);
    });


    newFetchLocNumber = sortUserList.map((val) => {
        return { id: val.id, name: `${val.LOCATIONNUMBER}` }
    })

    userList = _.uniqBy(newFetchLocNumber, function (e) {
        return e.name;
    });
}



import { reportTypes } from "../types/reportTypes";
const initialState = {
    reportData: []
};

export const reportReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case reportTypes.REPORT_DATA:
      return {
        ...state,
        reportData: payload
      }

    default:
      return state;
  }
}
import { useEffect, useState } from "react";

import { Paper, TableContainer, Box, TextField, Button, InputAdornment } from "@mui/material";
import { DataGridPro, GridActionsCellItem, GridRowModes } from '@mui/x-data-grid-pro';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useSelector, useDispatch } from "react-redux";

import TemplateTable from "./components/TemplateTable";
import PopOver from "./components/PopOver";
import { getTemplateData, searchTemplate } from "../../store/actions/templateAction";
import { maptoTemplateTable } from "./components/commonFn";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from '@mui/icons-material/Close'


export default function TemplateContainer(props) {
    const { setSelected,selected, setSelectedRow,selectedRow, setTemplateObj, setRows, rows, isLoading, templateTableApiCall, rowData, setRowdata, setOrderType,setRowsCount,rowsCount,setEditTemplate,setAddNewTemplate, addNewTemplate,editTemplate , templateSearchError,handleSearchReq} = props;
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    // const [templateSearchError, setTemplateSearchError] = useState('')
    const open = Boolean(anchorEl);
    const popOverID = open ? "new-order-popover" : undefined;


    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    // const handleClearSearch=()=>{
    //     setSearchText("")
    //     if (typeof ordersLocation?.locationNumber !== 'undefined') {
    //         templateTableApiCall(ordersLocation?.locationNumber);
    //     }
    // }
    const handleSearchText = (event) => {
        setSearchText(event);
        if (event === "") {
            handleSearch("")
        }

    }
    const handleSearch = (searchNameText) => {

        handleSearchReq(searchNameText)

    }
    useEffect(() => {

    }, [rows])

    const newOrderClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleQueryPageChange = (params,sortData={
        field:"shipperAgent", 
        sort:"asc"}) => {
        
        setSelected("")
        setSelectedRow("")
        if(searchText){
            handleSearchReq(searchText,{page: params?.page+1, pageSize: params?.pageSize},sortData)
        }else{
            templateTableApiCall({page: params?.page+1, pageSize: params?.pageSize},sortData )
        }
        
    }
    const handleSortChange = (params,sortData={
        field:"shipperAgent", 
        sort:"asc"}) => {
        setSelected("")
        setSelectedRow("")
        if(searchText){
            handleSearchReq(searchText,{page: params?.page+1, pageSize: params?.pageSize},sortData)
        }else{
            templateTableApiCall({page: params?.page+1, pageSize: params?.pageSize},sortData )
        }
        
    }

    return (
        <Paper style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

            <div style={{ width: '100%', height: '40px', backgroundColor: '#E4EEFA', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                <label style={{ fontSize: '14px', color: '#091DA3' }}>Templates</label>
            </div>

            <div style={{ width: '100%', height: '50px', display: 'flex', flexDirection: 'row', alignItems: 'center', padding: '10px' }}>
                <div style={{ width: '70%', height: '40px', display: 'flex', alignItems: 'center', paddingRight: '5px' }}>
                    <TextField
                        label="Search"
                        margin="dense"
                        style={{ height: '40px', width: "100%", fontSize: '10px' }}
                        size="small"
                        inputProps={{ style: { fontSize: 10 } }}
                        InputLabelProps={{ style: { fontSize: 14, height: 40 } }}
                        value={searchText}
                        // onChange={(event) => { setSearchText(event.target.value) }}
                        onChange={(event) => {
                            handleSearchText(event.target.value)

                        }}
                        onKeyPress={(event) => { event.key === 'Enter' && handleSearch(event.target.value) }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment>
                                    <IconButton onClick={() => { searchText ? handleSearchText("") : handleSearch() }}>
                                        {searchText ? <CloseIcon /> : <SearchIcon />}
                                    </IconButton>
                                    {/* <IconButton onClick={() => { handleSearch() }}>
                                        <SearchIcon />
                                    </IconButton> */}
                                </InputAdornment>
                            )
                        }}

                    />
                    {/* {templateSearchError && <div style={{ color: 'red' }}>{templateSearchError}</div>} */}
                </div>

                <div style={{ width: '30%', height: '40px', display: 'flex', alignItems: 'center' }}>
                    <Button variant="outlined" style={{ width: '100%', height: '35px', fontSize: '10px' }} onClick={newOrderClick} endIcon={<AddCircleOutlineIcon style={{ width: '15px', height: '15px' }} />}>Add Template</Button>
                    <PopOver
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        popOverID={popOverID}
                        ordersLocation={ordersLocation}
                        open={open}
                        setOrderType={setOrderType}
                        setSelected={setSelected}
                        setSelectedRow={setSelectedRow}
                        setTemplateObj={setTemplateObj}
                        setEditTemplate={setEditTemplate}
                        setAddNewTemplate={setAddNewTemplate}
                        addNewTemplate={addNewTemplate}
                        editTemplate={editTemplate}
                    />
                </div>

            </div>

            <div style={{ width: '100%', height: `calc(100vh - 200px)` }}>

                {templateSearchError ? <div style={{ color: 'red', textAlign: "center" }}>{templateSearchError}</div> :
                    <TemplateTable
                        rows={rows}
                        setRows={setRows}
                        rowData={rowData}
                        setRowdata={setRowdata}
                        isLoading={isLoading}
                        setSelected={setSelected}
                        selected={selected}
                        setSelectedRow={setSelectedRow}
                        setTemplateObj={setTemplateObj}
                        templateTableApiCall={templateTableApiCall}
                        handleQueryPageChange={handleQueryPageChange}
                        handleSortChangeData ={handleSortChange }
                        rowsCount={rowsCount}
                        setEditTemplate={setEditTemplate}
                        setAddNewTemplate={setAddNewTemplate}
                        addNewTemplate={addNewTemplate}
                        editTemplate={editTemplate}
                    />
                }
            </div>




        </Paper>

    )

}
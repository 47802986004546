import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { Paper, TableContainer, Box, FormControlLabel,Stack,Checkbox, Button,Typography} from "@mui/material";
import { DataGridPro} from '@mui/x-data-grid-pro';
import {moveCodeToLocation} from "../../../../store/actions/maintenanceActions"

export default function BillToOrderByTable(props) {
                        useState('')
    const { rows, setSelectedRow , isLoading,handleQueryPageChange,rowsCount,handleSortChangeData,setAddNewPickUpDispo,orderByTableApiCall, setEditShipperClicked , editShipperClicked,addNewPickUpDispo,isOrderScreen} = props;

    const [paginationModel, setPaginationModel] = useState({
        pageSize: 50,
        page: 1,
    });
    const [sortData, setSortData] = useState({
        field:"name", 
        sort:"asc"
        
    });
    const[selectedRowId,setSelectedRowId]=useState("")
    // useEffect(() => {
    //   setSelectedRow("");
    
    //   if (isOrderScreen && typeof ordersLocation?.locationNumber !== "undefined") {
    //     // setIsLoading(true);
    //     orderByTableApiCall();
    //   }
    // }, [isOrderScreen]);

    // const [selectedRowsToChangeLoc, setSelectedRowsToChangeLoc] = useState([]);
    // const [showSpecificLocData, setShowSpecificLocData]=useState(false)
    const dispatch = useDispatch();
 

    const tableColumns = [
        
        { field: 'code', headerName: 'Code', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'name', headerName: 'Name', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'location', headerName: 'Location', headerClassName: 'MuiDataGrid-columnHeaders', flex: 1, minWidth: 100},
        { field: 'city', headerName: 'City', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100},
        { field: 'state', headerName: 'State', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1 ,minWidth:100},
        { field: 'billToHash', headerName: 'Bill To #', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'billTo', headerName: 'Bill To', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        { field: 'status', headerName: 'Status', headerClassName: 'MuiDataGrid-columnHeaders',  flex: 1,minWidth:100 },
        
        
    ]

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const handleRowClick = (params) => {

        if(editShipperClicked || addNewPickUpDispo){
          let confirmAlert = window.confirm("Please save your changes or click 'OK' to open seleted Pickup/Disposition");
          if (confirmAlert) {
            console.log("params",params)
            setSelectedRowId(params?.id)
            setSelectedRow(params?.row?.code)
            setAddNewPickUpDispo(false)
            setEditShipperClicked(false)
           
        }
       
         
        } else {
          console.log("params",params)
          setSelectedRowId(params?.id)
          setSelectedRow(params?.row?.code)
          setAddNewPickUpDispo(false)
        setEditShipperClicked(false)
        }
      
      }
       
    const handlePageChange = (params) => {
        setPaginationModel({ pageSize: params.pageSize, page: params.page })
        handleQueryPageChange(params,sortData)
        // orderByTableApiCall(ordersLocation.locationNumber,{page: params.page, pageSize: params.pageSize  })
    }
    const handleSortChange = (sortModel) => {
        const sortDataToServer={
            field:"name", 
            sort:"asc"
            
        }
        if(sortModel[0]){
            const { field, sort } = sortModel[0];
            sortDataToServer.field=field
            sortDataToServer.sort=sort
        }

        setSortData(sortDataToServer)
        handleSortChangeData(paginationModel,sortDataToServer)
       
        
    };
    return (

        <TableContainer component={Paper} style={{ width: '100%', height: '100%'}}>
            <Box sx={{
                width: '100%',
                overflowX: 'auto',
                height: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .tableTH': {
                    fontWeight: 700
                },
                '& .MuiDataGrid-columnHeaders': {
                    // backgroundColor: "rgba(9, 29, 163, 1)",
                    backgroundColor: "rgb(11, 67, 135)",
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: '12px'
                },
                '& .MuiDataGrid-sortIcon': {
                    opacity: 1,
                    color: "rgba(255, 255, 255, 1)",
                },
                '& .MuiDataGrid-menuIconButton': {
                    opacity: 1,
                    color: "rgba(255, 255, 255, 1)",
                },
                '& .super-app-theme--cell': {
                    backgroundColor: 'white',
                    fontSize: '11px'

                },
                "& .selected-row": {
                  backgroundColor: "#D3D3D3 !important", // Highlight selected row
                },


            }}>
                 {/* <Stack
            direction="column"
            spacing={2}
            boxShadow={2}
            // padding={3}
            // height={80}
          >
            <Stack direction="row" spacing={1}>
                
       { !showSpecificLocData?
        <Button
            variant="outlined"
            style={{ width: "50%", height: "35px", fontSize: "10px" }}
            onClick={handleMoveToLocation}
            // dissabled={selectedRowsToChangeLoc?false:true}
            disabled={!selectedRowsToChangeLoc}
            // onClick={isNewOrderClicked}
            // onClick={() => {
            //   setAddNewPickUpDispo(true);
            //   setSelectedRow("");
            // }}
            // endIcon={
            //   <AddCircleOutlineIcon style={{ width: "15px", height: "15px" }} />
            // }
          >
            Move  to location {ordersLocation?.locationNumber}
          </Button>:
          <Button
            variant="outlined"
            style={{ width: "50%", height: "35px", fontSize: "10px" }}
            onClick={handleMoveToLocation}
            dissabled={selectedRowsToChangeLoc?false:true}
            // onClick={isNewOrderClicked}
            // onClick={() => {
            //   setAddNewPickUpDispo(true);
            //   setSelectedRow("");
            // }}
            // endIcon={
            //   <AddCircleOutlineIcon style={{ width: "15px", height: "15px" }} />
            // }
          >
            Move  to show all
          </Button>
}
           
              <FormControlLabel
                control={
                  <Checkbox
                  checked={!showSpecificLocData}
                  onChange={showSpecificLocDataHandler}
                    // checked={showActiveTruck}
                    // onChange={(e) => setShowActiveTruck(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                    <Typography sx={{ fontSize: '0.7rem' }}>
                      {`Show All`}
                    </Typography>
                  }
                // label="Show All"
              />
            
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showSpecificLocData}
                    onChange={showSpecificLocDataHandler}
                    color="primary"
                  />
                }
                // label={`show for location ${ordersLocation?.locationNumber}`}
                label={
                    <Typography sx={{ fontSize: '0.7rem' }}>
                      {`show for location ${ordersLocation?.locationNumber}`}
                    </Typography>
                  }
              />
            </Stack> */}
          {/* </Stack> */}
                <DataGridPro
                    rows={rows}
                    rowCount={rowsCount}
                    columns={tableColumns}
                    getRowClassName={(params) =>
                      selectedRowId=== params?.id? "selected-row" : ""
                   }
                    editMode="row"
                    columnHeaderHeight={30}
                    rowHeight={30}
                    // checkboxSelection
                    disableRowSelectionOnClick {...rows}
                    // hideFooter={true}
                    // hideFooterRowCount={true}
                    // onRowClick={handleRowClick}
                    onRowClick={isOrderScreen? null : handleRowClick}
                    onRowDoubleClick={isOrderScreen? handleRowClick : null}
                    loading={isLoading}
                    paginationMode="server"
                        pagination={true}
                        paginationModel={paginationModel}
                        pageSizeOptions={[10, 25, 50]}
                        onPaginationModelChange={(params) => { handlePageChange(params) }}
                        initialState={{
                            pagination: { paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page } },
                        }}
                        sortingOrder={['desc', 'asc']}
                        sortingMode="server"
                        onSortModelChange={handleSortChange}
                        // onRowSelectionModelChange={handleSelectionChange}

                />
              
            </Box>
        </TableContainer>
    )

}
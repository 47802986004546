// import React ,{ useEffect, useState } from "react";
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import TextField from '@mui/material/TextField';

// // const rows = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun'];

// export default function ShipRecHoursTable({hoursRows}) {
//   console.log("hoursRows",hoursRows)
//   const [hoursRowsData,setHoursRowsData]=useState(hoursRows?hoursRows:[])
//   return (
//     <TableContainer component={Paper}>
//       <Table sx={{ width: '100%' }}>
//         <TableHead sx={{ borderBottom: 'none',padding: '8px' }}>
//           <TableRow>
//             <TableCell colSpan={7} >Shipping Hours</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           <TableRow>
//           <TableCell colSpan={7}>
//           <div style={{ display: 'flex' }}>
//             {hoursRowsData?.shippingHours?.map((day) => (
              
//  <React.Fragment key={day?.id}>
//           <TextField variant="outlined" size="small" placeholder="Mon"  sx={{ minWidth: 5 }}/>
//           <TextField variant="outlined" size="small" placeholder="Tue" sx={{ minWidth: 5 }} />
//           <TextField variant="outlined" size="small" placeholder="Wed"   sx={{ minWidth: 5 }}/>
//           <TextField variant="outlined" size="small" placeholder="Thur"  sx={{ minWidth: 20 }}/>
//           <TextField variant="outlined" size="small" placeholder="Fri" sx={{ minWidth: 20 }} />
//           <TextField variant="outlined" size="small" placeholder="Sat"  sx={{ minWidth: 20 }}/>
//           <TextField variant="outlined" size="small" placeholder="Sun" sx={{ minWidth: 20 }} />
//         </React.Fragment>
               
             
//             ))}
//             </div>
            
//            </TableCell>
//           </TableRow>
//         </TableBody>
//         <TableHead sx={{ borderBottom: 'none' ,padding: '8px'}}>
//           <TableRow>
//             <TableCell colSpan={7} >Receiving Hours</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           <TableRow>
//           <TableCell colSpan={7}>
//           <div style={{ display: 'flex' }}>
//           {hoursRowsData?.shippingHours?.map((day) => (
              
//  <React.Fragment key={day?.id}>
//           <TextField variant="outlined" size="small" placeholder="Mon"  sx={{ minWidth: 5 }}/>
//           <TextField variant="outlined" size="small" placeholder="Tue" sx={{ minWidth: 5 }} />
//           <TextField variant="outlined" size="small" placeholder="Wed"   sx={{ minWidth: 5 }}/>
//           <TextField variant="outlined" size="small" placeholder="Thur"  sx={{ minWidth: 20 }}/>
//           <TextField variant="outlined" size="small" placeholder="Fri" sx={{ minWidth: 20 }} />
//           <TextField variant="outlined" size="small" placeholder="Sat"  sx={{ minWidth: 20 }}/>
//           <TextField variant="outlined" size="small" placeholder="Sun" sx={{ minWidth: 20 }} />
//         </React.Fragment>
               
             
//             ))}
//             </div>
            
//            </TableCell>
           
            
//           </TableRow>
//         </TableBody>
//       </Table>
//     </TableContainer>
//   );
// }


import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';

export default function ShipRecHoursTable({ hoursRowsRef}) {
  console.log("hoursRowsRef-----",hoursRowsRef)
  const [shippingHoursData, setShippingHoursData] = useState(hoursRowsRef?.current?.shippingHours || []);
  const [receivingHoursData, setReceivingHoursData] = useState(hoursRowsRef?.current?.receivingHours || []);


 

  const handleShippingHoursChange = ( day, value) => {
    const updatedHours ={...shippingHoursData}
    updatedHours[day] = value;
    setShippingHoursData(updatedHours);
    hoursRowsRef.current={
      ...hoursRowsRef.current,
      shippingHours:updatedHours
    }
  };

  const handleReceivingHoursChange = (day, value) => {
    const updatedHours = {...receivingHoursData}
    updatedHours[day] = value;
    setReceivingHoursData(updatedHours);
    hoursRowsRef.current={
      ...hoursRowsRef.current,
      receivingHours:updatedHours
    }
  };
 


  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: '100%' }}>
        <TableHead sx={{ borderBottom: 'none', padding: '8px' }}>
          <TableRow>
            <TableCell colSpan={7}>Shipping Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <div style={{ display: 'flex' }}>
                {/* {shippingHoursData?.map((day, index) => ( */}
                
                  {/* <React.Fragment key={index}> */}
                  <>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Mon"
                      sx={{ minWidth: 5 }}
                      value={shippingHoursData.day1}
                      onChange={(e) => handleShippingHoursChange('day1', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Tue"
                      sx={{ minWidth: 5 }}
                      value={shippingHoursData.day2}
                      onChange={(e) => handleShippingHoursChange('day2', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Wed"
                      sx={{ minWidth: 5 }}
                      value={shippingHoursData.day3}
                      onChange={(e) => handleShippingHoursChange('day3', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Thur"
                      sx={{ minWidth: 20 }}
                      value={shippingHoursData.day4}
                      onChange={(e) => handleShippingHoursChange('day4', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Fri"
                      sx={{ minWidth: 20 }}
                      value={shippingHoursData.day5}
                      onChange={(e) => handleShippingHoursChange('day5', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Sat"
                      sx={{ minWidth: 20 }}
                      value={shippingHoursData.day6}
                      onChange={(e) => handleShippingHoursChange('day6', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Sun"
                      sx={{ minWidth: 20 }}
                      value={shippingHoursData.day7}
                      onChange={(e) => handleShippingHoursChange('day7', e.target.value)}
                    />
                    </>
                  {/* </React.Fragment> */}
                {/* ))} */}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
        <TableHead sx={{ borderBottom: 'none', padding: '8px' }}>
          <TableRow>
            <TableCell colSpan={7}>Receiving Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
            <div style={{ display: 'flex' }}>
            <>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Mon"
                      sx={{ minWidth: 5 }}
                      value={receivingHoursData?.day1}
                      onChange={(e) => handleReceivingHoursChange('day1', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Tue"
                      sx={{ minWidth: 5 }}
                      value={receivingHoursData?.day2}
                      onChange={(e) => handleReceivingHoursChange('day2', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Wed"
                      sx={{ minWidth: 5 }}
                      value={receivingHoursData?.day3}
                      onChange={(e) => handleReceivingHoursChange('day3', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Thur"
                      sx={{ minWidth: 20 }}
                      value={receivingHoursData?.day4}
                      onChange={(e) => handleReceivingHoursChange('day4', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Fri"
                      sx={{ minWidth: 20 }}
                      value={receivingHoursData?.day5}
                      onChange={(e) => handleReceivingHoursChange('day5', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Sat"
                      sx={{ minWidth: 20 }}
                      value={receivingHoursData?.day6}
                      onChange={(e) => handleReceivingHoursChange('day6', e.target.value)}
                    />
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder="Sun"
                      sx={{ minWidth: 20 }}
                      value={receivingHoursData?.day7}
                      onChange={(e) => handleReceivingHoursChange('day7', e.target.value)}
                    />
                    </>
                    </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { Table, TableBody, TableHead, TableRow, Paper, TableContainer } from "@mui/material";
import TableCell, { TableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import {colors} from '../../../theme/colors'

const columns = [
    { key: 'Stop', label: 'Stop', minWidth: 30, align: 'left', width:'50%' },
    { key: 'Consignee', label: 'Consignee', minWidth: 50, align: 'left' },
    { key: 'Name', label: 'Name', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Address', label: 'Address', minWidth: 50, flex: 1, align: 'left' },
    { key: 'City', label: 'City', minWidth: 50, flex: 1, align: 'left' },
    { key: 'State', label: 'State', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Zip', label: 'Zip', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Phone', label: 'Phone', minWidth: 60, flex: 1, align: 'left' },
    { key: 'Fax', label: 'Fax', minWidth: 60, flex: 1, align: 'left' },
    { key: 'Appointment Date', label: 'Appointment Date', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Appointment Time', label: 'Appointment Time', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Total Pieces', label: 'Total Pieces', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Total Weight', label: 'Total Weight', minWidth: 50, flex: 1, align: 'left' },
    { key: 'Commodity', label: 'Commodity', minWidth: 50, flex: 1, align: 'left' },
    { key: 'DoorSeal', label: 'Door Seal', minWidth: 50, flex: 1, align: 'left' },
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.MuiTableCell-head`]: {
      backgroundColor: colors.blue.dark,
      color: colors.white.normal,
    },
    [`&.MuiTableCell-body`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function StopsTable(props) {
    const {stopsRef} = props

    return (
        <Paper style={{ padding: '1rem', marginTop: '1em' }} variant="outlined">
            <TableContainer>
                <Table>
                    <TableHead>
                        <StyledTableRow>
                            {columns.map(item => (
                                <StyledTableCell
                                    key={item.key}
                                    align={item.align}
                                    style={{ minWidth: item.minWidth, flex: item.flex }}
                                >
                                    {item.label}
                                </StyledTableCell>
                            )
                            )}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {stopsRef.current.map((row, index) => {
                            return (
                                <StyledTableRow key={index}>
                                    <StyledTableCell>
                                        {row.stop}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.consignee}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.address}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.city}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.state}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.zip}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.Phone}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.fax}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.appointmentDate}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.appointmentTime}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.totalPieces}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.totalWeight}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.commodity}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {row.doorSeal}
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}
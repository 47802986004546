export enum loadsTypes {
    SELECT_LOAD = 'SELECT_LOAD',
    SET_LOAD = 'SET_LOAD',
    SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
    SET_LOAD_DETAILS = 'SET_LOAD_DETAILS',
    SET_LOAD_LOADING = 'SET_LOAD_LOADING',
    SET_DRIVERS = 'SET_DRIVERS',
    SET_DRIVER_INFO = 'SET_DRIVER_INFO',
    SET_SEARCH_VALUES = 'SET_SEARCH_VALUES',
    SET_LOCATIONS = 'SET_LOCATIONS',

    GET_TRUCK_DRIVER_INFO = 'GET_TRUCK_DRIVER_INFO',
    DRIVER_TOGGLE="DRIVER_TOGGLE",
    SUBMIT_SEARCH="SUBMIT_SEARCH",
    SCREEN_CHANGE="SCREEN_CHANGE",
    REMOVE_TENDER_TYPE="REMOVE_TENDER_TYPE",
    ORDER_BY_TYPE="ORDER_BY_TYPE"
}
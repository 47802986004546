import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
} from '@material-ui/core';

export default function ConfirmationDialog(props) {
    //alert(props);
    if (!props.isConfirmDialogOpen) {
        return null;
    }

    return (
        <Dialog
            maxWidth="xs"
            open={props.isConfirmDialogOpen}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent dividers>
                <Typography
                    variant="subtitle1"
                    align="center"
                >
                    {props.description}
                </Typography>

            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleNo}>
                    {props.rejectTxt}
                </Button>
                <Button onClick={props.handleYes}>{props.acceptTxt}</Button>
            </DialogActions>
        </Dialog>
    );
}
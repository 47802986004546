import jsPDF from "jspdf";
import "jspdf-autotable";

const PTReportPDF = (rows,processDate,selectedTruck,selectedVendor) => {
    console.log("rows", rows);
    
    const generatePDF = () => {
        if (!rows || rows.length === 0) {
            console.error("No data available to generate PDF.");
            return;
        }

        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            hotfixes: ['pxsplit'] // To prevent split issue in large tables
        });

        // Title Section
        doc.setFontSize(12);
        const title = "Purchase Transportation Report";
        const titleWidth = doc.getTextWidth(title);
        const pageWidth = doc.internal.pageSize.getWidth();
        const titleX = (pageWidth - titleWidth) / 2;

        doc.text(title, titleX, 20);
        doc.text(`Processed Date: ${processDate}`, 14, 30);
        doc.text(`Vendor #${selectedVendor?selectedVendor:"ALL"} | Truck # ${selectedTruck?selectedTruck:"ALL"}`, 14, 40);

        // Prepare table body
        const tableData = rows.map((row) => [
            row.vendor || "",
            row.truck || "",
            row.controlNumber || "",
            row.unit || "",
            row.status || "",
            row.transmitted || "",
            row.description || "",
            row.processedDate || "",
            row.rate || "",
            row.unitValue || "",
            row.amount || "",
            row.pt || "",
        ]);

        // AutoTable configuration for the PDF
        doc.autoTable({
            startY: 50,
            head: [["Vendor #", "Truck #", "Control #", "Unit #", "Status", "Transmitted", "Payable Description", "Processed Date", "Rate", "Unit", "Amount", "PT%"]],
            body: tableData,
            styles: {
                fontSize: 8,
                cellPadding: 2,
                overflow: 'linebreak',
            },
            headStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0], // Header text color (black)
            },
            columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 20 },
                2: { cellWidth: 25 },
                3: { cellWidth: 30 },
                4: { cellWidth: 20 },
                5: { cellWidth: 20 },
                6: { cellWidth: 35 },
                7: { cellWidth: 25 },
                8: { cellWidth: 15 },
                9: { cellWidth: 25 },
                10: { cellWidth: 20 },
                11: { cellWidth: 20 },
            },
            margin: { top: 50 },
            theme: 'grid',
            didParseCell: (data) => {
                // Check if the row corresponds to the data being processed
                const rowIndex = data.row.index;
                if (rows[rowIndex] && rows[rowIndex].isSubtotal) {
                    data.cell.styles.fillColor = [232, 232, 232]; // Grey background
                }
            },
        });

        // Save the PDF
        doc.save("pt-purchase-transportation-report.pdf");
    };

    return generatePDF;
};

export default PTReportPDF;
import { locationTypes } from "../types/locationTypes";

const initialState = {

    allLocationData: [],
    // addNewLocationUser: {},
    reloadLoadPaginationData: false,
    addResponseMsg: {},
    deletedLocation: '',
    fetchLocationNumbers: []
}

export const locationReducer = (state = initialState, { type, payload }) => {
    switch (type) {

        case locationTypes.GET_LOCATION_DATA: {
            let { allLocationData } = state;
            localStorage.setItem('getAllLocationData', JSON.stringify(payload.data))
            if (allLocationData.length > 0) {

                allLocationData = []
            }

            return {
                ...state,
                allLocationData: payload
            }
        }
        case locationTypes.ADD_NEW_LOCATION: {

            return {
                ...state,
                addResponseMsg: { message: payload.message, states: payload.states }
            }
        }

        case locationTypes.SEARCH_EMAIL_VALUE: {

            return {
                ...state,
                searchdata: { search: payload.data[0].isSearchRecord }
            }
                 }

        case locationTypes.DELETE_LOCATION_NUMBER: {

            return {
                ...state,
                deletedLocation: payload.message
            }
        }
        case locationTypes.FETCH_LOCATION_DATA: {
            localStorage.setItem('fetLocationNumber', JSON.stringify(payload))
            return {
                ...state,
                fetchLocationNumbers: payload
            }
        }
        default:
            return state;

    }
}
import { Grid } from "@material-ui/core";

import { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';

import TemplateContainer from "./TemplateContainer";
import { OrdersWrapper } from "../../components/styledComponents";
import OrderContainer from "./OrderContainer";
import { useSelector, useDispatch } from "react-redux";
import { getTemplateData } from "../../store/actions/templateAction";
import { maptoTemplateTable } from "./components/commonFn";
import useUnsavedChangesWarning from '../../components/Alerts/useUnsavedChangesWarning';
import { searchTemplate } from "../../store/actions/templateAction";

export default function Templates() {
    const [selected, setSelected] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState([]);
    const [rowData, setRowdata] = useState([]);
    const [templatenameobj, setTemplateObj] = useState();
    const[rowsCount,setRowsCount]=useState(1)
    const dispatch = useDispatch();
    const [orderType, setOrderType] = useState('INBOUND');
    const [editTemplate, setEditTemplate] = useState(false);
    const [addNewTemplate, setAddNewTemplate] = useState(false);
    const [reqQuery, setReqQuery] = useState({
        pageData: { page: 1, pageSize: 50 },
        sortData: { field: "name", sort: "asc" },
        searchText: "",
      });
        const [templateSearchError, setTemplateSearchError] = useState('')
    

    const condition = editTemplate || addNewTemplate;

    useUnsavedChangesWarning(condition);
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    useEffect(() => {

    }, [selected])

    useEffect(() => {
        setIsLoading(true);
        setSelectedRow('');
        setSelected('');
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            templateTableApiCall();
        }
    }, [ordersLocation])


    
    const templateTableApiCall =(
            pageData,
            sortData 
          ) => {
            // History Unassigned Pending
            setIsLoading(true);
            setReqQuery(prevState => ({
              ...prevState,
              ...(pageData && { pageData }),
              ...(sortData && { sortData }),
            //   ...(searchText !== undefined && { searchText })
            }));
        dispatch(getTemplateData({ 
            locationNumber: ordersLocation?.locationNumber, 
            query: pageData?pageData:reqQuery.pageData,
            sortData:sortData?sortData:reqQuery.sortData,
            // search:searchText !== undefined ?searchText:reqQuery.searchText,
        })).then((res) => {
           
            if (res?.data?.hits?.length > 0) {
                setRowdata(res)
                let data = maptoTemplateTable(res?.data?.hits);
                setRows(data)
            setRowsCount(res?.data?.total?.value)

            } else {
                setRowdata(res)
                let data = maptoTemplateTable(res);
                setRows(data)
                setRowsCount(res?.data?.total?.value)
            }
            setIsLoading(false);
        });
    }
    function correctSpaceFormat(value) {
        // Replace any non-standard whitespace characters with regular spaces
        return value.replace(/\s+/g, ' ');
    }
    const handleSearch = (searchText,pageData,sortData) => {
        setReqQuery(prevState => ({
            ...prevState,
            ...(pageData && { pageData }),
            ...(sortData && { sortData }),
            ...(searchText !== undefined && { searchText })
          }));
         

        if (!(searchText==="")) {
            let searchName=correctSpaceFormat(searchText.toString()).trim()
            let postParams = [
                {
                    clause: "AND",
                    field: "locationNumber",
                    operand: "IS",
                    value: ordersLocation?.locationNumber
                },
                {
                    clause: "OR",
                    field: "templateName",
                    operand: "IS",
                    value: `${encodeURIComponent(searchName)}*`
                },
                {
                    clause: "OR",
                    field: "shipperAgent",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "stops.code",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "stops.name",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "orderType",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "poruPoint",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "stops.state",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "stops.city",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "disposition",
                    operand: "IS",
                    value: `${searchName}*`
                },
                {
                    clause: "OR",
                    field: "shipperOrConsShipper",
                    operand: "IS",
                    value: `${searchName}*`
                }

            ]

            dispatch(searchTemplate( {
                locationNumber: ordersLocation?.locationNumber, 
                query: pageData?pageData:reqQuery.pageData,
                sortData:sortData?sortData:reqQuery.sortData,
                search:searchText !== undefined ?searchText:reqQuery.searchText,
                postParams})).then((response) => {
                setRowsCount(response?.data?.total?.value)
                const res= response.data.hits

                if (res.length > 0) {
                    setTemplateSearchError("")
                    let data = maptoTemplateTable(res);
                    setRows(data)
                    setRowdata(response)
                } else {
                    setTemplateSearchError("No Data Found")
                }
            });

        } else {
            if (typeof ordersLocation?.locationNumber !== 'undefined') {
                setTemplateSearchError("")
                templateTableApiCall();
            }
        }


    }
    return (
        <OrdersWrapper>
            <Grid container  >
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <div style={{ height: `calc(100vh - 90px)`, width: '100%', padding: '10px' }}>
                        <TemplateContainer
                            setSelected={setSelected}
                            selected={selected}
                            setSelectedRow={setSelectedRow}
                            setTemplateObj={setTemplateObj}
                            setRows={setRows}
                            rows={rows}
                            setOrderType={setOrderType}
                            setRowdata={setRowdata}
                            rowData={rowData}
                            templateTableApiCall={templateTableApiCall}
                            isLoading={isLoading} 
                            rowsCount={rowsCount}
                            setRowsCount={setRowsCount}
                            setEditTemplate={setEditTemplate}
                            setAddNewTemplate={setAddNewTemplate}
                            addNewTemplate={addNewTemplate}
                            editTemplate={editTemplate}
                            templateSearchError={templateSearchError}
                            handleSearchReq={handleSearch}

                            />
                            
                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                    <div style={{ height: `calc(100vh - 90px)`, width: '100%', padding: selected === undefined || selected === '' ? '0px' : '10px' }}>
                        {
                            selected === undefined || selected === '' ?
                                <div style={{ width: '100%', height: `calc(100vh - 90px)`, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                    <h3 style={{ fontSize: '22px', color: '#707070' }}> Open any Template</h3>
                                    <h3 style={{ fontSize: '22px', color: '#707070' }}>or</h3>
                                    <h3 style={{ fontSize: '22px', color: '#707070' }}>Create new Template</h3>
                                </div>
                                :
                                (<div key={selected}>
                                <OrderContainer
                                    selected={selected}
                                    setSelected={setSelected}
                                    selectedRow={selectedRow}
                                    templatenameobj={templatenameobj}
                                    setRows={setRows}
                                    setOrderType={setOrderType}
                                    orderType={orderType}
                                    templateTableApiCall={templateTableApiCall}
                                   editTemplate={editTemplate}
                                   setAddNewTemplate={setAddNewTemplate}
                                   setEditTemplate ={setEditTemplate} 
                                   searchText={reqQuery.searchText}
                                   handleSearch={handleSearch}
                                />
                                </div>)
                        }

                    </div>
                </Grid>
            </Grid>

        </OrdersWrapper>
    )

}
import React from 'react'
import { TextField } from '@material-ui/core';

const CompanyInfo = ({ truckDriverInfo, overAllStatus}) => {

     const fontColor = {
        style: { color: 'rgb(50, 50, 50)' }
    }

    return (
        <div>
            <h2 className="company-info-heading">Truck/Driver Information </h2>
            <div className="driver-form-container">
                <div className="driver-input-container">
                    <label>Company</label>
                    <TextField
                        disabled={true}
                        data-test="companyinfofields"
                        inputProps={fontColor}
                        value={truckDriverInfo?.company}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                    />
                </div>
                <div className="driver-input-container">
                    <label>Location</label>
                    <TextField
                        disabled={true}
                        data-test="companyinfofields"
                        inputProps={fontColor}
                        value={truckDriverInfo?.location}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                    />
                </div>
                <div className="driver-input-container">
                    <label>Truck</label>
                    <TextField
                        disabled={true}
                        data-test="companyinfofields"
                        value={truckDriverInfo?.equipmentMasterSegNo}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        inputProps={fontColor}
                    />
                </div>
                <div className="driver-input-container">
                    <label>Overall Availability</label>
                    <TextField
                        inputProps={fontColor}
                        data-test="companyinfofields"
                        disabled={true}
                        value={overAllStatus}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                    />
                </div>

            </div>
            <div className="overall-avail-status">
                {overAllStatus == 'YES'?<h3>Overall Availability: This Truck is <span style={{color:"green"}}>Available</span>.</h3>:<h3>Overall Availability: This Truck is <span style={{color:"red"}}>Not Available</span>.</h3>}
            </div>
        </div>
    )
}

export default CompanyInfo

import React, { useCallback, useState } from 'react'
import DriversList from '../../../scenes/TenderList/LoadsSearch/DriversList';
import { ModalOverlay, TenderModalWrapper, H2, SquareButton, Button, CancelButton } from '../../styledComponents'
import { useDispatch } from 'react-redux';
import { tenderStops } from '../../../store/actions/loadsActions';

// const TenderRadio = <Radio classes={{ root: { "&$checked": { color: colors.blue.noram } } }} />

export default function TenderModal({ closeModal, selectedStops, controlNumber }) {
    const [drop, setDrop] = useState('staywith');
    const dispatch = useDispatch();
    const [driverId, setDriverId] = useState();
    const setStayOrDrop = useCallback((isDrop) => {
        setDrop(isDrop);
    }, [setDrop]);
    return (
        <ModalOverlay onClick={() => closeModal()}>
            <TenderModalWrapper onClick={(e) => e.stopPropagation()}>
                <div style={{ alignItems: 'center', flexDirection: 'column', marginBottom: '20px' }}>
                    <div>
                        <H2 style={{ color: 'black' }}>Driver Selection</H2>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                            <SquareButton active={drop==='staywith'} onClick={() => setStayOrDrop('staywith')} style={{ minWidth: '80px' }}>Stay With</SquareButton>
                            <SquareButton active={drop==='drop'} onClick={() => setStayOrDrop('drop')} style={{ minWidth: '80px' }}>Drop</SquareButton>
                            <SquareButton active={drop==='pull'} onClick={() => setStayOrDrop('pull')} style={{ minWidth: '80px' }}>Pull</SquareButton>
                        </div>
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <DriversList onUpdate={(driverId) => setDriverId(driverId)} />
                        </div>
                    </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CancelButton style={{ marginRight: '5px' }} onClick={() => closeModal()}>Cancel</CancelButton>
                    <Button active onClick={() => {
                        if (driverId) {
                            dispatch(tenderStops(selectedStops, driverId, drop, controlNumber))
                        }
                        closeModal();
                    }
                    }>OK</Button>
                </div>
            </TenderModalWrapper>
        </ModalOverlay>
    )
}

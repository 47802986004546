import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, MenuItem } from "@mui/material";

import { Paper, TableContainer, Box, TextField, Button } from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  GridRowModes,
  GridRowEditStopReasons,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";

import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { getTemplateData } from "../../../../../store/actions/templateAction";
import { maptoTemplateTable } from "../commonFn";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import {
  getDefaultShipperContact,
  mapShipperContactToServer,
} from "../commonFn";
import AddIcon from "@mui/icons-material/Add";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";

// import { PhoneNumberMask } from '../../../components/TextFieldFormat';
import { PhoneNumberMask } from '../../../../../components/TextFieldFormat';

import {  deleteOrderByContact}  from "../../../../../store/actions/maintenanceOrderByActions";

export default function ContactTable({ contactRowsRef, onContactData }) {
  const [editRowId, setEditRowId] = useState(null);

  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const dispatch = useDispatch();
  const [checkboxSelected, setCheckboxSelected] = useState(null);


  useEffect(() => {
    setRows(contactRowsRef?.current);
    const selectedRow = contactRowsRef.current.find((item) => item.primary === "Y");
  setCheckboxSelected(selectedRow || null);
  
  }, [contactRowsRef?.current]);

  // const handleEditRow = (id) => {
  //   setEditRowId(id);
  // };
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    console.log(rows.filter((row) => row.id === id))
    const deletedRow =rows.filter((row) => row.id === id)
   
   
    if( deletedRow[0].seq!== -1 ){
 dispatch(deleteOrderByContact(deletedRow[0].seq )).then((res) => {
      if (res?.httpCode === 200 && res?.message === 'Records has been deleted successfully!') {
        const updateRows=rows.filter((row) => row.id !== id)
        console.log("updated rows",updateRows)
        setRows(updateRows);
        contactRowsRef.current = updateRows
      } else {
       console.log("error")
       setRows(rows.filter((row) => row.id !== id));
      }
    });
   }else{

    setRows(rows.filter((row) => row.id !== id));
   }


  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    console.log("processRowUpdate", updatedRow);
    const updatedRows = rows.map((row) =>
      row.id === newRow.id ? updatedRow : row
    );
    setRows(updatedRows);
    contactRowsRef.current = updatedRows;

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
    console.log("newRowModesModel", newRowModesModel);
  };

  const handleEditCellChange = React.useCallback(
    ({ id, field, value }) => {
      const editedRowIndex = rows.findIndex((row) => row.id === id);
      if (editedRowIndex !== -1) {
        const updatedRow = { ...rows[editedRowIndex], [field]: value };
        console.log("updatedRow", updatedRow);
        setRows((prevRows) => {
          const newRows = [...prevRows];
          newRows[editedRowIndex] = updatedRow;
          return newRows;
        });
      }
    },
    [rows]
  );

  const handleClick = () => {
    const newRecord = getDefaultShipperContact(); // Assuming getDefaultShipperContact generates a new record
    const newRowId = Date.now(); // Generate a random UUID
    // const newRow = { ...newRecord, id: newRowId };
    // setRows((oldRows) => [...oldRows, newRow])
    setRows((oldRows) => [...oldRows, { ...newRecord, id: newRowId }]); // Add new row with unique ID
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [newRowId]: { mode: GridRowModes.Edit, fieldToFocus: "contact_name" }, // Set edit mode for the new row
    }));
    console.log("New record:", newRecord);
    // setEditRowId(newRowId);
  };
 
  
  
  const tableColumns = [
    
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: (params) => {
        const id =params?.id
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<DoneIcon />}
              label="Save"
              sx={{ color: "primary.main" }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            // onClick={(params?.row?.isDisabled && params?.row?.isDisabled===true)? null : handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon  style={{ color: params?.row?.isDisabled === true ? 'lightgrey' : 'inherit' }}/>}
            label="Delete"
            // onClick={handleDeleteClick(id)}
            onClick={(params?.row?.isDisabled && params?.row?.isDisabled===true)?null : handleDeleteClick(id)}
           
            color="inherit"
          />,
        ];
      },
    },

  
    {
      field: "contact_name",
      headerName: "Contact Name",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
      //   editable: true,
      editable: (params) => params.id === editRowId,
    },
    {
      field: "title",
      headerName: "Title",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
      //   editable: true,
      editable: (params) => params.id === editRowId,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
      //   editable: true,
      editable: (params) => params.id === editRowId,
      renderEditCell: (params) => (
        // console.log("params-phone",params)
        <PhoneNumberMask
    label=""
    size="small"
    className="width_100"
    value={params.row.phone_number || ""}
    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
    onChange={(event) => {

      params.api.setEditCellValue({
       id:params.id,
        field:params.field,
        value:event.target.value
      })  
    }}
    hasFocus={params.hasFocus}
  />
      )
    },
    {
      field: "secondary_phone_number",
      headerName: "Secondary Phone",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
      //   editable: true,
      editable: (params) => params.id === editRowId,
      renderEditCell: (params) => (
        // console.log("params-phone",params)
        <PhoneNumberMask
    label=""
    size="small"
    className="width_100"
    value={params.row.secondary_phone_number || ""}
    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
    onChange={(event) => {

      params.api.setEditCellValue({
       id:params.id,
        field:params.field,
        value:event.target.value
      })  
    }}
    hasFocus={params.hasFocus}
  />
      )
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "MuiDataGrid-columnHeaders",
      flex: 1,
      minWidth: 100,
      //   editable: true,
      // editable: (params) => params.id === editRowId,
      editable: (params) => params.id === editRowId && params.id !== -1,
    },
  ];

  const CircleButton = ({ value, isEditing }) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            border: "1px solid black",
            backgroundColor: value === "Y" ? "rgb(11, 67, 135)" : "transparent",
          }}
        />
      </div>
    );
  };


  return (
    <>
      <TableContainer style={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            overflowX: "auto",
            // height: '100%',
            "& .actions": {
              color: "text.secondary",
            },
            "& .textPrimary": {
              color: "text.primary",
            },
            "& .tableTH": {
              fontWeight: 700,
            },
            "& .MuiDataGrid-columnHeaders": {
              // backgroundColor: "rgba(9, 29, 163, 1)",
              backgroundColor: "rgb(11, 67, 135)",
              color: "rgba(255, 255, 255, 1)",
              fontSize: "12px",
            },
            "& .MuiDataGrid-sortIcon": {
              opacity: 1,
              color: "rgba(255, 255, 255, 1)",
            },
            "& .MuiDataGrid-menuIconButton": {
              opacity: 1,
              color: "rgba(255, 255, 255, 1)",
            },
            "& .super-app-theme--cell": {
              backgroundColor: "white",
              fontSize: "11px",
            },
            "& .disabled-row": {
              backgroundColor: "#D3D3D3",
            },
          }}        >
          <DataGridPro
            rows={rows}
            columns={tableColumns}
            columnHeaderHeight={30}
            rowHeight={30}
            editMode="row"
            // isCellEditable={(params) => params?.row?.isDisabled !== true}
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onEditCellChange={handleEditCellChange}
           
          />

        

          {!editRowId ? (
            <Button
              //   variant="outlined"
              size="small"
              style={{
                color: "black",
                textDecoration: "underline",
                display: "flex",
                alignItems: "center",
                fontSize: "0.70rem",
              }}
              onClick={handleClick}
              endIcon={
                <AddCircleOutlineIcon
                  style={{ width: "18px", height: "18px", margin: 5 }}
                />
              }
            >
              Add New Row
            </Button>
          ) : null}
        </Box>
      </TableContainer>
    </>
  );
}

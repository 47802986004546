import moment from "moment";
import TenderButton from './components/TenderButton';

export const todaysDate = moment().format('1YYMMDD');
export const apptDateGTE180d = moment().subtract(180, 'days').format('1YYMMDD');

export const loadsTableTabs = () => {
    let days = [];
    let daysRequired = 8

    for (let i = 0; i <= daysRequired; i++) {
        let dayName = moment().add(i, 'days').format('dddd');
        let obj = { id: i, dayDate: moment().add(i, 'days').format('1YYMMDD'), uniqKey: dayName.toLowerCase() }
        if (i === 0) {
            obj = { ...obj, label: 'Past/Today', uniqKey: (dayName === 'Saturday') ? 'satsun' : 'pasttoday' }
        } else if (i === 7) {
            obj = { ...obj, label: 'Future Loads', uniqKey: 'futureload' }
        } else if (i === 8) {
            obj = { ...obj, label: 'Unappointed', uniqKey: 'unappointed' }
        } else if (dayName === 'Saturday') {
            obj = { ...obj, label: 'Sat/Sun', uniqKey: 'satsun' }
        } else if (dayName === 'Sunday') {
            continue;
        } else {
            obj = { ...obj, label: dayName }
        }
        days.push(obj)
    }
    return days;
}

const commonTableColumn = [
    { field: 'io', headerName: 'I/O', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spec', headerName: 'Spec', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'unit', headerName: 'Unit', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'chassisNumber', headerName: 'Chassis', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'ssl', headerName: 'SSL/Eqp', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'size', headerName: 'Size', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'puloc', headerName: 'PU Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'rtnloc', headerName: 'RTN Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'shipConsName', headerName: 'Shipper/Cgnee', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'cityState', headerName: 'City,ST', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
]

const apptDatetimeComparator = (v1, v2, params1, params2) => {
    return new Date(v1) - new Date(v2)
}

export const loadsTableColumns = [
    { field: 'stop', headerName: 'Stop', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell' },
    { field: 'control', headerName: 'Control', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true, renderCell: (params) => (<TenderButton {...params} type='loads' btnLabel={`T`} />) },
    { field: 'io', headerName: 'I/O', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'puloc', headerName: 'PU Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'unit', headerName: 'Unit', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'chassisNumber', headerName: 'Chassis', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    {
        field: 'apptDateTime',
        headerName: 'Appt Date/Time',
        headerClassName: 'MuiDataGrid-columnHeaders',
        cellClassName: 'super-app-theme--cell',
        disableColumnMenu: true,
        renderCell: (params) => params.row.apptDateTime,
        valueGetter: (params) => params.row.apptDateForSort,
        sortComparator: apptDatetimeComparator,
        minWidth: 110
    },
    { field: 'shipConsName', headerName: 'Shipper/Cgnee', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'cityState', headerName: 'City,ST', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'rtnloc', headerName: 'RTN Loc', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'etaDateTime', headerName: 'ETA', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'lfdcut', headerName: 'LFD/CUT', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'release', headerName: 'Release/Bkg#', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spec', headerName: 'Spec', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'ssl', headerName: 'SSL/Eqp', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'size', headerName: 'Size', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true }
]

export const spottedTableColumns = [
    { field: 'stop', headerName: 'Stop', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell' },
    { field: 'control', headerName: 'Control', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true, renderCell: (params) => (<TenderButton {...params} type='spotted' btnLabel={`T`} />) },
    ...commonTableColumn,
    { field: 'outageDateTime', headerName: 'Outage Date/Time', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'spotDateTime', headerName: 'Spotted Date/Time', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'availDateTime', headerName: 'Available Date/Time', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true },
    { field: 'lastFreeDay', headerName: 'Last Diem Day', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true }
]

export const subItemTableColumns = [
    { field: 'stop', headerName: 'Stop', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell' },
    {
        field: 'control', headerName: 'Control', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', disableColumnMenu: true, minWidth: 91,
        renderCell: (params) => (<TenderButton {...params} type='driver' btnLabel={`T`} />)
    },
    ...commonTableColumn,
    { field: 'apptDateTime', headerName: 'Appt Date/Time', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', editable: false, disableColumnMenu: true, minWidth: 110 },
    { field: 'tenderType', headerName: 'Type', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', editable: false, disableColumnMenu: true },
    { field: 'lastEvent', headerName: 'Last Event', headerClassName: 'MuiDataGrid-columnHeaders', cellClassName: 'super-app-theme--cell', editable: false, disableColumnMenu: true },
]

export function validateHhMm(inputField) {
    var isValid = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(inputField);
    if (isValid) {
        return true;
    } else {
        return false;
    }
}

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const setRowOrderByCookie = (truck, arr) => {
    let reOrder = arr.map((v, i) => ({ ...v, displayPosition: i }))
    localStorage.setItem(truck, JSON.stringify(reOrder));
}

export const updateRowOrderByCookie = (truck, arr) => {
    localStorage.setItem(truck, JSON.stringify(arr));
}

export const getRowOrderFromCookie = (truck) => {
    const arrFromCookie = JSON.parse(localStorage.getItem(truck));
    return arrFromCookie?.length > 0 ? arrFromCookie : [];
}


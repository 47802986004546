import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useRef } from "react";
import { getStopsData, setReloadTableState, getTrucksData, stopSearchWSReq, setStops } from "../../store/actions/dispatchActions";

import './DispatchStyle.css'

import LoadsTable from './LoadsTable';
import SpottedTable from './SpottedTable';
import DriverTable from './DriverTable';
import { DispatchWrapper } from "../../components/styledComponents";
import { apptDateGTE180d } from './commonFn';
import {
    Panel,
    PanelGroup
} from "react-resizable-panels";

import ResizableHandle from "../../components/ResizableHandle/ResizableHandle";
import { useHistory } from 'react-router';
import WebSocketService from '../../utils/WebSocketService';
import _ from 'loadsh'

export default function Dispatch() {
    const history = useHistory();

    const [isEdrayOrders, setIsEdrayOrders] = useState(false);
    const [trucksData, setTrucksData] = useState([]);
    // const [stopsData, setStopsData] = useState([]);
    const [loadsData, setLoadsData] = useState([]);
    const [isLoadsLoading, setIsLoadsLoading] = useState(false);
    const [spottedData, setSpottedData] = useState([]);
    const [isSpottedLoading, setIsSpottedLoading] = useState(false);
    const [subItemLoadsData, setSubItemLoadsData] = useState([]);
    const [isSubItemsLoading, setIsSubItemsLoading] = useState(false);
    // const [isLoading, setIsLoading] = useState(false);
    const [isTrucksLoading, setIsTrucksLoading] = useState(false);
    const [isAvailableState, setIsAvailableState] = useState('all');
    const [isRowOrderEnabled, setIsRowOrderEnabled] = useState(false);
    const [isRefreshBtnClicked, setIsRefreshBtnClicked] = useState(false);
    const loadsRef = useRef(loadsData);
    const spottedRef = useRef(spottedData);
    const subItemLoadsRef = useRef(subItemLoadsData);

    const dispatch = useDispatch();
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    const reloadTablesOnTender = useSelector(
        ({ dispatchReducer }) => dispatchReducer.reloadTablesOnTender
    );

    useEffect(() => {
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            setIsLoadsLoading(true);
            setIsSpottedLoading(true);
            setIsSubItemsLoading(true);
            fetchTrucksData();

            /**
             * Date: 04-May-2024
             * Socket Integration
             */
            const wsService = new WebSocketService();
            wsService.connect();

            // Sending request payload to server via socket
            const requestDataViaSocket = () => {
                
                wsService.sendMessage('dispatchLoads', { body: searchCriteria('loads'), page: 1, pageSize: 10000, sortBy: 'desc', sortOrder: 'controlNumber' });

                // Spotted API Call
                wsService.sendMessage('dispatchSpotted', { body: searchCriteria('spotted'), page: 1, pageSize: 10000, sortBy: 'desc', sortOrder: 'controlNumber' });

                // SubItems API Call
                wsService.sendMessage('dispatchSubItem', { body: searchCriteria('subItems'), page: 1, pageSize: 10000, sortBy: 'desc', sortOrder: 'controlNumber' });
            };

            // Simulate data update every 3 seconds
            const interval = setTimeout(requestDataViaSocket, 3000);

            // Read message from server 
            wsService.onMessage((data) => {
                // console.log('message received:', data)
                setIsLoadsLoading(false);
                setIsSpottedLoading(false);
                setIsSubItemsLoading(false);
                // Handle data according to event type
                let wsData = stopSearchWSReq(data.payload);
                dispatch(setStops(wsData))
                switch (data.event) {
                    case 'dispatchLoads':
                        if (!isArrayEqual(loadsRef.current, wsData) || loadsRef.current.length === 0) {
                            loadsRef.current = wsData;
                            setLoadsData(wsData);
                        }

                        break;
                    case 'dispatchSpotted':
                        if (!isArrayEqual(spottedRef.current, wsData) || spottedRef.current.length === 0) {
                            spottedRef.current = wsData;
                            setSpottedData(wsData);
                        }
                        break;
                    case 'dispatchSubItem':
                        if (!isArrayEqual(subItemLoadsRef.current, wsData) || subItemLoadsRef.current.length === 0) {
                            subItemLoadsRef.current = wsData;
                            setSubItemLoadsData(wsData);
                        }
                        break;
                    default:
                        break;
                }
            });

            return () => {
                wsService.disconnect();
            }
        }
    }, [ordersLocation, isEdrayOrders])

    // const isArrayEqual = (x, y) => {
    //     return _(x).differenceWith(y, _.isEqual).isEmpty();
    // }
    const isArrayEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (!_.isEqual(arr1[i], arr2[i])) {
                return false;
            }
        }
        return true;
    };
    /**
     * Commented on 04-June-2024
     * @param {*} timer 
     */
    // Reload component on tender changes
    // useEffect(() => {
    //     if (reloadTablesOnTender) {
    //         setIsLoadsLoading(true);
    //         setIsSpottedLoading(true);
    //         setIsSubItemsLoading(true);
    //         setIsRefreshBtnClicked(true);
    //         setTimeout(() => {
    //             fetchDataFromServer(0)
    //         }, 6000)
    //     }
    // }, [reloadTablesOnTender])

    const fetchDataFromServer = (timer) => {
        if (typeof ordersLocation?.locationNumber !== 'undefined') {

            //setIsLoading(true);
            setIsLoadsLoading(true);
            setIsSpottedLoading(true);
            setIsSubItemsLoading(true);
            setIsRefreshBtnClicked(true);
            
            // Loads API Call
            dispatch(getStopsData(searchCriteria('loads'), ordersLocation.locationNumber, 'loads')).then((res) => {
                setLoadsData(res);
                setIsLoadsLoading(false);
                dispatch(setReloadTableState(false));
                setIsRefreshBtnClicked(false);
            });

            // Spotted API Call
            dispatch(getStopsData(searchCriteria('spotted'), ordersLocation.locationNumber, 'spotted')).then((res) => {
                setSpottedData(res);
                setIsSpottedLoading(false);
                dispatch(setReloadTableState(false));
            });

            dispatch(getStopsData(searchCriteria('subItems'), ordersLocation.locationNumber, 'driver')).then((res) => {
                setSubItemLoadsData(res);
                setIsSubItemsLoading(false);
                dispatch(setReloadTableState(false));
            });
        }
    }

    const fetchTrucksData = () => {
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            setIsTrucksLoading(true)
            dispatch(getTrucksData(ordersLocation.locationNumber)).then((res) => {
                setIsTrucksLoading(false);
                if (res?.length > 0) {
                    setTrucksData(res)
                } else {
                    setTrucksData([])
                }
            })
        }
    }

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.location.reload();
        });
        return () => {
            unlisten();
        };
    }, [history]);

    const refreshData = () => {
        if (typeof ordersLocation?.locationNumber !== 'undefined') {
            fetchDataFromServer(0)
            fetchTrucksData();
        }
    }

    const searchCriteria = (type) => {

        let finalSearchCriteria;
        let commonSearchCriteria = [
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation.locationNumber
            },
            {
                "clause": "AND",
                "field": "returnDate",
                "operand": "IS",
                "value": 0
            },
            // {
            //     "clause": "AND",
            //     "field": "stops.departTruckStatus",
            //     "operand": "IS NOT",
            //     "value": "C"
            // },
            {
                "clause": "AND",
                "field": "status",
                "operand": "IS NOT",
                "value": 'V'
            },
            {
                "clause": "AND",
                "field": "status",
                "operand": "IS NOT",
                "value": 'B'
            },
            {
                "clause": "AND",
                "field": "status",
                "operand": "IS NOT",
                "value": 'M'
            }
        ]

        switch (type) {
            case 'loads':
                finalSearchCriteria = [
                    ...commonSearchCriteria,
                    {
                        "clause": "AND",
                        "field": "status",
                        "operand": "IS NOT",
                        "value": 'C'
                    },
                    {
                        "clause": "AND",
                        "field": "stops.arriveDriver",
                        "operand": "IS",
                        "value": 0
                    },
                    // {
                    //     "clause": "AND",
                    //     "field": "stops.departDriver",
                    //     "operand": "IS",
                    //     "value": 0
                    // },
                    {
                        "clause": "AND",
                        "field": "stops.arriveTruck",
                        "operand": "IS",
                        "value": 0
                    },
                    {
                        "clause": "AND",
                        "field": "stops.apptDate",
                        "operand": "GTE",
                        "value": 0
                    },
                    // {
                    //     "clause": "AND",
                    //     "field": "stops.departTruckStatus",
                    //     "operand": "IS NOT",
                    //     "value": 'C'
                    // }
                    // {
                    //     "clause": "AND",
                    //     "field": "stops.departTruck",
                    //     "operand": "IS",
                    //     "value": 0
                    // }
                ]
                break;
            case 'spotted':
                finalSearchCriteria = [
                    ...commonSearchCriteria,
                    {
                        "clause": "AND",
                        "field": "status",
                        "operand": "IS NOT",
                        "value": 'C'
                    },
                    {
                        "clause": "AND",
                        "field": "stops.apptDate",
                        "operand": "GTE",
                        "value": 1 //Number(apptDateGTE180d)
                    },
                    {
                        "clause": "AND",
                        "field": "stops.arriveDriver",
                        "operand": "GTE",
                        "value": 1
                    },
                    {
                        "clause": "AND",
                        "field": "stops.arriveTruck",
                        "operand": "GTE",
                        "value": 1
                    },
                    {
                        "clause": "AND",
                        "field": "stops.spotDate",
                        "operand": "GTE",
                        "value": 1
                    },
                    {
                        "clause": "AND",
                        "field": "stops.departDriver",
                        "operand": "IS",
                        "value": 0
                    },
                    {
                        "clause": "AND",
                        "field": "stops.departTruck",
                        "operand": "IS",
                        "value": 0
                    },
                    // {
                    //     "clause": "AND",
                    //     "field": "stops.departTruckStatus",
                    //     "operand": "IS NOT",
                    //     "value": 'C'
                    // }
                ]
                break;
            case 'subItems':
                finalSearchCriteria = [
                    ...commonSearchCriteria,
                    {
                        "clause": "AND",
                        "field": "stops.apptDate",
                        "operand": "GTE",
                        "value": 1 //Number(apptDateGTE180d)
                    },
                    {
                        "clause": "AND",
                        "field": "stops.arriveDriver",
                        "operand": "GTE",
                        "value": 1
                    },
                    {
                        "clause": "AND",
                        "field": "stops.arriveTruck",
                        "operand": "GTE",
                        "value": 1
                    }
                ]
                break;
            default:
                finalSearchCriteria = commonSearchCriteria;
                break;
        }
        return finalSearchCriteria
    }

    return (
        <DispatchWrapper>
            <div className={`BottomRow`}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <div style={{
                        height: '25px',
                        marginLeft: '10px',
                        marginTop: '10px',
                        width: '50%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <ToggleButtonGroup
                            color="primary"
                            value={isAvailableState}
                            exclusive
                            onChange={(e, val) => setIsAvailableState(val)}
                            aria-label="Platform"
                            sx={{
                                height: '25px'
                            }}
                        >
                            <ToggleButton value="all">All</ToggleButton>
                            <ToggleButton value="Y">Available</ToggleButton>
                            <ToggleButton value="N">Not Available</ToggleButton>
                        </ToggleButtonGroup>
                        {/* <FormControlLabel control={<Switch checked={isRowOrderEnabled} onChange={e => setIsRowOrderEnabled(e.target.checked)} />} label={`Row Ordering`} /> */}
                    </div>
                    <div style={{
                        //float: 'right',
                        height: '25px'
                    }}>
                        {/* <FormControlLabel control={<Switch checked={isEdrayOrders} onChange={e => setIsEdrayOrders(e.target.checked)} />} label={`eDray orders`} /> */}
                        <IconButton aria-label="refresh" title='Reload Data' onClick={() => refreshData()} disabled={isRefreshBtnClicked}>
                            <RefreshIcon />
                        </IconButton>
                    </div>
                </div>
                <PanelGroup direction="horizontal">
                    <Panel className={`Panel`}>
                        <div className={`PanelContent`}>
                            <DriverTable
                                trucksData={trucksData}
                                stopsDataParent={subItemLoadsData}
                                isLoading={isSubItemsLoading}
                                isTrucksLoading={isTrucksLoading}
                                isAvailableState={isAvailableState}
                                isRowOrderEnabled={isRowOrderEnabled}
                            />
                        </div>
                    </Panel>
                    <ResizableHandle direction="horizontal" />
                    <Panel>
                        <PanelGroup direction="vertical">
                            <Panel className={`Panel`}>
                                <div className={`PanelContent`}>
                                    <LoadsTable
                                        stopsDataParent={loadsData}
                                        isLoading={isLoadsLoading}
                                    />
                                </div>
                            </Panel>
                            <ResizableHandle direction="vertical" />
                            <Panel className={`Panel`}>
                                <div className={`PanelContent`}>
                                    <SpottedTable
                                        stopsDataParent={spottedData}
                                        isLoading={isSpottedLoading}
                                    />
                                </div>
                            </Panel>
                        </PanelGroup>
                    </Panel>
                </PanelGroup>
            </div>
        </DispatchWrapper>
    )
}
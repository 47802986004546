import React, { useState } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { capitalize } from './../../../utils/text';
import { setDriverInfo } from '../../../store/actions/loadsActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
    width: {
        // maxWidth: '170px'
    },
    input: {
        fontSize: '13px',
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
    },
})

export default function DriversList({ onUpdate, clearSelected, disable = false }) {
    const driverList = useSelector(({ loadsReducer }) => loadsReducer.driverList);
    const classes = useStyles();
    const [selectedDriver, setSelectedDriver] = useState(null);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if(clearSelected){
            clearSelected.current = resetInputValue
        }
        
      }, [])
    const resetInputValue = () => {
        setSelectedDriver(null);
      };
    
    return (
        <div style={{ width: '100%' }}>
            <Autocomplete
                autoComplete
                openOnFocus
                data-test="driverslistfields"
                clearOnBlur
                classes={{ inputRoot: classes.input }}
                className={classes.width}
                getOptionLabel={driver => `${capitalize(driver.name)} [${driver.id}]`}
                options={driverList}
                renderInput={params => {
                    return <TextField name="drivers" autoComplete="false" placeholder={'Drivers'} {...params} variant="outlined"></TextField>;
                }}
                onChange={(e, driver, reason) => { 
                    setSelectedDriver(driver);
                    onUpdate && onUpdate(driver ? driver.id : '');
                    if(reason === 'select-option') { 
                        dispatch(setDriverInfo(driver)) 
                    } else {
                        dispatch(setDriverInfo(undefined)) 
                    }
                }}
                disabled={disable}
                value={selectedDriver}
            />
        </div>
    )
}

import { rateBookTypes } from "../types/rateBookTypes";

const initialState = {
    isLoading: false,
    rateBookList: [],
    GLCodeData: [],
    stateData: [],
    cityData: [],
    actionMessage: "",
    exportedValues: [],
    importedValues: "",
    headerLocationValues: ""
}

export const rateBookReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case rateBookTypes.SET_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case rateBookTypes.GET_RATEBOOK:
            return {
                ...state,
                rateBookList: payload
            }
        case rateBookTypes.GET_GL_CODE:
            return {
                ...state,
                GLCodeData: payload
            }
        case rateBookTypes.GET_STATES:
            return {
                ...state,
                stateData: payload
            }
        case rateBookTypes.GET_CITIES:
            return {
                ...state,
                cityData: payload
            }
        case rateBookTypes.ADD_RATEBOOK:
            return {
                ...state,
                actionMessage: payload
            }
        case rateBookTypes.EDIT_RATEBOOK:
            return {
                ...state,
                actionMessage: payload
            }
        case rateBookTypes.DELETE_RATEBOOK:
            return {
                ...state,
                actionMessage: payload
            }
        case rateBookTypes.GET_EXPORT_VALUE:
            return {
                ...state,
                exportedValues: payload
            }
        case rateBookTypes.GET_IMPORT_VALUE:
            return {
                ...state,
                importedValues: payload
            }
        case rateBookTypes.GET_HEADER_LOCATION_VALUE:
            return {
                ...state,
                headerLocationValues: payload
            }
        default:
            return state;

    }
}
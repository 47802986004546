import React from 'react'
import { Option } from '../Option/Option';
import { H2, TenderWrapper } from './../styledComponents/index';
import { Grid } from '@material-ui/core';

export default function Box({ items = [] , title = ''}) {
    return (
        <TenderWrapper>
            <H2 style={{padding: '10px 5px'}}>{title}</H2>
            {items.map((item, i) => (
                <Grid key={i} style={{ display: 'flex' }}>
                    {item.options.map((option, i) => (
                        <Option size={6} key={i} {...option} />
                    ))}
                </Grid>
            ))}
        </TenderWrapper>
    )
}

import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { H4, StyledSceneHeader } from '../styledComponents'

function SceneHeader(props) {

    return (
        <StyledSceneHeader>
            {props.children ? 
                props.children :
                <Typography variant="h6" style={{ color: 'black', fontWeight: 'bold' }} > {props.title.toString().toUpperCase()} </Typography>
            }
        </StyledSceneHeader>
    )
}

export default SceneHeader

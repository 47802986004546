import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
import { userReducer } from './reducers/userReducer'
import { loadsReducer } from './reducers/loadsReducer'
import { locationReducer } from './reducers/locationReducer'
import { orderReducer } from './reducers/orderReducer'
import { rateBookReducer } from './reducers/rateBookReducer'
import { dispatchReducer } from './reducers/dispatchReducer'
import { templateReducer } from './reducers/templateReducer';
import { shipperReducer } from './reducers/shipperReducer';
import { traceReducer } from './reducers/traceReducer';
import { truckdriverReducer } from './reducers/truckdriverReducer';
import { reportReducer } from './reducers/reportReducer';

const rootReducer = combineReducers({ userReducer, loadsReducer, locationReducer, orderReducer, rateBookReducer, dispatchReducer, templateReducer, shipperReducer, traceReducer,truckdriverReducer,reportReducer});

const middleWares = applyMiddleware(thunk);
// const store = createStore(rootReducer, compose(
//   middleWares,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// ));
const store = createStore(rootReducer, middleWares);
export default store;
import React, { useState, useEffect,useSelector } from 'react';
import Button from '@mui/material/Button';
import {TextField,Box,Paper }from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { POST, PUT,GET } from '../../utils/axios';
// import { getUserFromCookie } from '../../utils/cookie';
import { getUserFromCookie } from '../../utils/cookie';

function OrderNotes({ controlNumber,locationNumber,orderNotesRef}) {
  const [notes, setNotes] = useState(orderNotesRef?.current||[]);
  const [newNote, setNewNote] = useState('');
  const notesUrl = `/orders/order/note/${locationNumber}/${controlNumber}`;
const user = getUserFromCookie();
  useEffect(() => {
if (controlNumber !=="new" && locationNumber){
  GET(notesUrl)
      .then((response) =>{
        if(response?.httpCode===200 && response?.status==="success"){
          setNotes(response.data)
        }
        
      } )
    }
  }, [controlNumber,locationNumber]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const timestamp = moment().toISOString();
    const data =controlNumber?{
      note: newNote,
      time: timestamp,
      lineNumber:notes &&notes.length>0?notes[notes.length-1].lineNumber+1:1
    }: {
      note: newNote,
      time: timestamp,
    };
    if (controlNumber !=="new" && locationNumber){
      console.log(data,data)
      POST(notesUrl, data)
      .then((response) => {
        if(response?.httpCode===200 && response?.status==="success"){
          const newdata={...data,lineNumber:response?.data?.lineNumber,userId:user?.email.split("@")[0]
            }
          setNotes([...notes, newdata]);
          setNewNote('')
        }
       
})
}else{
  const newdata={...data,userId:user?.email.split("@")[0]}
  let allNotes=[...notes, newdata]
  setNotes(allNotes);
  orderNotesRef.current=allNotes
  setNewNote('')
}
   
  };
  return (
    <Box component={Paper} sx={{ border: '2px solid #EEEEEE', padding: 2, borderRadius: 1, marginBottom:5 }} >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <TextField
              label="Enter Note"
              value={newNote}
              onChange={(event) => setNewNote(event.target.value)}
              fullWidth
              required
              multiline
              rows={2} 
            />
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent="center" alignItems="center" style={{ height: '100%' }}>
              <Button type="submit" variant="contained" fullWidth>
                Add Note
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <List>
        {notes.map((note) => (
          <ListItem key={note.id}>
            <ListItemText
              primary={note.note}
              secondary={(note?.userId ? note?.userId : "" ) + " " + (note?.time ? moment(note.time).format('YYYY-MM-DD HH:mm:ss') :"")}
                // : moment().format('YYYY-MM-DD HH:mm:ss'))}

            />
          </ListItem>
        ))}
      </List>
      </Box>
  );
}

export default OrderNotes;


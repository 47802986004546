import Chip from "@mui/material/Chip";
import Switch from '@mui/material/Switch';
import Divider from "@mui/material/Divider";
import { Paper, Button, Box, Typography } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StopsList from "../Orders/Stops/StopsList";
import orderContext from "../Orders/orderContext";
import Loader from "../../components/Loader/Lodaer";
import StopsTable from "../Orders/Stops/StopsTable";
import RatesAndCharges from "../Orders/RatesAndCharges";
import { StyledSceneContent } from "../../components/styledComponents";
import BillingControl from "../Orders/BillingControl/BillingControlForm";
import { getDefaultBillingControl, orderTypeOptions } from "./OrdersLogics";
import CopyOrderMenu from "../../components/CopyOrderDropDown/CopyOrderMenu";
import { copyOrderReq, getSingleOrder } from "../../store/actions/orderActions";
import { mapOrderToServer,mapOrderToTemplateServer ,mapOrderToClient, mapTemplateToServer } from "./components/commonFn";
import { deleteTemplate, saveTemplate ,getTemplateData,searchTemplate} from "../../store/actions/templateAction";
// import { getGLCodes } from "../../../store/actions/rateBookActions";
import { getGLCodes } from "../../store/actions/rateBookActions";
import { useHistory } from 'react-router-dom';
import OrderNotes from "../Orders/OrderNotes";


const actionStyles = { display: 'flex', width: '100%', padding: '2em', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }

export default function OrderContainer(props) {
    const { selected, setSelected, selectedRow, templatenameobj, setRows, templateTableApiCall,orderType,setOrderType,editTemplate,setEditTemplate,setAddNewTemplate ,searchText,handleSearch } = props;

    const history = useHistory();
    const params = useParams();
    const dispatch = useDispatch();
    const [postObj, setPostObj] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('');
    const [editTempName, setEditTempName] = useState(true);
    const [templateSave, settemplateSave] = useState(false);
    // const [editTemplate, setEditTemplate] = useState(false);
    const [orderCreateDate,setorderCreateDate]=useState('');
    const [stopsGridView, setStopsGridView] = useState(false);
    let [currentOrder, setCurrentOrder] = useState([]);
    const [isStateChanged, setIsStateChanged] = useState(false);
    const [templateName, settemplateName] = useState(templatenameobj?.tamplatename);
    const [isLoading, setIsLoading] = useState((params.controlNumber === 'new') ? false : true);
    const [templateNameError, setTemplateNameError] = useState('');
    const [isEdi210, setIsEdi210] = useState(false);
    
    const open = Boolean(anchorEl);

    let stopsRef = useRef([]);
    let linehaulRef = useRef([]);
    let accessorialRef = useRef([]);
    let orderNotesRef = useRef([]);

    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );


    let billingControlRef = useRef(getDefaultBillingControl());


    const [showCopyOrderModal, setShowCopyOrderModal] = useState(false);
    const [isCopyOrderLoading, setIsCopyOrderLoading] = useState(false);
    const [stopsUpdate, setStopsUpdate] = useState(0); 
    const [stopsMasterLoad, setStopsMasterLoad] = useState([]); 


    useEffect(() => {
       
        stopsRef.current=[]
        linehaulRef.current =[]
        accessorialRef.current =[]
        billingControlRef.current= getDefaultBillingControl()
        orderNotesRef.current =[]
        let mapOrderData
            if (selectedRow && selectedRow[0]._source){
                handleCancelEdit()
        const  decodedTemplateName= decodeURIComponent(templatenameobj?.tamplatename)
                settemplateName(decodedTemplateName)
                mapOrderData = mapOrderToClient(selectedRow[0]._source)
                
            }else{
                settemplateName("")
                mapOrderData = mapOrderToClient()
                setEditTempName(false)
            }
           
           
            setCurrentOrder(mapOrderData);
        // }
        fetchGlCodes()
        
    }, [selectedRow])
    const fetchGlCodes = async () => {
        dispatch(getGLCodes());
      }
    
     
 
    const fetchData = (currentOrder,callback) => {
        setIsLoading(true);
        billingControlRef.current = currentOrder?.billingControl || getDefaultBillingControl();
        stopsRef.current = currentOrder?.stops || [];
        linehaulRef.current = currentOrder?.linehaul || [];
        accessorialRef.current = currentOrder?.accessorial || [];
        orderNotesRef.current=currentOrder?.orderNotes || []
        
           if (callback){
        setTimeout(() => {
            callback();
          }, 2000);
         };
      };
      const testFunction=()=>{
      
        setOrderType(currentOrder?.billingControl?.orderType || orderType);
        setorderCreateDate(currentOrder?.billingControl?.orderType)
        setIsLoading(false);
    }
    useEffect(() => {
       
          fetchData(currentOrder,testFunction);
          if(typeof currentOrder !== undefined && currentOrder?.stops?.length > 0){
            setStopsMasterLoad(currentOrder?.stops)
          }
      }, [currentOrder]);

    const handleEditClick = () => {
        setEditTemplate(true)
    }

    const handleCancelClick = () => {

        setSelected(undefined);
        setAddNewTemplate(false)
    }

    const handleCancelEdit = () => {
        settemplateSave(false);
        setEditTemplate(false);
    }
const checkTemplateExistingorNot = async (encodedTemplateName, query = { page: 1, pageSize: 50 }) => {
    try {
        let postParams = [
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation?.locationNumber
            },
            {
                clause: "OR",
                field: "templateName",
                operand: "IS",
                value: encodedTemplateName
            }
        ];

        const response = await dispatch(searchTemplate({postParams, query}));

        if (response?.data?.hits?.length > 0) {
            let confirmAlert = window.confirm(templateName + ' already exists. Do you want to proceed?');
            if (!confirmAlert) {
                settemplateName('');
                setSnackOpen(false);
                setSnackContent("");
                return false;
            } else {
                return true;
            }
        } else {
            return true; // No existing template found, so it's safe to proceed
        }
    } catch (error) {
        console.log("error",error)
        setSnackContent({ severity: "error", message: "Error checking template existence" })
        // console.error("Error checking template existence:", error);
        // Handle errors here, you may want to return false or throw an error depending on your requirements
        return false;
    }
};

    const handleSave = async(type="new") => {

        if (templateName.length !== 0 && billingControlRef?.current?.orderBy?.orderBy && stopsRef?.current[0]?.name ) {
            setSnackOpen(true)
            setSnackContent({ severity: "info", message: `Template ${type==="new"?"Saving":"Updating"} ...` })
        
          
            let reqParams = {
                locationNumber: params.locationNumber === undefined ? ordersLocation?.locationNumber : params.locationNumber,
                controlNumber: params.controlNumber === undefined ? selectedRow[0]?._source?.controlNumber : params.controlNumber,
            }
            let postObj= mapOrderToTemplateServer({ billingControl: { ...billingControlRef.current, orderType: orderType,orderCreateDate:orderCreateDate }, stops: stopsRef.current, ratecharges: {linehaul:linehaulRef.current, accessorial:accessorialRef.current} })
            

            const encodedTemplateName=encodeURIComponent(templateName)
            let postData = mapTemplateToServer(postObj, reqParams, encodedTemplateName)
            let post={...postData,orderNotes:orderNotesRef.current}
            if(type==="new"){
                const response = await checkTemplateExistingorNot(encodedTemplateName, post);
                if (response) {
                    saveAPICall(post, encodedTemplateName,type);
                }
                
            }else{
                saveAPICall(post,encodedTemplateName,type)
            }
           
            // setSelected("")
           
           
        } else {
            setSnackOpen(true)
            if(!(templateName.length !== 0)){
        
            setSnackContent({ severity: "error", message: "TemplateName field are mandatory!" })
            }else if (!(billingControlRef?.current?.orderBy?.orderBy)){
            
                setSnackContent({ severity: "error", message: "Order By field are mandatory!" })

            }else if(!(stopsRef?.current[0]?.name)){
             
                setSnackContent({ severity: "error", message: "Stop field are mandatory!" })
            }else{
                setSnackContent({ severity: "error", message: "something went wrong!" })
            }
        }


    }

    const saveAPICall = (postObj,encodedTemplateName,type) => {
       

        dispatch(saveTemplate(ordersLocation.locationNumber, encodedTemplateName, postObj)).then((res) => {
            if (res?.httpCode === 200 && (res?.message === 'Order Created successfully.' || "Order Created successfully")) {
             if(searchText){
                handleSearch(searchText)

             }else{
                templateTableApiCall();

             }
                
               
                settemplateSave(false);
                setSnackOpen(true)
                setSnackContent({ severity: "success", message: type==="new"?`New Template ${templateName} has created`:` Template ${templateName} has updated` })
                settemplateSave(true);
                setEditTemplate(false)
             
            } else {
                // setSnackOpen(true)
                // setSnackContent({ severity: "error", message: res.message })
                setSnackOpen(false)
                settemplateSave(true);
                setEditTemplate(false)

            }
        });
    }

    const handleDeleteClick = () => {

        let confirmAlert = window.confirm(`Are you sure you want to delete ${templateName}?`);

        if (confirmAlert) {
            const encodedTemplateName=encodeURIComponent(templateName)
            dispatch(deleteTemplate(ordersLocation.locationNumber,encodedTemplateName )).then((res) => {
                if (res?.httpCode === 200 && res?.message === 'Success!') {
                    templateTableApiCall();
                    setSelected(undefined);

                } else {
                    templateTableApiCall();
                    setSelected(undefined);
                }
            });

        }
    }


    const handleCopyClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        // eslint-disable-next-line
        const forbiddenCharacters = /[\!\(\)\[\]\*\"\/\\\#\?]/;
    
        if (forbiddenCharacters.test(inputValue)) {
            const forbiddenCharacter = inputValue.match(forbiddenCharacters)[0];
            setTemplateNameError(`Input contains forbidden characters: ${forbiddenCharacter}` );
         
        } else {
            setTemplateNameError('');
            settemplateName(inputValue);
        }
    }
    const handleClose = (event) => {
        setSnackOpen(false);
    };

    const handleCopyOrder = async (copies, postObj) => {
        setIsCopyOrderLoading(true);
        if (!postObj) {
            setIsCopyOrderLoading(false);
            setSnackOpen(true)
            setSnackContent({ severity: "error", message: "Unable to copy, Request failed" })
            return;
        }
    
        if (copies > 0 && copies <= 25) {
            setSnackOpen(true)
            setSnackContent({ severity: "info", message: "Copying Template to Order ..." })

            let apiRes = await copyOrderReq(ordersLocation.locationNumber, postObj, copies);
            if (apiRes.status === 'success') {
              //grabFirstOrder = apiRes.data.orderNumber;
              setShowCopyOrderModal(false);
              setIsCopyOrderLoading(false);
              setSnackOpen(true)
              //setSnackContent({ severity: "success", message: `New orders has been created from ${grabFirstOrder} to ${apiRes.data.orderNumber}` })
              setSnackContent({ severity: "success", message: apiRes.message })
              setTimeout(() => {
                history.replace("/orders");
                history.replace(`/orders/${ordersLocation?.locationNumber}/${apiRes.data.orderNumber}`)
              }, 2000)
            } else if (apiRes.status === 'error') {
                setIsCopyOrderLoading(false);
                setSnackOpen(true)
                setSnackContent({ severity: "error", message: apiRes.message })
              }
              //}
            } else {
              setIsCopyOrderLoading(false);
              setSnackOpen(true)
              setSnackContent({ severity: "error", message: "Copies must be greater than 0 & less than 25" })
            }
          }
    
            useEffect(() => {
                setStopsMasterLoad(stopsRef?.current)
            } , [stopsUpdate] );

    return (
        <Paper style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: '100%', height: '40px', backgroundColor: '#E4EEFA', alignItems: 'center', display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: "20%" }}>
                    <label style={{ fontSize: '14px', color: '#091DA3' }}>{templatenameobj?.type}</label>
                    <input
                        type="text"
                        id="templatename"
                        name="templatename"
                        value={templateName}
                        maxlength="20"
                        readOnly={editTempName}
                        placeholder="TemplateName"
                        onChange={handleChange}
                        style={{ height: '30px', width: '150px', marginLeft: '15px', borderRadius: '5px', fontSize: '14px' }}

                    />
                </div>
                {templateNameError && <div style={{ color: 'red' }}>{templateNameError}</div>}
                {
                   (selected === 'INBOUND' || selected === 'OUTBOUND' || selected === 'IMPORT' || selected === 'EXPORT'|| editTemplate) &&(editTemplate ||!templateSave)?

                        <div>
                            <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} startIcon={<SaveIcon style={{ width: '14px', height: '14px' }} />} onClick={()=>{handleSave(editTemplate?"edit":"new")}}>Save</Button>
                            <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }} startIcon={<ClearIcon style={{ width: '14px', height: '14px' }} />} onClick={editTemplate?handleCancelEdit:handleCancelClick}>Cancel</Button>
                        </div>

                        :
                        <div>
                            {/* {
                                editTemplate === true ?
                                    <>
                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} startIcon={<SaveIcon style={{ width: '14px', height: '14px' }} />} onClick={handleSave}>Save</Button>
                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }} startIcon={<ClearIcon style={{ width: '14px', height: '14px' }} />} onClick={handleCancelEdit}>Cancel</Button>

                                    </>

                                    : */}
                                    <>
                                        <Button variant="outlined" style={{ width: '140px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} className="dropdown" startIcon={<ContentCopyIcon style={{ width: '14px', height: '14px' }} />} onClick={handleCopyClick}>Copy order</Button>

                                        <CopyOrderMenu
                                            anchorEl={anchorEl}
                                            open={open}
                                            reqParams={{
                                                locationNumber: ordersLocation?.locationNumber ,
                                                controlNumber:  selectedRow[0]?._source?.controlNumber ,
                                            }}
                                            setAnchorEl={setAnchorEl}
                                            currentOrderData={mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })}
                                            currentOrderDataTemplate= {mapOrderToTemplateServer({ billingControl: { ...billingControlRef.current, orderType: orderType, }, stops: stopsRef.current, ratecharges: {linehaul:linehaulRef.current, accessorial:accessorialRef.current} })}
                                            handleModalSubmitButton={(copies, postData) => handleCopyOrder(copies, postData)}
                                            templateTableApiCall={templateTableApiCall}
                                            isTemplateScreenReload={true}
                                           
                                        />

                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} startIcon={<DeleteIcon style={{ width: '14px', height: '14px' }} />} onClick={handleDeleteClick}>Delete</Button>
                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }} startIcon={<EditIcon style={{ width: '14px', height: '14px' }} />} onClick={handleEditClick}>Edit</Button>
                                    </>
                            {/* } */}

                        </div>
                }

                {/* {
                    selected === 'INBOUND' || selected === 'OUTBOUND' || selected === 'IMPORT' || selected === 'EXPORT' ?

                        <div>
                            <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} startIcon={<SaveIcon style={{ width: '14px', height: '14px' }} />} onClick={handleSave}>Save</Button>
                            <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }} startIcon={<ClearIcon style={{ width: '14px', height: '14px' }} />} onClick={handleCancelClick}>Cancel</Button>
                        </div>

                        :
                        <div>
                            {
                                editTemplate === true ?
                                    <>
                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} startIcon={<SaveIcon style={{ width: '14px', height: '14px' }} />} onClick={handleSave}>Save</Button>
                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }} startIcon={<ClearIcon style={{ width: '14px', height: '14px' }} />} onClick={handleCancelEdit}>Cancel</Button>

                                    </>

                                    :
                                    <>
                                        <Button variant="outlined" style={{ width: '140px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} className="dropdown" startIcon={<ContentCopyIcon style={{ width: '14px', height: '14px' }} />} onClick={handleCopyClick}>Copy order</Button>

                                        <CopyOrderMenu
                                            anchorEl={anchorEl}
                                            open={open}
                                            reqParams={{
                                                locationNumber: ordersLocation?.locationNumber ,
                                                controlNumber:  selectedRow[0]?._source?.controlNumber ,
                                            }}
                                            setAnchorEl={setAnchorEl}
                                            currentOrderData={mapOrderToServer({ billingControl: { ...billingControlRef.current, orderType: orderType,orderCreateDate:orderCreateDate }, stops: stopsRef.current, ratecharges: [...linehaulRef.current, ...accessorialRef.current] })}
                                            handleModalSubmitButton={(copies, postData) => handleCopyOrder(copies, postData)}
                                        />

                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', marginRight: '10px', color: '#000000' }} startIcon={<DeleteIcon style={{ width: '14px', height: '14px' }} />} onClick={handleDeleteClick}>Delete</Button>
                                        <Button variant="outlined" style={{ width: '80px', height: '30px', fontSize: '10px', color: '#000000' }} startIcon={<EditIcon style={{ width: '14px', height: '14px' }} />} onClick={handleEditClick}>Edit</Button>
                                    </>
                            }

                        </div>
                } */}

            </div>
            {isLoading ?
                <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                    <Loader />
                </div>


                : (
                    <StyledSceneContent>
                        <orderContext.Provider value={{ isStateChanged, setIsStateChanged, isEdi210, setIsEdi210}}>

                            <div style={selected === 'INBOUND' || selected === 'OUTBOUND' || selected === 'IMPORT' || selected === 'EXPORT' || editTemplate ? {} : { pointerEvents: "none", opacity: "0.8" }}>
                                <Divider
                                    textAlign="left"
                                    style={{ marginTop: "1%" }}>
                                    <Chip label="BILLING CONTROL" size="large" variant="filled" />
                                </Divider>

                                <BillingControl billingControlRef={billingControlRef} stopsMasterLoad={stopsMasterLoad} orderType={orderType} orderTypeOptions={orderTypeOptions} disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'} toTemplate={true}/>
                            </div>

                            <div style={selected === 'INBOUND' || selected === 'OUTBOUND' || selected === 'IMPORT' || selected === 'EXPORT' || editTemplate ? {} : { pointerEvents: "none", opacity: "0.8" }}>
                                {/* <Divider
                                    textAlign="left"
                                    style={{ marginTop: "2%", marginBottom: "1%" }}
                                >
                                    <Chip label="STOPS" size="large" variant="filled" />
                                </Divider>

                                <div style={actionStyles}>
                                    <FormGroup>
                                        <FormControlLabel
                                            label="Grid View"
                                            style={{ fontSize: 50 }}
                                            control={
                                                <Switch
                                                    checked={stopsGridView}
                                                    onChange={(event) => setStopsGridView(event.target.checked)}
                                                />
                                            }
                                        />
                                    </FormGroup>
                                </div> */}
                                <Box display="flex" alignItems="center" width="100%">
                                    <Divider sx={{ flex: 1 }} >
                                        <Typography variant="body1" sx={{ mx: 2 }}>
                                                <Chip label="STOPS" size="large" variant="filled"/>
                                        </Typography>
                                    </Divider>
                                    <Divider sx={{ flex: 1 }} />
                                    <Divider sx={{ flex: 1 }} />
                                    <Divider sx={{ flex: 1 }} />
                                    <Typography variant="body1" sx={{ mx: 2 }}>
                                        <FormControlLabel
                                            label="Grid View"
                                            style={{ fontSize: 50,  }}
                                                control={
                                                    <Switch
                                                        checked={stopsGridView}
                                                        onChange={(event) => setStopsGridView(event.target.checked)}
                                                    />
                                                }
                                            />
                                    </Typography>
                                </Box>

                                {!stopsGridView && <StopsList stopsRef={stopsRef} setStopsUpdate={()=>setStopsUpdate( stopsUpdate + 1 )} orderType={orderType} orderTypeOptions={orderTypeOptions} disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'} toTemplate={true} locationNumber={ordersLocation?.locationNumber}  controlNumber={selectedRow[0]?._source?.controlNumber}/>}
                                {stopsGridView && <StopsTable stopsRef={stopsRef} disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'} />}
                            </div>

                            <div style={selected === 'INBOUND' || selected === 'OUTBOUND' || selected === 'IMPORT' || selected === 'EXPORT' || editTemplate ? {} : { pointerEvents: "none", opacity: "0.8" }}>
                                <Divider
                                    textAlign="left"
                                    style={{ marginTop: "2%", marginBottom: "1%" }}
                                >
                                    <Chip label="RATES & CHARGES" size="large" variant="filled" />
                                </Divider>
                                <RatesAndCharges
                                    // receivablesRef={receivablesRef}
                                    // payablesRef={payablesRef}
                                    accessorialRef={accessorialRef}
                                    linehaulRef={linehaulRef}
                                    orderType={orderType}
                                    snackBarHandler={(content) => { setSnackOpen(content.status); setSnackContent({ severity: content.type, message: content.message }) }}
                                    disableFull={''}
                                    // disableFull={currentOrder.billingControl.orderBy.billToStatus === 'V'}
                                    billingControlRef={billingControlRef}
                                    ordersLocation={ordersLocation?.locationNumber}
                                    isTemplateScreen={true} 
                                />
                            </div>
                            <div style={selected === 'INBOUND' || selected === 'OUTBOUND' || selected === 'IMPORT' || selected === 'EXPORT' || editTemplate ? {} : { pointerEvents: "none", opacity: "0.8" }}>

                            <Divider
                                    textAlign="left"
                                    style={{ marginTop: "2%", marginBottom: "1%" }}
                                >
                                    <Chip label="Order Notes" size="large" variant="filled" />
                                </Divider>
                                <OrderNotes
                                controlNumber={"new"}
                                locationNumber={ordersLocation?.locationNumber}
                                orderNotesRef={orderNotesRef}
                                />
                                </div>
                        </orderContext.Provider>
                    </StyledSceneContent>
                )}
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
                <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>


        </Paper>


    )
}
import React from 'react'
import { TextField } from '@material-ui/core';
import Tooltip from '@mui/material/Tooltip';

function VendorInfo({truckDriverInfo}) {
    return (
        <div>
            <h3 className="truck-driver-subheading">Vendor Information</h3>
            <div className="truck-info-input">
                <label>Code</label>
                <Tooltip title={truckDriverInfo?.code} arrow>  
                <TextField
                    value={truckDriverInfo?.code}
                    id="outlined-size-small"
                    data-test="vendorinfofields"
                    size="small"
                    variant="outlined"
                    className="vendor-code"
                    
                /></Tooltip>
            </div>
            <div className="truck-info-input">
                <label>Name</label>
                <Tooltip title={truckDriverInfo?.vendorName} arrow>  
                <TextField
                    value={truckDriverInfo?.vendorName}
                    id="outlined-size-small"
                    data-test="vendorinfofields"
                    size="small"
                    variant="outlined"
                    className="vendor-text-field"
                    
                /></Tooltip>
            </div>
            <div className="truck-info-input">
                <label>Address</label>
                <Tooltip title={truckDriverInfo?.address_1} arrow>  
                <TextField         
                    value={truckDriverInfo?.address_1}
                    id="outlined-size-small"
                    data-test="vendorinfofields"
                    size="small"
                    variant="outlined"
                    className="vendor-text-field"
                /></Tooltip>
            </div>
            <div className="truck-info-input">
                <label>City/St/Zip</label>
                <Tooltip title={`${truckDriverInfo?.city}, ${truckDriverInfo?.state}, ${truckDriverInfo?.zipcode}`} arrow>  
                <TextField
                    value={`${truckDriverInfo?.city}, ${truckDriverInfo?.state}, ${truckDriverInfo?.zipcode}`}
                    id="outlined-size-small"
                    data-test="vendorinfofields"
                    size="small"
                    variant="outlined"
                    className="vendor-text-field"
                /></Tooltip>
            </div>
            
        </div>
        
    )
}

export default VendorInfo

export const colors = {
    // add project colors here
    blue: {
        light: '#212529',
        normal: '#0911A7',
        dark: '#0939bc',
    },
    white: {
        normal: '#ffffff',
    },
    gray: {
        normal: '#F7F5F5',
    }
    // shark: '#212529',
    // dark: '#212529',
    // tropicalRainForest: '#018053',
    // tropicalRainForestDark: '#006a52',
    // slateGray: '#6b768c',
    // abbey: '#4d565c',
    // fuelYellow: '#f0ad24',
    // vermilion: '#ff4100',
    // alabaster: '#f7f7f7',
    // white: '#ffffff',
    // narvic: '#f6fcf9',
    // loginText: '#575757',
    // grey: '#ebebeb',
    // navyBlue: '#0939bc',
    // darkRed: '#DF003C',
    // lightRed: 'rgba(255,0,0,0.6)',
    // lightBlue: '#1D36BA',
    // lightGray: '#F2F2F2',
    // blue: '#002463',
    // link: '#52ABF1'
};
import React, { useState } from 'react'
import { TenderListWrapper } from '../../components/styledComponents'
import { IconButton } from '@material-ui/core';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@material-ui/core'
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearIcon from '@mui/icons-material/Clear';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { searchEmailString } from '../../store/actions/locationActions';
import CustomizedTables from '../../components/Table/LocationTable'

export default function Location() {

  const dispatch = useDispatch();
  const [getSearchEmail, setGetSearchEmail] = useState('');
  const [hide, setHide] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const history = useHistory();

  // Search Email Function
  const handlerGetSearchEmail = (e) => {
    setGetSearchEmail(e.target.value)
  }

  const submitEmailSearchString = async () => {
    if (getSearchEmail) {
      setIsSearch(true)
      let res = await dispatch(searchEmailString(getSearchEmail))
      setHide(true)

    }
  }

  function addNewLocation() {
    history.push('/addlocation')
  }

  function refreshEmail(value) {
    setGetSearchEmail(value)
    setHide(false)
  }

  function clearEmail() {
    setGetSearchEmail('');
    setHide(false)
  }

  return (
    <TenderListWrapper>
      <Paper
        component=""
        sx={{
          p: '2px 4px',
          display: 'flex',
          marginTop: '20px',
          marginBottom: '20px',
          marginLeft: 'auto',
          marginRight: '10px',
          alignItems: 'center',
          width: 400
        }}
      >

        <InputBase
          value={getSearchEmail}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search Email"
          inputProps={{ 'aria-label': 'search Location' }}
          onChange={handlerGetSearchEmail} />
        {hide ? <IconButton onClick={clearEmail}>

          <ClearIcon />

        </IconButton> :
          <IconButton type="submit"
            onClick={submitEmailSearchString}
            sx={{ p: '10px' }}
            aria-label="search">
            <SearchIcon />
          </IconButton>

        }

        <Divider sx={{ height: 28, m: 0.5 }}
          orientation="vertical" />
        <IconButton
          color="primary"
          sx={{ p: '10px' }}
          aria-label="directions">
          <AddIcon onClick={addNewLocation} />
        </IconButton>
      </Paper>

      <CustomizedTables isSearch={isSearch} getSearchEmail={getSearchEmail} refreshEmail={refreshEmail}/>

    </TenderListWrapper >
  )
}


import config from '../config/config';
import { getToken } from './cookie';

const WSURL = config.api.WSURL;

class WebSocketService {
    constructor() {
        this.url = WSURL;
        this.socket = null;
        this.subscriptions = {}; // To store callbacks for each event
        this.token = getToken();
        this.reconnectInterval = 5000;
        this.heartbeatInterval = 30000;
        this.heartbeatIntervalId = null;
        this.reconnectTimeoutId = null;
        this.isConnected = false;
    }

    connect() {
        this.socket = new WebSocket(this.url, this.token);

        this.socket.onopen = () => {
            // console.log('WebSocket connected');
            this.isConnected = true;
            this.startHeartbeat();
        };

        this.socket.onclose = () => {
            // console.log('WebSocket disconnected');
            this.isConnected = false;
            this.stopHeartbeat();
            this.scheduleReconnect();
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.socket.close();
        };

        this.socket.onmessage = (event) => {
            if (event.data === 'pong') {
                console.log('Received pong from server');
            } else{
                const data = JSON.parse(event.data);
                const { event: eventName, message } = data;
                if (eventName && this.subscriptions[eventName]) {
                    this.subscriptions[eventName](message);
                }
            }
        };
    }

    disconnect() {
        if (this.socket) {
            this.socket.close();
            console.log('WebSocket disconnected');
        }
    }

    sendMessage(eventName, message) {
        const data = JSON.stringify({ type: 'subscribe', event: eventName, reqPayload: message });
        //const data = JSON.stringify(message);
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(data);
        } else {
            console.error('WebSocket is not connected');
        }
    }

    onMessage(callback) {
        if (this.socket) {
            this.socket.onmessage = (event) => {
                if (event.data !== 'pong') {
                const data = JSON.parse(event.data);
                callback(data);
                }
            };
        }
    }

    subscribe(eventName) {
        //this.subscriptions[eventName] = callback;
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify({ type: 'subscribe', event: eventName }));
        }
    }

    unsubscribe(eventName) {
        delete this.subscriptions[eventName];
    }


    startHeartbeat() {
        this.heartbeatIntervalId = setInterval(() => {
          if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send('ping');
            console.log('Sent ping to server');
          }
        }, this.heartbeatInterval);
      }
    
      stopHeartbeat() {
        clearInterval(this.heartbeatIntervalId);
      }
    
      scheduleReconnect() {
        this.reconnectTimeoutId = setTimeout(() => {
          this.connect();
        }, this.reconnectInterval);
      }

      close() {
        if (this.socket) {
          this.socket.close();
        }
        this.stopHeartbeat();
        clearTimeout(this.reconnectTimeoutId);
      }
}

export default WebSocketService;
import {maintenanceTypes } from "../types/maintenanceTypes"
import { Dispatch } from 'redux';
import { GET, POST, PUT, DELETE } from '../../utils/axios';
import {mapShipperFormDataToClient,maptopickUpDispositionTable} from "../../scenes/Maintenance/PickupDisposition/components/commonFn"


export const getMaintenanceData = ( params: any) => {
    return async (dispatch: Dispatch) => {
        const res = await maintenanceApiReq( params);
        dispatch(setMaintenance(res));
        return res;
    };
}

const setMaintenance = (apiData: any) => {
    return {
        type: maintenanceTypes.MAINTENANCE_DATA,
        payload: apiData,
    };
};


export const maintenanceApiReq = async (params: any) => {
    console.log("params--sort",params)
    // let url = `/shipperconsignee/${params.locationNumber}`
    let url = "/rail-road/list"
    
    let queryString = `?page=${params?.pageData?.page}&size=${params?.pageData?.pageSize}&sortBy=${params?.sortData?.field}&sortOrder=${params?.sortData?.sort}`;
    if(params?.search){
        queryString  +=`&search=${params?.search}`
    }
    
    // const queryString =""
    try {
        let res = await POST(url + queryString,params.locationlist);
        if (res && res.httpCode === 200 && res.status === 'success')  {
            console.log("res.data",res)
                let data = {data:maptopickUpDispositionTable(res?.data?.data),count:res?.data?.totCount}
                console.log("----data",data)
              
                return data;
            }
            return [];
       
    } catch (err) {
        return [];
    }
   
}



export const editMaintenance= (locationNumber: number,postData:any) => {
    return async (dispatch: Dispatch) => {
         const res = await editMaintenanceReq(locationNumber,postData);
        // dispatch(setShippers(res));
        return res;
    }
}

export const editMaintenanceReq = async (locationNumber: number, postData: any) => {
    try {
       
          let  res = await PUT(`/trucks/driver/maintenance/${locationNumber}`, postData); // Assuming you have a PUT function
      
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}


export const saveMaintenanceLocData= (toLocationNo:any,code:any,type:any) => {
    return async (dispatch: Dispatch) => {
         const res = await saveMaintenanceLocDataReq(toLocationNo,code,type);
        // dispatch(setShippers(res));
        return res;
    }
}

export const saveMaintenanceLocDataReq = async ( toLocationNo: any,code:any,type:any) => {
    console.log("toLocationNo",toLocationNo,"code",code,"type",type)

    try { 
        let  res =""
        if (type==="ADD"){
              res = await POST(`/rail-road/pucode-location/${toLocationNo}`, code)
        }else{
           res= await DELETE(`/rail-road/pucode-location/${toLocationNo}`, code)
        }
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}
export const getSingleMaintenanceData = (req:any) => {
    return async (dispatch: Dispatch) => {
        const res = await singleMaintenanceApiReq(req);
        dispatch(setMaintenance(res));
        return res;
    };
}

export const singleMaintenanceApiReq = async (req:any) => {
    try {
        
        // let res = await GET(`/trucks/driver/maintenance/single/${truckNumber}/${driverNumber}`)
        if(req.code){
            let res = await GET(`/rail-road/code/${req.code}`)
            console.log("res",res)
            if (res && res.httpCode === 200 && res.status === 'success')  {
            console.log("res.data",res)
            let data = mapShipperFormDataToClient(res?.data);
            console.log("----data",data)
          
            return data;
               
            }

        }else{
            let data = mapShipperFormDataToClient();
            console.log(">>>>>data",data)
          
            return data;

        }
  
    } catch (err) {
        return [];
    }
}


export const saveMaintenanceData = (LocationNumber: any,params:any,type:any) => {
    return async (dispatch: Dispatch) => {
         const res = await saveMaintenanceDataReq(LocationNumber,params,type);
        dispatch(setMaintenance(res));
        return res;
    }
}


export const saveMaintenanceDataReq = async (locationNumber: number, params: any, type: any) => {
    try {
        let res;
        if (type === 'new') {
            // Perform POST request
           
            // res = await POST(`/shipperconsignee/${locationNumber}`, params);
            res = await POST("/rail-road", params); 
        } else {
            // Perform PUT request
            res = await PUT("/rail-road", params); 
        }
        return res;
    } catch (err) {
        // Handle errors appropriately
        console.error("Error:", err);
        return [];
    }
}

export const moveCodeToLocation = (LocationNumber: any,postData: any,type: any) => {
    return async (dispatch: Dispatch) => {
         const res = await moveCodeToLocationReq(LocationNumber,postData,type);
         dispatch(setMaintenance(res));
    
        return res;
    }
}


export const moveCodeToLocationReq = async (locationNumber: number, postData: any,type: any) => {
    try {
        let res=""
        if(type==="add"){
            res = await POST(`/rail-road/pucode-location/${locationNumber}`, postData);
        }else{
            res = await DELETE(`/rail-road/pucode-location/${locationNumber}`, postData);
        }
       
        return res;
    } catch (err) {
        console.log(err)
    }
}


export const deleteMaintenanceContact = (pucode:any,phone:any) => {
    return async (dispatch: Dispatch) => {
         const res = await deleteMaintenanceContactReq(pucode,phone);
         dispatch(setMaintenance(res));
    
        return res;
    }
}

export const deleteMaintenanceContactReq = async (pucode:any,phone:any) => {
    try {
        let res = await DELETE(`/rail-road/contact/${pucode}/${phone}`);
        return res;
    } catch (err) {
        return [];
    }
}

export const deleteMaintenancePickUpDispo = (pucode:any) => {
    return async (dispatch: Dispatch) => {
         const res = await deleteMaintenancePickUpDispoReq(pucode);
         dispatch(setMaintenance(res));
    
        return res;
    }
}

export const deleteMaintenancePickUpDispoReq = async (pucode:any) => {
    try {
        let res = await DELETE(`/rail-road/pucode/${pucode}`);
        return res;
    } catch (err) {
        return [];
    }
}
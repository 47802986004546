import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import React, { forwardRef, useState } from 'react'
import { TextField, Grid, Checkbox } from '@material-ui/core'
import Moment from 'moment';

import Loader from './../../../components/Loader/Lodaer';
import { Formik } from 'formik';
import { Button, H2 } from './../../../components/styledComponents/index';
//error
import ErrorAlerts from './../../../components/Alerts/ErrorAlerts';
import { Divider, makeStyles, FormControlLabel } from '@material-ui/core';
import DriversList from './DriversList';
import LocationList from './LocationList';
import DatePicker from "react-datepicker";
import { colors } from './../../../theme/colors';
import "react-datepicker/dist/react-datepicker.css";
import { searchLoads, getDriverListByLocation, setLoads } from '../../../store/actions/loadsActions';
import { useDispatch, useSelector } from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList'
import { setSearchValues, submitSearch } from './../../../store/actions/loadsActions';;

const useStyles = makeStyles({
    input: {
        padding: '8px 8px !important',
    },
    shrink: {
        opacity: 0.3,
        transform: 'translate(28px, 2px) scale(0.75) !important',
    },
    outline: {
        fontSize: '13px',
        transform: 'translate(24px, 20px) scale(1)',
    },
    driverInput: {
        padding: '8px',
        backgroundColor: colors.white.normal,
        color: colors.blue.normal,
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '5px',
        width: '100%',
        fontSize: '14px',
        '&[disabled]': {
            opacity: 0.6
        }
    }
});

const ExampleCustomInput = forwardRef(({ value, onClick, disabled }, ref) => {
    const classes = useStyles();
    return (<input className={classes.driverInput} readOnly disabled={disabled} onClick={onClick} ref={ref} value={value} placeholder={'Pick a date range'} />
    );
});


export default function LoadSearch() {
    //const [startDate, setStartDate] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [Location, setLocation] = useState(null);
    const loadSearchValues = useSelector(({ loadsReducer }) => loadsReducer.loadSearchValues);
    const classes = useStyles();
    const dispatch = useDispatch();
    const clearSelected = React.useRef(null)

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            {/*<ErrorAlerts /> */}

            {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <LocationList onUpdate={(location) => {
                    setLocation(location);
                    //const searchValue = { param: '', data: '' };   
                    dispatch(setSearchValues(location));
                    //searchValue.param = "location";
                    //searchValue.data = location;
                    var searchValue = "location=" + location;
                    //dispatch(searchLoads(searchValue));
                    dispatch(getDriverListByLocation(searchValue));
                }} />
            </div> */}
            <Accordion
                // expanded={true}
                expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
            >
                <AccordionSummary expandIcon={<FilterListIcon />}>
                    <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <H2>List Of Loads</H2>
                    </div>
                </AccordionSummary>


                <AccordionDetails>
                    <Grid style={{ display: 'flex', width: '100%', userSelect: 'none' }}>
                        <Formik
                            initialValues={loadSearchValues}
                            validate={(values) => {
                                const dateSplitter = values?.unassignedDate?.split('--') || [];
                                const errors = {};
                                const { container, driverId, unassignedDate } = values;
                                if ((container && driverId) || (container && unassignedDate) || (driverId && unassignedDate)) errors.onlyOne = 'Required';

                                if (dateSplitter[0] === "Invalid date" || dateSplitter[1] === "Invalid date") {
                                    errors.unassignedDate = "Invalid date range";
                                }
                                return errors;
                            }}
                            onSubmit={values => {
                                dispatch(submitSearch(true))
                                setExpanded(false);
                                values['location'] = (Location != null) ? Location : loadSearchValues.location;
                                const { container, driverId, unassignedDate, available, controlNumber, referenceNumber, location } = values;
                                var searchValue = "";
                                const params = { container, driverId, unassignedDate, available, referenceNumber, controlNumber, location };
                                dispatch(setSearchValues(values));
                                Object.keys(params).forEach(param => {
                                    if (params[param] !== "" && params[param] !== null && params[param] !== undefined) {
                                        // searchValue.param = param;
                                        // if (param === 'available') {
                                        //     searchValue.data = params[param] === 'available'
                                        // } else {
                                        //     searchValue.data = params[param];
                                        // }
                                        searchValue += param + "=" + params[param] + "&";
                                    }
                                })
                                searchValue = searchValue.slice(0, -1);
                                return dispatch(searchLoads(searchValue));
                            }}
                            onReset={() => {
                                //setStartDate(null);
                                setDateRange([null, null]);
                                dispatch(setLoads([]));
                            }}
                        >
                            {({ handleChange, handleBlur, handleSubmit, handleReset, isSubmitting, values, setFieldValue, errors, setErrors }) => (
                                <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                    {/* {errors.input && <div style={{ width: '100%', textAlign: 'center' }}><span className="error">{errors.input}</span></div>} */}
                                    {/* <div style={{ width: '100%', textAlign: 'center' }}><span className="error">{errors.unassignedDate}</span></div> */}
                                    <Grid container wrap={'wrap'} style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        <Grid item xs={6} md={3} style={{ display: 'flex', alignItems: 'center' }} >
                                            <TextField
                                                name='container'
                                                data-test="loadSearchFields"
                                                type='text'
                                                label={'Container'}
                                                classes={{ root: classes.input }}
                                                fullWidth={true}
                                                variant='outlined'
                                                onChange={handleChange}
                                                disabled={!!(values.driverId || values.unassignedDate || values.available)}
                                                inputProps={{ className: classes.input, style: { textTransform: "uppercase" } }}
                                                InputLabelProps={{ classes: { shrink: classes.shrink, outlined: classes.outline } }}
                                                onBlur={handleBlur}
                                                value={values.container}

                                            />
                                        </Grid>
                                        <Grid item xs={6} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <DriversList onUpdate={(driverId) => {
                                                setFieldValue('driverId', driverId)
                                            }} clearSelected={clearSelected} disable={!!(values.container || values.unassignedDate || values.available)} />
                                        </Grid>
                                        <Grid item xs={6} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

                                            <DatePicker
                                                selectsRange={true}
                                                data-test="loadSearchFields"
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                selected={startDate}
                                                startDate={startDate}
                                                endDate={endDate}
                                                dateFormat="MM/dd/yyyy"
                                                onChange={(date) => {
                                                    //setStartDate(date);
                                                    setDateRange(date);
                                                    //setFieldValue('unassignedDate', date ? startDate.toLocaleDateString('en-GB').replaceAll('/', '-').split('-').reverse().join('-') : '');
                                                    setFieldValue('unassignedDate', date ? Moment(date[0]).format('YYYY-MM-DD') + "--" + Moment(date[1]).format('YYYY-MM-DD') : "");
                                                }}
                                                disabled={!!(values.driverId || values.container || values.available)}
                                                isClearable
                                                placeholderText={'Pick a date range'}
                                                customInput={<ExampleCustomInput disabled={values.driverId || values.container || values.available} />}
                                            />
                                            <p className="error" style={{ textAlign: 'center', color: "red" }}>{errors.unassignedDate}</p>
                                        </Grid>
                                        <Grid item xs={6} md={3} style={{ display: 'flex', alignItems: 'center' }} >
                                            <TextField
                                                name='controlNumber'
                                                data-test="loadSearchFields"
                                                type='number'
                                                label={'Control Number'}
                                                classes={{ root: classes.input }}
                                                fullWidth={true}
                                                variant='outlined'
                                                onChange={handleChange}
                                                disabled={!!(values.container || values.driverId || values.unassignedDate || values.available)}
                                                inputProps={{ className: classes.input }}
                                                InputLabelProps={{ classes: { shrink: classes.shrink, outlined: classes.outline } }}
                                                onBlur={handleBlur}
                                                value={values.controlNumber}

                                            />
                                        </Grid>

                                    </Grid>


                                    <Grid container wrap={'wrap'} style={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        <Grid item xs={6} md={3} style={{ display: 'flex', alignItems: 'center' }} >
                                            <TextField
                                                name='referenceNumber'
                                                data-test="loadSearchFields"
                                                type='number'
                                                label={'Reference Number'}
                                                classes={{ root: classes.input }}
                                                fullWidth={true}
                                                variant='outlined'
                                                onChange={handleChange}
                                                disabled={!!(values.container || values.driverId || values.unassignedDate || values.available || values.controlNumber)}
                                                inputProps={{ className: classes.input }}
                                                InputLabelProps={{ classes: { shrink: classes.shrink, outlined: classes.outline } }}
                                                onBlur={handleBlur}
                                                value={values.referenceNumber}

                                            />
                                        </Grid>
                                        <Grid item xs={8} md={6} style={{ display: 'flex', alignItems: 'center' }} >
                                            <FormControlLabel value={"available"}
                                                control={<Checkbox name="available" color="primary"
                                                    disabled={!!(values.driverId || values.container || values.unassignedDate)}
                                                    checked={values.available === 'available'}
                                                    onChange={() => {
                                                        if (values.available !== 'available') {
                                                            setFieldValue('available', 'available');
                                                        } else {
                                                            setFieldValue('available', '');
                                                        }
                                                    }} />}
                                                label="Available On Spot" />

                                            <FormControlLabel value={"unavailable"}
                                                control={<Checkbox name="unavailable" color="primary"
                                                    disabled={!!(values.driverId || values.container || values.unassignedDate)}
                                                    checked={values.available === 'unavailable'}
                                                    onChange={() => {
                                                        if (values.available !== 'unavailable') {
                                                            setFieldValue('available', 'unavailable');
                                                        } else {
                                                            setFieldValue('available', '');
                                                        }
                                                    }} />}
                                                label="Unavailable On Spot" />
                                        </Grid>
                                        <Grid item xs={8} md={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10 }} >
                                            <Button type='reset' data-test="loadsearchbutton" variant='contained' style={{ marginRight: 5 }} color='primary'
                                                disabled={!(values.driverId || values.unassignedDate || values.available || values.container || values.controlNumber || values.referenceNumber)}
                                                onClick={() => { handleReset(); clearSelected.current(); }}>
                                                {('Reset')}
                                            </Button>
                                            <Button type='submit' variant='contained' data-test="searchBtn" color='primary' disabled={!(values.driverId || values.unassignedDate || values.available || values.container || values.controlNumber || values.referenceNumber) || isSubmitting}>
                                                {isSubmitting ? (<Loader label={"Searching..."} />) : ('Search')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Formik>
                    </Grid>

                </AccordionDetails>

            </Accordion>
        </div>
    )
}

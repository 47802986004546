import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { deleteLoc } from '../../store/actions/locationActions';
import { userList } from '../../scenes/Location/fetch-location';

export default function AutocompleteControlled({ props, editHandler, addHandler, autoComDefValue, editCount }) {
    
    const params = useParams();
    const dispatch = useDispatch();
    let getDefaultValue = props[0]?.default || 0;
    let getAutoCompleteLoc = props[0]?.locationNumber || [];
    const [value, setValue] = useState(autoComDefValue);
    let newValue = value;
    const [open, setOpen] = React.useState(false);

    // Convert Location Number to Array and set to Value
    useEffect(() => {
        if (!Array.isArray(getAutoCompleteLoc)) {
            let splitGetAutoCompleteLoc = getAutoCompleteLoc.split(',');
            setValue(splitGetAutoCompleteLoc)
        } else {
            setValue(getAutoCompleteLoc)
        }
    }, [getAutoCompleteLoc.length > 0])

    // To store edit location number value to Autocomplete, function created in Edit Screen
    
    useEffect(() => {
        editHandler(value)
    }, [value])

    const handleClick = () => {
        setOpen(true);
    };
    const handleClose = (event) => {
        setOpen(false);
    };

   //To clear the Autocomplete value, edit count parameter comes from edit Location screen

    useEffect(() => {
        setValue([])
    }, [editCount == 1])

    return (
        <div style={{
            width: '20%',
            margin: '0 auto',
            marginTop: '5%'
        }}>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    addHandler(false);
                    let initialValue = value.length;
                    let deletedValue = newValue.length;
                    let editEmailValue = props[0]?.email;
                    if (initialValue > deletedValue) {
                        let differenceValue = value.filter(x => !newValue.includes(x))
                        let deletedNum = Number(differenceValue.toString());
                        // Used to check default number in deleted function
                        if (deletedNum === getDefaultValue) {
                            setOpen(true);
                        }
                        else {
                            let deleteParams = { locationNumber: deletedNum, email: params.email }
                            setValue(newValue);
                            dispatch(deleteLoc(deleteParams));
                        }

                    } else {
                        setValue(newValue);
                    }

                }}
                multiple
                id="checkboxes-tags-demo"
                options={userList.map((option) => option.name)}
                disableCloseOnSelect
                freeSolo
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                        />
                    ))
                }
                style={{ width: 300, marginLeft: "-95%" }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Location Number"

                    />
                )}
            />

            < Snackbar open={open} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} autoHideDuration={5000} onClose={handleClose} >
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Default Location cannot be deleted!
                </Alert>
            </Snackbar>

        </div>
    );
}
import { shippersTypes } from "../types/shippersType";
const initialState = {
  shipperData: [],
  shipperContactData: [],
  shipperHoursData:[],
};

export const shipperReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case shippersTypes.SHIPPERS_DATA:
      return {
        ...state,
        shipperData: payload,
      };
    case shippersTypes.SHIPPERS_CONTACT_DATA:
      return {
        ...state,
        shipperContactData: payload,
      };

    case shippersTypes.SHIPPERS_HOURS_DATA:
      return {
        ...state,
        shipperHoursData: payload,
      };
    default:
      return state;
  }
};

import { CodeRounded } from '@material-ui/icons';
import moment from 'moment';


export const mapShipperFormToClient = (item) => {
  console.log("mapShipperFormToClient",item)
 const data= {
  code: item?.code || "",
  description:item?.description||"",
  description2:item?.description2||"",
  scac:item?.scac||"",
  hubid:item?.hubid||"",
  name:item?.name||"",
  address: item?.address || "",
  city: item?.city || "",
  state: item?.state || "",
  zip: item?.zip || "",
  phone: item?.phone || "",
  fax: item?.fax || "",
  internalNote: `${item?.notes1?item?.notes1+" ":""}${item?.notes2?item.notes2+" ":""}${item?.notes3?item?.notes3+" ":""}`,
  note1: item?.notes1 || "",
  note2: item?.notes2 || "",
  note3: item?.notes3 || "",
  location:item?.location ||[],
   
  }
  return data
  
}
//new
export const mapShipperFormDataToClient = (item) => {
  console.log("mapShipperFormToClient",item)
 const shipperForm= mapShipperFormToClient(item)
  const shipperContacts=maptoClientContactTable(item?.contacts,item|| "")
  const shipperHour=maptoClientHoursTable(item?.hours)
  return { shipperForm,shipperContacts,shipperHour}
  
}

// export const mapShipperFormDataToServer = (item) => {
//   console.log("mapShipperFormToClient",item)
//   mapShipperFormToServer(item.shipperForm)
//  contacts:mapShipperContactToServer(item.shipperContacts)
//   hours:mapHoursTableToServer(item?shipperHour)
//   return { shipperForm,shipperContacts,shipperHour}
  
// }

function splitStringIntoThree(text) {
  const maxLength = 125;
  const chunkSize = 25;
  const chunks = [];

  if (text.length <= chunkSize) {
      chunks.push(text);
      chunks.push('');
      chunks.push('');
  } else if (text.length <= chunkSize * 2) {
      chunks.push(text.substring(0, chunkSize));
      chunks.push(text.substring(chunkSize));
      chunks.push('');
  } else {
      chunks.push(text.substring(0, chunkSize));
      chunks.push(text.substring(chunkSize, chunkSize * 2));
      chunks.push(text.substring(chunkSize * 2));
  }

  return chunks;
}


export const mapShipperFormToServer = (item,contact) => {
  console.log("mapShipperFormToServer",item,"---contact",contact)
  const splitNote =item?.internalNote?.match(/.{1,25}/g)
  console.log("splitNote",splitNote)
 const data= {
    address: item?.address || "",
    city: item?.city || "",
    code: item?.code || "",
    fax: item?.fax || "",
    description: item?.description || "",
    description2: item?.description2 || "",
    scac: item?.scac || "",
    hubid: item?.hubid || "",
    name: item?.name || "",
    notes1: splitNote && splitNote[0] ? splitNote[0] : "",
    notes2: splitNote && splitNote[1] ? splitNote[1] : "",
    notes3:splitNote && splitNote[2] ? splitNote[2] : "",
    phone: (contact[0] &&contact[0].id===-1)?contact[0].phone_number: "",
    state: item?.state || "",
    zip: item?.zip || ""
  }
  return data
  
}
export const convert_hours_data = (receiving_hours, shipping_hours, code) => {
  console.log("receiving_hours,shipping_hours,code", receiving_hours, shipping_hours, code);
  
  const data = {
      hours: [
          {
              seq: receiving_hours && receiving_hours.seq ? receiving_hours.seq : -1,
              hoursType: "L",
              shipperCode: code,
              day1: receiving_hours && receiving_hours.day1 ? receiving_hours.day1 : "",
              day2: receiving_hours && receiving_hours.day2 ? receiving_hours.day2 : "",
              day3: receiving_hours && receiving_hours.day3 ? receiving_hours.day3 : "",
              day4: receiving_hours && receiving_hours.day4 ? receiving_hours.day4 : "",
              day5: receiving_hours && receiving_hours.day5 ? receiving_hours.day5 : "",
              day6: receiving_hours && receiving_hours.day6 ? receiving_hours.day6 : "",
              day7: receiving_hours && receiving_hours.day7 ? receiving_hours.day7 : "",
          },
          {
              seq: shipping_hours && shipping_hours.seq ? shipping_hours.seq : -1,
              hoursType: "Y",
              shipperCode: code,
              day1: shipping_hours && shipping_hours.day1 ? shipping_hours.day1 : "",
              day2: shipping_hours && shipping_hours.day2 ? shipping_hours.day2 : "",
              day3: shipping_hours && shipping_hours.day3 ? shipping_hours.day3 : "",
              day4: shipping_hours && shipping_hours.day4 ? shipping_hours.day4 : "",
              day5: shipping_hours && shipping_hours.day5 ? shipping_hours.day5 : "",
              day6: shipping_hours && shipping_hours.day6 ? shipping_hours.day6 : "",
              day7: shipping_hours && shipping_hours.day7 ? shipping_hours.day7 : "",
          }
      ]
  };

  return data;
};



//above
export const maptopickUpDispositionTable = (shipperRes) => {
 
  try {
    let shipper = shipperRes?.map((item,index) => {
      return {id: index,
        code: item?.code || "",
        description:item?.description||"",
        description2:item?.description2||"",
        scac:item?.scac||"",
        hubid:item?.hubid||"",
        name:item?.name||"",
        address: item?.address || "",
        city: item?.city || "",
        state: item?.state || "",
        zip: item?.zip || "",
        phone: item?.phone || "",
        fax: item?.fax || "",
        internalNote: `${item?.note1?item?.note1+" ":""}${item?.note2?item.note2+" ":""}${item?.note3?item?.note3+" ":""}`,
        note1: item?.note1 || "",
        note2: item?.note2 || "",
        note3: item?.note3 || "",
      }
    })
    return shipper;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

export const maptoClientContactTable = (shipperRes,shipperData) => {


  try {
    // if (!shipperRes) {
    //   return  getDefaultShipperContact()
      
    // }
    let shipperContacts = shipperRes?.map((item,index) => {
      return {id: index,
       
        email: item?.email|| "",
        contact_name: item?.name|| "",
       
        phone_number: item?.phone|| "",
        seq: item?.seq|| "",
        code: shipperData?.code|| "",
        
        primary:item?.isPrimary ||"N"
        
        
      }
    })
    const additionalContact={
      id: -1,
     
        email: shipperData?.email|| "",
      
        contact_name: shipperData.name|| "",
       
        phone_number: shipperData?.phone|| "",
        seq:"",
        code:"",
        primary:"N",
        isDisabled:true
        
        
      
    }
    if(shipperData?.phone){
      shipperContacts.unshift(additionalContact)
    }
    
    return shipperContacts;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

// export const maptoClientHoursTable = (shipperRes) => {

//   try {
//     let shipperHours = shipperRes?.map((item,index) => {
//       return {id: index,
       
//         hoursType: item?.hoursTypes|| "",
//         shipperCode: item?.shipperCode|| "",
//         day1: item?.day1|| "",
//         day2: item?.day2|| "",
//         day3: item?.day3|| "",
//         day4: item?.day4|| "",
//         day5: item?.day5|| "",
//         day6: item?.day6|| "",
//         day7: item?.day7|| "",
        
//       }
//     })
//     return shipperHours;

//   } catch (e) {
//     console.log('Error occurred while mapping', e)
//     return false;
//   }
// }

const getDefaultHoursObject = (hoursType) => ({
  // id: 0,
  seq:-1,
  hoursType,
  shipperCode: "",
  day1: "",
  day2: "",
  day3: "",
  day4: "",
  day5: "",
  day6: "",
  day7: "",
});

export const maptoClientHoursTable = (shipperRes) => {
  console.log("shipperRes",shipperRes)
  try {
    let receivingHours = [];
    let shippingHours = [];

    // Check if shipperRes is null or undefined
    if (!shipperRes) {
      receivingHours.push(getDefaultHoursObject("L"));
      shippingHours.push(getDefaultHoursObject("Y"));
      return { receivingHours, shippingHours };
    }

    shipperRes.forEach((item, index) => {
      console.log("item---",item.seq)
      const hoursData = {
        // id: index,
        seq:item?.seq?item?.seq:-1,
        hoursType: item?.hoursTypes || "",
        shipperCode: item?.code || "",
        day1: item?.day1 || "",
        day2: item?.day2 || "",
        day3: item?.day3 || "",
        day4: item?.day4 || "",
        day5: item?.day5 || "",
        day6: item?.day6 || "",
        day7: item?.day7 || "",
      };
      if (item?.hoursType === "L" && receivingHours.length === 0) {
        receivingHours.push(hoursData);
      } else if (item?.hoursType === "Y" && shippingHours.length === 0) {
        shippingHours.push(hoursData);
      }
    });

    // If receivingHours or shippingHours is empty, push default hours object
    if (receivingHours.length === 0) {
      receivingHours.push(getDefaultHoursObject("L"));
    }

    if (shippingHours.length === 0) {
      shippingHours.push(getDefaultHoursObject("Y"));
    }
console.log("finaltest",{ receivingHours: receivingHours[0], shippingHours: shippingHours[0] })
    return { receivingHours: receivingHours[0], shippingHours: shippingHours[0] };
  } catch (e) {
    console.log('Error occurred while mapping', e);
    return false;
  }
};


export const getDefaultShipperContact = () => {

  return {
      id: "",
      email: "",
      fax: "",
      contact_name: "",
      phone_number: "",
      seq: "",
      code: "",
      primary:"N",
      isNew: true

  }
}

// export const mapShipperContactToServer = (item) => {

//   return {
//     IMAddr: item?.IMAddr|| "",
//     addr1: item?.addr1|| "",
//     addr2: item?.addr2|| "",
//     addr3: item?.addr3|| "",
//     agentCode: item?.agentCode|| "",
//     city: item?.city|| "",
//     company: item?.company|| "",
//     country: item?.country|| "",
//     customer: item?.customer|| "",
//     email: item?.email|| "",
//     fax: item?.fax|| "",
//     fname: item.contact_name ? item.contact_name.split(" ")[0] : "",
//     lname: item.contact_name ? item.contact_name.split(" ")[1] : "",
//     locationNumber: item?.locationNumber|| "",
//     mobilePhone: item?.mobilePhone|| "",
//     note1: item?.note1|| "",
//     note2: item?.note2|| "",
//     note3: item?.note3|| "",
//     primaryPhone: item?.phone_number|| "",
//     secondaryPhone: item?.secondaryPhone|| "",
//     seq: item?.seq|| "",
//     shipperCode: item?.shipperCode|| "",
//     state: item?.state|| "",
//     title: item?.title|| "",
//     zip: item?.zip|| "",
//     isPrimary:item?.primary ||"N"

//   }
// }


export const mapShipperContactToServer = (data) => {
  return data
      // .filter(item => item.isNew === false ||item.hasChanged===true)
      .filter(item => item.id!== -1 )
      .map(item => ({

          email: item?.email || "",
          name:item.contact_name || "",
          phone: item?.phone_number || "",
          // seq: item?.seq?item?.seq:-1,
          // isPrimary: item?.primary || "N"
      }));
};

import { useRef, useLayoutEffect, useEffect } from 'react';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';

export default function CustomDateTimePicker(props) {
    const { label, size, className, value, onValueChange, disabled, sx, variant, hasFocus, isArrowKeyDisabled } = props;
    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

      const handleKeyDown = (event) => {
        const { key } = event;
        // Prevent default behavior for specific keys
        if ((key === 'ArrowUp' || key === 'ArrowDown') && isArrowKeyDisabled) {
            event.stopPropagation(); // Stop event propagation
            event.preventDefault(); // Prevent default behavior
        }
      };

      useEffect(() => {
        const inputElement = ref.current;//?.querySelector('input');
        
        if (inputElement) {
          inputElement.addEventListener('keydown', handleKeyDown);
        }
    
        return () => {
          if (inputElement) {
            inputElement.removeEventListener('keydown', handleKeyDown);
          }
        };
      }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                label={label}
                value={moment.utc(value)}
                //defaultValue={moment.utc('2022-04-17T15:30')}
                onChange={(newValue) => onValueChange({ eDate: newValue?.format('YYYY-MM-DD') || "", eTime: newValue?.format('HH:mm') || '' })}
                slotProps={{
                    textField: { size: size, className: className, error: false, disabled: disabled, sx: sx, variant: variant,
                        // readOnly:isReadOnly,
                        // onKeyDown: handleKeyDown,
                    },
                    actionBar: { actions: ['clear', 'accept'] },
                    openPickerIcon: {
                        sx: {
                            fontSize: '1.1rem'
                        }
                    }
                }}
                clearable={true}
                format='MM/DD/YY HH:mm'
                ampm={false}
                reduceAnimations={true}
                disabled={disabled}
                variant={variant}
                inputRef={ref}
            />
        </LocalizationProvider>
    );
}

{/* <TextField
    label="Pickup Date & Time"
    size="small"
    type="datetime-local"
    className="width_100"
    InputLabelProps={{ shrink: true }}
    value={`${pickupDetailState.pickupDate}T${pickupDetailState.pickupTime}` || ''}
    // onChange={(event) => {console.log(event.target.value)}}
    onChange={(event) => {
        let timestamp = event.target.value.toString().split('T')
        setPickupDetailState({ ...pickupDetailState, pickupDate: timestamp[0], pickupTime: timestamp[1] });
        updateBillingControlRef({ ...pickupDetailState, pickupDate: timestamp[0], pickupTime: timestamp[1] })
    }}
    disabled={disableFull}
/> */}
import { useContext } from "react";
import { TextField, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import './Styles.css'
import { useState, useEffect } from "react";
import orderContext from '../orderContext';
import {checkNonMatchingFields} from "../../EdiOrder/components/commonFn"

export default function MasterLoadDetails(props) {
    const { billingControlRef, isTemplateScreen, stopsMasterLoad, disableFull,nonMatchingFields } = props
    const [masterLoadDetailsState, setMasterLoadDetails] = useState(billingControlRef.current.masterLoadDetails)
    const [stopCalcFields, setStopCalcFields] = useState({ totalPieces: 0, totalWeight: 0, finalSeal: ''});
    const { setIsStateChanged } = useContext(orderContext);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        setIsStateChanged(true);
        /** END */
    }

    useEffect(() => {
        let pieces = 0
        let weight = 0
        let seal = ''
        const stops = stopsMasterLoad
        const total = calculateFinalTotals(stops)

        weight = total.finalTotalWeight
        pieces = total.finalTotalPieces
        seal = stops.length > 0 ? stops[stops.length-1]?.doorSeal || '' : ''

        setStopCalcFields({ totalPieces: pieces, totalWeight: weight, finalSeal: seal})
    },[stopsMasterLoad])
    function calculateFinalTotals(stops) {
        if(stops && stops.length > 0){
            return stops.reduce(
                (totals, stop) => {
                    // Add the total weight, ensuring it doesn't go below 0
                    totals.finalTotalWeight += parseFloat(stop.totalWeight) || 0;
                    if (totals.finalTotalWeight < 0) {
                        totals.finalTotalWeight = 0;
                    }

                    // Handle totalPieces as a string and extract numeric part before space
                    let pieces = 0;
                    if (typeof stop.totalPieces === 'string') {
                        pieces = parseFloat(stop.totalPieces.split(' ')[0]) || 0;
                    } else {
                        pieces = stop.totalPieces || 0;
                    }

                    totals.finalTotalPieces += pieces;

                    // Ensure total pieces doesn't go below 0
                    if (totals.finalTotalPieces < 0) {
                        totals.finalTotalPieces = 0;
                    }

                    return totals;
                },
                { finalTotalWeight: 0, finalTotalPieces: 0 }
            )
        } else {
            return { finalTotalWeight: 0, finalTotalPieces: 0 }
        }
    }

    const handlePurchaseOrderChange = (event) => {
        setMasterLoadDetails({
            ...masterLoadDetailsState,
            purchaseOrder: event.target.value
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            masterLoadDetails: {
                ...masterLoadDetailsState,
                purchaseOrder: event.target.value
            }
        }
        handleFieldChanges();
    }

    const handleBillOfLadingChange = (event) => {
        setMasterLoadDetails({
            ...masterLoadDetailsState,
            billOfLading: event.target.value
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            masterLoadDetails: {
                ...masterLoadDetailsState,
                billOfLading: event.target.value
            }
        }
        handleFieldChanges();
    }

    return (
        (isTemplateScreen ? (
            <div className="mainContainer width_100" >
                <Divider
                    textAlign="left"
                    className="dividersty"
                >
                    <Chip label="Master Load Details" size="small" variant="outlined" />
                </Divider>
                <Paper className="paperstyl" variant="outlined">
                    <div style={{ width: '100%' }}>
                        <table style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr>
                                    <td colSpan={2} style={{ width: '100%' }}>
                                        <TextField
                                            label="MBOL"
                                            size="small"
                                            value={masterLoadDetailsState?.billOfLading || ''}
                                            onChange={handleBillOfLadingChange}
                                            disabled={disableFull}
                                            fullWidth
                                            sx={{background: (checkNonMatchingFields(nonMatchingFields,`billingControl.masterLoadDetails.billOfLading`))? "#EDE21196": null }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: '100%' }}>
                                        <TextField
                                            label="MPO#"
                                            size="small"
                                            value={masterLoadDetailsState?.purchaseOrder || ''}
                                            onChange={handlePurchaseOrderChange}
                                            disabled={disableFull}
                                            fullWidth
                                            sx={{background: (checkNonMatchingFields(nonMatchingFields,`billingControl.masterLoadDetails.purchaseOrder`))? "#EDE21196": null }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: '50%', paddingRight: '10px' }}>
                                        <div style={{ width: '100%' }}>
                                            <TextField
                                            label="TOTAL WEIGHT"
                                            size="small"
                                            value={stopCalcFields.totalWeight || ''}
                                            disabled
                                            fullWidth
                                            sx={{background: (checkNonMatchingFields(nonMatchingFields,`stops[${(stopsMasterLoad?.[0]?.stop)-1}].totalWeight`))? "#EDE21196": null }}
                                            />
                                        </div>
                                    </td>
                                    <td style={{ width: '50%', paddingLeft: '10px' }}>
                                        <div style={{ width: '100%' }}>
                                            <TextField
                                            label="TOTAL PIECES"
                                            size="small"
                                            value={stopCalcFields.totalPieces || ''}
                                            disabled
                                            fullWidth
                                            />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ width: '100%' }}>
                                        <TextField
                                            label="FINAL SEAL"
                                            size="small"
                                            value={stopCalcFields.finalSeal || ''}
                                            disabled
                                            fullWidth
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Paper>
            </div>
        ) : (
            <div className="mainContainer width_100" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider style={{ flex: 1, marginRight: '8px' }}
                    >
                        <Chip label="Master Load Details" size="small" variant="outlined" />
                    </Divider >
                </div>
                <Paper className="paperstyl1" variant="outlined" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <div style={{ width: '100%' }}>
                    <table style={{ width: '100%', tableLayout: 'fixed', borderCollapse: 'collapse' }}>
                        <tbody>
                            <tr>
                                <td colSpan={2} style={{ width: '100%' }}>
                                    <TextField
                                        label="MBOL"
                                        size="small"
                                        value={masterLoadDetailsState?.billOfLading || ''}
                                        onChange={handleBillOfLadingChange}
                                        disabled={disableFull}
                                        fullWidth
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ width: '100%' }}>
                                    <TextField
                                        label="MPO#"
                                        size="small"
                                        value={masterLoadDetailsState?.purchaseOrder || ''}
                                        onChange={handlePurchaseOrderChange}
                                        disabled={disableFull}
                                        fullWidth
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: '50%', paddingRight: '10px' }}>
                                    <div style={{ width: '100%' }}>
                                        <TextField
                                        label="TOTAL WEIGHT"
                                        size="small"
                                        value={stopCalcFields.totalWeight || ''}
                                        disabled
                                        fullWidth
                                        />
                                    </div>
                                </td>
                                <td style={{ width: '50%', paddingLeft: '10px' }}>
                                    <div style={{ width: '100%' }}>
                                        <TextField
                                        label="TOTAL PIECES"
                                        size="small"
                                        value={stopCalcFields.totalPieces || ''}
                                        disabled
                                        fullWidth
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ width: '100%' }}>
                                    <TextField
                                        label="FINAL SEAL"
                                        size="small"
                                        value={stopCalcFields.finalSeal || ''}
                                        disabled
                                        fullWidth
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                </Paper>
            </div>
        ))
    );
}
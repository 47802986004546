import React from 'react'
import { H2, LoginWrapper } from '../../components/styledComponents'
import { GoogleLogin } from 'react-google-login'
import logo from '../../assets/logo/eagle-systems-logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { loginReq } from '../../store/actions/userActions'

import { POST, PUT } from '../../utils/auth-axios';

export default function Login() {
    const loggedIn = useSelector(({ userReducer }) => userReducer.loggedIn);
    const dispatch = useDispatch();
    const history = useHistory();
    if (loggedIn) history.push('/orders')

    return (
        <div style={{
            width: '100%',
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <LoginWrapper>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '100px', marginTop: '30px' }}>
                        <img src={logo} width="120px" style={{ marginBottom: '20px' }} alt={'Eagle logo'} />
                        <H2>Load Dispatch System</H2>
                    </div>
                    <div style={{ marginBottom: '100px' }}>

                        <GoogleLogin
                            clientId={'231767053305-h0ps5uct5r4rdi7b6ft62im3thvv1e1s.apps.googleusercontent.com'}
                            //responseType="token id_token refresh_token" //"token id_token"
                            // loginHint={'AJDLj6Ke_Fip_OqN_65SEmso7rRgjGXBHPVermWJQyNt1UwcTKP8lIfRGci2s4LtJ5LPVySk2FkhAQIcn-CsJELzeNAzaCuQ6F9SMxPuTJqay3bYoBmc4jkUzI0mezyfJYuxBthAbcAA'}
                            //grantType='authorization_code'
                            responseType='code'
                            accessType='offline'
                            prompt='consent'
                            onFailure={(e) => console.log(e)}
                            onSuccess={(async (code) => {
                                if (code) {
                                    POST(`/getRefreshToken`, { authCode: code, redirectUri: window.location.origin })
                                        .then(res => {
                                            if (Object.hasOwn(res, 'accessToken')) {
                                                dispatch(loginReq(res.accessToken, res.refreshToken))
                                            }
                                        })
                                }
                            })} //dispatch(loginReq(res.tokenId)))}
                        />
                    </div>
                </div>
            </LoginWrapper>
        </div>
    )
}

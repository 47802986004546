import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { Paper, Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@material-ui/core";
import { TextField, Autocomplete } from "@mui/material";
import "./Styles.css";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import Loader from "../../components/Loader/Lodaer";

import { StyledSceneContent } from "../../components/styledComponents";

import {
  deleteShipper,
  saveShipper,
  saveShipperHours,
  getShipperData,
  searchShipper,
} from "../../store/actions/shipperActions";
// import { getGLCodes } from "../../../store/actions/rateBookActions";
import { getGLCodes } from "../../store/actions/rateBookActions";
import { useHistory } from "react-router-dom";
import { OrdersWrapper } from "../../components/styledComponents";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
//new
import ContactTable from "./components/allShipperForms/ContactTable";
import ShipRecHoursTable from "./components/allShipperForms/ShipRecHoursTable";
import ShipperForm from "./components/allShipperForms/ShipperForm";
import { getShipperContactData } from "../../store/actions/shipperActions";
import { getShipperHoursData } from "../../store/actions/shipperActions";
import { maptoClientContactTable } from "./components/commonFn";
import {
  maptoClientHoursTable,
  mapShipperFormToClient,
  mapShipperFormToServer,
  mapShipperFormDataToClient,
  mapShipperContactToServer,
  convert_hours_data
} from "./components/commonFn";

export default function ShipperConsigneeFormContainer(props) {
  const {
    selectedRow,
    addNewShipperCons,
    shipperTableApiCall,
    formKey,
    setAddNewShipperCons,
    setSelectedRow,
    onClose,
    isOrderScreen,
    shipperSelect,
    shipperOptions,
    stopState,
    newShipper,
    handleAddShipper,
    isEditScreen,
    onEditShipper
  } = props;

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState("");

  const [editShipper, setEditShipper] = useState(isEditScreen?true:false);

  const [isLoading, setIsLoading] = useState(
    params.controlNumber === "new" ? false : true
  );
  const contactRowsRef = useRef([]);
  const hoursRowsRef = useRef([]);
  const shipperFormRef = useRef([]);

  
  const open = Boolean(anchorEl);

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );


// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(() => {
  console.log("selectedRow || addNewShipperCons", selectedRow, addNewShipperCons);
  setIsLoading(true);
  shipperFormRef.current = [];
  contactRowsRef.current = [];
  hoursRowsRef.current = [];

  const fetchData = async () => {
      try {
          if ((selectedRow || addNewShipperCons) && !shipperSelect?.code) {
              console.log("selectedrow", selectedRow);
              if (selectedRow) {
                  const mapShipperForm = mapShipperFormDataToClient(selectedRow._source);
                  setFormValues(mapShipperForm); // Update form values
              }
          } else if (shipperSelect?.code) {
              console.log("orderscreendata1");
              const postParams = [
                  {
                      clause: "AND",
                      field: "locationNumber",
                      operand: "IS",
                      value: ordersLocation?.locationNumber
                  },
                  {
                      clause: "OR",
                      field: "shipperCode",
                      operand: "IS",
                      value: `${shipperSelect?.code}`
                  },
              ];

              const res = await dispatch(getShipperData({
                  postParams,
                  locationNumber: ordersLocation?.locationNumber,
                  query: { page: 1, pageSize: 1 },
                  sortData: { field: "name", sort: "asc" }
              }));

              if (res?.data?.hits?.length > 0) {
                  const mapShipperForm = mapShipperFormDataToClient(res?.data.hits?.[0]?._source);
                  setFormValues(mapShipperForm); // Update form values
              } else {
                  const mapShipperForm = mapShipperFormDataToClient();
                  setFormValues(mapShipperForm); // Update form values
              }
          } else {
              console.log("orderscreendata2");
              const mapShipperForm = mapShipperFormDataToClient();
              setFormValues(mapShipperForm); // Update form values
          }
      } catch (error) {
          console.error("Error fetching data:", error);
      } finally {
          setIsLoading(false);
      }
  };

  fetchData();
}, [formKey, selectedRow, addNewShipperCons, shipperSelect]);
const setFormValues = (mapShipperForm) => {
  if (mapShipperForm) {
      shipperFormRef.current = mapShipperForm.shipperForm;
      contactRowsRef.current = mapShipperForm.shipperContacts || [];
      hoursRowsRef.current = mapShipperForm.shipperHour;
  } else {
      // If mapShipperForm is not provided or invalid, reset form values
      shipperFormRef.current = [];
      contactRowsRef.current = [];
      hoursRowsRef.current = [];
  }
};

  const handleCancelClick = () => {
    // setAddNewShipperCons(false)
    if(isOrderScreen){
      onClose()}else{
        setAddNewShipperCons(false);
  
      }
    // setEditShipper(false);
  
  };

  const handleCancelEdit = () => {
    if(isOrderScreen){
      onClose()}else{
        setAddNewShipperCons(false);
        // Only present Shipper/Consignee Page
        onEditShipper(false);
      }
          
            setEditShipper(false);
          
            
  };
  const checkShipperExistingorNot = async (shipperCode,query = { page: 1, pageSize: 50 }) => {
    try {
        let postParams = [
            {
                clause: "AND",
                field: "locationNumber",
                operand: "IS",
                value: ordersLocation?.locationNumber
            },
            {
                clause: "OR",
                field: "shipperCode",
                operand: "IS",
                value: shipperCode
            }
        ];

        const response = await dispatch(
          getShipperData({
            postParams,
            locationNumber: ordersLocation?.locationNumber,
            query: query,
            sortData:{field:"name",sort:"asc"}
          })
        )
        console.log("response",response)

        if (response?.data?.hits?.length > 0) {
            let confirmAlert = window.confirm(shipperCode + ' already exists. Do you want to proceed?');
            if (!confirmAlert) {
                setSnackOpen(false);
                setSnackContent("");
                return false;
            } else {
                return true;
            }
        } else {
            return true; // No existing shipper found, so it's safe to proceed
        }
    } catch (error) {
        console.log("error",error)
        setSnackContent({ severity: "error", message: "Error checking shipper existence" })
        // console.error("Error checking shipper existence:", error);
        return false;
    }
};

  const handleSave = async (type = "new") => {
   
    console.log("shipperFormRef.current",shipperFormRef.current)
      console.log(contactRowsRef.current)
      console.log( hoursRowsRef.current )
let shipperCode=shipperFormRef.current.code
    if (shipperCode) {
      setSnackOpen(true);
      setSnackContent({
        severity: "info",
        message: `Shipper Data ${type === "new" ? "Saving" : "Updating"} ...`,
      });

      const postShipperFormData = mapShipperFormToServer(shipperFormRef.current);
      const converted_data = convert_hours_data(hoursRowsRef.current.receivingHours, hoursRowsRef.current.shippingHours,shipperCode)
      console.log("contactRowsRef.current",contactRowsRef.current)
      const mapShipContactToServer=mapShipperContactToServer(contactRowsRef.current)
      console.log("postShipperFormData",postShipperFormData)
      console.log("converted_data",converted_data)
      console.log("mapShipContactToServer",mapShipContactToServer)
      
const postObj={...postShipperFormData,...converted_data,contacts:mapShipContactToServer}
if(type==="new"){
  const response = await checkShipperExistingorNot(shipperCode);
  if (response) {
    saveAPICall(postObj, type)
  }
  
}else{
  saveAPICall(postObj, type)
}


    
   
    }

    else {
        setSnackOpen(true)
        if(!shipperCode){

        setSnackContent({ severity: "error", message: "shipperCode is mandatory!" })
        }else{
            setSnackContent({ severity: "error", message: "something went wrong!" })
        }
    }
  };

  const saveAPICall = (postObj, type) => {
    dispatch(saveShipper(ordersLocation.locationNumber, postObj, type))
    .then(
      (res) => {
        console.log("res-shipperForm", res);
        if (
          res?.httpCode === 200 &&
          res?.message === "Record has been updated successfully!"
        ) {
          setSnackOpen(true);
          setSnackContent({
            severity: "success",
            message:
              type === "new"
                ? `New Shipper/consignee has created`
                : ` Shipper/consignee  has updated`,
          });
          
          // shipperTableApiCall();
          // setAddNewShipperCons(false)
          if(isOrderScreen){
            setEditShipper(false)
            handleAddShipper(postObj,contactRowsRef.current)
           onClose()
           
          }else{
            onEditShipper(false);
            setEditShipper(false);
            setAddNewShipperCons(false);
              setTimeout(() => {
                const shipperCode =type === "new"?postObj.code:""
                shipperTableApiCall({ page: 1, pageSize: 50 }, { field: "name", sort: "asc" }, shipperCode);
               
            }, 1000);
          }
         

         
        } else {
          setSnackOpen(true);
          setSnackContent({
            severity: "error",
            message:
              type === "new"
                ? `Error occured while saving Shipper/Consignee`
                : `Shipper field doesnt changed or occured error while updating the Shipper/Consignee`,
          });
        }
      }
    );
  };
  

  const handleDeleteClick = () => {
            let confirmAlert = window.confirm(`Are you sure you want to delete ?`);
            if (confirmAlert) {
              
                const encodedShipperCode=encodeURIComponent(selectedRow?._source?.shipperCode)
                dispatch(deleteShipper(ordersLocation.locationNumber,encodedShipperCode )).then((res) => {
                    if (res?.httpCode === 200 && res?.message === 'Success!') {
                        shipperTableApiCall();
                        setSelectedRow(undefined);
                    } else {
                      shipperTableApiCall();
                        setSelectedRow(undefined);
                    }
                });
            }
  };
  const handleClose = (event) => {
            setSnackOpen(false);
  };

  const handleContactData = (contactData, isAddContact) => {
    console.log("data", contactData, isAddContact);
  };
  
 
  console.log("shipperFormRef",shipperFormRef.current);
  console.log("hoursRowsRef",hoursRowsRef.current);
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          <label style={{ fontSize: "14px", color: "#091DA3" }}>
            New Shipper Consignee
          </label>
        </div>
        {addNewShipperCons || editShipper ? (
          <div>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={<SaveIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                handleSave(editShipper ? "edit" : "new");
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={editShipper ? handleCancelEdit : handleCancelClick}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={
                <DeleteIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={handleDeleteClick}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={<EditIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                setEditShipper(true);
                onEditShipper(true)
              }}
            >
              Edit
            </Button>
            {/* {isOrderScreen?(<Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
                marginLeft:10
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={ handleCancelClick}
            >
              Cancel
            </Button>):null} */}
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div style={editShipper || addNewShipperCons? {}: {pointerEvents: "none", opacity: "0.8" }}>
        <StyledSceneContent>
          <OrdersWrapper >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <ShipperForm shipperFormRef={shipperFormRef} editShipper={editShipper}/>
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div style={{ height: "100%" }}>
                  <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ContactTable
                        contactRowsRef={contactRowsRef}
                        onContactData={handleContactData}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: "auto" }}
                    >
                      <ShipRecHoursTable hoursRowsRef={hoursRowsRef}   />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </OrdersWrapper>
        </StyledSceneContent>
        </div>
      )}
      <Snackbar
        open={snackOpen}
        style={{ marginTop: "5%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackContent.severity}
          sx={{ width: "100%" }}
        >
          {snackContent.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

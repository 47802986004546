import React, { useContext } from "react";
import { TextField, Paper, Autocomplete } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './Styles.css'
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { orderByLookUp } from "../../../store/actions/orderActions";
import { GET, POST } from '../../../utils/axios'
import { useParams } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';

import { AnimatedComponent } from '../../../components/styledComponents'
import orderContext from '../orderContext';
import {checkNonMatchingFields} from "../../EdiOrder/components/commonFn"
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
// import ShipperConsigneeFormContainer from "../../ShipperConsignee/ShipperConsigneeFormContainer";
// import  OrderByTable from "../../Maintenance/OrderBy/components/OrderByTable"
import OrderBY from "../../Maintenance/OrderBy/OrderBy"


export default function OrderBy(props) {

    const { billingControlRef, disableFull, isTemplateScreen,nonMatchingFields,newContactsEdi,isEdiScreen,setFilteredNewContacts} = props
    console.log('Global Disable', disableFull)
    const dispatch = useDispatch();
    let params = useParams();

    // Local State for Select Box of Order By
    // const orderByOptions = useSelector(({ orderReducer }) => orderReducer.orderLookupRes)
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [orderByOptions, setOrderByOptions] = useState([])
    const [orderByCodeSelect, setOrderByCodeSelect] = useState(null)
    const [orderByContactsOptions, setOrderByContactsOptions] = useState([])
    const [orderByContactsSelect, setOrderByContactsSelect] = useState(null)
    const [openOrderByList,setOpenOrderByList]=useState(false)

    const [ordererState, setOrdererState] = useState(billingControlRef.current.orderBy)

    const { setIsStateChanged, setIsEdi210 } = useContext(orderContext);
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('order By fields are changed')
        setIsStateChanged(true);
        setIsEdi210(billingControlRef.current.orderBy.edi210);
        /** END */
    }
    const clearAutocomplete = () => {
        let updatedOrderer = {
            ...ordererState,
            orderBy: '',
            name: '',
            location: '',
            phone: '',
            fax: '',
            billTo: '',
            billToName: '',
            edi204: 'N',
            edi214: 'N',
            edi210: 'N',
            billToStatus: '',
            billingComment: ''
        }
        setOrderByCodeSelect('')
        setOrdererState(updatedOrderer)
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...ordererState,
                ...updatedOrderer
            }
        }
        handleFieldChanges();
    }
    
    const handleOrderByChange = (event, value, reason) => {
        // console.log(value)
        if (reason === "clear") {
            clearAutocomplete();
            return false;
        }

        let updatedOrderer = {
            ...ordererState,
            orderBy: '',
            name: '',
            location: '',
        //    phone: '',
            fax: '',
            billTo: '',
            billToName: '',
            edi204: 'N',
            edi214: 'N',
            edi210: 'N',
            billToStatus: '',
            billingComment: ''
        }
        if (value) {
            updatedOrderer = {
                ...ordererState,
                orderBy: value.code,
                name: value.name,
                location: value.orderByLocation,
            //    phone: value.phone,
                fax: value.fax,
                billTo: value.billTo,
                billToName: value.billToName,
                edi204: value.edi204 ? value.edi204 : 'N',
                edi214: value.edi214 ? value.edi214 : 'N',
                edi210: value.edi210 ? value.edi210 : 'N',
                billToStatus: 'N',
                billingComment: value.billingComment
            }
            setOrderByCodeSelect(value)
            setOrdererState(updatedOrderer)
        }
        else {
            // Reset Order By
            setOrderByCodeSelect('')
            setOrdererState(updatedOrderer)
        }
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...ordererState,
                ...updatedOrderer
            }
        }

        /**
         * 28-09-2023
         * update global context that field has changed
         */
        handleFieldChanges()
        //setIsStateChanged(true);
        /** END */
        // console.log('Updated Orderer Ref', billingControlRef.current)
    }

    const handleOrderByContactsChange = (event, value, reason) => {
        // console.log(value)
        let updatedOrderer = {
            ...ordererState,
            email: '',
            contact: '',
            phone: '',
            orderContactSeq: 0
        }
        if (reason === "clear" || !value) {
            setOrdererState(updatedOrderer)
            billingControlRef.current = {
                ...billingControlRef.current,
                orderBy: {
                    ...ordererState,
                    ...updatedOrderer
                }
            }
            setOrderByContactsSelect("")
            handleFieldChanges();
            return false;
        }

        if (value) {
            updatedOrderer = {
                ...ordererState,
                email: value?.email || "",
                contact: `${value?.fname? value?.fname:""} ${value?.lname? value?.lname:""}`,
                phone: value?.primaryPhone,
                orderContactSeq: value?.seq ||0
            }
           
        }
        setOrderByContactsSelect(value)
        setOrdererState(updatedOrderer)
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...ordererState,
                ...updatedOrderer
            }
        }
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        handleFieldChanges()
        //setIsStateChanged(true);
        /** END */
        // console.log('Updated Orderer Ref', billingControlRef.current)
    }
    const handleReferenceChange = (event) => {
        setOrdererState({
            ...ordererState,
            reference: event.target.value
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...ordererState,
                reference: event.target.value
            }
        }
        handleFieldChanges();
    }

    const handleEmailChange = (event) => {
        setOrdererState({
            ...ordererState,
            email: event.target.value
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...ordererState,
                email: event.target.value
            }
        }
        handleFieldChanges();
    }

    // const handleContactChange = (event) => {
    //     setOrdererState({
    //         ...ordererState,
    //         contact: event.target.value
    //     })
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             contact: event.target.value
    //         }
    //     }
    //     handleFieldChanges();
    // }

    const handlePhoneChange = (event) => {
        setOrdererState({
            ...ordererState,
            phone: event.target.value
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            orderBy: {
                ...ordererState,
                phone: event.target.value
            }
        }
        handleFieldChanges();
    }

    // Lookup handlers
    const orderByLookupTimeout = useRef(null)
    const handleOrderByLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (orderByLookupTimeout.current)
                clearTimeout(orderByLookupTimeout.current)

            const timer = setTimeout(async () => {
                // dispatch(orderByLookUp({ code: "searchVal", value: value }))
                let url = `/orders/getOrderByLookUpData?searchVal=${value}`
                let res = await GET(url)
                if (res && res.status !== 'error') {
                    setOrderByOptions(res.data);
                }
            }, 1000)

            orderByLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }

    }

    const orderByContactsTimeout = useRef(null)

    // Load existing order by
    useEffect(() => {

        if (billingControlRef.current.orderBy.orderBy) {
            console.log(('dispatching orderby', `_${billingControlRef.current.orderBy.orderBy}_`))
            let url = `/orders/getOrderByLookUpData?searchVal=${billingControlRef.current.orderBy.orderBy}`
            console.log('url', url)
            GET(url)
                .then((res) => {
                    console.log('Response', res)
                    if (res.status !== 'error') {
                        setOrderByOptions(res.data)
                        const selected = res.data.filter(item => item.code === billingControlRef.current.orderBy.orderBy)
                        if (selected.length > 0) {
                            setOrderByCodeSelect(selected[0])
                            setOrdererState({
                                ...ordererState,
                                billToName: selected[0].billToName,
                                edi204: selected[0].edi204 ? selected[0].edi204 : 'N',
                                edi214: selected[0].edi214 ? selected[0].edi214 : 'N',
                                edi210: selected[0].edi210 ? selected[0].edi210 : 'N',

                            })
                            setIsEdi210(selected[0].edi210 ? selected[0].edi210 : 'N');
                        }
                    }
                })
                .catch((err) => {
                    console.log('error occurred')
                });
        }
    }, [])

    useEffect(() => {
        let contactCode = orderByCodeSelect?.code;

        if(contactCode) {
            let url = `/contacts/list/code/${contactCode}`

            GET(url)
                .then((res) => {
                    if (res.status !== 'error') {
                        const existingContacts=res.data
                        if(isEdiScreen){
                        const filteredNewContacts = newContactsEdi.filter(
                            newContact => !existingContacts.some(
                                existingContact => existingContact.email.toLowerCase() === newContact.email.toLowerCase()
                            )
                        );
                        const allContacts=[...existingContacts,...filteredNewContacts]
                        setFilteredNewContacts(filteredNewContacts)
                        setOrderByContactsOptions(allContacts);
                    }else{
                        setOrderByContactsOptions(existingContacts);
                    }
                        const selected = res.data.filter(item => item.seq === billingControlRef.current.orderBy.orderContactSeq)
                        if (selected.length > 0) {
                            const data = {
                                email: selected[0].email || "",
                                contact: `${selected[0]?.fname? selected[0]?.fname:""} ${selected[0]?.lname? selected[0]?.lname:""}`,
                                phone: selected[0]?.primaryPhone,
                            }
                            
                            let updatedOrderer = {
                                ...ordererState,
                                ...data
                            }
                            billingControlRef.current = {
                                ...billingControlRef.current,
                                orderBy: {
                                    ...ordererState,
                                    ...data
                                }
                            }
                            setOrdererState(updatedOrderer)
                            //setOrderByContactsSelect(selected)
                        } 
                    }
                })
                .catch((err) => {
                    console.log('error occurred')
                });
        }
    },[orderByCodeSelect])

    const validateDuplicateEntry = () => {
        if (billingControlRef.current.equipmentDetails.unitNum !== '' && billingControlRef.current.orderBy.orderBy !== '') {
            setSnackOpen(true)
            setSnackContent({ severity: "info", message: `Validating duplicate order content!` })
            let postData = [
                { "clause": "AND", "field": "customerNumber", "operand": "IS", "value": billingControlRef.current.orderBy.billTo },
                { "clause": "AND", "field": "unitNumber", "operand": "IS", "value": billingControlRef.current.equipmentDetails.unitNum },
                { "clause": "AND", "field": "locationNumber", "operand": "IS", "value": params.locationNumber }
            ]
            POST(`/elastic/stops/_search?includeRates=true`, postData)
                .then(res => {
                    if (res?.httpCode === 200 && res?.status === "success") {
                        if (res.data.total.value > 0) {
                            setSnackOpen(false)
                            let controlNo = res.data.hits?.[0]?._source.controlNumber || 0;
                            if (Number(params.controlNumber) === Number(controlNo))
                                return false;
                                const confirm = window.confirm(`Duplicate Order - A record with same Unit# & Bill To number already exist. [Active Order: ${controlNo}]`);
                            if (!confirm) {
                                handleOrderByChange('', '', 'clear');
                                setSnackOpen(false)
                            }
                        } else {
                            setSnackOpen(true)
                            setSnackContent({ severity: "success", message: `There is no duplicate order content!` })
                        }
                    }
                }).catch(error => {
                    setSnackOpen(true)
                    setSnackContent({ severity: "error", message: `Failed to validate duplicate order content!` })
                })
        }
    }

    const handleOrderByData=(data)=>{
        console.log("----data-selectedRow",data)
        
        setOpenOrderByList(false)
        handleOrderByChange("",data,"")
       

    }
    return (
    <>
    {isTemplateScreen? (
        <div className="mainContainer width_100">
            <Divider
                textAlign="left"
                className="dividersty"
            >
                <Chip label="Order By" size="small" variant="outlined" />
            </Divider>
            <Paper className="paperstyl" variant="outlined">
                <table className="width_100" >
                    <tbody className="width_100">

                        <tr className="width_100">
                            <td className="inputField width_50" style={{ padding: 0, verticalAlign: 'middle' }}>
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                <Autocomplete
                                    disablePortal
                                    //disableClearable
                                    forcePopupIcon={false}
                                    id="order-by-select"
                                    size="small"
                                    value={orderByCodeSelect}
                                    className="width_100"
                                    // sx={{
                                    //     "& .MuiInputBase-root": { height: "30px" },
                                    //   }}
                                    options={orderByOptions}
                                    getOptionLabel={(option) => option ? `${option.code} - ${option.name}` : ""}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                {`${option.code} - ${option.name} [${option.orderByLocation}, ${option.city}, ${option.state}]`}
                                            </li>
                                        )
                                    }}
                                    isOptionEqualToValue={(option, value) => option.code === value.code || option === ""}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            value={params.code}
                                            label="Order By"
                                            sx={{
                                                input: {
                                                    background: isTemplateScreen ? null : "#F8F0E3",
                                                    // background: (!isTemplateScreen ||(checkNonMatchingFields(nonMatchingFields, "billingControl.orderBy.orderBy")))? "#F8F0E3" : null ,
                                                    fontSize: '14px',
                                                    textTransform: 'uppercase'
                                                }
                                            }}
                                        />
                                    }
                                    onInputChange={handleOrderByLookup}
                                    onChange={handleOrderByChange}
                                    componentsProps={{
                                        paper: {
                                            sx: {
                                                width: 500
                                            }
                                        }
                                    }}
                                    disabled={disableFull}
                                    onBlur={() => validateDuplicateEntry()}
                                />
                                  <SearchIcon sx={{ marginLeft: '8px', cursor: 'pointer' }} onClick={()=>{setOpenOrderByList(true)}} />
                            </div>
                            </td>
                            <td className="width_50" style={{ padding: 0, verticalAlign: 'middle' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '5px', height: '100%' }}>
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, margin: 0 }}
                                        label="204"
                                        size="small"
                                        value={ordererState.edi204 || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, margin: 0 }}
                                        label="214"
                                        size="small"
                                        value={ordererState.edi214 || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, margin: 0 }}
                                        label="210"
                                        size="small"
                                        value={ordererState.edi210 || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                    {ordererState.billingComment === 'COD' || ordererState.location === 'COD' ? (

                                        <TextField
                                            style={{ flex: 1, maxWidth: 60, margin: 0 }}
                                            label=""
                                            size="small"
                                            value={`COD`}
                                            disabled
                                            InputLabelProps={{
                                                style: { color: 'rgb(0 0 0)' },
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#ffff",
                                                    backgroundColor: 'rgb(255 29 10) !important'
                                                },
                                            }}
                                        />

                                    ) : null}
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, marginLeft: 'auto' }} // Pushes "Status" to the right
                                        label="Status"
                                        size="small"
                                        value={ordererState.billToStatus || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Name"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.name || ''}
                                    disabled
                                />
                            </td>
                            <td className="width_50">
                                <TextField
                                    label="Location"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.location || ''}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Bill To"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.billTo || ''}
                                    disabled
                                />
                            </td>
                            <td className="width_50">
                                <Autocomplete
                                    disablePortal
                                    forcePopupIcon={false}
                                    id="order-by-select"
                                    size="small"
                                    value={ordererState}
                                    className="width_100"
                                    options={orderByContactsOptions}
                                    getOptionLabel={(option) => `${option.contact}`}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                {`${option.fname} ${option.lname} [${option.primaryPhone}, ${option.email}]`}
                                            </li>
                                        )
                                    }}
                                    isOptionEqualToValue={(option, value) => `${option?.fname? option?.fname:""} ${option?.lname? option?.lname:""}` === value.contact || option === ""}
                                    renderInput={(params) =>
                                        <TextField
                                            {...params}
                                            label="Contact"
                                            sx={{
                                                input: {
                                                    background: isTemplateScreen ? null : "#F8F0E3",
                                                    fontSize: '14px',
                                                    textTransform: 'uppercase'
                                                }
                                            }}
                                        />
                                    }
                                    onChange={handleOrderByContactsChange}
                                    componentsProps={{
                                        paper: {
                                            sx: {
                                                width: 500
                                            }
                                        }
                                    }}
                                    disabled={disableFull}
                                />                                        
                            </td>
                        </tr>
                        <tr className="width_100">
                                <td className="width_50">
                                    <TextField
                                        label="Bill To Name"
                                        size="small"
                                        className="width_100"
                                        value={ordererState.billToName || ''}
                                        disabled
                                    />
                                </td>
                                <td className="width_50">
                                    <TextField
                                        label="Phone"
                                        size="small"
                                        className="width_100"
                                        value={ordererState.phone || ''}
                                        onChange={handlePhoneChange}
                                        disabled={disableFull}
                                    />
                                </td>
                            </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Reference"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.reference || ''}
                                    inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' }, maxLength: 30 }}
                                    onChange={handleReferenceChange}
                                    sx={{
                                        input: {
                                            // background: (!isTemplateScreen ||(checkNonMatchingFields(nonMatchingFields, "billingControl.orderBy.orderBy")))? "#F8F0E3" : null ,
                                            background: isTemplateScreen ? null : "#F8F0E3",
                                        }
                                    }}
                                    disabled={disableFull}
                                />
                            </td>
                            <td className="width_50">
                                <TextField
                                    className="width_100"
                                    label="Email"
                                    size="small"
                                    value={ordererState.email || ''}
                                    inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' } }}
                                    onChange={handleEmailChange}
                                    disabled={disableFull}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Paper>
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackOpen(false)} >
                <Alert onClose={() => setSnackOpen(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </div> ) : (
        <div className="mainContainer width_100" style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Divider style={{ flex: 1, marginRight: '8px' }} />
                <Chip label="Order By" size="small" variant="outlined" />
                <Divider style={{ flex: 1, marginLeft: '8px' }} />
            </div>
            <Paper className="paperstyl1" variant="outlined" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <table className="width_100" >
                    <tbody className="width_100">
                        <tr className="width_100">
                            <td className="inputField width_50" style={{ padding: 0, verticalAlign: 'middle' }}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <Autocomplete
                                        disablePortal
                                        forcePopupIcon={false}
                                        id="order-by-select"
                                        size="small"
                                        value={orderByCodeSelect}
                                        className="width_100"
                                        options={orderByOptions}
                                        getOptionLabel={(option) => option ? `${option.code} - ${option.name}` : ""}
                                        renderOption={(props, option) => (
                                            <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                {`${option.code} - ${option.name} [${option.orderByLocation}, ${option.city}, ${option.state}]`}
                                            </li>
                                        )}
                                        isOptionEqualToValue={(option, value) => option.code === value.code || option === ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Order By"
                                                sx={{
                                                    input: {
                                                        background: isTemplateScreen ? null : "#F8F0E3",
                                                        fontSize: '14px',
                                                        textTransform: 'uppercase'
                                                    },
                                                    '& .MuiInputBase-root': {
                                                        padding: '0 8px', // Ensure consistent padding
                                                    }
                                                }}
                                            />
                                        )}
                                        onInputChange={handleOrderByLookup}
                                        onChange={handleOrderByChange}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    width: 500
                                                }
                                            }
                                        }}
                                        disabled={disableFull}
                                        onBlur={() => validateDuplicateEntry()}
                                    />
                                    <SearchIcon sx={{ marginLeft: '8px', cursor: 'pointer' }} onClick={()=>{setOpenOrderByList(true)}} />
                                </div>
                            </td>
                            <td className="width_50" style={{ padding: 0, verticalAlign: 'middle' }}>
                                <div style={{ display: 'flex', alignItems: 'flex-end', gap: '5px', height: '100%' }}>
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, margin: 0 }}
                                        label="204"
                                        size="small"
                                        value={ordererState.edi204 || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, margin: 0 }}
                                        label="214"
                                        size="small"
                                        value={ordererState.edi214 || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, margin: 0 }}
                                        label="210"
                                        size="small"
                                        value={ordererState.edi210 || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                    {ordererState.billingComment === 'COD' || ordererState.location === 'COD' ? (
                                        <TextField
                                            style={{ flex: 1, maxWidth: 60, margin: 0 }}
                                            label=""
                                            size="small"
                                            value="COD"
                                            disabled
                                            InputLabelProps={{
                                                style: { color: 'rgb(0 0 0)' }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input.Mui-disabled": {
                                                    WebkitTextFillColor: "#ffff",
                                                    backgroundColor: 'rgb(255 29 10) !important'
                                                },
                                            }}
                                        />
                                    ) : null}
                                    <TextField
                                        style={{ flex: 1, maxWidth: 40, marginLeft: 'auto' }} // Pushes "Status" to the right
                                        label="Status"
                                        size="small"
                                        value={ordererState.billToStatus || ''}
                                        inputProps={{ style: { textTransform: "uppercase", fontSize: '14px', lineHeight: 'normal' } }}
                                        disabled
                                    />
                                </div>
                            </td>
                        </tr>

                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Name"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.name || ''}
                                    disabled
                                />
                            </td>
                            <td className="width_50">
                                <TextField
                                    label="Location"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.location || ''}
                                    disabled
                                />
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Bill To"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.billTo || ''}
                                    disabled
                                />
                            </td>
                            <td className="inputField width_50" style={{ padding: 0, verticalAlign: 'middle' }}>
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <Autocomplete
                                        disablePortal
                                        forcePopupIcon={false}
                                        id="order-by-select"
                                        size="small"
                                        value={ordererState}
                                        className="width_100"
                                        options={orderByContactsOptions}
                                        getOptionLabel={(option) => `${option.contact}`}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                    {`${option.fname} ${option.lname} [${option.primaryPhone}, ${option.email}]`}
                                                </li>
                                            )
                                        }}
                                        isOptionEqualToValue={(option, value) => `${option?.fname? option?.fname:""} ${option?.lname? option?.lname:""}` === value.contact || option === ""}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                label="Contact"
                                                sx={{
                                                    input: {
                                                        background: isTemplateScreen ? null : "#F8F0E3",
                                                        fontSize: '14px',
                                                        textTransform: 'uppercase'
                                                    }
                                                }}
                                            />
                                        }
                                        onChange={handleOrderByContactsChange}
                                        componentsProps={{
                                            paper: {
                                                sx: {
                                                    width: 500
                                                }
                                            }
                                        }}
                                        disabled={disableFull}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Bill To Name"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.billToName || ''}
                                    disabled
                                />
                            </td>
                            <td className="width_50">
                                <TextField
                                    label="Phone"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.phone || ''}
                                    onChange={handlePhoneChange}
                                    disabled={disableFull}
                                />
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Reference"
                                    size="small"
                                    className="width_100"
                                    value={ordererState.reference || ''}
                                    inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' }, maxLength: 30 }}
                                    onChange={handleReferenceChange}
                                    sx={{
                                        input: {
                                            // background: (!isTemplateScreen ||(checkNonMatchingFields(nonMatchingFields, "billingControl.orderBy.orderBy")))? "#F8F0E3" : null ,
                                            background: isTemplateScreen ? null : "#F8F0E3",
                                        }
                                    }}
                                    disabled={disableFull}
                                />
                            </td>
                            <td className="width_50">
                                <TextField
                                    className="width_100"
                                    label="Email"
                                    size="small"
                                    value={ordererState.email || ''}
                                    inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' } }}
                                    onChange={handleEmailChange}
                                    disabled={disableFull}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Paper>
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackOpen(false)} >
                <Alert onClose={() => setSnackOpen(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </div>)

        }
        <Dialog
                               open={openOrderByList}
                               onClose={()=>{setOpenOrderByList(false)}}
                               maxWidth="md"
                               fullWidth
                               PaperProps={{
                                 style: {
                                   minHeight: "400px",
                                 },
                               }}
                             >
                             {/* <DialogTitle>OrderBy Consignee Form</DialogTitle> */}
                               <DialogContent>
                                {/* <OrderByTable/> */}
                                <OrderBY isOrderScreen={true}
                                handleOrderByData={handleOrderByData}
                                />
                               </DialogContent>
                             </Dialog>
                             </>
                         
    );
}
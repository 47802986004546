export const miscReceivables = [
    2026000,
    2035000,
    2175000,
    2177000,
    3500000,
    3510000,
    3530000,
    3550000,
    3580000,
    3585000,
    3630000,
    3645000,
    3655000,
    2039000,
    4150000
]
export const miscPayables = [
    2026000,
    2035000,
    7045000,
    2039000,
    4150000,
    6690000,
    7755000
]
export const FSCCodes = [
    3318000,
    3324000,
    6988000
]
export const getRatesCalculations = (rates, excludeFSC=false) => {

    // Calculate totals
    let totalRevenue = 0;
    let totalExpense = 0;
    let totalMiscRevenue = 0;
    let totalMiscExpense = 0;
    let receivables = [];
    let payables = []

    rates.forEach(rate => {

        if (excludeFSC && FSCCodes.includes(rate.glCode)) {
            return;
        }
        const amount = parseFloat(rate.amount);
        if (rate.payableReceivable === 'R') {
            totalRevenue += amount;
            totalMiscRevenue += miscReceivables.includes(rate.glCode)?amount:0;
            receivables.push({
                receivable: rate.glCode, 
                description: rate.description, 
                amount: amount.toFixed(2)
            });
        } else if (rate.payableReceivable === 'P') {
            totalExpense += amount;
            totalMiscExpense += miscPayables.includes(rate.glCode)?amount:0;
            payables.push({ 
                payable: rate.glCode,
                description: rate.description, 
                truck: rate.truck,  
                amount: amount.toFixed(2)
            });
        }
    });

    const totRevenueAftDeductions = totalRevenue - totalMiscRevenue;
    const totalNet = totalRevenue - totalExpense;
    const totalPercent = totalRevenue !== 0 ? (totalExpense / totalRevenue) * 100 : 0;
    const ptNet = totalNet - totalMiscRevenue;
    const ptPercent = totalRevenue !== 0 ? (totalExpense / totRevenueAftDeductions) * 100 : 0;

    return {totalRevenue, totalExpense, totalMiscRevenue, totalMiscExpense, totalNet, totalPercent, ptNet, ptPercent, receivables, payables}; 
}

export const billingReportProperties = ( source, index ) => {
    // Calculate totals
    const {totalRevenue, totalExpense, totalNet, totalPercent, ptNet, ptPercent, receivables, payables, totalMiscRevenue, totalMiscExpense,} = getRatesCalculations(source.rates);
    return {
        id: index,
        controlNumber: source.controlNumber,
        totalLoads: source.totalStops,
        customerName: source.customerName,
        billToCode: source.billTo,
        shipperAgentCode: source.shipperAgentCode,
        billingDate: source.billingDate,
        reference: source.reference,
        unitNumber: source.unit,
        io: source.io,
        apptDate: source.apptDate,
        PULoc: source.puLocation,
        dispositionLoc: source.dispLocation,
        returnDate: source.returnDate,
        shipperName: source.shipper,
        shipperAddress: [source.shipperCity, source?.shipperState].filter(Boolean).join(', '), //`${source.shipperCity +','+ source.shipperState}`,
        consigneeName: source.consignee,
        consigneeAddress: [source.consigneeCity, source?.consigneeState].filter(Boolean).join(', '), //`${source.consigneeCity +','+ source.consigneeState}`,
        totalRevenue: totalRevenue.toFixed(2),
        totalNet: totalNet.toFixed(2),
        totalPercent: totalPercent.toFixed(2),
        totalExpense: totalExpense.toFixed(2),
        ptNet: ptNet.toFixed(2),
        ptPercent: ptPercent.toFixed(2),
        receivables: receivables, 
        payables: payables,
        totalMiscRevenue: totalMiscRevenue.toFixed(2),
        totalMiscExpense: totalMiscExpense.toFixed(2)
    };
}
import { useEffect, useState } from 'react';
// import material components
import {
    Paper, Tabs, Tab, Box, Grid, Checkbox, FormControlLabel, Divider, Chip,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Backdrop,
    CircularProgress
} from "@mui/material";

// import checkbox fields from json files
import optionsJson from './options.json';
import defaultOptionsJson from './defaultOptions.json';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ mt: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CopyOrderModal = (props) => {
    // props from parent component
    const { openCO, closeCO, currentOrderData, handleModalSubmitButton, isCopyOrderLoading, isTemplateCopyOrder } = props;

    const [value, setValue] = useState(0);
    const [checked, setChecked] = useState([]);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [copyStopsStatus, setCopyStopsStatus] = useState(true);
    const [copies, setCopies] = useState(0);


    // Resetting stops to null on page load
    const initializingStopsData = () => {
        const stopData = currentOrderData?.stops.map((e) => {
            const newStopSet = { ...e }
            newStopSet.apptDate = '';
            newStopSet.apptTime = '';
            newStopSet.commodity = '';
            newStopSet.weight = '';
            newStopSet.shipperBillOfLadding = '';
            newStopSet.pieces = '';
            newStopSet.pickupNumber = '';
            newStopSet.doorSeal = '';
            newStopSet.spotDate = '';
            newStopSet.spotTime = '';
            newStopSet.arriveDate = '';
            newStopSet.arriveTime = '';
            newStopSet.unloadDate = '';
            newStopSet.unloadTime = '';
            newStopSet.departDate = '';
            newStopSet.departTime = '';
            newStopSet.availDate = '';
            newStopSet.availTime = '';
            newStopSet.etaDate = '';
            newStopSet.etaTime = '';
            newStopSet.driverNotes = '';
            newStopSet.driverNotes2 = '';
            newStopSet.arriveTruck = '';
            newStopSet.arriveTruckStatus = '';
            newStopSet.arriveDriver = '';
            newStopSet.departTruck = '';
            newStopSet.departTruckStatus = '';
            newStopSet.departDriver = '';

            return newStopSet;
        });
        return stopData;
    }
    // Resetting elected order field values to null 
    const [newOrderData, setNewOrderData] = useState({
        ...currentOrderData,
        driverNotes: { driverNotes: '', driverNotes2: '' },
        orderBy: { ...currentOrderData?.orderBy, billingReference: '', status: 'N', billingDate: '' },
        equipmentDetails: { ...currentOrderData?.equipmentDetails, equipSize: '', vessel: '', voyageNumber: '', billingBooking: '', dispositionCDestinationPort: '', unitNumber: '', chassisNumber: '', releaseNumber: '', realeaseNumber: '', secondaryUnit: '', secondaryChassis: '', chassisRelease: '', chassisProvider: '' },
        pickup: { ...currentOrderData?.pickup, cuttOffDate: '', cutOffTime: '', pickupDate: '', pickupTime: '', returnDate: '', returnTime: '', notifyDate: '', notifyTime: '', pickDate: '', poruDate: '', poruTime: '' },
        stops: initializingStopsData(),
        ratesCharges: []
    })

    const alloptions = (copyStopsStatus === true) ? [
        ...optionsJson.copyOrderOptionSet.billingControl,
        ...optionsJson.copyOrderOptionSet.stops,
        ...optionsJson.copyOrderOptionSet.rates
    ] : [
        ...optionsJson.copyOrderOptionSet.billingControl,
        ...optionsJson.copyOrderOptionSet.rates
    ]

    // handle copy all checkbox
    const selectAll = (event, state) => {
        setIsAllChecked(state);
        let checkItems = [];
        Object.keys(optionsJson.copyOrderOptionSet).map((val, ind) => {
            if (val === 'stops' && copyStopsStatus === false) {
            } else {
                optionsJson.copyOrderOptionSet[val].map(e => {
                    if (event.target.checked)
                        checkItems.push(e.fieldName);
                    handleCheckedOption(val, e.fieldName, event)
                })
            }
        })
        setChecked(checkItems);
    }

    // handle single checkbox
    const handleChange = (event, checkedFieldName) => {
        if (event.target.checked) {
            setChecked([...checked, checkedFieldName]);
        } else {
            setChecked(checked.filter(e => e != checkedFieldName));
        }
    };

    // monitoring check-uncheck status of all checkboxes for copy all checkbox
    useEffect(() => {
        if (alloptions.length === checked.length) {
            setIsAllChecked(true);
        } else {
            setIsAllChecked(false);
        }
    }, [checked])

    useEffect(() => {
        selectAll({target:{checked:true}}, true);
    }, [isTemplateCopyOrder])


    const handleCheckedOption = (type, fieldName, event) => {
        switch (type) {
            case 'billingControl':
                if (['billingReference'].includes(fieldName)) {
                    handleOrderbyData(fieldName, event);
                } else if (['cuttOffDate', 'cutOffTime', 'pickDate'].includes(fieldName)) {
                    handlePickupData(fieldName, event);
                } else if (['bc_driverNotes', 'bc_driverNotes2'].includes(fieldName)) {
                    fieldName = (fieldName === 'bc_driverNotes') ? 'driverNotes' : 'driverNotes2';
                    handleDriverNotesData(fieldName, event)
                } else {
                    handleEquipmentData(fieldName, event);
                }
                break;
            case 'stops':
                handleStopsData(fieldName, event);
                break;
            case 'rates':
                handleRatesData(fieldName, event);
                break;
            default:
                break;
        }
    }
    const handleOrderbyData = (fieldName, event) => {
        let updateOrderContent = newOrderData;
        if (event.target.checked) {
            updateOrderContent.orderBy[fieldName] = currentOrderData.orderBy[fieldName]
        } else {
            updateOrderContent.orderBy[fieldName] = ''
        }
        setNewOrderData(updateOrderContent);
    }
    const handleEquipmentData = (fieldName, event) => {
        let updateOrderContent = newOrderData;
        if (event.target.checked) {
            updateOrderContent.equipmentDetails[fieldName] = currentOrderData.equipmentDetails[fieldName]
        } else {
            updateOrderContent.equipmentDetails[fieldName] = ''
        }
        setNewOrderData(updateOrderContent);
    }
    const handleDriverNotesData = (fieldName, event) => {
        let updateOrderContent = newOrderData;
        if (event.target.checked) {
            updateOrderContent.driverNotes[fieldName] = currentOrderData.driverNotes[fieldName]
        } else {
            updateOrderContent.driverNotes[fieldName] = ''
        }
        setNewOrderData(updateOrderContent);
    }
    const handlePickupData = (fieldName, event) => {
        let updateOrderContent = newOrderData;
        if (event.target.checked) {
            updateOrderContent.pickup[fieldName] = currentOrderData.pickup[fieldName]
        } else {
            updateOrderContent.pickup[fieldName] = ''
        }
        setNewOrderData(updateOrderContent);
    }
    const handleStopsData = (fieldName, event) => {
        let updateOrderContent = newOrderData;
        if (copyStopsStatus) {
            for (let i = 0; i < updateOrderContent.stops.length; i++) {
                if (event.target.checked) {
                    updateOrderContent.stops[i][fieldName] = currentOrderData.stops[i][fieldName]
                } else {
                    updateOrderContent.stops[i][fieldName] = ''
                }
            }
        } else {
            updateOrderContent.stops = []
        }

        setNewOrderData(updateOrderContent);
    }
    const handleStopsBulkData = (event) => {
        let updateOrderContent = newOrderData;
        if (event.target.checked) {
            updateOrderContent.stops = initializingStopsData()
        } else {
            const stopFields = optionsJson.copyOrderOptionSet.stops.map(e => e.fieldName);
            setChecked(checked.filter(e => !stopFields.includes(e)));

            updateOrderContent.stops = []
        }

        setNewOrderData(updateOrderContent);
    }
    const handleRatesData = (fieldName, event) => {
        let updateOrderContent = newOrderData;
        if (event.target.checked) {
            updateOrderContent.ratesCharges = currentOrderData.ratesCharges.length > 0 && currentOrderData.ratesCharges.map((e) => {
                return {
                    ...e,
                    processedDate: '',
                    truckNumber: '',
                    transmitted: 'N'
                }
            })
        } else {
            updateOrderContent.ratesCharges = []
        }

        setNewOrderData(updateOrderContent);
    }

    const handleSubmit = () => {
        handleModalSubmitButton(copies, newOrderData);
    }
    const formatLabel = (val) => {
        let label = "NA";
        switch (val) {
            case 'billingControl':
                label = 'Billing Control';
                break;
            case 'stops':
                label = 'Stops';
                break;
            case 'rates':
                label = 'Rates';
                break;
            default:
                label = "";
                break;
        }
        return label;
    }
    return (
        <div className="mainContainer" >
            <Dialog
                fullWidth={true}
                maxWidth={`sm`}
                open={openCO} onClose={closeCO}>
                <DialogTitle sx={{ textAlign: 'center', color: '#fff', backgroundColor: '#0911A7' }}>Copy Orders Options</DialogTitle>
                <DialogContent>
                    <Box >
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={(e, newVal) => setValue(newVal)} aria-label="basic tabs example">
                                <Tab label="Elected Fields" {...a11yProps(0)} />
                                <Tab label="Default Fields" {...a11yProps(1)} />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <FormControlLabel
                                    value="0"
                                    control={<Checkbox checked={isAllChecked} onChange={selectAll} />}
                                    label="Copy All"
                                    labelPlacement="start"
                                />
                                <TextField
                                    type='number'
                                    inputProps={{ maxLength: 3 }}
                                    label="Copies (Max: 25)"
                                    id="outlined-size-small"
                                    defaultValue={copies}
                                    onChange={(e) => (e.target.value <= 25) ? setCopies(e.target.value) : 0}
                                    value={copies}
                                    size="small"
                                />
                            </Box>
                            <Box sx={{}}>
                                {/* <Divider textAlign="left" className="dividersty">
                                    <Chip label="Check Items to copy" size="small" sx={{ backgroundColor: '#fff', fontWeight: 700 }} />
                                </Divider> */}
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    open={isCopyOrderLoading}
                                >
                                    <CircularProgress color="inherit" />
                                </Backdrop>

                                <Paper elevation={2} sx={{ p: 0 }}>

                                    {Object.keys(optionsJson.copyOrderOptionSet).map((val, ind) =>
                                    (
                                        <Box sx={{ p: 0 }} key={ind}>
                                            <Divider textAlign="left" className="dividersty">
                                                <Chip label={formatLabel(val)} size="small" sx={{ backgroundColor: '#fff', fontWeight: 700 }} />
                                            </Divider>
                                            <Paper elevation={2}>
                                                {val === "stops" ? (
                                                    <Grid container sx={{ m: 1 }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox disabled={true} checked={copyStopsStatus} onChange={(e) => { setCopyStopsStatus(e.target.checked); handleStopsBulkData(e); }} name={`copyStops`} />
                                                            }
                                                            label={`Copy Stops`}
                                                        />
                                                    </Grid>
                                                ) : null}


                                                <Grid container sx={{ m: 1 }}>

                                                    {
                                                        optionsJson.copyOrderOptionSet[val].map((child_val, child_ind) => {
                                                            return (
                                                                <Grid item xs={6} key={child_ind}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                disabled={(!copyStopsStatus && val === 'stops') ? true : false}
                                                                                checked={checked.includes(child_val.fieldName)}
                                                                                onChange={(e) => {
                                                                                    handleChange(e, child_val.fieldName);
                                                                                    handleCheckedOption(val, child_val.fieldName, e)
                                                                                }}
                                                                                name={child_val.fieldName} />
                                                                        }
                                                                        label={child_val.label}
                                                                    />
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Paper>
                                        </Box>
                                    )
                                    )}
                                </Paper>

                            </Box>
                        </TabPanel>
                        <TabPanel value={value} index={1} >
                            <Paper elevation={2}>
                                <Grid container sx={{ m: 1 }}>
                                    {defaultOptionsJson.defaultFields.map((d_val, d_ind) => {
                                        return (
                                            <Grid item xs={6} key={d_ind}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox checked={true} disabled />
                                                    }
                                                    label={d_val.label}
                                                />
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Paper>
                        </TabPanel>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeCO} variant="outlined" size="small" color="primary">Cancel</Button>
                    <Button onClick={handleSubmit} disabled={(!isCopyOrderLoading) ? false : true} variant="contained" size="small" color="primary">Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CopyOrderModal;
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { TextField, Paper, Autocomplete } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { pickupPointLookup } from "../../../store/actions/orderActions";


export default function PickupPointAutoComplete(props) {
    const { valueChange, existingValue, hasFocus } = props;
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState({ code: existingValue, name: '' });

    const dispatch = useDispatch();
    const pickupPointLookupTimeout = useRef(null)

    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const handleLookupAPICall = (event, value, reason) => {
        if (value && reason !== 'reset') {
            if (pickupPointLookupTimeout.current)
                clearTimeout(pickupPointLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(pickupPointLookup({ code: "code", value: value.trimStart() })).then(res => {
                    setAutocompleteOptions(res)
                })
            }, 1000)

            pickupPointLookupTimeout.current = timer;
        } else {
            console.log('Reason is reset not making an api call')
        }
    }

    const handleInputChange = (event, value) => {
        setSelectedValue(value);
        if (value !== null) {
            valueChange(value.code)
        } else {
            valueChange(null)
        }
    }

    return (
        <Autocomplete
            id="pickup-point-select"
            size="small"
            value={selectedValue}
            options={autocompleteOptions}
            getOptionLabel={(option) => `${option.code} ${option.name}`}
            className="width_100"
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                    </li>
                )
            }}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            renderInput={(params) => <TextField {...params} variant="standard" value={params.code} inputRef={ref} />}
            onInputChange={handleLookupAPICall}
            onChange={handleInputChange}
            componentsProps={{
                paper: {
                    sx: {
                        width: 500
                    }
                }
            }}
        />
    )
}
import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { colors } from '../../theme/colors';
const useStyles = makeStyles( {
    root: {},
    description: {
        fontSize: '14px',
        overflowWrap: 'anywhere'
    },
    label: {
        fontSize: '11px',
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.50)'
    },
    option: {
        padding: '10px',
        textTransform: 'capitalize'
    },
    hover: {
        fontWeight: 'bold',
        "&:hover": {
            cursor: 'pointer'
        },
        "& span": {
            color: colors.blue.normal,
        }
    },
    labelColor: {
        color: 'white',
    }
});
export const Option = (props) => {
    const classes = useStyles();
    const { size = 4, description = '', label = '', onClick, labelColor = '', phone} = props;
    const renderLabel = () => {
        if (phone) {
            return <a href={`tel:${phone}`}>{phone}</a>
        }
        return label || '-'
    }
    
    function Description(){
        if (description === '') return null
        return (<span className={`${classes.label} ${labelColor ?  classes.labelColor : ''}`}>{description}<br/></span>)
    }

    return (
        <Grid item xs={size} onClick={onClick ? onClick : undefined} className={`${classes.option} ${onClick ? classes.hover : ''}`}>
            <Description />
            <span className={classes.description}>{renderLabel()}</span>
        </Grid>
    )
}


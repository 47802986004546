/* eslint-disable no-underscore-dangle */
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Stack,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  TextField,
} from "@mui/material";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { TraceWrapper } from "../../components/styledComponents";
import {
  DataGridPro,
  useGridApiRef,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { getTruckDriverData} from "../../store/actions/truckdriverActions";
import TruckDriverDetailEditPanel from "./component/TruckDriverDetailEditPanel";
import EditIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import { highlightDate } from './component/commonFn';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';



export default function TruckDriver({ navigation }) {
  const dispatch = useDispatch();
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const [filteredRows, setFilteredRows] = useState([]);
  
  // const [hideSort99999, setHideSort99999] = useState(false);
  const [showAvailableTruck, setShowAvailableTruck] = useState(false);
  const [showAvailableDriver,setShowAvailableDriver]=useState(false)
  const [searchTruck, setSearchTruck] = useState("");

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState("");
  const [openDetailEditPanel, setOpenDetailEditPanel] = useState(false)
  const [detailEditPanelData, setDetailEditPanelData] = useState("")
  // Bulk edit save
  const apiRef = useGridApiRef();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (typeof ordersLocation?.locationNumber !== "undefined") {
     
      fetchDataFromServer();
    }
  }, [ordersLocation]);

  const fetchDataFromServer = () => {
    //setShowActiveTruck(true)
    setShowAvailableTruck(false)
    setShowAvailableDriver(false)
    setSearchTruck("")
    setIsLoading(true);
    setSnackOpen(true);
    setSnackContent({
      severity: "info",
      message: "Reloading data from server...!",
    });
    dispatch(
      getTruckDriverData(
        ordersLocation.locationNumber,
      )
    ).then((res) => {
      setIsLoading(false);
      setRows(res);
      //setFilteredRows(res);
      setSnackOpen(false);
      filterRows(res)
    });
  };
const handleDetailEditRow =(params)=>{
    setOpenDetailEditPanel(true)
    setDetailEditPanelData(params?.row)
}
const Circle = styled('div')(({ theme, color }) => ({
  width: 20,
  height: 20,
  borderRadius: '50%',
  backgroundColor: color,
  display: 'inline-block',
}));
  const tableColumns = [
    {
        field: "edit",
        headerName: "Action",
        headerClassName: "MuiDataGrid-columnHeaders",
        flex: 1,
        renderCell: (params) => (
              <>
                  <IconButton
                    onClick={() => handleDetailEditRow(params)}
                    aria-label="edit"
                    size="small"
                    disabled={params.row.truck ? false : true}
                  >
                    <EditIcon />
                  </IconButton>
                
              </>
            )
      },
    {
      field: "truck",
      headerName: "Truck",
      editable: false,
      minWidth: 90,
      flex: 1
    },
    {
      field: "licence",
      headerName: "Licence",
      editable: false,
      minWidth: 90,
      flex: 1
    },
    {
      field: "sort",
      headerName: "Sort",
      editable: false,
      minWidth: 90,
      flex: 1
    },
    // {
    //   field: "truck_status",
    //   headerName: "Truck Status",
    //   editable: true,
    //   minWidth: 90,
    //   flex: 1
    // },
    {
      field: 'truck_status',
      headerName: 'Truck Status',
      editable:  false,
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        
        let color;
        if (params.value === 'green') {
          color = 'green';
        } else if (params.value === 'red') {
          color = 'red';
        }else if (params.value === 'yellow') {
          color = ' #f29407';
         
        } else if(params.value ==="none"){
          color = 'transparent';  // For Driver Rows only 
        } else {
          color = 'gray'; // Default color if status is neither green nor red
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
        <Circle color={color} />
        </div>)
      },
    },
    // {
    //   field: "truck_status_des",
    //   headerName: "Truck Status Des",
    //   editable:  false,
    //   minWidth: 190,
    //   flex: 2
    // },
    {
      field: "truck_driver_label",
      headerName: "Driver Name",
      editable:  false,
      minWidth: 90,
      flex: 1
    },
    {
      field: "active",
      headerName: "Active",
      editable:  false,
      minWidth: 90,
      flex: 1
    },
    {
      field: "available",
      headerName: "Available",
      editable:  false,
      minWidth: 90,
      flex: 1
    },
    // {
    //   field: "equipment_termination",
    //   headerName: "Equip Term",
    //   editable: true,
    //   renderCell: (params) => highlightDate(params.value),
    // },
    {
      field: "registration_exp",
      headerName: "RegExp",
      editable:  false,
      renderCell: (params) => highlightDate(params.value),
      minWidth: 90,
      flex: 1
    },
    // {
    //   field: "ex_service_date",
    //   headerName: "Ex Ser",
    //   editable: true,
    //   renderCell: (params) => highlightDate(params.value),
    // },
    {
      field: "fhwa_insp_exp",
      headerName: "FWHA Exp",
      editable:  false,
      renderCell: (params) => highlightDate(params.value),
      minWidth: 90,
      flex: 1
    },
    {
      field: "vendor_name",
      headerName: "Description",
      editable:  false,
      minWidth: 90,
      flex: 1
    },
    {
      field: "vendor_code",
      headerName: "Vendor",
      editable:  false,
      minWidth: 90,
      flex: 1
    },
    // {
    //   field: "owner",
    //   headerName: "Owner",
    //   editable: true,
    // },
    {
      field: "comment",
      headerName: "Comment",
      editable:  false,
      minWidth: 90,
      flex: 1
    },
    // {
    //   field: "driver_status",
    //   headerName: "Driver Status",
    //   editable: true,
    //   minWidth: 90,
    //   flex: 1
    // },
    {
      field: 'driver_status',
      headerName: 'Driver Status',
      editable:  false,
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        let color;
        if (params.value === 'green') {
          color = 'green';
        } else if (params.value === 'red') {
          color = 'red';
        } else if (params.value === 'yellow') {
          color = '#f29407';
        }else {
          color = 'gray'; // Default color if status is neither green nor red
        }
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
          <Circle color={color} />
          </div>)
      },
    },
    // {
    //   field: "driver_status_description",
    //   headerName: "Driver Status Description",
    //   editable:  false,
    //   minWidth: 190,
    //   flex: 2
    // },
   
    {
        field: "driver_number",
        headerName: "Driver#",
        editable:  false,
        minWidth: 90,
        flex: 1
      },
      {
        field: "driver_name",
        headerName: "Name",
        editable:  false,
        minWidth: 90,
        flex: 1
      },
      {
        field: "license_exp",
        headerName: "License Exp",
        editable:  false,
        renderCell: (params) => highlightDate(params.value),
        minWidth: 90,
        flex: 1
      },
      {
        field: "medical_exam_exp",
        headerName: "Medical Exam Exp",
        editable:  false,
        renderCell: (params) => highlightDate(params.value),
        minWidth: 90,
        flex: 1
      },
      // {
      //   field: "annual_review_exp",
      //   headerName: "Annual Review Exp",
      //   editable:  false,
      //   renderCell: (params) => highlightDate(params.value),
      //   minWidth: 90,
      //   flex: 1
      // },
      {
        field: "termination_date",
        headerName: "Termination",
        editable:  false,
        renderCell: (params) => highlightDate(params.value,false,true),
        minWidth: 90,
        flex: 1
      },
  ];
  const filterRows = (data="") => {
  
    let allrows=data?data:rows
    let filteredData = allrows.filter((row) => {
      
      if (row.active && row.active !== "Y") {
        return false;
      }
      // if (hideSort99999 && row.sort === "99999") {
      //   return false;
      // }
      if (showAvailableTruck && (row.available && row.available !== "Y")) {
        return false;
      }
      if (showAvailableDriver && !(row.truck === "" && row.driver_status !== "red")) {
        return false;
      }
     
     
      if (searchTruck) {
        // Convert searchTruck to a number for comparison
        const searchTruckNum = Number(searchTruck);
        if (isNaN(searchTruckNum) || row.truck !== searchTruckNum) {
          return false;
        }
      }
      return true;
    });
    setFilteredRows(filteredData);
  };

  // Effect to filter rows whenever checkbox state changes
  useEffect(() => {
    filterRows();
  }, [ showAvailableTruck,showAvailableDriver, searchTruck]);

//   const getDetailPanelHeight = React.useCallback(() => 400, []);
  const handleCloseModal = () => {
    setOpenDetailEditPanel(null);
    setDetailEditPanelData("")
  };
  const handleClear = () => {
    setSearchTruck('');
  };
  return (
    <TraceWrapper>
      <Stack spacing={2}>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Stack
            direction="column"
            spacing={2}
            boxShadow={2}
            padding={3}
            height={80}
          >
            <Stack direction="row" spacing={2}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  flexDirection: "columns",
                  color: "#0911A7",
                }}
              >
                TRUCK
              </Typography>
              <TextField
                label="Search Truck"
                variant="outlined"
                size="small"
                value={searchTruck}
                onChange={(e) => setSearchTruck(e.target.value)}
                sx={{ marginLeft: 2 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTruck && (
                        <IconButton onClick={handleClear} edge="end">
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={showActiveTruck}
                    onChange={(e) => setShowActiveTruck(e.target.checked)}
                    color="primary"
                  />
                }
                label="Only Show Active Truck"
              /> */}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={hideSort99999}
                    onChange={(e) => setHideSort99999(e.target.checked)}
                    color="primary"
                  />
                }
                label="Hide 99999 Sort Order"
              /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAvailableTruck}
                    onChange={(e) => setShowAvailableTruck(e.target.checked)}
                    color="primary"
                  />
                }
                label="Only Show Available Truck"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showAvailableDriver}
                    onChange={(e) => setShowAvailableDriver(e.target.checked)}
                    color="primary"
                  />
                }
                label="Only Show Available Driver"
              />
            </Stack>
          </Stack>
          <Stack
            marginTop={5}
            sx={{
              height: window.innerHeight - Number(250),
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "rgba(11, 67, 135, 1)",
                color: "rgba(255, 255, 255, 255)",
              },
              "& .non-editable": {
                backgroundColor: "#69696929 !important",
              },
              "& .MuiDataGrid-menuIconButton": {
                color: "rgba(255, 255, 255, 255)",
              },
              // '& .MuiIconButton-root': {
              //     color: "rgba(255, 255, 255, 255)"
              // },
              "& .highLightWithRed > .MuiDataGrid-cellContent": {
                color: "red",
                fontWeight: "bold",
              },
              "& .highLightWithYellow > .MuiDataGrid-cellContent": {
                backgroundColor: "yellow",
                color: "rgba(0, 0, 0, 0.87)",
              },
              "& .MuiDataGrid-toolbarContainer": {
                marginTop: "-82px",
                marginBottom: "35px",
                marginLeft: "100px",
              },
            }}
          >
            <DataGridPro
              hideFooter={true}
              hideFooterRowCount={false}
              rows={filteredRows}
              columns={tableColumns}
              loading={isLoading || isSaving}
              
            />
          </Stack>
        </Box>
      </Stack>

      <Dialog
        open={openDetailEditPanel}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            minHeight: "400px",
          },
        }}
      >
        <DialogTitle></DialogTitle>
        <DialogContent>
          <TruckDriverDetailEditPanel
            onClose={handleCloseModal}
            truckDriverData={detailEditPanelData}
            // handleSaveForm={handleSaveForm}
            fetchDataFromServer={fetchDataFromServer}
            rows={rows}
          />
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackOpen}
        style={{ marginTop: "5%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setSnackOpen(false)}
      >
        <Alert
          onClose={() => setSnackOpen(false)}
          severity={snackContent.severity}
          sx={{ width: "100%" }}
        >
          {snackContent.message}
        </Alert>
      </Snackbar>
    </TraceWrapper>
  );
}

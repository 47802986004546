import { useEffect, useState } from 'react';
import {
    gridPageCountSelector,
    GridPagination,
    useGridApiContext,
    useGridSelector,
    gridPageSizeSelector,
    gridFilteredTopLevelRowCountSelector,
    useGridRootProps
} from '@mui/x-data-grid-pro';
import MuiPagination from '@mui/material/Pagination';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const getPageCount = (rowCount, pageSize) => {
    if (pageSize > 0 && rowCount > 0) {
        return Math.ceil(rowCount / pageSize);
    }

    return 0;
};

function Pagination({ page, onPageChange, className }) {
    const apiRef = useGridApiContext();
    const pageSize = useGridSelector(apiRef, gridPageSizeSelector);
    const visibleTopLevelRowCount = useGridSelector(apiRef, gridFilteredTopLevelRowCountSelector);
    const rootProps = useGridRootProps();
    const pageCount = getPageCount(rootProps.rowCount ?? visibleTopLevelRowCount, pageSize);
    const [customPageNo, setCustomPageNo] = useState(1);

    return (
        // <MuiPagination
        //     color="primary"
        //     className={className}
        //     count={pageCount}
        //     page={page + 1}
        //     onChange={(event, newPage) => {
        //         onPageChange(event, newPage - 1);
        //     }}
        // />
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '14ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id="filled-start-adornment"
                    InputProps={{
                        endAdornment: <InputAdornment position="end" sx={{ fontSize: '14px' }}>of {pageCount}</InputAdornment>,
                    }}
                    size='small'
                    value={customPageNo}
                    onBlur={(event) => {
                        if (Number(event.target.value) > pageCount) {
                            alert(`Page Number should be less than: ${pageCount}`);
                        } else {
                            onPageChange(event, event.target.value - 1);
                        }
                    }}
                    onChange={(event) => {
                        if (Number(event.target.value) <= pageCount) {
                            setCustomPageNo(event.target.value);
                        }
                    }}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            e.target.blur();
                            //Write you validation logic here
                        }
                    }}
                />
                {/* <TextField
                    id="standard-size-small"
                    label="Page No"
                    //type="number"
                    // InputLabelProps={{
                    //     shrink: true,
                    // }}
                    size='small'
                    //value={page + 1}
                    onBlur={(event) => {
                        if (Number(event.target.value) > pageCount) {
                            alert(`Page Number should be less than: ${pageCount}`);
                        } else {
                            onPageChange(event, event.target.value - 1);
                        }
                    }}
                //variant="standard"
                /> */}
            </div>
        </Box>
    );
}

export default function CustomPagination(props) {
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}
import { templatesTypes } from "../types/templatesType";
const initialState = {
    templateData: [],

  };

  export const templateReducer = (state = initialState, { type, payload }) => {
    switch (type) {
      case templatesTypes.TEMPLATES_DATA:
        return {
          ...state,
          templateData: payload
        }
        default:
            return state;
    }
}
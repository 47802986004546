import { orderTypes } from "../types/orderTypes";

const initialState = {
  isLoading: false,
  allLocationData: [],

  // Lookup Options
  orderLookupRes: [],
  shipperLookupRes: [],
  searchFieldsRes:[],
  pickupPointLookupRes: [],
  dispositionLookupRes: [],
  chassisPickupLookupRes: [],
  chassisDispositionLookupRes: [],

  ratesChargesLookupRes: {},
  selectedValueConsignee: [],
  selectedStopsFinalValue: [],
  stopCntrlFinalValue: [
    {
      stop: "",
      code: "",
      name: "",
      address: "",
      city: "",
      company: "",
      phone: "",
      fax: "",
      apptDate: "",
      apptTime: "",
      totalPieces: "",
      totalWeight: "",
      selectedStop: "",
    },
  ],
  billingCntrlInputValueParams: {
    unit: "",
    chassis: "",
    release: "",
    reference: "",
  },

  stopCntrlInputValue: [],
  ratesSelectedValues: [
    {
      code: "",
      payRec: "",
      units: "",
      rateAmount: "",
      amount: NaN,
      account: "",
      accountLine: "",
      department: "",
      description: "",
      associatedLeg: "",
      selectedStop: "",
    },
  ],
  createOrderFinalResponse: "",
  ratesDispatchValues: [],
  ratesUnitNumberDispatchValues: [],
  errorHandlerData: { status: "", statusText: "" },
  orderTypePickupRes: [],
  pickupChassisData: "",
  pickupDispositionDispatch: [],
  allOrders: {
    isLoading: false,
    data: []
  },
  allExtendedSearchViews: {
    isLoading: false,
    data: []
  },
  allElasticOrders: {
    isLoading: false,
    data: []
  },
  singleOrder: {
    isLoading: false,
    data: []
  },
  deleteStopEntry: "",
  deleteRCEntry: "",
  ordersLocation: false

};

export const orderReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case orderTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    case orderTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorHandlerData: payload,
      };

    // --------------- LOOKUP OPTIONS FOR ORDER  
    case orderTypes.ORDER_LOOKUP_DATA: {
      return {
        ...state,
        orderLookupRes: payload,
      };
    }

    case orderTypes.ORDER_SHIPPER_DATA: {
      return {
        ...state,
        shipperLookupRes: payload,
      };
    }

    case orderTypes.PICKUP_POINT_LOOKUP_DATA: {
      return {
        ...state,
        pickupPointLookupRes: payload,
      };
    }

    case orderTypes.DISPOSITION_LOOKUP_DATA: {
      return {
        ...state,
        dispositionLookupRes: payload,
      };
    }

    case orderTypes.ORDER_SEARCH_FIELDS_DATA: {
      return {
        ...state,
        searchFieldsRes: payload.data.sort((a,b) => a.label > b.label),
      };
    }

    case orderTypes.CHASSIS_PICKUP_LOOKUP_DATA: {
      return {
        ...state,
        chassisPickupLookupRes: payload,
      };
    }

    case orderTypes.CHASSIS_DISPOSITION_LOOKUP_DATA: {
      return {
        ...state,
        chassisDispositionLookupRes: payload,
      };
    }

    // --------------- LOOKUP OPTIONS FOR ORDER END



    case orderTypes.ORDERBY_SELECTEDVALUE: {
      return {
        ...state,
        selectedValueOrderby: payload,
      };
    }

    case orderTypes.SHIPPER_SELECTEDVALUE: {
      return {
        ...state,
        selectedValueShipper: payload,
      };
    }

    case orderTypes.CONSIGNEE_SELECTEDVALUE: {
      return {
        ...state,
        selectedValueConsignee: payload,
      };
    }

    case orderTypes.BILLINGCNTRL_FINALVALUE: {
      return {
        ...state,
        billingCntrlFinalValue: payload,
      };
    }

    case orderTypes.STOPCNTRL_FINALVALUE: {
      return {
        ...state,
        stopCntrlFinalValue: payload,
      };
    }

    case orderTypes.SELECTEDSTOPCNTRL_FINALVALUE: {
      return {
        ...state,
        selectedStopsFinalValue: payload,
      };
    }

    case orderTypes.BILLINGCNTRL_INPUTVALUE: {
      return {
        ...state,
        billingCntrlInputValueParams: payload,
      };
    }

    case orderTypes.STOPCNTRL_INPUTVALUE: {
      return {
        ...state,
        stopCntrlInputValue: payload,
      };
    }

    case orderTypes.SELECTEDRATES_FINALVALUE: {
      return {
        ...state,
        ratesSelectedValues: payload,
      };
    }

    case orderTypes.CREATE_ORDER_FINAL: {
      return {
        ...state,
        createOrderFinalResponse: payload,
      };
    }

    case orderTypes.ADD_SHIPPER_CONSIGNEE: {
      return {
        ...state,
        addShipperConsigneeResponse: payload,
      };
    }

    case orderTypes.EDIT_SHIPPER_CONSIGNEE: {
      return {
        ...state,
        editShipperConsigneeResponse: payload,
      };
    }

    case orderTypes.ORDER_RATESCHARGES_DATA: {
      return {
        ...state,
        ratesChargesLookupRes: payload,
      };
    }
    case orderTypes.RATESDISPATCH_VALUE: {
      return {
        ...state,
        ratesDispatchValues: payload,
      };
    }
    case orderTypes.RATESUNITDISPATCH_VALUE: {
      return {
        ...state,
        ratesUnitNumberDispatchValues: payload,
      };
    }

    case orderTypes.ORDER_PICKUPCHASIS_DATA: {
      return {
        ...state,
        orderTypePickupRes: payload,
      };
    }

    case orderTypes.PICKUPCHASSISDISPATCH_VALUE: {
      return {
        ...state,
        pickupChassisData: payload,
      };
    }

    case orderTypes.PICKUPDISPOSITIONDISPATCH_VALUE: {
      let newArr = [];
      if (state.pickupDispositionDispatch.length > 0) {
        state.pickupDispositionDispatch.forEach((ele, i) => {
          if (ele.head == payload.head) {
            state.pickupDispositionDispatch.splice(i, 1);
            newArr.push({
              head: payload.head,
              value: payload.value,
            });
          } else {
            newArr.push({
              head: payload.head,
              value: payload.value,
            });
          }
        });
      } else {
        newArr.push({
          head: payload.head,
          value: payload.value,
        });
      }

      return {
        ...state,
        pickupDispositionDispatch: [...state.pickupDispositionDispatch, ...newArr],
      };
    }

    case orderTypes.GET_ALL_ORDERS: {
      return {
        ...state,
        allOrders: payload
      }
    }
    case orderTypes.GET_ALL_VIEWS_EXTENDED_SEARCH: {
      return {
        ...state, 
        allExtendedSearchViews: payload
      }
    }
    case orderTypes.GET_ALL_ORDERS_ELASTIC: {
      return {
        ...state,
        allElasticOrders: payload
      }
    }
    case orderTypes.GET_SINGLE_ORDER: {
      return {
        ...state,
        singleOrder: payload
      }
    }
    case orderTypes.ORDERS_LOCATION: {
      return {
        ...state,
        ordersLocation: payload
      }
    }
    case orderTypes.DELETE_STOP_RECORD: {
      return {
        ...state,
        deleteStopEntry: payload
      }
    }
    case orderTypes.DELETE_RATES_CHARGES_RECORD: {
      return {
        ...state,
        deleteRCEntry: payload
      }
    }

    default:
      return state;
  }
};

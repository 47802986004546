import { Grid } from "@material-ui/core";
import {
  useEffect,
  useState,
} from "react";
import OrderByTableContainer from "./OrderByTableContainer";
import { OrdersWrapper } from "../../../components/styledComponents";
import OrderByFormContainer from "./OrderByFormContainer";
import { useSelector, useDispatch } from "react-redux";
import {getOrderByData,getSingleOrderByData} from"../../../store/actions/maintenanceOrderByActions"
import useUnsavedChangesWarning from '../../../components/Alerts/useUnsavedChangesWarning';
import{mapOrderByScreenData} from "./components/commonFn"

export default function BillToOrderBy({isOrderScreen, handleOrderByData}) {
  const [addNewPickUpDispo, setAddNewPickUpDispo] = useState(false);
  const[editShipperClicked,setEditShipperClicked]=useState(false)
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(1);
  const dispatch = useDispatch();
  const [formKey, setFormKey] = useState(0);
  const [reqQuery, setReqQuery] = useState({
    pageData: { page: 1, pageSize: 50 },
    sortData: { field: "name", sort: "asc" },
    searchText: "",
    orderByStatus:"A"
    // searchBy:"status"
  });
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const condition = editShipperClicked || addNewPickUpDispo;

  useUnsavedChangesWarning(condition);

  useEffect(() => {
    setSelectedRow("");

    if (typeof ordersLocation?.locationNumber !== "undefined") {
      setIsLoading(true);
      orderByTableApiCall();
    }
  }, [ordersLocation]);
  
  const orderByTableApiCall = (
    pageData,
    sortData,
    searchText,
    // searchBy,
    orderByStatus,
  ) => {
    
      setReqQuery(prevState => ({
        ...prevState,
        ...(pageData && { pageData }),
        ...(sortData && { sortData }),
        ...(searchText !== undefined && { searchText }),
        // ...(searchBy && { searchBy })
        ...( orderByStatus && {  orderByStatus })
      }));
  
  
    setIsLoading(true);
    dispatch(
      getOrderByData({
        // searchBy:searchBy?searchBy:reqQuery.searchBy,
        orderByStatus:orderByStatus?orderByStatus:reqQuery.orderByStatus,
        pageData: pageData?pageData:reqQuery.pageData,
        sortData:sortData?sortData:reqQuery.sortData,
        search:searchText !== undefined ?searchText:reqQuery.searchText
        
      })
    ).then((res) => {
      if (res?.data) {
        setRows(res?.data);
        setRowsCount(res?.count);
      } else {
       
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    setFormKey((prevKey) => (prevKey === 0 ? 1 : 0)); // Toggle between 0 and 1
  }, [selectedRow, addNewPickUpDispo]);

  useEffect(() => {
    if(isOrderScreen && selectedRow){
      const fetchDataToSendOrderScreen = async () => {
      
        const code= selectedRow
        // const proceed = window.confirm("You have selected a row. Do you want to proceed?");
        //     if (!proceed) {
        //       setSelectedRow("")
        //         return; // Exit if the user chooses not to proceed
        //     }
          try {
            const res = await dispatch(getSingleOrderByData({
                      locationNumber: ordersLocation?.locationNumber,
                      code:code
                  }));
                  const mapDataToSendOrderScreen =mapOrderByScreenData(res?.shipperForm)
                  handleOrderByData(mapDataToSendOrderScreen)
             
          } catch (error) {
              console.error("Error fetching data:", error);
          } finally {
              setIsLoading(false);
          }
      };
    
      fetchDataToSendOrderScreen();  
      
    }
    
  }, [selectedRow]);
  if (isOrderScreen) {
    // Show only the full-width grid if isOrderScreen is true
    return (
      <OrdersWrapper>
        <Grid container>
          <Grid item xs={12}>
            <div
              style={{
                height: "100%",
                width: "100%",
                padding: "10px",
              }}
            >
              <OrderByTableContainer
                setSelectedRow={setSelectedRow}
                setAddNewPickUpDispo={setAddNewPickUpDispo}
                setEditShipperClicked={setEditShipperClicked}
                setRows={setRows}
                rows={rows}
                orderByTableApiCall={orderByTableApiCall}
                isLoading={isLoading}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                setIsLoading={setIsLoading}
                selectedRow={selectedRow}
                addNewPickUpDispo={addNewPickUpDispo}
                editShipperClicked={editShipperClicked}
                isOrderScreen={isOrderScreen}
              />
            </div>
          </Grid>
        </Grid>
      </OrdersWrapper>
    );
  }
  
  return (
    <OrdersWrapper>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: "10px",
            }}
          >
            <OrderByTableContainer
              setSelectedRow={setSelectedRow}
              setAddNewPickUpDispo={setAddNewPickUpDispo}
              setEditShipperClicked={ setEditShipperClicked}
              setRows={setRows}
              rows={rows}
              // rowsData={rowsData}
              orderByTableApiCall={orderByTableApiCall}
              isLoading={isLoading}
              rowsCount={rowsCount}
              setRowsCount={setRowsCount}
              // setRowsData={setRowsData}
              setIsLoading={setIsLoading}
              selectedRow={selectedRow}
              addNewPickUpDispo={addNewPickUpDispo}
              editShipperClicked={editShipperClicked}
              isOrderScreen={false}
      

            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: !addNewPickUpDispo ? "0px" : "10px",
            }}
          >
            {addNewPickUpDispo || selectedRow ? (
             
              <OrderByFormContainer
                key={formKey}
                formKey={formKey}
                selectedRow={selectedRow}
                setRows={setRows}
                orderByTableApiCall={orderByTableApiCall}
                addNewPickUpDispo={addNewPickUpDispo}
                setAddNewPickUpDispo={setAddNewPickUpDispo}
                setSelectedRow={ setSelectedRow}
                onEditShipper={setEditShipperClicked}
              />
            
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ fontSize: "22px", color: "#707070" }}>
                  {" "}
                  Open any Pickup/Disposition
                </h3>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </OrdersWrapper>
  );
}

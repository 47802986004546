import { useState } from 'react';
import {
    Button
} from "@mui/material";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { unTenderType } from '../../../store/actions/loadsActions';
import { useDispatch } from 'react-redux';
import { getStopsData, setReloadTableState } from '../../../store/actions/dispatchActions';
import { apptDateGTE180d } from '../commonFn';

export default function UntenderButton(props) {
    const { row } = props
    const dispatch = useDispatch();
    const [snackState, setSnackState] = useState(false);
    const [snackContent, setSnackContent] = useState('');
    const [isUntenderClicked, setIsUntenderClicked] = useState(false);

    const handleUntender = () => {
        const confirm = window.confirm('Are you sure?');
        if (confirm) {
            setIsUntenderClicked(true);
            setSnackState(true);
            setSnackContent({ severity: "info", message: "Removing Tender changes..." })
            dispatch(unTenderType({ unTenderLoc: row.locationNumber, unTenderCntrlNum: row?.control, unTenderStopNum: row?.lineNumber }))
                .then(res => {
                    setIsUntenderClicked(false);
                    if (typeof res?.status !== 'undefined' && res.status === 'success') {
                        setSnackContent({ severity: 'success', message: res.message })
                        dispatch(setReloadTableState(true));
                        // setTimeout(() => {
                        //     window.location.reload()
                        // }, 1000)
                    } else {
                        setSnackContent({ severity: "error", message: res?.message || 'Something went wrong, please try again!' })
                    }
                })
        }
    }
    return (
        <>
            <div style={{ paddingLeft: '6px' }}>
                <Button
                    disabled={isUntenderClicked}
                    title='Remove'
                    variant="contained"
                    onClick={() => handleUntender()}
                    style={{ maxWidth: '13px', maxHeight: '16px', minWidth: '13px', minHeight: '16px', fontSize: '10px', padding: '2px' }}
                >R</Button>
            </div>
            <Snackbar open={snackState} style={{ position: 'fixed', top: 0, left: '30%' }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={() => setSnackState(false)} >
                <Alert onClose={() => setSnackState(false)} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </>
    )
}
export enum orderTypes {
    SET_LOADING = "SET_LOADING",
    GET_LOCATION_DATA = 'GET_LOCATION_DATA',
    ADD_NEW_LOCATION = 'ADD_NEW_LOCATION',
    SEARCH_EMAIL_VALUE = 'SEARCH_EMAIL_VALUE',
    DELETE_LOCATION_NUMBER = 'DELETE_LOCATION_NUMBER',
    FETCH_LOCATION_DATA = 'FETCH_LOCATION_DATA',

    // LOOKUP OPTIONS
    ORDER_LOOKUP_DATA = 'ORDER_LOOKUP_DATA',
    ORDER_SHIPPER_DATA = 'ORDER_SHIPPER_DATA',
    ORDER_SEARCH_FIELDS_DATA = 'ORDER_SEARCH_FIELDS_DATA',
    PICKUP_POINT_LOOKUP_DATA = 'PICKUP_POINT_LOOKUP_DATA',
    DISPOSITION_LOOKUP_DATA = 'DISPOSITION_LOOKUP_DATA',
    CHASSIS_PICKUP_LOOKUP_DATA = 'CHASSIS_PICKUP_LOOKUP_DATA',
    CHASSIS_DISPOSITION_LOOKUP_DATA = 'CHASSIS_DISPOSITION_LOOKUP_DATA',
    GET_ALL_VIEWS_EXTENDED_SEARCH = 'GET_ALL_VIEWS_EXTENDED_SEARCH',

    ORDERBY_SELECTEDVALUE = 'ORDERBY_SELECTEDVALUE',
    SHIPPER_SELECTEDVALUE = 'SHIPPER_SELECTEDVALUE',
    CONSIGNEE_SELECTEDVALUE = 'CONSIGNEE_SELECTEDVALUE',
    BILLINGCNTRL_FINALVALUE = 'BILLINGCNTRL_FINALVALUE',
    STOPCNTRL_FINALVALUE = 'STOPCNTRL_FINALVALUE',
    SELECTEDSTOPCNTRL_FINALVALUE = 'SELECTEDSTOPCNTRL_FINALVALUE',
    STOPCNTRL_INPUTVALUE = 'STOPCNTRL_INPUTVALUE',
    SELECTEDRATES_FINALVALUE = 'SELECTEDRATES_FINALVALUE',
    CREATE_ORDER_FINAL = 'CREATE_ORDER_FINAL',
    ADD_SHIPPER_CONSIGNEE = 'ADD_SHIPPER_CONSIGNEE',
    EDIT_SHIPPER_CONSIGNEE = 'EDIT_SHIPPER_CONSIGNEE',
    BILLINGCNTRL_INPUTVALUE = 'BILLINGCNTRL_INPUTVALUE',
    ORDER_RATESCHARGES_DATA = 'ORDER_RATESCHARGES_DATA',
    RATESDISPATCH_VALUE = 'RATESDISPATCH_VALUE',
    RATESUNITDISPATCH_VALUE = 'RATESUNITDISPATCH_VALUE',
    SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE',
    ORDER_PICKUPCHASIS_DATA = 'ORDER_PICKUPCHASIS_DATA',
    PICKUPDISPOSITIONDISPATCH_VALUE = 'PICKUPDISPOSITIONDISPATCH_VALUE',
    PICKUPCHASSISDISPATCH_VALUE = 'PICKUPCHASSISDISPATCH_VALUE',
    CHASISPICKUPDISPATCH_VALUE = 'CHASISPICKUPDISPATCH_VALUE',
    GET_ALL_ORDERS = 'GET_ALL_ORDERS',
    GET_ALL_ORDERS_ELASTIC = 'GET_ALL_ORDERS_ELASTIC',
    ORDERS_LOCATION = 'ORDERS_LOCATION',
    DELETE_STOP_RECORD = 'DELETE_STOP_RECORD',
    DELETE_RATES_CHARGES_RECORD = 'DELETE_RATES_CHARGES_RECORD',
    GET_SINGLE_ORDER = 'GET_SINGLE_ORDER'
}
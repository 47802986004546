import { useEffect, useState } from "react";
import { TextField, Autocomplete,Chip } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useSelector,useDispatch } from 'react-redux';
import {saveMaintenanceLocData} from "../../../../../store/actions/maintenanceActions"

export default function PickupDispoForm({ shipperFormRef }) {
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});
  const stringifiedLocationList = shipperFormRef?.current?.location?.map(String);
  const[selectedLocationList,setSelectedLocationList]=useState(stringifiedLocationList||[])
  const locationList = useSelector(({ loadsReducer }) => loadsReducer.locationList);
  const stringAllLocationList = locationList?.map(item => String(item.locationNumber)) || [];


  const dispatch = useDispatch();
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  useEffect(() => {
    setFormData(shipperFormRef?.current);
    
  }, [shipperFormRef, locationList]);
  function correctSpaceFormat(value) {
    // Replace any non-standard whitespace characters with regular spaces
    return value.replace(/\s+/g, ' ');
}

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    let newValue = correctSpaceFormat(value.toString().toUpperCase()); // Convert value to uppercase

    // Validate the length based on the field name
    const maxCharacterLength = {
      code: 5,
      scac:4,
      name: 25,
      address: 25,
      city: 25,
      state: 2,
      zip: 10,
      phone: 13,
      fax: 13,
      internalNote: 75,
      notes1: 25,
      notes2: 25,
      notes3: 25,
      description: 25
    };

    if (newValue.length > maxCharacterLength[name]) {
      newValue = newValue.slice(0, maxCharacterLength[name]); // Truncate the value if it exceeds the limit
    }

    // Update the state with the validated value
    setFormData({
      ...formData,
      [name]: newValue,
    });
    // Update the ref with the validated value
    shipperFormRef.current = {
      ...shipperFormRef.current,
      [name]: newValue,
    };
  };
  
  // const handleChangeLocationData=(event,value)=>{
  
  //  let postObj=value[0].locationNumber
  //  console.log("---postobj",postObj)
  //  setSelectedLocationList([...selectedLocationList,postObj])
  //     dispatch(saveMaintenanceLocData(postObj,[formData.code]))
  //     .then(
  //       (res) => {
  //         console.log("---res-shipperForm", res);
  //         if (
  //           res?.httpCode === 200 &&
  //           res?.message === "Record has been updated successfully!"
  //         ) {
  //           // setSnackOpen(true);
  //           // setSnackContent({
  //           //   severity: "success",
  //           //   message:
  //           //     type === "new"
  //           //       ? `New PickUp/Disposition has created`
  //           //       : ` PickUp/Disposition has updated`,
  //           // });

  //         }
  //       }
        
  //     );
  //   };
  
  const handleChangeLocationData = (event, value) => {
    console.log("value",value)
    // const locationNumbers = value;
    const locationNumbers = value.filter(loc => stringAllLocationList.includes(loc));
   
    setSelectedLocationList(locationNumbers);

    // Handle API call for adding and removing locations
    const addedLocations = locationNumbers.filter(loc => !selectedLocationList.includes(loc));
    const removedLocations = selectedLocationList.filter(loc => !locationNumbers.includes(loc));

    if (addedLocations.length > 0) {
      // Process added locations
      addedLocations.forEach(locationNumber => {
        dispatch(saveMaintenanceLocData(locationNumber, [formData.code], "ADD")).then(res => {
          if (res?.httpCode === 200 && res?.message === "Record has been updated successfully!") {
            console.log("Location data added successfully!");
          } else {
            console.error("Error adding location data:", res?.message);
          }
        });
      });
    } else if (removedLocations.length > 0) {
      removedLocations.forEach(locationNumber => {
        if (stringAllLocationList.includes(locationNumber)) {
          dispatch(saveMaintenanceLocData(locationNumber, [formData.code], 'DELETE')).then(res => {
            if (res?.httpCode === 200 && res?.message === "Records has been deleted successfully!") {
              console.log("Location data removed successfully!");
            } else {
              console.error("Error removing location data:", res?.message);
            }
          });
        } else {
          console.warn(`Location ${locationNumber} cannot be removed.`);
        }
      });
    }
  }
  return (
    <>
      <form style={{ margin: "10px" }}>
     
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px" }}>
    <div style={{ flex: 2, marginRight: "10px" }}>
        <InputLabel
            htmlFor="code"
            style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
           Code
        </InputLabel>
        <TextField
            id="code"
            name="code"
            value={formData.code?.toUpperCase()|| ""}
            onChange={handleChangeData}
            margin="none"
            fullWidth
            variant="outlined"
            size="small"
            InputLabelProps={{ style: { color: "black" } }}
            disabled={errors.code}
        />
    </div>
    <div style={{ flex: 1 }}>
        <InputLabel
            htmlFor="scac"
            style={{
                color: "black",
                marginBottom: "5px",
                fontSize: "0.85rem",
            }}
        >
            SCAC
        </InputLabel>
    
       
        <TextField
          id="scac"
          name="scac"
          value={formData.scac?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.scac}
        />
    </div>
    
</div>
<div>
<InputLabel
          htmlFor="location"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
         Tagged Eagle Locations
        </InputLabel>
    <Autocomplete
      multiple
      // limitTags={10}
      filterSelectedOptions
        size="small"
             id="tags-outlined"
      options={stringAllLocationList }
      getOptionLabel={(option) => option}
  
      defaultValue={selectedLocationList}

      onChange={handleChangeLocationData}
    renderInput={(params) => (
        <TextField id="location" name="location" {...params} />
      )}
      renderTags={(value, getTagProps) => 
        value.map((option, index) => (
          <Chip
            label={option}
            {...getTagProps({ index })}
            disabled={!stringAllLocationList.includes(option)}
          />
        ))
      }
      clearIcon={null}
      sx={{ width: '100%' }}
    />
       
    </div>

        <InputLabel
          htmlFor="description"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Description
        </InputLabel>
        <TextField
          id="description"
          name="description"
          value={formData.description?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.description}
        />

        <InputLabel
          htmlFor="name"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Name
        </InputLabel>
        <TextField
          id="name"
          name="name"
          value={formData.name?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.name}
        />
        <InputLabel
          htmlFor="address"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Address
        </InputLabel>
        <TextField
          id="address"
          name="address"
          value={formData.address?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.address}
        />

        <InputLabel
          htmlFor="city"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          City
        </InputLabel>
        <TextField
          id="city"
          name="city"
          value={formData.city?.toUpperCase()|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.city}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
          <div style={{ flex: 1, marginRight: "10px" }}>
            <InputLabel
              htmlFor="state"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
              State
            </InputLabel>
            <TextField
              id="state"
              //   label="State"
              name="state"
              value={formData.state?.toUpperCase()|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.state}
            />
          </div>
          <div style={{ flex: 1, marginLeft: "10px" }}>
            <InputLabel
              htmlFor="zip2"
              style={{
                color: "black",
                marginTop: "10px",
                fontSize: "0.85rem",
              }}
            >
              Zip Code
            </InputLabel>
            <TextField
              id="zip2"
              name="zip"
              value={formData.zip|| ""}
              onChange={handleChangeData}
              margin="none"
              fullWidth
              variant="outlined"
              size="small"
              InputLabelProps={{ style: { color: "black" } }}
              disabled={errors.zip}
            />
          </div>
        </div>

        <InputLabel
          htmlFor="internalNote"
          style={{
            color: "black",
            marginTop: "10px",
            fontSize: "0.85rem",
          }}
        >
          Internal Note
        </InputLabel>
        <TextField
          id="internalNote"
          name="internalNote"
          value={formData.internalNote|| ""}
          onChange={handleChangeData}
          margin="none"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          size="small"
          InputLabelProps={{ style: { color: "black" } }}
          disabled={errors.internalNote}
        />
      </form>
    </>
  );
}

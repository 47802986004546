// set serial number

export function setSerialNumber(res, no) {
    let sNo = no || 0;
    res.data.data.forEach((ele) => {
        let locNum = ele.locationNumber.split(',');
        sNo = sNo + 1;
        ele.sNo = sNo;
        ele.locationNumber = locNum;
        if (ele.status == 1) {
            ele.status = "Active"
        } else {
            ele.status = "InActive"
        }
    })
    return res
}

export function setFetchSerialNumber(res) {
    let id = 0;

    res.forEach((ele) => {
        id = id + 1;
        ele.id = id
    });
    return res
}
import { useState, useEffect } from "react";

// import material components
import {
    Paper, Tabs, Tab, Box, Stack, Grid, Checkbox, FormControl, InputLabel, FormControlLabel, Divider, Chip,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Backdrop,
    CircularProgress,
    IconButton
} from "@mui/material";

import InputBase from "@mui/material/InputBase";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import OilBarrel from '@mui/icons-material/OilBarrel';

import { useDispatch } from "react-redux";

import { POST, PUT, GET } from '../../../utils/axios';

export default function FuelSurcharge(props) {
    const { locationNumber, setAlertMessage } = props;
    // const dispatch = useDispatch();
    const [editMode, setEditMode] = useState(false);
    const [fscvalueR, setFscvalueR] = useState('');
    const [fscvalueB, setFscvalueB] = useState('');
    const [fscvalueC, setFscvalueC] = useState('');
    const [open, setOpen] = useState(false);

    useEffect(async () => {
        if (locationNumber !== undefined && locationNumber !== "") {
            const response = await GET(`/fuelsurcharge/${locationNumber}`);
            if (response?.status === 'success') {
                setFscvalueR(response?.data?.fsc_per_r || 0);
                setFscvalueB(response?.data?.fsc_per_b || 0);
                setFscvalueC(response?.data?.fsc_per_c || 0);
            }
        }
    }, [locationNumber]);

    const handleSubmit = async () => {
        let postData = {
            fsc_per_r: fscvalueR,
            fsc_per_b: fscvalueB,
            fsc_per_c: fscvalueC,
            locationNumber: locationNumber
        }
        const response = await POST(`/fuelsurcharge`, postData);
        if (response?.status === 'success') {
            setFscvalueR(fscvalueR);
            setFscvalueB(fscvalueB);
            setFscvalueC(fscvalueC);
            setEditMode(false);
            setAlertMessage({
                children: response.message,
                severity: response.status,
            });
        } else {
            setAlertMessage({
                children: 'Bad Data Supplied to API',
                severity: 'error',
            });
        }

    }
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around'
        }}>
            <Box
                sx={{
                    p: '2px 4px',
                    display: 'flex',
                    alignItems: 'center',
                    width: 145,
                    height: "34px",
                    marginTop: '12px',
                    borderRadius: '4px',
                    marginRight: '5px'
                }}
                //boxShadow={3}
                border={0.3}
                borderColor={`#1976d2`}
            >
                <InputLabel
                    sx={{
                        fontSize: 12,
                        color: 'black',
                        fontWeight: 'bold',
                        justifyContent: 'center',
                        alignContent: 'center'
                    }}
                >FSC</InputLabel>
                <InputBase
                    //className=''
                    readOnly={!editMode}
                    variant="outlined"
                    value={fscvalueR}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="00"
                    onChange={(e) => {
                        if (e.target.value.length <= 3) {
                            if (!Number.isInteger(Number(e.target.value))) {
                                alert('Decimal is not allowed!');
                            } else {
                                setFscvalueR(Number(e.target.value))
                            }
                        } else {
                            alert('FSC should not be greater than 3 digits');
                        }
                    }}
                    type={`number`}
                />

                {editMode ? (
                    <Stack direction="row">
                        <IconButton onClick={() => handleSubmit()} size={`small`} sx={{ width: '20px' }}>
                            <DoneIcon titleAccess='Save Changes' fontSize="small" color="success" />
                        </IconButton>
                        <IconButton onClick={() => setEditMode(false)} size={`small`} sx={{ width: '20px' }}>
                            <CloseIcon titleAccess='Cancel Changes' fontSize="small" sx={{ color: 'red' }} />
                        </IconButton>
                    </Stack>
                ) : (
                    <IconButton onClick={() => setEditMode(true)} size={`small`}>
                        <EditIcon fontSize="small" titleAccess='Edit' />
                    </IconButton>
                )}

            </Box>
            <Box sx={{ marginTop: '12px', height: "34px" }} >
                <Button
                    variant="outlined"
                    onClick={() => setOpen(true)}
                >
                    <OilBarrel fontSize="small" titleAccess='Edit FSC Pay %' />
                </Button>
                <Dialog
                    open={open} onClose={() => setOpen(false)}>
                    <DialogTitle sx={{ textAlign: 'center' }}>Broken & IC Payout %</DialogTitle>
                    <DialogContent dividers>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                m: 'auto',
                                width: 'fit-content',
                            }}
                        >
                            <TextField
                                margin="dense"
                                id="broker"
                                label="Broker(%)"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={fscvalueB}
                                onChange={(e) => setFscvalueB(Number(e.target.value))}
                            />
                            <TextField
                                margin="dense"
                                id="ic"
                                label="IC(%)"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={fscvalueC}
                                onChange={(e) => setFscvalueC(Number(e.target.value))}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button onClick={() => handleSubmit()}>Save</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
}
import { useEffect, useState } from "react";

import {
  Paper,
  Box,
  TextField,
  Button,
  InputAdornment,
  Autocomplete,
  FormControlLabel,Checkbox,Typography
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector, useDispatch } from "react-redux";

import BillToOrderByTable  from "./components/OrderByTable";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";



export default function BillToOrderByTableContainer(props) {
  const {
    setSelectedRow,
    setRows,
    // setRowsData,
    rows,
    // rowsData,
    isLoading,
    orderByTableApiCall,
    setRowsCount,
    rowsCount,
    setAddNewPickUpDispo,
    setEditShipperClicked,
    setIsLoading,
    selectedRow,
    addNewPickUpDispo,
    editShipperClicked,
    isOrderScreen,
 
  } = props;
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [shipperSearchError, setShipperSearchError] = useState("");
  const open = Boolean(anchorEl);
  const[selectedLocationList,setSelectedLocationList]=useState([])
  const[showActiveBillTo,setShowActiveBillTo]=useState(true)
  // const popOverID = open ? "new-order-popover" : undefined;
  const locationList = useSelector(({ loadsReducer }) => loadsReducer.locationList);
  const stringAllLocationList = locationList?.map(item => String(item.locationNumber)) || [];
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const handleSearchText = (event) => {
    setSearchText(event);
    if (event === "") {
      handleSearch("");
    }
  };
  const handleSearch = (searchName) => {
    // setShowActiveBillTo(false)


      setIsLoading(true)
      orderByTableApiCall(
        { page: 1, pageSize: 50 },
       "",
        searchName,
        "",
      );
    
}
  useEffect(() => {}, [rows]);

  const handleQueryPageChange = (
    params,
    sortData = {
      field: "name",
      sort: "asc",
    },
   
  ) => {
    setSelectedRow("");
      orderByTableApiCall(
        { page: params?.page + 1, pageSize: params?.pageSize },
        sortData,
        searchText,
        "",
      );
    // }
  };
  const handleSortChange = (
    params,
    sortData = {
      field: "name",
      sort: "asc",
    }
  ) => {
    setSelectedRow("");
      orderByTableApiCall(
        { page: params?.page + 1, pageSize: params?.pageSize },
        sortData,
        searchText,
        "",
      );
  };
  const ShowActiveBillToDataHandler =(data)=>{
    setShowActiveBillTo(data)
    if(data===false){
      orderByTableApiCall(
        { page: 1, pageSize: 50 },
       "",
       searchText,
        "all",
      );

    }else{
      orderByTableApiCall(
        { page: 1, pageSize: 50 },
       "",
       searchText,
        "A",
      );
    }

  }
// console.log("rowsData-contable",rowsData)
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <label style={{ fontSize: "14px", color: "#091DA3" }}>
          Order By
        </label>
      </div>

      <div
        style={{
          width: "100%",
          height: "50px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "60%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            marginRight: "4%",
          }}
        >
          <TextField
            label="Search"
            margin="dense"
            style={{ height: "40px", width: "100%", fontSize: "10px" }}
            size="small"
            inputProps={{ style: { fontSize: 10 } }}
            InputLabelProps={{ style: { fontSize: 14, height: 40 } }}
            value={searchText}
            
            // onChange={(event) => { setSearchText(event.target.value) }}
            onChange={(event) => {
              handleSearchText(event.target.value);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && handleSearch(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      searchText ? handleSearchText("") : handleSearch();
                    }}
                  >
                    {searchText ? <CloseIcon /> : <SearchIcon />}
                  </IconButton>
                  
                </InputAdornment>
              ),
            }}
          />
        </div>
       <div
        style={{
          width: "40%",
          height: "40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormControlLabel
                control={
                  <Checkbox
                  onChange={(e) =>ShowActiveBillToDataHandler(e.target.checked)}
                    checked={showActiveBillTo}
                    color="primary"
                  />
                }
                label={
                    <Typography sx={{ fontSize: '0.8rem' }}>
                      Show only active Bill To's
                    </Typography>
                  }
              
              />
              </div>
       
      </div>

      <div style={{ width: "100%", height: `calc(100vh - 200px)` }}>
        {shipperSearchError ? (
          <div style={{ color: "red", textAlign: "center" }}>
            {shipperSearchError}
          </div>
        ) : (
          <BillToOrderByTable 
            rows={rows}
            // rowsData={rowsData}
            isLoading={isLoading}
            // setSelected={setSelected}
            setSelectedRow={setSelectedRow}
            handleQueryPageChange={handleQueryPageChange}
            handleSortChangeData={handleSortChange}
            rowsCount={rowsCount}
            setAddNewPickUpDispo={setAddNewPickUpDispo}
            orderByTableApiCall={orderByTableApiCall}
            setEditShipperClicked={setEditShipperClicked}
            editShipperClicked={editShipperClicked}
            addNewPickUpDispo={addNewPickUpDispo}
            isOrderScreen={isOrderScreen}
          />
        )}
      </div>

    </Paper>
  );
}

import config from "../config/config";
import Axios from "axios";
import { getToken, logoutInternal } from "./cookie";
import { setErrorData } from "../store/actions/loadsActions";
import reduxStore from "../store";
import { saveAs } from "file-saver";
// import { load } from 'recaptcha-v3';
const URL = config.api.URL;

// direct access to redux store.
const { dispatch } = reduxStore;

// doc - https://github.com/axios/axios
const axiosInstance = Axios.create({
  baseURL: URL,
  headers: {
    "Cache-control": "no-cache, no-store",
    Pragma: "no-cache",
    "Content-Type": "application/json",
  },
  responseType: "arraybuffer",
  // timeout: 10000,
  transformRequest: [
    function (data, headers) {
      headers["Expires"] = "0";
      if (getToken()) headers["Authorization"] = `Bearer ` + getToken();
      return JSON.stringify(data);
    },
  ],
  transformResponse: [
    function (data, headers) {
      if (headers["content-type"].indexOf("application/json") > -1) {
        const json = JSON.parse(data);
        return json;
      }
      return data;
    },
  ],
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      return logoutInternal();
    }
    const errObj = {
      status: 'ErrorCode: ' + 500, statusText: ', ErrorMessage: ' + 'Internal Server Error!!!'
    };
    dispatch(setErrorData(errObj));
    // if (error?.response?.status === 500) return;
    // throw new Error();
  }
);

export const GET = async (url, fileName) => {
  const res = await axiosInstance.get(url);
  if (res?.headers["content-type"].indexOf("application/pdf") > -1) {
    const blob = new Blob([res.data], { type: "application/pdf" });
    saveAs(blob, fileName);
  }
  return true;
};

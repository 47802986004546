import * as React from "react";
import { useState, useEffect } from "react";
import {
  Stack,
  Button
} from "@mui/material";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ComponentWrapper } from "../../../components/styledComponents";
import {
  DataGridPro, useGridApiRef
} from "@mui/x-data-grid-pro";
import { FSCCodes, miscReceivables, miscPayables } from '../CommonFn';
import { convertMilitaryToYMD, convertYMDToMonthName, formatCurrency } from '../../../utils/global-functions';
import ExportSummaryByDay from './ExportSummaryByDay';

export default function SummaryByDay(props) {
  const { activeTab, rawData, locationNumber, isLoading, filterParams } = props;
  const [rows, setRows] = useState([]);
  const apiRef = useGridApiRef()

  useEffect(() => {

    // Filter by dateRange using dateType (e.g., billingDate)
    if (locationNumber !== 0 && filterParams && filterParams?.dateType && filterParams?.dateRange && filterParams?.dateRange.length === 2) {
      setRows(processDataForDateRange(rawData || [], filterParams));
    }

    // if (locationNumber !== 0 && rawData.length > 0 ) {
    //   setRows(processDataForDateRange(rawData || [], filterParams));
    // }
  }, [activeTab, rawData])

  // Function to convert date range and process data
  const processDataForDateRange = (inputData, filters) => {
    const { dateType, startingPercentage, endingPercentage, revenuePercentage, excludeFSC, dateRange } = filters;
    const dateMap = new Map();

    // Clear the map
    dateMap.clear();

    // Initialize the date range from the provided start and end dates
    const [startDate, endDate] = dateRange;
    let currentDate = Number(startDate);
    const endDateObj = Number(endDate);

    // validate if date is set or not
    // if (dateType === '' || startDate === null || endDate === null) {
    //   alert('Date type & date values are mandatory!')
    //   return rows;
    // }

    // Loop to initialize the map for each day in the date range
    while (currentDate <= endDateObj) {
      const dayKey = currentDate;

      dateMap.set(dayKey, {
        id: dayKey,
        day: convertYMDToMonthName(convertMilitaryToYMD(dayKey)),
        totalLoads: 0,
        revenue: 0,
        miscRevenue: 0,
        totalRevenue: 0,
        expense: 0,
        miscExpense: 0,
        totalExpense: 0,
        totalNet: 0,
        ptNetExclMisc: 0,
        totalPercent: 0,
        ptPercent: 0
      });
      currentDate += 1
    }

    // Make a deep copy of the data to avoid mutating the original
    const clonedData = JSON.parse(JSON.stringify(inputData));

    // Process each input object
    clonedData.forEach(item => {
      const source = item;
      const dateField = source[dateType];
      const dateKey = dateField;

      if (dateMap.has(dateKey)) {

        // Filter by excludeFSC (exclude entries with FUEL SURCHARGE in glDescription)
        if (excludeFSC) {
          source.rates = source.rates.filter(rate => !FSCCodes.includes(rate.glCode));
        }

        let rates = source.rates;

        let revenue = rates
          .filter(rate => rate.payableReceivable === "R" && !miscReceivables.includes(rate.glCode))
          .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

        let expense = rates
          .filter(rate => rate.payableReceivable === "P" && !miscPayables.includes(rate.glCode))
          .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

        let miscRevenue = rates
          .filter(rate => rate.payableReceivable === "R" && miscReceivables.includes(rate.glCode))
          .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

        let miscExpense = rates
          .filter(rate => rate.payableReceivable === "P" && miscPayables.includes(rate.glCode))
          .reduce((sum, rate) => sum + parseFloat(rate.amount), 0);

        // Update the map for the current date
        const existingData = dateMap.get(dateKey);

        // Calculations 
        revenue = existingData.revenue + revenue;
        expense = existingData.expense + expense;
        miscRevenue = existingData.miscRevenue + miscRevenue;
        miscExpense = existingData.miscExpense + miscExpense;

        const totalRevenue = revenue + miscRevenue;
        const totalExpense = expense + miscExpense;
        const totalNet = totalRevenue - totalExpense;
        const totalPercent = (totalExpense / totalRevenue) * 100;
        const ptPercent = (expense / revenue) * 100;
        const ptNetExclMisc = revenue - expense;
        const totalLoads = Number(existingData.totalLoads) + 1; //Number(source.totalStops);

        if (dateKey != 0)
          dateMap.set(dateKey, {
            id: dateKey,
            day: convertYMDToMonthName(convertMilitaryToYMD(dateKey)),
            totalLoads: totalLoads,
            revenue: Number(revenue), // correct
            miscRevenue: Number(miscRevenue),
            totalRevenue: Number(totalRevenue), // correct
            expense: Number(expense), // correct
            miscExpense: Number(miscExpense),
            totalExpense: Number(totalExpense),
            totalNet: Number(totalNet), // correct
            ptNetExclMisc: Number(ptNetExclMisc), // correct
            totalPercent: Number(totalPercent), // correct
            ptPercent: Number(ptPercent) // correct
          });
      }
    });

    // Filter by percentage if necessary
    let filteredData = Array.from(dateMap.values());

    if (revenuePercentage && startingPercentage && endingPercentage) {
      filteredData = filteredData.filter(item => item[revenuePercentage] >= startingPercentage && item[revenuePercentage] <= endingPercentage)
    }

    // First filter out the items where `day` is 0, then map over the filtered data
    filteredData = filteredData
      .filter(item => item.day !== 0)  // Remove entries where `day` is 0
      .map((item, index) => {
        return {
          id: index,
          day: item.day,
          totalStops: Number(item.totalLoads),
          revenue: item.revenue.toFixed(2),
          miscRevenue: item.miscRevenue.toFixed(2),
          totalRevenue: item.totalRevenue.toFixed(2),
          expense: item.expense.toFixed(2),
          miscExpense: item.miscExpense.toFixed(2),
          totalExpense: item.totalExpense.toFixed(2),
          totalNet: item.totalNet.toFixed(2),
          ptNetExclMisc: item.ptNetExclMisc.toFixed(2),
          totalPercent: item.totalPercent.toFixed(2),
          ptPercent: item.ptPercent.toFixed(2)
        };
      });
    return filteredData;
  }

  // Function to calculate totals for price and quantity columns
  const calculateTotals = (rows) => {
    let revenue = 0;
    let miscRevenue = 0;
    let totalRevenue = 0;
    let expense = 0;
    let miscExpense = 0;
    let totalExpense = 0;
    let totalNet = 0;
    let ptNetExclMisc = 0;
    let totalStops = 0;

    rows.forEach((row) => {
      revenue += parseFloat(row.revenue) || 0;
      miscRevenue += parseFloat(row.miscRevenue) || 0;
      totalRevenue += parseFloat(row.totalRevenue) || 0;
      expense += parseFloat(row.expense) || 0;
      miscExpense += parseFloat(row.miscExpense);
      totalExpense += parseFloat(row.totalExpense) || 0;
      totalNet += parseFloat(row.totalNet) || 0;
      ptNetExclMisc += parseFloat(row.ptNetExclMisc) || 0;
      totalStops += Number(row.totalStops);
    });

    return {
      revenue,
      miscRevenue,
      totalRevenue,
      expense,
      miscExpense,
      totalExpense,
      totalNet,
      ptNetExclMisc,
      totalPercent: ((totalExpense / totalRevenue) * 100).toFixed(2),
      ptPercent: ((expense / revenue) * 100).toFixed(2),
      totalStops
    };
  };

  const totals = calculateTotals(rows);

  const pinnedRows = {
    bottom: [
      {
        id: 'total-rows',
        day: 'Totals',
        totalStops: Number(totals.totalStops),
        revenue: `${totals.revenue.toFixed(2)}`,
        miscRevenue: `${totals.miscRevenue.toFixed(2)}`,
        totalRevenue: `${totals.totalRevenue.toFixed(2)}`,
        expense: `${totals.expense.toFixed(2)}`,
        miscExpense: `${totals.miscExpense.toFixed(2)}`,
        totalExpense: `${totals.totalExpense.toFixed(2)}`,
        totalNet: `${totals.totalNet.toFixed(2)}`,
        ptNetExclMisc: `${totals.ptNetExclMisc.toFixed(2)}`,
        totalPercent: `${parseFloat(totals.totalPercent) || 0}`,
        ptPercent: `${parseFloat(totals.ptPercent) || 0}`
      }
    ],

  };

  const tableColumns = [
    {
      field: "day",
      headerName: "Day",
      minWidth: 90,
      flex: 1,
      sortable: false
    },
    {
      field: "totalStops",
      headerName: "Total Loads",
      minWidth: 90,
      flex: 1,
      sortable: false
    },
    {
      field: "revenue",
      headerName: "Revenue",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value)
    },
    { field: "miscRevenue", headerName: "Misc Revenue", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value) },
    { field: "totalRevenue", headerName: "Total Revenue", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value) },
    {
      field: "expense",
      headerName: "Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value)
    },
    {
      field: "miscExpense",
      headerName: "Misc Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value)
    },
    {
      field: "totalExpense",
      headerName: "Total Expense",
      minWidth: 90,
      flex: 1,
      renderCell: (params) => formatCurrency(params.value)
    },
    { field: "totalNet", headerName: "Total Net", minWidth: 90, flex: 1, renderCell: (params) => formatCurrency(params.value) },
    { field: "ptNetExclMisc", headerName: "PT Net (Excl Misc)", minWidth: 90, flex: 1 },
    { field: "totalPercent", headerName: "Total %", minWidth: 90, flex: 1, renderCell: (params) => `${params.value}%` },
    { field: "ptPercent", headerName: "PT %", minWidth: 90, flex: 1, renderCell: (params) => `${params.value}%` },
  ];

  return (
    <ComponentWrapper>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'absolute',
          //bottom:0,
          right: "1%",
          zIndex: 9999,
          marginTop: "-2%"
        }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => apiRef.current.exportDataAsCsv()}
          startIcon={<TextSnippetIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          CSV
        </Button>
        <Button
          variant="text"
          size="small"
          onClick={ExportSummaryByDay(rows, tableColumns)}
          startIcon={<PictureAsPdfIcon color="primary" />}
          color={`primary`}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          PDF
        </Button>
      </Stack>
      <Stack
        //marginTop={2}
        sx={{
          height: window.innerHeight - 270,
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "rgba(11, 67, 135, 1)",
            color: "white",
          },
          "& .isSubtotal": {
            fontWeight: "bold",
            // color: "blue",
            backgroundColor: "#E8E8E8 !important",
            // backgroundColor: "#F0F0F0 !important",
          },

          "& .pinned-row": {
            backgroundColor: "#f0f0f0",  /* Light gray background */
            fontWeight: "bold",          /* Bold text */
            color: "#000000"            /* Black text */
          },
          "& .pinned-row .MuiDataGrid-cell": {
            borderTop: "2px solid #000000"
          }

        }}
      >
        <DataGridPro
            apiRef={apiRef}
            hideFooter={true}
            rows={rows}
            columns={tableColumns}
            loading={isLoading}
            pinnedRows={pinnedRows}
            getRowClassName={(params) => params.id === 'total-rows' ? 'pinned-row' : ''}
        />
      </Stack>
    </ComponentWrapper>
  );
}

import { Grid } from "@material-ui/core";
import {
  useEffect,
  useState,
} from "react";
import PickupDispositionTableContainer from "./PickupDispositionTableContainer";
import { OrdersWrapper } from "../../../components/styledComponents";
import PickupDispositionFormContainer from "./PickupDispositionFormContainer";
import { useSelector, useDispatch } from "react-redux";
import {getMaintenanceData} from"../../../store/actions/maintenanceActions"
import useUnsavedChangesWarning from '../../../components/Alerts/useUnsavedChangesWarning';

export default function PickupDisposition() {
  const [addNewPickUpDispo, setAddNewPickUpDispo] = useState(false);
  const[editShipperClicked,setEditShipperClicked]=useState(false)
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(1);
  const dispatch = useDispatch();
  const [formKey, setFormKey] = useState(0);
  const [reqQuery, setReqQuery] = useState({
    pageData: { page: 1, pageSize: 50 },
    sortData: { field: "name", sort: "asc" },
    searchText: "",
    selectedLocationList: []
  });
  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );
  const condition = editShipperClicked || addNewPickUpDispo;

  useUnsavedChangesWarning(condition);

  useEffect(() => {
    setSelectedRow("");

    if (typeof ordersLocation?.locationNumber !== "undefined") {
      setIsLoading(true);
      pickUpDispositionTableApiCall();
    }
  }, [ordersLocation]);
  
  const pickUpDispositionTableApiCall = (
    pageData,
    sortData,
    searchText,
    selectedLocationList,
  ) => {
    
      setReqQuery(prevState => ({
        ...prevState,
        ...(pageData && { pageData }),
        ...(sortData && { sortData }),
        ...(searchText !== undefined && { searchText }),
        ...(selectedLocationList && { selectedLocationList })
      }));
  
  
    setIsLoading(true);
    dispatch(
      getMaintenanceData({
        locationlist:selectedLocationList?selectedLocationList:reqQuery.selectedLocationList,
        pageData: pageData?pageData:reqQuery.pageData,
        sortData:sortData?sortData:reqQuery.sortData,
        search:searchText !== undefined ?searchText:reqQuery.searchText
        
      })
    ).then((res) => {
      if (res?.data) {
        setRows(res?.data);
        setRowsCount(res?.count);
      } else {
       
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    setFormKey((prevKey) => (prevKey === 0 ? 1 : 0)); // Toggle between 0 and 1
  }, [selectedRow, addNewPickUpDispo]);
  
  return (
    <OrdersWrapper>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: "10px",
            }}
          >
            <PickupDispositionTableContainer
              setSelectedRow={setSelectedRow}
              setAddNewPickUpDispo={setAddNewPickUpDispo}
              setEditShipperClicked={ setEditShipperClicked}
              setRows={setRows}
              rows={rows}
              // rowsData={rowsData}
              pickUpDispositionTableApiCall={pickUpDispositionTableApiCall}
              isLoading={isLoading}
              rowsCount={rowsCount}
              setRowsCount={setRowsCount}
              // setRowsData={setRowsData}
              setIsLoading={setIsLoading}
              selectedRow={selectedRow}
              addNewPickUpDispo={addNewPickUpDispo}
              editShipperClicked={editShipperClicked}

            />
          </div>
        </Grid>

        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
          <div
            style={{
              height: `calc(100vh - 90px)`,
              width: "100%",
              padding: !addNewPickUpDispo ? "0px" : "10px",
            }}
          >
            {addNewPickUpDispo || selectedRow ? (
             
              <PickupDispositionFormContainer
                key={formKey}
                formKey={formKey}
                selectedRow={selectedRow}
                setRows={setRows}
                pickUpDispositionTableApiCall={pickUpDispositionTableApiCall}
                addNewPickUpDispo={addNewPickUpDispo}
                setAddNewPickUpDispo={setAddNewPickUpDispo}
                setSelectedRow={ setSelectedRow}
                onEditShipper={setEditShipperClicked}
              />
            
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <h3 style={{ fontSize: "22px", color: "#707070" }}>
                  {" "}
                  Open any Pickup/Disposition
                </h3>
                <h3 style={{ fontSize: "22px", color: "#707070" }}>or</h3>
                <h3 style={{ fontSize: "22px", color: "#707070" }}>
                  Create new Pickup/Disposition
                </h3>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </OrdersWrapper>
  );
}

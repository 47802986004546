import { useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { GET } from '../../../../utils/axios'

export default function RatesAutoComplete(props) {
    const { valueChange, existingValue, lineAccount, isDisabled, orderType, edi210 } = props
    let params = useParams();
    const [value, setValue] = useState({ id: -1, notes: existingValue || "", GLDesc: '' });
    const [options, setOptions] = useState([{ id: -1, notes: existingValue || "", GLDesc: '' }])

    const timeOutRef = useRef(null)
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation

    );
    params= params?.locationNumber?params:{locationNumber: ordersLocation.locationNumber, controlNumber: ''}
   

    const handleLookup = (event, value, reason) => {
        //console.log(value, reason)
        if (value && reason !== 'reset') {
            if (timeOutRef.current)
                clearTimeout(timeOutRef.current)

            const timer = setTimeout(async () => {
                const response = await GET(`/rate-book/?searchByRateName=${value}&locationNumber=${params?.locationNumber}&type=${lineAccount}`)
                if (response?.status !== 'error') {
                    let apiRes = (edi210 === 'Y') ? response.data.data.filter((e) => e.edi210 === 'Y') : response.data.data;
                    if (['DOMOUT', 'DOMINB', 'INBOUND', 'OUTBOUND'].includes(orderType) && lineAccount === 'L') {
                        setOptions(apiRes.filter((e) => e.GLCode !== 3320000))
                    } else if (['EXPORT', 'IMPORT'].includes(orderType) && lineAccount === 'L') {
                        setOptions(apiRes.filter((e) => e.GLCode !== 3310000))
                    } else {
                        setOptions(apiRes)
                    }
                } else {
                    setOptions([])
                }
            }, 1000)

            timeOutRef.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }
    console.log("isDisabled", isDisabled)
    return (
        <Autocomplete
            disabled={isDisabled}
            // disabled={false}
            freeSolo
            autoSelect
            options={options?.filter(item => item.LorA === lineAccount)}
            getOptionLabel={(option) => `${(option.notes) ? option.notes + ' [ ' + option.destinationCity + ', ' + option.destinationState + ' - ' + option.originCity + ' - ' + option.GLCode + ' - ' + ((option.billTo) ? option.billTo : 'BOOK RATE') + ']' : ''}`}
            id="rate-autocomplete"
            renderInput={(params) => (
                <TextField
                    {...params}
                    name="rateName"
                    //label="Rate Name"
                    variant="standard"
                    sx={{
                        input: {
                            textTransform: 'uppercase'
                        }
                    }}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${(option.notes) ? option.notes + ' [ ' + option.destinationCity + ', ' + option.destinationState + ' - ' + option.originCity + ' - ' + option.GLCode + ' - ' + ((option.billTo) ? option.billTo : 'BOOK RATE') + ']' : ''}`}
                    </li>
                )
            }}

            componentsProps={{ popper: { style: { width: 300 } } }}
            onInputChange={handleLookup}
            onChange={(event, value, reason) => {
                if (typeof (value) === 'string') {
                    //console.log('on Change Some New Value', value, reason);
                    setValue({ notes: value, GLDesc: '' });
                }
                else {
                    setValue(value);
                    //console.log('on Change', value, reason);
                }
                if (typeof (value) === 'object') {
                    //console.log('Changing parent', value)
                    valueChange(value)
                }
            }}
            //isOptionEqualToValue={(option, value) => option.notes === value.notes || option || option === ""}
            value={value}
        />


    );
}
import React, { useState } from 'react'
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { capitalize } from './../../../utils/text';
import { useDispatch } from "react-redux";
import { setItem, getItem } from '../../../utils/cookie';
import {
    locationHeaderValue,
} from "../../../store/actions/rateBookActions";

import { ordersLocation } from '../../../store/actions/orderActions'
import { getDriverListByLocation, setSearchValues } from '../../../store/actions/loadsActions';
import { useLocation } from 'react-router-dom';
import {isNullOrZeroOrEmptyOrUndefined} from '../../../utils/global-functions';

const useStyles = makeStyles({
    width: {
        // maxWidth: '170px'
    },
    input: {
        fontSize: '13px',
        paddingTop: '0 !important',
        paddingBottom: '0 !important'
    },
})

export default function LocationList({ onUpdate, clearSelected, disable = false }) {
    const locationList = useSelector(({ loadsReducer }) => loadsReducer.locationList);
    const loadSearchValues = useSelector(({ loadsReducer }) => loadsReducer.loadSearchValues);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedLocation, setselectedLocation] = useState(null);

    const url = useLocation();
    const currentRoute = url.pathname; 

    React.useEffect(() => {
        console.log('Clear Selected', clearSelected)
        setselectedLocation(getItem('userSelectedLocation'))
        if (clearSelected) {
            clearSelected.current = resetInputValue
        }
    }, [])

    React.useEffect(() => {
        let userSelected = getItem("userSelectedLocation");
        console.log('Location is already selected by user', userSelected)

        if (userSelected) {
            console.log('Location is already selected by user', userSelected)
            setselectedLocation(userSelected.location)
        }
        else if (loadSearchValues.location !== undefined && loadSearchValues.location !== "") {
            var locationObject = findByMatchingProperties(locationList, { locationNumber: loadSearchValues.location });
            setselectedLocation(locationObject[0]);
        } else {
            setselectedLocation((locationList?.length > 0) ? locationList[0] : null);
        }
    }, [locationList])

    React.useEffect(() => {
        // console.log("selected location--", selectedLocation);
        //persist for 7 days
        if (selectedLocation)
            setItem('userSelectedLocation', { location: selectedLocation }, 7)

        dispatch(locationHeaderValue(selectedLocation))
        dispatch(ordersLocation(selectedLocation))
        dispatch(getDriverListByLocation(selectedLocation?.locationNumber));
        dispatch(setSearchValues({ location: selectedLocation?.locationNumber }));

        /**
         * Added: 28-08-24
         * check visibility of dispach screen and set selected local location to reload data only if the location has changed
         * This function compares the selected Location with userSelectedLocation cookie and set Location only if cookie location
         * is different
         */
         const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && window.location.pathname === '/dispatch') {
              
              const userSelectedFromCookie = getItem('userSelectedLocation');
              
              // Ensure that userSelectedFromCookie is valid and compare with local location
              if (userSelectedFromCookie?.location?.locationNumber &&
                  userSelectedFromCookie.location.locationNumber !== selectedLocation?.locationNumber) {
                
                if (clearSelected) {
                  clearSelected.current = resetInputValue
                }
                //set local location to cookie value to trigger reload
                setselectedLocation(userSelectedFromCookie.location);
              }
            } 
          };
      
          // Add event listener
          document.addEventListener('visibilitychange', handleVisibilityChange);
      
          // Cleanup the event listener on component unmount
          return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
          };        
        //End of handling visibility change 

    }, [selectedLocation])

    const resetInputValue = () => {
        setselectedLocation(null);
    };

    const findByMatchingProperties = (set, properties) => {
        return set.filter(function (entry) {
            return Object.keys(properties).every(function (key) {
                return entry[key] === properties[key];
            });
        });
    }
    const sortedlocationList = locationList.sort((a, b) => {
        if (a.default === 'Y' && b.default !== 'Y') {
            return -1; // "a" comes first
        } else if (a.default !== 'Y' && b.default === 'Y') {
            return 1; // "b" comes first
        } else {
            return a.locationNumber - b.locationNumber; // Sort by locationNumber
        }
    });

    return (
        <div style={{ width: '100%' }}>
            <Autocomplete
                autoComplete
                openOnFocus
                data-test="locationlistfields"
                clearOnBlur
                classes={{ inputRoot: classes.input }}
                className={classes.width}
                getOptionLabel={location => `Loc ${capitalize(location.locationNumber)} [${location.locationNumber}]`}
                options={sortedlocationList}
                renderInput={params => {
                    return <TextField name="locations" autoComplete="false" placeholder={'Locations'} {...params} variant="outlined"></TextField>;
                }}
                onChange={(e, location) => {
                    setselectedLocation(location);
                    onUpdate && onUpdate(location ? location.locationNumber : '');
                }}
                disabled={disable}
                value={selectedLocation}
            />
        </div>
    )
}

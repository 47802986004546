import { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from "@mui/material";

import cancelicon from '../../assets/icons/cancel_icon.png'
import addicon from '../../assets/icons/add_icon.png'
import { Button } from "../styledComponents";
import moment from 'moment';
import dayjs from 'dayjs';
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

function Filter(props) {

    const { showFilter, setShowFilter, handleFilter } = props;
    // const [inputFields, setInputFields] = useState([
    //     { clause: '', field: '', operand: '',value:'' }
    // ])

    const { searchClause, setSearchClause } = props

    const viewname = [
        { label: 'OR' },
        { label: 'AND' },
    ]
    console.log(props)
    const viewfield = props.searchFields
    // const viewfield = [
    //     { label: 'Control #', fieldName:"controlNumber" },
    //     { label: 'Order By', fieldName:"shipperAgentCode" },
    //     { label: 'Shipper' },
    // ]

    const viewnumber = [
        { label: 'IS' },
        { label: 'IS NOT' },
        { label: 'BETWEEN' }
    ]

    const relativeDate = [
        'Today',
        'Yesterday',
    ]

    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState([null, null]);

    const handleFilterClose = () => {
        setShowFilter(false);
    }

    const handleApplyFilter = () => {
        console.log('filter Object', searchClause)

        const updatedSearch = searchClause.map(item =>
            item.operand.label === 'BETWEEN' && item.field.format === 'Date' ? { ...item, field: { ...item.field, format: 'DateRange'} } : item
        );

        // const filter = searchClause.map(item => ({
        //     clause: item.clause.label,
        //     field: item.field.fieldName,
        //     operand:item.operand.label,
        //     value: item.value,
        // }))

        handleFilter(updatedSearch);
        setShowFilter(false);
    }

    const handleAddnew = () => {
        let newfield = { clause: '', field: '', operand: '', value: '' }
        setSearchClause([...searchClause, newfield])
    }

    const handleRemoveField = (index) => {
        let data = [...searchClause];
        data.splice(index, 1)
        setSearchClause(data)
    }

    const onFieldChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].field = value
        setSearchClause([...values])
    }

    const onClauseChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].clause = value
        setSearchClause([...values])
    }

    const onOperandChange = (index, value) => {
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].operand = value
        setSearchClause([...values])
    }

    const onValueChange = (index, value) => {
        console.log('Changing Value', value)
        let values = searchClause
        if (index === 0) {
            values[0].clause = { label: 'AND' }
        }
        values[index].value = value
        setSearchClause([...values])
    }

    const convertRelativeDate = (term) => {
        const normalizedTerm = term.toLowerCase().trim();

        if (normalizedTerm === 'today') {
            return moment().format('MM/DD/YY');
        } else if (normalizedTerm === 'yesterday') {
            return moment().subtract(1, 'days').format('MM/DD/YY');
        }

        // Default to today's date if term doesn't match
        return moment().format('MM/DD/YY');
    };
    

    const handleAccept = (index, dateValue) => {
        console.log("dateValue", dateValue);
        const formattedStartDate = dateValue[0] ? "1" + moment(dateValue[0].toDate()).format('YYMMDD') : '';
        const formattedEndDate = dateValue[1] ? "1" + moment(dateValue[1].toDate()).format('YYMMDD') : '';
        let values = searchClause;
        values[index].value = formattedStartDate + '|' + formattedEndDate;
        setSearchClause([...values]);
        handleClosePicker()
      };

    const formatedDateRangeFunc = (dateValueStr) => {
        // Check if dateValueStr exists and is in the expected format
        if (!dateValueStr || !dateValueStr.includes('|')) {
          return '';
        }
      
        // Split the dateValueStr to get the start and end dates
        const [startDateStr, endDateStr] = dateValueStr.split('|');
      
        // Extract the actual date by removing the leading '1'
        const startDate = startDateStr ? dayjs(startDateStr.substring(1), 'YYMMDD') : null;
        const endDate = endDateStr ? dayjs(endDateStr.substring(1), 'YYMMDD') : null;
      
        // Format the dates in MM/DD/YY format
        const formattedStartDate = startDate ? startDate.format('MM/DD/YY') : '';
        const formattedEndDate = endDate ? endDate.format('MM/DD/YY') : '';
      
        // Return the formatted date range in MM/DD/YY - MM/DD/YY format
        return `${formattedStartDate} - ${formattedEndDate}`;
      };

      const handleOpenPicker = (index) => {
        setIsPickerOpen(index); // Set picker open for the clicked index
      };
    
      // Function to handle closing the date picker
      const handleClosePicker = () => {
        setIsPickerOpen(null); // Close picker
      };
    return (
        <div style={{ width: '30%', position: 'absolute', zIndex: '10', backgroundColor: '#FFFFFF', marginLeft: '250px', marginTop: '265px', borderRadius: '5px', borderColor: '#DCDCDC', borderStyle: 'solid', borderWidth: '2px', visibility: (showFilter == true ? 'visible' : 'hidden') }} >

            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', padding: '1rem', justifyContent: 'space-between' }}>
                <div style={{ width: '55%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Button onClick={handleApplyFilter}>Apply</Button>
                    <Button onClick={() => props.loadDefaultView(null, null)}>Clear</Button>
                </div>
                <img src={cancelicon} style={{ width: '12px', height: '12px' }} onClick={handleFilterClose} />
                {/* <div style={{ width: '50%', display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', paddingRight: '1rem' }} onClick={() => props.loadDefaultView(null, null)}> {`Clear Filter`} </Typography>

                </div>

                <div style={{ width: '50%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Button onClick={handleApplyFilter}>Apply</Button>
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={cancelicon} style={{ width: '12px', height: '12px' }} onClick={handleFilterClose} />
                    </div>
                </div> */}
            </div>
            <div style={{ width: '94%', backgroundColor: '#707070', height: '0.5px', opacity: '40%', marginLeft: '1rem' }}></div>

            <div style={{ flexDirection: 'row', padding: '1rem', alignItems: 'center', display: 'flex', width: '100%', }}>
                <div style={{ width: "20%" }}>
                    <label>Where :</label>
                </div>
                <div style={{ width: "80%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Autocomplete
                        disablePortal
                        disableClearable
                        className="autocomplestyl"
                        id="combo-box-demo"
                        options={viewfield}
                        value={searchClause[0].field}
                        onChange={(event, value) => onFieldChange(0, value)}
                        style={{ width: '30%' }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled
                    />

                    <Autocomplete
                        disablePortal
                        disableClearable
                        className="autocomplestyl"
                        id="combo-box-demo"
                        options={viewnumber}
                        value={searchClause[0].operand}
                        onChange={(event, value) => onOperandChange(0, value)}
                        // defaultValue={'is'}
                        style={{ width: '24%' }}
                        renderInput={(params) => <TextField {...params} />}
                        disabled
                    />

                    <TextField
                        disablePortal
                        size="small"
                        className="autocomplestyl"
                        id="combo-box-demo"
                        defaultValue={'value'}
                        value={searchClause[0].value}
                        style={{ width: '30%' }}
                        onChange={(event) => onValueChange(0, event.target.value)}
                        disabled
                    />
                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={cancelicon} style={{ width: '12px', height: '12px' }} />
                    </div>
                </div>
            </div>

            {searchClause.map((input, index) => {
                if (index === 0)
                    return (<></>)
                return (
                    <div key={index} style={{ flexDirection: 'row', paddingLeft: '1rem', paddingRight: '1rem', alignItems: 'center', alignItems: 'flex-start', display: 'flex', marginBottom: '5px' }}>
                        <div style={{ width: "20%", marginright: '2px', display: 'flex', alignItems: 'flex-start' }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={viewname}
                                // defaultValue={'OR'}
                                value={searchClause[index].clause}
                                onChange={(event, value) => onClauseChange(index, value)}
                                sx={{ width: '90%', paddingBottom: '2px' }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </div>
                        <div style={{ width: "80%", display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={viewfield}
                                // defaultValue={'Name'}
                                style={{ width: '30%' }}
                                renderInput={(params) => <TextField {...params} />}
                                value={searchClause[index].field}
                                onChange={(event, value) => onFieldChange(index, value)}
                            />

                            <Autocomplete
                                disablePortal
                                disableClearable
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={searchClause[index].field.format === 'Date' ? viewnumber: viewnumber.filter(e=>e.label != 'BETWEEN')}
                                // defaultValue={'is'}
                                sx={{ width: 75 }}
                                renderInput={(params) => <TextField {...params} />}
                                value={searchClause[index].operand}
                                onChange={(event, value) => onOperandChange(index, value)}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 120
                                        }
                                    }
                                }}
                            />

                            {searchClause[index].operand.label !== 'BETWEEN' ?
                            (<Autocomplete
                                disablePortal
                                disableClearable
                                freeSolo // Allows entering custom values
                                className="autocomplestyl"
                                id="combo-box-demo"
                                options={searchClause[index].field.format === 'Date' ? relativeDate : []}
                                style={{ width: '30%' }}
                                renderInput={(params) => <TextField {...params} />}

                                // Ensure that value is a string, fallback to empty string if not
                                value={typeof searchClause[index].value === 'string'
                                    ? searchClause[index].value
                                    : searchClause[index].value?.label || ''}

                                onChange={(event, newValue) => {
                                    // If user selects a predefined option
                                    if (typeof newValue === 'string' && relativeDate.includes(newValue)) {
                                        const formattedValue = newValue.match(/^(Today|Yesterday)$/i)
                                                            ? convertRelativeDate(newValue)
                                                            : newValue;
                                        onValueChange(index, formattedValue);
                                    } else {
                                        // Handle custom input
                                        onValueChange(index, newValue);
                                    }
                                }}

                                // Capture free text input when typing
                                onInputChange={(event, newInputValue) => {
                                    onValueChange(index, newInputValue); // Store custom input value in state
                                }}

                                // Ensure string comparison for both custom input and predefined options
                                isOptionEqualToValue={(option, value) => option === value}

                                // Ensure that the correct label is shown for string options
                                getOptionLabel={(option) => typeof option === 'string' ? option : ''}

                                componentsProps={{
                                    paper: {
                                        sx: {
                                            width: 120
                                        }
                                    }
                                }}
                            />) : (
                               
                                <div style={{ width: '30%', position: 'relative' }}>
                                <TextField
                                  disablePortal
                                  size="small"
                                  className="autocomplestyl"
                                  id="combo-box-demo"
                                value={formatedDateRangeFunc(searchClause[index].value) ||"" }
                                onClick={() => handleOpenPicker(index)}
                                  readOnly // Makes the field read-only, only clickable
                                  variant="outlined"
                                  style={{ cursor: 'pointer', width: '100%' }} // Styling for better UI
                                />
                                 {isPickerOpen === index && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '-10%',
                                      left: '10%',
                                      width: 'max-content',
                                      zIndex: 1000,
                                      background: '#fff',
                                      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                                    }}
                                  >
                                    <CustomDateRangePicker indexValue={index} defaultDateRangeValue={searchClause[index].value?searchClause[index].value:""} onDateSelect={handleAccept} handleCloseDatePicker={handleClosePicker} />
                                  </div>
                                )}
                              </div>
                            )}
                            {/* <TextField
                                className="autocomplestyl"
                                size="small"
                                id="combo-box-demo"
                                options={relativeDate}
                                //defaultValue={'value'}
                                style={{ width: '30%' }}
                                value={searchClause[index].value}
                                onChange={(event) => onValueChange(index, event.target.value)}
                            /> */}
                            <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => handleRemoveField(index)}>
                                <img src={cancelicon} style={{ width: '12px', height: '12px' }} />
                            </div>
                        </div>
                    </div>
                )
            })}

            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', paddingTop: '1rem', paddingBottom: '1rem' }} onClick={handleAddnew}>
                <img src={addicon} style={{ width: '15px', height: '15px' }} />
                <Typography variant="h6" style={{ color: '#102254', fontWeight: 'bold', fontSize: '16px', marginLeft: '0.5rem' }} > {`Add Filter`} </Typography>
            </div>

        </div>
    )

}
export default Filter;
import React, { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core';
import { dateConverter } from './DriverInfo';
import { truckDriverStatus } from '../../scenes/TenderDetails/truck-driver-utils';
import Tooltip from '@mui/material/Tooltip';


function TruckInfo({ truckDriverInfo, truckStatusFunc }) {
    const [truckSafetyStatus, setTruckSafetyStatus] = useState('');
    const [truckSafetyMessage, setTruckSafetyMessage] = useState('');

    useEffect(() => {
        const status = truckDriverStatus(dateConverter(truckDriverInfo.registrationExpiry));
        setTruckSafetyStatus(status)
    }, [truckDriverInfo.registrationExpiry]);

    useEffect(() => {
        truckStatusFunc(truckSafetyStatus)
        if(truckSafetyStatus==="OK"){
            setTruckSafetyMessage("OK")
        }
        if(truckSafetyStatus==="NA"){
            setTruckSafetyMessage("Registration Expired!")
        }
    }, [truckSafetyStatus])
    return (
        <>
            <h3 className="truck-driver-subheading">Truck Information</h3>
            <div>
                <div className="truck-info-input">
                    <label>Description</label>
                    <Tooltip title={truckDriverInfo?.description} arrow>  
                    <TextField
                        value={truckDriverInfo?.description}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truck-info-text-description"
                    />
                    </Tooltip>
                </div>
                <div className="truck-info-input">
                    <label>Comment</label>
                    <Tooltip title={truckDriverInfo?.comment} arrow>  
                    <TextField
                        value={truckDriverInfo?.comment}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truck-info-text-description"
                    /></Tooltip>
                </div>
                <div className="truck-info-multi-input">
                    <label>Safety Truck Status</label>
                    <Tooltip title={truckSafetyStatus} arrow>  
                    <TextField
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        value={truckSafetyStatus}
                    /></Tooltip>
                    <Tooltip title={truckSafetyMessage} arrow>  
                    <TextField
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truck-info-safetytruck"
                        
                        value={truckSafetyMessage}
                        
                    /></Tooltip>
                </div>
                <div className="truck-info-input">
                    <label>Available</label>
                    <TextField
                        value={truckDriverInfo?.available}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="text-field-size"
                        
                    />
                </div>
                <div className="truck-info-input">
                    <label>Active</label>
                    <TextField
                        value={truckDriverInfo?.active}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="text-field-size"
                    />
                </div>
                <div className="truck-info-input">
                    <label>Equipment License</label>
                    <Tooltip title={truckDriverInfo?.equipmentLicense} arrow>  
                    <TextField
                        value={truckDriverInfo?.equipmentLicense}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-date-field-size"
                        
                    /></Tooltip>
                </div>
                <div className="truck-info-input">
                    <label>Equipment Termination</label>
                     
                    <TextField
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-date-field-size"
                    />
                </div>
                <div className="truck-info-input">
                    <label>Registration Expires</label>
                    <TextField
                        value={dateConverter(truckDriverInfo?.registrationExpiry)}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-date-field-size"
                    />
                </div>
                <div className="truck-info-input">
                    <label>Ex-Service Date</label>
                    <TextField
                        value={truckDriverInfo?.date_ex_service}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-date-field-size"
                    />
                </div>
                <div className="truck-info-input">
                    <label>FHWA Inspection Expires</label>
                    <TextField
                        value={dateConverter(truckDriverInfo?.fhwaInspectionExpires)}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-date-field-size"
                    />
                </div>
                <div className="truck-info-input">
                    <label>Truck Driver/Label</label>
                    <TextField
                        value={truckDriverInfo?.truckDriverLabel}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-phone-field-size"
                        
                    />
                </div>
                <div className="truck-info-input">
                    <label>Phone</label>
                    <TextField
                        value={truckDriverInfo?.phone2}
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        className="truckinfo-phone-field-size"
                    />
                </div>

            </div>
        </>

    )
}

export default TruckInfo

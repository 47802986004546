import React, { useContext } from "react";
import { TextField, Paper, Autocomplete, FormControlLabel, FormGroup, IconButton, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Checkbox from '@mui/material/Checkbox'
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import './Styles.css'
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { GET } from '../../../utils/axios'
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { POST, PUT } from "../../../utils/axios";
import { shipperLookup } from "../../../store/actions/orderActions";
import orderContext from '../orderContext';
import {checkNonMatchingFields} from "../../EdiOrder/components/commonFn"

export default function EquipmentDetailsForm(props) {

    const { billingControlRef, selectedOrderType, disableFull, isTemplateScreen,nonMatchingFields } = props
    const dispatch = useDispatch();
    let params = useParams();

    // Local State for Select Box of Order By
    const [equipmentState, setEquipmentState] = useState(billingControlRef.current.equipmentDetails)
    // const [equipmentState, setEquipmentState] = useState({})
    const [sslSelect, setSSLSelect] = useState(billingControlRef.current.shipper.shipper ? { code: billingControlRef.current.shipper.shipper, name: billingControlRef.current.shipper.name } : null)
    const sslOptions = useSelector(({ orderReducer }) => orderReducer.shipperLookupRes)

    // shipper state
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [shipperState, setShipperState] = useState(null);
    const [addEditShipper, setAddEditShipper] = useState(false);
    const [addEditShipperMode, setAddEditShipperMode] = useState(false);
    const { setIsStateChanged } = useContext(orderContext);
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );
    params = params?.locationNumber ? params : { locationNumber: ordersLocation.locationNumber, controlNumber: '' }
    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('equipment details fields are changes')
        setIsStateChanged(true);
        /** END */
    }

    const handleClose = (event) => {
        setSnackOpen(false);
    };

    const clearAutocomplete = () => {
        setSSLSelect(null);
        setAddEditShipperMode(false);
        setShipperState(null);
        setEquipmentState({
            ...equipmentState,
            ssl: ""
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            shipper: {
                shipper: "",
                name: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                fax: "",
            }
        }
        handleFieldChanges()
    }
    const handleSSLChange = (event, value, reason) => {
        console.log('Selected Shipper', value);
        if (reason === "clear") {
            clearAutocomplete();
            return false;
        }

        setSSLSelect(value)
        setEquipmentState({
            ...equipmentState,
            ssl: value?.code
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            shipper: {
                shipper: value?.code,
                name: value?.name,
                address: value?.address,
                city: value?.city,
                state: value?.state,
                zip: value?.zip,
                phone: value?.phone,
                fax: value?.fax,
            }
        }

        // setting up shipper values
        setShipperState(value);
        setAddEditShipperMode(true);
        handleFieldChanges()
    }

    // Lookup handlers
    const sslLookupTimeout = useRef(null)

    const handleSSLLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (sslLookupTimeout.current)
                clearTimeout(sslLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(shipperLookup({ searchKey: "searchVal", searchValue: value, locationNumber: params.locationNumber }))
            }, 1000)

            sslLookupTimeout.current = timer
        }
        else {
            if (value === "") {
                setAddEditShipperMode(false);
            }
            console.log('Reason is reset not making an api call')
        }
    }

    // Start:: Shipper/Consignee code 
    const handleAddEditShipperClick = () => {
        setAddEditShipper(true);
    }
    const handleAddShipper = () => {

        setSnackOpen(true)
        setSnackContent({ severity: "info", message: "Adding new SSL Shipper/Consignee ..." })

        let newShipper = {
            ...shipperState,
            company: 1,
            // code: shipperState?.code || '',
            // name: shipperState?.name || '',
            // address: shipperState?.address || '',
            // state: shipperState?.state || '',
            // city: shipperState?.city || '',
            // zip: shipperState?.zip || '',
            yard: shipperState?.yard || '',
            phone: shipperState?.phone || '',
            fax: shipperState?.fax || '',
            note1: shipperState?.note1 || '',
            note3: shipperState?.note3 || '',
            note2: shipperState?.note2 || ''
        };

        if (addEditShipperMode) {
            PUT(`/shippers/${params.locationNumber}`, newShipper)
                .then(res => {
                    if (res?.httpCode === 200 && res?.status === "success") {

                        setAddEditShipper(false);
                        setAddEditShipperMode(true);

                        setSnackOpen(true)
                        setSnackContent({ severity: "success", message: `${newShipper.code} SSL Consignee/Shipper added` })
                    }
                    else {
                        setSnackOpen(true)
                        setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper: ${res.message}` })
                    }
                })
                .catch(error => {
                    setSnackOpen(true)
                    setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper - Bad Data Supplied to API` })
                })
        } else {
            POST(`/shippers/${params.locationNumber}`, newShipper)
                .then(res => {
                    if (res?.httpCode === 200 && res?.status === "success") {

                        setAddEditShipper(false);
                        setAddEditShipperMode(false);

                        setSnackOpen(true)
                        setSnackContent({ severity: "success", message: `${newShipper.code} Consignee/Shipper added` })
                    }
                    else {
                        setSnackOpen(true)
                        setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper: ${res.message}` })
                    }
                })
                .catch(error => {
                    setSnackOpen(true)
                    setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper - Bad Data Supplied to API` })
                })
        }
    }
    const resetShipper = () => {
        if (addEditShipperMode) {
            setAddEditShipper(false);
            setAddEditShipperMode(true);
        } else {
            setAddEditShipper(false);
            setAddEditShipperMode(false);
        }

    }

    useEffect(() => {
        if (billingControlRef.current.shipper.shipper !== "") {
            setAddEditShipperMode(true);
            setShipperState({
                company: '1',
                code: billingControlRef.current?.shipper?.shipper,
                yard: billingControlRef.current?.shipper?.yard,
                name: billingControlRef.current?.shipper?.name,
                address: billingControlRef.current?.shipper?.address,
                city: billingControlRef.current?.shipper?.city,
                state: billingControlRef.current?.shipper?.state,
                zip: billingControlRef.current?.shipper?.zip,
                phone: billingControlRef.current?.shipper?.phone,
                fax: billingControlRef.current?.shipper?.fax,
                note1: billingControlRef.current?.shipper?.note1,
                note2: billingControlRef.current?.shipper?.note2,
                note3: billingControlRef.current?.shipper?.note3,
            });
        }
    }, [billingControlRef.current])
    // End:: Shipper/Consignee code

    // const handleEquipmentDetailsChange = (event, value) => {
    //     console.log(value)
    //     let updatedOrderer = {
    //         ...ordererState,
    //         orderBy: '',
    //         name: '',
    //         location: '',
    //         phone: '',
    //         fax: '',
    //         billTo: '',
    //         billToName: '',
    //         edi204: 'N',
    //         edi214: 'N',
    //         edi210: 'N',
    //         billToStatus: ''
    //     }
    //     if (value) {
    //         updatedOrderer = {
    //             ...ordererState,
    //             orderBy: value.code,
    //             name: value.name,
    //             location: value.orderByLocation,
    //             phone: value.phone,
    //             fax: value.fax,
    //             billTo: value.billTo,
    //             billToName: value.billToName,
    //             edi204: value.edi204 ? value.edi204 : 'N',
    //             edi214: value.edi214 ? value.edi214 : 'N',
    //             edi210: value.edi210 ? value.edi210 : 'N',
    //             billToStatus: value.billToStatus
    //         }
    //         setOrderByCodeSelect(value)
    //         setOrdererState(updatedOrderer)
    //     }
    //     else {
    //         // Reset Order By
    //         setOrderByCodeSelect('')
    //         setOrdererState(updatedOrderer)
    //     }
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             ...updatedOrderer
    //         }
    //     }
    //     console.log('Updated Orderer Ref', billingControlRef.current)
    // }
    // const handleUnitNumChange = (event) => {
    //     console.log(event.target.value)
    //     setOrdererState({
    //         ...ordererState,
    //         unitNum: event.target.value
    //     })
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             unitNum: event.target.value
    //         }
    //     }
    // }
    // const handleReferenceChange = (event) => {
    //     console.log(event.target.value)
    //     setOrdererState({
    //         ...ordererState,
    //         reference: event.target.value
    //     })
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             reference: event.target.value
    //         }
    //     }
    // }
    // const handleEquipmentSizechange = (event) => {
    //     console.log(event.target.value)
    //     setOrdererState({
    //         ...ordererState,
    //         equipmentSize: event.target.value
    //     })
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             equipmentSize: event.target.value
    //         }
    //     }
    // }
    // const handleReleaseChange = (event) => {
    //     console.log(event.target.value)
    //     setOrdererState({
    //         ...ordererState,
    //         release: event.target.value
    //     })
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             release: event.target.value
    //         }
    //     }
    // }
    // const handleChassisChange = (event) => {
    //     console.log(event.target.value)
    //     setOrdererState({
    //         ...ordererState,
    //         chassis: event.target.value
    //     })
    //     billingControlRef.current = {
    //         ...billingControlRef.current,
    //         orderBy: {
    //             ...ordererState,
    //             chassis: event.target.value
    //         }
    //     }
    // }

    // // Lookup handlers
    // const orderByLookupTimeout = useRef(null)
    // const handleOrderByLookup = (event, value, reason) => {
    //     console.log(value, reason)
    //     if (value && reason !== 'reset') {
    //         if (orderByLookupTimeout.current)
    //             clearTimeout(orderByLookupTimeout.current)

    //         const timer = setTimeout(async () => {
    //             // dispatch(orderByLookUp({ code: "searchVal", value: value }))
    //             let url = `/orders/getOrderByLookUpData?searchVal=${value}`
    //             let res = await GET(url)
    //             if (res.status !== 'error') {
    //                 setOrderByOptions(res.data)
    //             }
    //         }, 1000)

    //         orderByLookupTimeout.current = timer
    //     }
    //     else {
    //         console.log('Reason is reset not making an api call')
    //     }
    // }

    // // Load existing order by
    // useEffect(() => {
    //     if (billingControlRef.current.orderBy.orderBy) {
    //         console.log(('dispatching orderby', `_${billingControlRef.current.orderBy.orderBy}_`))
    //         let url = `/orders/getOrderByLookUpData?searchVal=${billingControlRef.current.orderBy.orderBy}`
    //         console.log('url', url)
    //         GET(url)
    //             .then((res) => {
    //                 console.log('Response', res)
    //                 if (res.status !== 'error') {
    //                     setOrderByOptions(res.data)
    //                     const selected = res.data.filter(item => item.code === billingControlRef.current.orderBy.orderBy)
    //                     if (selected.length > 0) {
    //                         setOrderByCodeSelect(selected[0])
    //                         setOrdererState({
    //                             ...ordererState,
    //                             billToName: selected[0].billToName,
    //                             edi204: selected[0].edi204 ? selected[0].edi204 : 'N',
    //                             edi214: selected[0].edi214 ? selected[0].edi214 : 'N',
    //                             edi210: selected[0].edi210 ? selected[0].edi210 : 'N',

    //                         })
    //                     }
    //                 }
    //             })
    //             .catch((err) => {
    //                 console.log('error occurred')
    //             });
    //     }
    // }, [])

    const validateDuplicateEntry = (event) => {
        if (event.target.value !== '' && billingControlRef.current.orderBy.orderBy !== '') {
            setSnackOpen(true)
            setSnackContent({ severity: "info", message: `Validating duplicate order content!` })
            let postData = [
                // { "clause": "AND", "field": "shipperAgentCode", "operand": "IS", "value": billingControlRef.current.orderBy.orderBy },
                { "clause": "AND", "field": "customerNumber", "operand": "IS", "value": billingControlRef.current.orderBy.billTo },
                { "clause": "AND", "field": "unitNumber", "operand": "IS", "value": event.target.value },
                { "clause": "AND", "field": "locationNumber", "operand": "IS", "value": params.locationNumber }
            ]
            POST(`/elastic/stops/_search?includeRates=true`, postData)
                .then(res => {
                    if (res?.httpCode === 200 && res?.status === "success") {
                        if (res.data.total.value > 0) {
                            setSnackOpen(false)
                            let controlNo = res.data.hits?.[0]?._source.controlNumber || 0;
                            if (Number(params.controlNumber) === Number(controlNo))
                                return false;
                            const confirm = window.confirm(`Duplicate Order - A record with same Unit# & Bill To number already exist. [Active Order: ${controlNo}]`);
                            if (!confirm) {
                                setEquipmentState({
                                    ...equipmentState,
                                    unitNum: ''
                                })
                                billingControlRef.current = {
                                    ...billingControlRef.current,
                                    equipmentDetails: {
                                        ...equipmentState,
                                        unitNum: ''
                                    }
                                }
                            }
                        } else {
                            setSnackOpen(true)
                            setSnackContent({ severity: "success", message: `There is no duplicate order content!` })
                        }
                    }
                }).catch(error => {
                    setSnackOpen(true)
                    setSnackContent({ severity: "error", message: `Failed to validate duplicate order content!` })
                })
        }
    }
    return (
        (isTemplateScreen ? (
        <div className="mainContainer width_100" >
            <Divider
                textAlign="left"
                className="dividersty"
            >
                <Chip label="Equipment Details" size="small" variant="outlined" />
            </Divider>
            <Paper className="paperstyl" variant="outlined">
                <table className="width_100">
                    <tbody className="width_100">

                        <tr className="width_100">
                            <td className="width_50" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    label="Unit #"
                                    size="small"
                                    className="width_100"
                                    style={{ flex: 1, minWidth: 0 }}
                                    value={equipmentState.unitNum || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setEquipmentState({
                                            ...equipmentState,
                                            unitNum: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            equipmentDetails: {
                                                ...equipmentState,
                                                unitNum: event.target.value
                                            }
                                        }
                                        handleFieldChanges()
                                    }}
                                    sx={
                                        (selectedOrderType === 'INBOUND' || selectedOrderType === 'IMPORT') ? {
                                            input: {
                                                // background: isTemplateScreen ? null : "#F8F0E3",
                                                background: (!isTemplateScreen)? "#F8F0E3" : (checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.unitNum"))? "#EDE21196": null ,
                                            }
                                        } : {
                                            input: {
                                                // background: isTemplateScreen ? null : "#F8F0E3",
                                                background: (checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.unitNum"))? "#EDE21196": null ,
                                            }
                                        }
                                    }
                                    disabled={disableFull}
                                    onBlur={(event) => validateDuplicateEntry(event)}
                                />
                            </td>
                            <td className="width_50">
                                    {
                                        ((selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') && !addEditShipper)? (
                                            <div className="width_100" style={{ display: 'flex', alignItems: 'center', padding: 0, width: '100%' }}>
                                                <TextField
                                                    label="Size"
                                                    size="small"
                                                    style={{ flex: '1 1 30%', marginRight: 5 }}
                                                    value={equipmentState.equipmentSize || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8 }}
                                                    onChange={(event) => {
                                                        setEquipmentState({
                                                            ...equipmentState,
                                                            equipmentSize: event.target.value
                                                        });
                                                        billingControlRef.current = {
                                                            ...billingControlRef.current,
                                                            equipmentDetails: {
                                                                ...equipmentState,
                                                                equipmentSize: event.target.value
                                                            }
                                                        };
                                                        handleFieldChanges();
                                                    }}
                                                    sx={{
                                                        input: {
                                                            background: (!isTemplateScreen) ? "#F8F0E3" : checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.equipmentSize")?"#EDE21196":null,
                                                            lineHeight: 1.2, // Ensures consistent height
                                                            padding: '8px 14px' // Matches the height with other fields
                                                        }
                                                    }}
                                                    disabled={disableFull}
                                                />
                                                <Autocomplete
                                                    disablePortal
                                                    label="SSL"
                                                    forcePopupIcon={false}
                                                    id="ssl-select"
                                                    size="small"
                                                    value={sslSelect}
                                                    options={sslOptions}
                                                    getOptionLabel={(option) => `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                    renderOption={(props, option) => (
                                                        <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                            {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                        </li>
                                                    )}
                                                    isOptionEqualToValue={(option, value) => option.code === value.code}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="SSL"
                                                            style={{  marginLeft: 'auto' }}
                                                            sx={{
                                                                input: {
                                                                    background: isTemplateScreen ? null : "#F8F0E3",
                                                                    textTransform: 'uppercase',
                                                                    lineHeight: 1.2, // Ensures consistent height
                                                                    padding: '8px 14px' // Matches the height with other fields
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    onInputChange={handleSSLLookup}
                                                    onChange={handleSSLChange}
                                                    componentsProps={{
                                                        paper: {
                                                            sx: {
                                                                width: 500
                                                            }
                                                        }
                                                    }}
                                                    disabled={disableFull}
                                                    style={{flex: '1 1 50%'}}
                                                />
                                                <IconButton  style={{ flex: '1 1 20%', marginLeft: 'auto' }} onClick={handleAddEditShipperClick} disabled={disableFull}>
                                                    {addEditShipperMode ? <EditIcon /> : <AddIcon />}
                                                </IconButton>
                                            </div>
                                        ) : (
                                            <div className="width_100" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <TextField
                                                    label="Size"
                                                    size="small"
                                                    style={{ flex: 1, minWidth: 0 }}
                                                    value={equipmentState.equipmentSize || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8 }}
                                                    onChange={(event) => {
                                                        setEquipmentState({
                                                            ...equipmentState,
                                                            equipmentSize: event.target.value
                                                        });
                                                        billingControlRef.current = {
                                                            ...billingControlRef.current,
                                                            equipmentDetails: {
                                                                ...equipmentState,
                                                                equipmentSize: event.target.value
                                                            }
                                                        };
                                                        handleFieldChanges();
                                                    }}
                                                    sx={{
                                                        input: {
                                                            background: (checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.equipmentSize")) ? "#EDE21196" : null,
                                                            lineHeight: 1.2, // Ensures consistent height
                                                            padding: '8px 14px' // Matches the height with other fields
                                                        }
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </div>
                                        )
                                    }
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Chassis"
                                    size="small"
                                    className="width_100"
                                    value={equipmentState.chassis || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setEquipmentState({
                                            ...equipmentState,
                                            chassis: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            equipmentDetails: {
                                                ...equipmentState,
                                                chassis: event.target.value
                                            }
                                        }
                                        handleFieldChanges()
                                    }}
                                    disabled={disableFull}
                                />
                            </td>
                            {
                                (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? (
                                        <td className="width_50">
                                            <TextField
                                                label="Chassis Provider"
                                                size="small"
                                                className="width_100"
                                                value={equipmentState.chassisProvider || ''}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                                onChange={(event) => {
                                                    console.log(event.target.value)
                                                    setEquipmentState({
                                                        ...equipmentState,
                                                        chassisProvider: event.target.value
                                                    })
                                                    billingControlRef.current = {
                                                        ...billingControlRef.current,
                                                        equipmentDetails: {
                                                            ...equipmentState,
                                                            chassisProvider: event.target.value
                                                        }
                                                    }
                                                    handleFieldChanges()
                                                }}
                                                disabled
                                            />
                                        </td> 
                                    ) : (
                                        <td className="width_50">
                                            <TextField
                                                label="Unit Release"
                                                size="small"
                                                className="width_100"
                                                value={equipmentState.release || ''}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                                onChange={(event) => {
                                                    console.log(event.target.value)
                                                    setEquipmentState({
                                                        ...equipmentState,
                                                        release: event.target.value
                                                    })
                                                    billingControlRef.current = {
                                                        ...billingControlRef.current,
                                                        equipmentDetails: {
                                                            ...equipmentState,
                                                            release: event.target.value
                                                        }
                                                    }
                                                    handleFieldChanges()
                                                }}
                                                disabled={disableFull}
                                            />
                                        </td>  
                                    )
                                }
                        </tr>
                        {
                            ((selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') && addEditShipper) &&
                            <div className="" style={{ width: '200%' }}>
                                <table>
                                    <tbody>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Shipper/Cons Code"
                                                    size="small"
                                                    value={shipperState?.code || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 7 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, code: event.target.value })
                                                    }}
                                                    disabled={disableFull || addEditShipperMode}
                                                />
                                            </td>
                                            <td className="width_50">
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Shipper/Cons Name"
                                                    size="small"
                                                    value={shipperState?.name || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, name: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Address"
                                                    size="small"
                                                    value={shipperState?.address || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, address: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="City"
                                                    size="small"
                                                    value={shipperState?.city || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, city: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="State"
                                                    size="small"
                                                    value={shipperState?.state || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 2 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, state: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Zip"
                                                    size="small"
                                                    value={shipperState?.zip || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, zip: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Phone"
                                                    size="small"
                                                    value={shipperState?.phone || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, phone: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Fax"
                                                    size="small"
                                                    value={shipperState?.fax || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, fax: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Yard"
                                                    size="small"
                                                    value={shipperState?.yard || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 1 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, yard: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                    select
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    <option selected value={`N`}>N</option>
                                                    <option value={`Y`}>Y</option >
                                                </TextField>
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Notes 1"
                                                    size="small"
                                                    value={shipperState?.note1 || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, note1: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Notes 2"
                                                    size="small"
                                                    value={shipperState?.note2 || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, note2: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Notes 3"
                                                    size="small"
                                                    value={shipperState?.note3 || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, note3: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <Button onClick={handleAddShipper} disabled={disableFull}>
                                                    {addEditShipperMode ? "Update" : "Add"}
                                                </Button>
                                                <Button onClick={() => resetShipper()}>
                                                    Back
                                                </Button>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                            <tr className="width_100">
                                <td className="width_50">
                                    {
                                        (selectedOrderType === 'IMPORT') ?
                                            (

                                                <TextField
                                                    label="Release"
                                                    size="small"
                                                    className="width_100"
                                                    value={equipmentState.release || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                                    onChange={(event) => {
                                                        console.log(event.target.value)
                                                        setEquipmentState({
                                                            ...equipmentState,
                                                            release: event.target.value
                                                        })
                                                        billingControlRef.current = {
                                                            ...billingControlRef.current,
                                                            equipmentDetails: {
                                                                ...equipmentState,
                                                                release: event.target.value
                                                            }
                                                        }
                                                        handleFieldChanges()
                                                    }}
                                                    disabled={disableFull}
                                                />

                                            ) : (

                                                <TextField
                                                    label="Booking"
                                                    size="small"
                                                    className="width_100"
                                                    value={equipmentState.booking || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={(event) => {
                                                        console.log(event.target.value)
                                                        setEquipmentState({
                                                            ...equipmentState,
                                                            booking: event.target.value
                                                        })
                                                        billingControlRef.current = {
                                                            ...billingControlRef.current,
                                                            equipmentDetails: {
                                                                ...equipmentState,
                                                                booking: event.target.value
                                                            }
                                                        }
                                                        handleFieldChanges()
                                                    }}
                                                    sx={
                                                        (selectedOrderType === 'EXPORT') ? {
                                                            input: {
                                                                background: isTemplateScreen ? null : "#F8F0E3",
                                                            }
                                                        } : {}
                                                    }
                                                    disabled={disableFull}
                                                />
                                            )
                                    }
                                </td>
                                <td className="width_50">
                                    <TextField
                                        label="Chassis Release"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.chassisRelease || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                chassisRelease: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    chassisRelease: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td>
                            </tr>

                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                            <tr className="width_100">
                                <td className="width_50" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <TextField
                                        label="Vessel"
                                        size="small"
                                        className="width_100"
                                        style={{ flex: 1, minWidth: 0 }}
                                        value={equipmentState.vessel || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                vessel: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    vessel: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td>
                                <td className="width_50">   
                                    <div className="width_100" style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            label="Voyage"
                                            size="small"
                                            style={{ flex: 1, maxWidth: 70, marginRight: 5 }}
                                            value={equipmentState.voyage || ''}
                                            inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' }, maxLength: 5 }}
                                            onChange={(event) => {
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    voyage: event.target.value
                                                });
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        voyage: event.target.value
                                                    }
                                                };
                                                handleFieldChanges();
                                            }}
                                            disabled={disableFull}
                                        />
                                        <TextField
                                            label="Destination Port"
                                            size="small"
                                            style={{ flex: 1 }}
                                            inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' } }}
                                            value={equipmentState.destinationPort || ''}
                                            onChange={(event) => {
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    destinationPort: event.target.value
                                                });
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        destinationPort: event.target.value
                                                    }
                                                };
                                                handleFieldChanges();
                                            }}
                                                disabled={disableFull}
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') &&
                            <tr className="width_100">
                                <td className="width_50">
                                    <TextField
                                        label="Equipment Type"
                                        size="small"
                                        value={equipmentState.equipmentType || ''}
                                        className="width_100"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                equipmentType: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    equipmentType: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td>
                                <td>
                                    <TextField
                                        label="Secondary Unit"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.secondaryUnit || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                secondaryUnit: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    secondaryUnit: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td>
                            </tr>
                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? (
                                <tr className="width_100">
                                    <td className="width_50">
                                        <TextField
                                            label="Secondary Unit"
                                            size="small"
                                            className="width_100"
                                            value={equipmentState.secondaryUnit || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                            onChange={(event) => {
                                                console.log(event.target.value)
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    secondaryUnit: event.target.value
                                                })
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        secondaryUnit: event.target.value
                                                    }
                                                }
                                                handleFieldChanges()
                                            }}
                                            disabled={disableFull}
                                        />
                                    </td>
                                    <td className="width_50">
                                        <TextField
                                            label="Secondary Chassis"
                                            size="small"
                                            className="width_100"
                                            value={equipmentState.secondaryChassis || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                            onChange={(event) => {
                                                console.log(event.target.value)
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    secondaryChassis: event.target.value
                                                })
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        secondaryChassis: event.target.value
                                                    }
                                                }
                                                handleFieldChanges()
                                            }}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                ) : (
                                <tr className="width_100">
                                    <td className="width_50">
                                        <TextField
                                            label="Secondary Chassis"
                                            size="small"
                                            className="width_100"
                                            value={equipmentState.secondaryChassis || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                            onChange={(event) => {
                                                console.log(event.target.value)
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    secondaryChassis: event.target.value
                                                })
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        secondaryChassis: event.target.value
                                                    }
                                                }
                                                handleFieldChanges()
                                            }}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                )
                            }
                    </tbody>
                </table>
                <FormGroup sx={{ position: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="HazMat"
                                checked={equipmentState.hazMat === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        hazMat: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            hazMat: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled={disableFull}
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> HazMat </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Tanker"
                                checked={equipmentState.tanker === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        tanker: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            tanker: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> Tanker </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="OW"
                                checked={equipmentState.overWeight === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        overWeight: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            overWeight: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> OW </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Reefer"
                                checked={equipmentState.reefer === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        reefer: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            reefer: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> Reefer </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Rez"
                                checked={equipmentState.residential === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        residential: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            residential: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> Rez </Typography>}
                        labelPlacement="end"
                    />
                </FormGroup>
            </Paper>
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
                <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </div>
        ) : (
            <div className="mainContainer width_100" style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Divider style={{ flex: 1, marginRight: '8px' }} />
                <Chip label="Equipment Details" size="small" variant="outlined" />
                <Divider style={{ flex: 1, marginLeft: '8px' }} />
            </div>
            <Paper className="paperstyl1" variant="outlined" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                <table className="width_100">
                    <tbody className="width_100">
                        <tr className="width_100">
                            <td className="width_50" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <TextField
                                    label="Unit #"
                                    size="small"
                                    className="width_100"
                                    style={{ flex: 1, minWidth: 0 }}
                                    value={equipmentState.unitNum || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, fontSize: '14px' }}
                                    onChange={(event) => {
                                        setEquipmentState({
                                            ...equipmentState,
                                            unitNum: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            equipmentDetails: {
                                                ...equipmentState,
                                                unitNum: event.target.value
                                            }
                                        }
                                        handleFieldChanges()
                                    }}
                                    sx={{
                                        input: {
                                            background: !isTemplateScreen ? "#F8F0E3" : checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.unitNum")? "#EDE21196":null,
                                            fontSize: '14px', 
                                            textTransform: 'uppercase'
                                        },
                                    }}
                                    disabled={disableFull}
                                    onBlur={(event) => validateDuplicateEntry(event)}
                                />
                            </td>
                            <td className="width_50">
                                {
                                    ((selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') && !addEditShipper)? (
                                        <div className="width_100" style={{ display: 'flex', alignItems: 'center', padding: 0, width: '100%' }}>
                                            <TextField
                                                label="Size"
                                                size="small"
                                                style={{ flex: '1 1 30%', marginRight: 5 }}
                                                value={equipmentState.equipmentSize || ''}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8 }}
                                                onChange={(event) => {
                                                    setEquipmentState({
                                                        ...equipmentState,
                                                        equipmentSize: event.target.value
                                                    });
                                                    billingControlRef.current = {
                                                        ...billingControlRef.current,
                                                        equipmentDetails: {
                                                            ...equipmentState,
                                                            equipmentSize: event.target.value
                                                        }
                                                    };
                                                    handleFieldChanges();
                                                }}
                                                sx={{
                                                    input: {
                                                        background: (!isTemplateScreen) ? "#F8F0E3":checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.equipmentSize")?"#EDE21196" : null,
                                                        lineHeight: 1.2, // Ensures consistent height
                                                        padding: '8px 14px' // Matches the height with other fields
                                                    }
                                                }}
                                                disabled={disableFull}
                                            />
                                            <Autocomplete
                                                disablePortal
                                                label="SSL"
                                                forcePopupIcon={false}
                                                id="ssl-select"
                                                size="small"
                                                value={sslSelect}
                                                options={sslOptions}
                                                getOptionLabel={(option) => `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                renderOption={(props, option) => (
                                                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                        {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                    </li>
                                                )}
                                                isOptionEqualToValue={(option, value) => option.code === value.code}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="SSL"
                                                        style={{ marginRight: 5 }}
                                                        sx={{
                                                            input: {
                                                                background: isTemplateScreen ? null : "#F8F0E3",
                                                                textTransform: 'uppercase',
                                                                lineHeight: 1.2, // Ensures consistent height
                                                                padding: '8px 14px' // Matches the height with other fields
                                                            }
                                                        }}
                                                    />
                                                )}
                                                onInputChange={handleSSLLookup}
                                                onChange={handleSSLChange}
                                                componentsProps={{
                                                    paper: {
                                                        sx: {
                                                            width: 500
                                                        }
                                                    }
                                                }}
                                                disabled={disableFull}
                                                style={{ flex: '1 1 50%' }}
                                            />
                                            <IconButton style={{ flex: '1 1 20%', marginLeft: 'auto' }} onClick={handleAddEditShipperClick} disabled={disableFull}> 
                                                {addEditShipperMode ? <EditIcon /> : <AddIcon />}
                                            </IconButton>
                                        </div>
                                    ) : (
                                        <div className="width_100" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <TextField
                                                label="Size"
                                                size="small"
                                                style={{ flex: 1, minWidth: 0 }}
                                                value={equipmentState.equipmentSize || ''}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8 }}
                                                onChange={(event) => {
                                                    setEquipmentState({
                                                        ...equipmentState,
                                                        equipmentSize: event.target.value
                                                    });
                                                    billingControlRef.current = {
                                                        ...billingControlRef.current,
                                                        equipmentDetails: {
                                                            ...equipmentState,
                                                            equipmentSize: event.target.value
                                                        }
                                                    };
                                                    handleFieldChanges();
                                                }}
                                                sx={{
                                                    input: {
                                                        background: (checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.equipmentSize")) ? "#EDE21196" : null,
                                                        lineHeight: 1.2, // Ensures consistent height
                                                        padding: '8px 14px' // Matches the height with other fields
                                                    }
                                                }}
                                                disabled={disableFull}
                                            />
                                        </div>
                                    )
                                }
                            </td>
                        </tr>
                        <tr className="width_100">
                            <td className="width_50">
                                <TextField
                                    label="Chassis"
                                    size="small"
                                    className="width_100"
                                    value={equipmentState.chassis || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setEquipmentState({
                                            ...equipmentState,
                                            chassis: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            equipmentDetails: {
                                                ...equipmentState,
                                                chassis: event.target.value
                                            }
                                        }
                                        handleFieldChanges()
                                    }}
                                    disabled={disableFull}
                                />
                            </td>
                            {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? (
                                    <td className="width_50">
                                        <TextField
                                            label="Chassis Provider"
                                            size="small"
                                            className="width_100"
                                            value={equipmentState.chassisProvider || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                            onChange={(event) => {
                                                console.log(event.target.value)
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    chassisProvider: event.target.value
                                                })
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        chassisProvider: event.target.value
                                                    }
                                                }
                                                handleFieldChanges()
                                            }}
                                            disabled
                                        />
                                    </td> 
                                ) : (
                                    <td className="width_50">
                                        <TextField
                                            label="Unit Release"
                                            size="small"
                                            className="width_100"
                                            value={equipmentState.release || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                            onChange={(event) => {
                                                console.log(event.target.value)
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    release: event.target.value
                                                })
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        release: event.target.value
                                                    }
                                                }
                                                handleFieldChanges()
                                            }}
                                            disabled={disableFull}
                                        />
                                    </td>  
                                )
                            }
                        </tr>
                        {/* <tr className="width_100"> */}
                            {/* <td className="width_50">
                                <TextField
                                    label="Equipment Size"
                                    size="small"
                                    className="width_100"
                                    value={equipmentState.equipmentSize || ''}
                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 8 }}
                                    onChange={(event) => {
                                        console.log(event.target.value)
                                        setEquipmentState({
                                            ...equipmentState,
                                            equipmentSize: event.target.value
                                        })
                                        billingControlRef.current = {
                                            ...billingControlRef.current,
                                            equipmentDetails: {
                                                ...equipmentState,
                                                equipmentSize: event.target.value
                                            }
                                        }
                                        handleFieldChanges()
                                    }}
                                    sx={
                                        (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? {
                                            input: {
                                                // background: isTemplateScreen ? null : "#F8F0E3",
                                                background: (!isTemplateScreen ||(checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.equipmentSize")))? "#F8F0E3" : null ,

                                            }
                                        } : {
                                            input: {
                                                
                                                background: (checkNonMatchingFields(nonMatchingFields, "billingControl.equipmentDetails.equipmentSize"))? "#F8F0E3" : null ,

                                            }
                                        }
                                    }
                                    disabled={disableFull}
                                />
                            </td> */}
                            {/* {
                                (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') ?
                                    (
                                        <td className="width_50">
                                            <TextField
                                                label="Unit Release"
                                                size="small"
                                                className="width_100"
                                                value={equipmentState.release || ''}
                                                inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                                onChange={(event) => {
                                                    console.log(event.target.value)
                                                    setEquipmentState({
                                                        ...equipmentState,
                                                        release: event.target.value
                                                    })
                                                    billingControlRef.current = {
                                                        ...billingControlRef.current,
                                                        equipmentDetails: {
                                                            ...equipmentState,
                                                            release: event.target.value
                                                        }
                                                    }
                                                    handleFieldChanges()
                                                }}
                                                disabled={disableFull}
                                            />
                                        </td>

                                    ) : (
                                        <td className="width_50">
                                            {(!addEditShipper &&
                                                <div className="width_100" style={{ display: 'flex' }}> */}
                                                    {/* <Autocomplete
                                                        disablePortal
                                                        //disableClearable
                                                        label="SSL"
                                                        forcePopupIcon={false}
                                                        id="ssl-select"
                                                        size="small"
                                                        className="width_100"
                                                        value={sslSelect}
                                                        options={sslOptions}
                                                        getOptionLabel={(option) => `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                        renderOption={(props, option) => {
                                                            return (
                                                                <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                                                                    {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                                                                </li>
                                                            )
                                                        }}
                                                        isOptionEqualToValue={(option, value) => option.code === value.code}
                                                        renderInput={(params) =>
                                                            <TextField
                                                                {...params}
                                                                value={params.code}
                                                                label="SSL"
                                                                sx={{
                                                                    input: {
                                                                        background: isTemplateScreen ? null : "#F8F0E3",
                                                                        textTransform: 'uppercase'
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                        onInputChange={handleSSLLookup}
                                                        onChange={handleSSLChange}
                                                        sx={
                                                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? {
                                                                input: {
                                                                    background: isTemplateScreen ? null : "#F8F0E3",
                                                                }
                                                            } : {}
                                                        }
                                                        componentsProps={{
                                                            paper: {
                                                                sx: {
                                                                    width: 500
                                                                }
                                                            }
                                                        }}

                                                        disabled={disableFull}
                                                    />
                                                    <IconButton onClick={handleAddEditShipperClick} disabled={disableFull}>
                                                        {addEditShipperMode ? <EditIcon /> : <AddIcon />}
                                                    </IconButton> */}
                                                {/* </div>
                                            )}
                                        </td>

                                    )
                            } */}
                        {/* </tr> */}
                        {
                            ((selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') && addEditShipper) &&
                            <div className="" style={{ width: '200%' }}>
                                <table>
                                    <tbody>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Shipper/Cons Code"
                                                    size="small"
                                                    value={shipperState?.code || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 7 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, code: event.target.value })
                                                    }}
                                                    disabled={disableFull || addEditShipperMode}
                                                />
                                            </td>
                                            <td className="width_50">
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Shipper/Cons Name"
                                                    size="small"
                                                    value={shipperState?.name || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, name: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Address"
                                                    size="small"
                                                    value={shipperState?.address || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, address: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="City"
                                                    size="small"
                                                    value={shipperState?.city || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, city: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="State"
                                                    size="small"
                                                    value={shipperState?.state || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 2 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, state: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Zip"
                                                    size="small"
                                                    value={shipperState?.zip || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, zip: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Phone"
                                                    size="small"
                                                    value={shipperState?.phone || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, phone: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Fax"
                                                    size="small"
                                                    value={shipperState?.fax || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, fax: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Yard"
                                                    size="small"
                                                    value={shipperState?.yard || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 1 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, yard: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                    select
                                                    SelectProps={{
                                                        native: true,
                                                    }}
                                                >
                                                    <option selected value={`N`}>N</option>
                                                    <option value={`Y`}>Y</option >
                                                </TextField>
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Notes 1"
                                                    size="small"
                                                    value={shipperState?.note1 || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, note1: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td className="width_50">
                                                <TextField
                                                    label="Notes 2"
                                                    size="small"
                                                    value={shipperState?.note2 || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, note2: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <TextField
                                                    label="Notes 3"
                                                    size="small"
                                                    value={shipperState?.note3 || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={event => {
                                                        setShipperState({ ...shipperState, note3: event.target.value })
                                                    }}
                                                    disabled={disableFull}
                                                />
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                        <tr className="width_100">
                                            <td className="width_50">
                                                <Button onClick={handleAddShipper} disabled={disableFull}>
                                                    {addEditShipperMode ? "Update" : "Add"}
                                                </Button>
                                                <Button onClick={() => resetShipper()}>
                                                    Back
                                                </Button>
                                            </td>
                                            <td>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                            <tr className="width_100">
                                <td className="width_50">
                                    {
                                        (selectedOrderType === 'IMPORT') ?
                                            (

                                                <TextField
                                                    label="Release"
                                                    size="small"
                                                    className="width_100"
                                                    value={equipmentState.release || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                                    onChange={(event) => {
                                                        console.log(event.target.value)
                                                        setEquipmentState({
                                                            ...equipmentState,
                                                            release: event.target.value
                                                        })
                                                        billingControlRef.current = {
                                                            ...billingControlRef.current,
                                                            equipmentDetails: {
                                                                ...equipmentState,
                                                                release: event.target.value
                                                            }
                                                        }
                                                        handleFieldChanges()
                                                    }}
                                                    disabled={disableFull}
                                                />

                                            ) : (

                                                <TextField
                                                    label="Booking"
                                                    size="small"
                                                    className="width_100"
                                                    value={equipmentState.booking || ''}
                                                    inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                                    onChange={(event) => {
                                                        console.log(event.target.value)
                                                        setEquipmentState({
                                                            ...equipmentState,
                                                            booking: event.target.value
                                                        })
                                                        billingControlRef.current = {
                                                            ...billingControlRef.current,
                                                            equipmentDetails: {
                                                                ...equipmentState,
                                                                booking: event.target.value
                                                            }
                                                        }
                                                        handleFieldChanges()
                                                    }}
                                                    sx={
                                                        (selectedOrderType === 'EXPORT') ? {
                                                            input: {
                                                                background: isTemplateScreen ? null : "#F8F0E3",
                                                            }
                                                        } : {}
                                                    }
                                                    disabled={disableFull}
                                                />
                                            )
                                    }
                                </td>
                                    <td className="width_50">
                                        <TextField
                                            label="Chassis Release"
                                            size="small"
                                            className="width_100"
                                            value={equipmentState.chassisRelease || ''}
                                            inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                            onChange={(event) => {
                                                console.log(event.target.value)
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    chassisRelease: event.target.value
                                                })
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        chassisRelease: event.target.value
                                                    }
                                                }
                                                handleFieldChanges()
                                            }}
                                            disabled
                                        />
                                    </td>
                                {/* <td className="width_50">
                                    <TextField
                                        label="Voyage"
                                        size="small"
                                        value={equipmentState.voyage || ''}
                                        className="width_100"

                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 5 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                voyage: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    voyage: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td> */}
                            </tr>

                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                            <tr className="width_100">
                                <td className="width_50" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                    <TextField
                                        label="Vessel"
                                        size="small"
                                        className="width_100"
                                        style={{ flex: 1, minWidth: 0 }}
                                        value={equipmentState.vessel || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                vessel: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    vessel: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td>
                                {/* <td className="width_50">
                                    <TextField
                                        label="Destination Port"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.destinationPort || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                destinationPort: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    destinationPort: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td> */}
                                <td className="width_50">
                                    <div className="width_100" style={{ display: 'flex', alignItems: 'center' }}>
                                        <TextField
                                            label="Voyage"
                                            size="small"
                                            style={{ flex: 1, maxWidth: 70, marginRight: 5 }}
                                            value={equipmentState.voyage || ''}
                                            inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' }, maxLength: 5 }}
                                            onChange={(event) => {
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    voyage: event.target.value
                                                });
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        voyage: event.target.value
                                                    }
                                                };
                                                handleFieldChanges();
                                            }}
                                            disabled={disableFull}
                                        />
                                        <TextField
                                            label="Destination Port"
                                            size="small"
                                            style={{ flex: 1 }}
                                            inputProps={{ style: { textTransform: "uppercase", fontSize: '14px' } }}
                                            value={equipmentState.destinationPort || ''}
                                            onChange={(event) => {
                                                setEquipmentState({
                                                    ...equipmentState,
                                                    destinationPort: event.target.value
                                                });
                                                billingControlRef.current = {
                                                    ...billingControlRef.current,
                                                    equipmentDetails: {
                                                        ...equipmentState,
                                                        destinationPort: event.target.value
                                                    }
                                                };
                                                handleFieldChanges();
                                            }}
                                            disabled={disableFull}
                                        />
                                    </div>
                                </td>
                            </tr>
                        }
                        {
                            (selectedOrderType === 'INBOUND' || selectedOrderType === 'OUTBOUND') &&
                            <tr className="width_100">
                                <td className="width_50">
                                    <TextField
                                        label="Equipment Type"
                                        size="small"
                                        value={equipmentState.equipmentType || ''}
                                        className="width_100"
                                        inputProps={{ style: { textTransform: "uppercase" } }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                equipmentType: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    equipmentType: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td>
                                <td>
                                    <TextField
                                        label="Secondary Unit"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.secondaryUnit || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                secondaryUnit: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    secondaryUnit: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td>
                            </tr>
                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') ? (
                                <tr className="width_100">
                                <td className="width_50">
                                    <TextField
                                        label="Secondary Unit"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.secondaryUnit || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                secondaryUnit: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    secondaryUnit: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled={disableFull}
                                    />
                                </td>
                                <td className="width_50">
                                    <TextField
                                        label="Secondary Chassis"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.secondaryChassis || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                secondaryChassis: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    secondaryChassis: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td>
                            </tr>
                            ) : (
                                <td className="width_50">
                                    <TextField
                                        label="Secondary Chassis"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.secondaryChassis || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                secondaryChassis: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    secondaryChassis: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td>
                            )
                        }
                        {
                            (selectedOrderType === 'IMPORT' || selectedOrderType === 'EXPORT') &&
                            <tr className="width_100">
                                {/* <td className="width_50">
                                    <TextField
                                        label="Chassis Provider"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.chassisProvider || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                chassisProvider: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    chassisProvider: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td> */}
                                {/* <td className="width_50">
                                    <TextField
                                        label="Chassis Release"
                                        size="small"
                                        className="width_100"
                                        value={equipmentState.chassisRelease || ''}
                                        inputProps={{ style: { textTransform: "uppercase" }, maxLength: 15 }}
                                        onChange={(event) => {
                                            console.log(event.target.value)
                                            setEquipmentState({
                                                ...equipmentState,
                                                chassisRelease: event.target.value
                                            })
                                            billingControlRef.current = {
                                                ...billingControlRef.current,
                                                equipmentDetails: {
                                                    ...equipmentState,
                                                    chassisRelease: event.target.value
                                                }
                                            }
                                            handleFieldChanges()
                                        }}
                                        disabled
                                    />
                                </td> */}
                            </tr>
                        }
                    </tbody>
                </table>
                <FormGroup sx={{ position: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="HazMat"
                                checked={equipmentState.hazMat === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        hazMat: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            hazMat: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled={disableFull}
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> HazMat </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Tanker"
                                checked={equipmentState.tanker === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        tanker: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            tanker: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> Tanker </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="OW"
                                checked={equipmentState.overWeight === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        overWeight: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            overWeight: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> OW </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Reefer"
                                checked={equipmentState.reefer === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        reefer: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            reefer: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> Reefer </Typography>}
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="Rez"
                                checked={equipmentState.residential === 'Y'}
                                onChange={(event) => {
                                    console.log(event.target.value)
                                    setEquipmentState({
                                        ...equipmentState,
                                        residential: event.target.checked ? 'Y' : 'N'
                                    })
                                    billingControlRef.current = {
                                        ...billingControlRef.current,
                                        equipmentDetails: {
                                            ...equipmentState,
                                            residential: event.target.checked ? 'Y' : 'N'
                                        }
                                    }
                                    handleFieldChanges()
                                }}
                                style={{ paddingRight: 3 }}
                                disabled
                            />
                        }
                        label={<Typography style={{ fontSize: '0.9em' }}> Rez </Typography>}
                        labelPlacement="end"
                    />
                </FormGroup>
            </Paper>
            < Snackbar open={snackOpen} style={{ marginTop: "5%" }} anchorOrigin={{ vertical: "top", horizontal: "center" }} onClose={handleClose} >
                <Alert onClose={handleClose} severity={snackContent.severity} sx={{ width: '100%' }}>
                    {snackContent.message}
                </Alert>
            </Snackbar>
        </div>
 
        ))
    );
}
import { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import DoneIcon from '@mui/icons-material/Done';
import CancelIcon from '@mui/icons-material/Close';

import {
    DataGridPro as DataGrid,
    GridRowModes,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
    DEFAULT_GRID_AUTOSIZE_OPTIONS,
    useGridApiRef
} from '@mui/x-data-grid-pro';
import {
    randomId
} from '@mui/x-data-grid-generator';

import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog'

import { calRevenue, validateField, formatRatioPercentage } from "./CommonFn";
import { CustomHeader, CustomColumnAutoResize, GLCodeEditInputCell, OrderByEditInputCellDropdown, CustomEditTextField, RPCEditInputCellDropdown, CityEditInputCellDropdown, StateEditInputCellDropdown } from "./CommonComponents";
import CustomPagination from './components/DGPagination';

// Import Redux Dependencies
import { useDispatch, useSelector } from "react-redux";
import {
    getRateBookData,
    addRateBookData,
    delRateBookData
} from "../../store/actions/rateBookActions";

import _ from 'lodash';

function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;
    const handleClick = () => {
        let id = 0;
        setRows((oldRows) => {

            const minimumIdItem = _.minBy(oldRows, "id");
            id = (typeof minimumIdItem !== 'undefined' && isNaN(minimumIdItem.id) === false) ? Number(minimumIdItem.id) - Number(1) : randomId();

            //if (!found) {
            let newRow = [...oldRows, {
                id,
                notes: "",
                GLCode: 0,
                invoiceDesc: "",
                GLDesc: "",
                originCity: "",
                originState: "",
                destinationCity: "",
                destinationState: "",
                totMiles: "",
                receivableRates: 0,
                LorA: "",
                payableRateContract: 0,
                payableRateBroker: 0,
                billTo: "",
                payableRatioContract: 0,
                payableRatioBroker: 0,
                marginContractor: 0,
                marginBroker: 0,
                revenuePerMile: 0,
                contractorCRM: 0,
                brokerCPM: 0,
                edi210: 'N',
                isNew: true
            }]
            newRow.sort((a, b) => a.id - b.id)

            return newRow;
            // } else {
            //     return oldRows;
            // }
        });
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'notes' },
        }));
    };

    return (
        <GridToolbarContainer sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // //marginTop: -5
        }}>
            <Button
                variant="outlined"
                size="small"
                startIcon={<AddIcon />}
                onClick={handleClick}
                style={{ textTransform: 'capitalize', marginTop: '-4rem', position: 'absolute' }}
            >
                Add record
            </Button>
        </GridToolbarContainer>
    );
}



export default function LinehaulTab(props) {
    //alert(window.innerHeight);
    const { searchStr, setAlertMessage, reloadData } = props;
    const dispatch = useDispatch();
    const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
    const headerLocation = useSelector(({ rateBookReducer }) => rateBookReducer.headerLocationValues);
    const [rows, setRows] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [fieldError, setFieldError] = useState(null);
    const [queryParams, setQueryParams] = useState({
        pageSize: 25,
        pageNumber: 0,
        sortBy: 'notes',
        sortOrder: 'asc',
        type: 'L',
        locationNumber: "",
        searchBy: searchStr,
        reloadData: false
    });
    const [confirmationAlert, setConfirmationAlert] = useState({ show: false, id: null });
    const [isLoading, setIsLoading] = useState(false);

    // Column auto resizing properties
    const [includeHeaders, setIncludeHeaders] = useState(
        DEFAULT_GRID_AUTOSIZE_OPTIONS.includeHeaders,
    );
    const [includeOutliers, setExcludeOutliers] = useState(
        DEFAULT_GRID_AUTOSIZE_OPTIONS.includeOutliers,
    );
    const [outliersFactor, setOutliersFactor] = useState(
        String(DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor),
    );
    const [expand, setExpand] = useState(DEFAULT_GRID_AUTOSIZE_OPTIONS.expand);


    useEffect(() => {
        if (headerLocation !== undefined) {
            setIsLoading(true);
            setQueryParams({ ...queryParams, locationNumber: headerLocation, searchBy: searchStr, reloadData: reloadData })
            fetchDataFromServer();
        }
        // else {
        //     setRows(rateBookReducer?.rateBookList?.data?.data);
        //     setTotalRows(rateBookReducer?.rateBookList?.data?.totalRows);
        // }
    }, [headerLocation, searchStr, queryParams.pageSize, queryParams.pageNumber, queryParams.sortOrder, reloadData]);


    const fetchDataFromServer = async () => {
        if (headerLocation) {
            let reqParams = {
                pageNumber: queryParams.pageNumber,
                pageSize: queryParams.pageSize,
                sortBy: queryParams.sortBy,
                sortOrder: queryParams.sortOrder,
                type: queryParams.type,
                locationNumber: headerLocation,
                searchBy: searchStr
            }
            dispatch(getRateBookData(reqParams)).then((response) => {
                if (response?.status === 'success') {
                    setIsLoading(false);
                    setRows(response.data.data);
                    setTotalRows(response.data.totalRows);
                }
            });

        } else {
            console.log('location is not available yet...');
        }
    }

    // sorting
    const sortChange = (event) => {
        console.log(event);
        if (event.length > 0) {
            setQueryParams({
                ...queryParams,
                sortBy: event[0].field,
                sortOrder: event[0].sort,
            });
        }
    };
    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
        setFieldError(null);
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };


    const handleDeleteClick = (id) => () => {
        setConfirmationAlert({
            show: true, id
        });
        // setRows(rows.filter((row) => row.id !== id));
    };
    const handleDeleteTrue = () => {
        if (confirmationAlert.show && confirmationAlert.id) {
            setRows(rows.filter((row) => row.id !== confirmationAlert.id));
            dispatch(delRateBookData(confirmationAlert.id));
            setAlertMessage({
                severity: 'success',
                children: 'Record has been deleted successfully!'
            });
        }
        setConfirmationAlert({
            show: false,
            id: null
        });
    }

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
        setFieldError(null);
    };

    const postDataToserver = (newRow) => {
        const autoId = (newRow.isNew) ? -1 : newRow.id;
        const updatedRow = { ...newRow };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));

        let postData = { ...updatedRow, autoId: autoId, LorA: queryParams.type, locationNumber: headerLocation }
        setIsLoading(true);
        return dispatch(addRateBookData(postData)).then((res) => {
            setIsLoading(false);
            if (res?.status !== 'success') {
                setAlertMessage({
                    severity: res?.status,
                    children: res.message
                });
                handleEditClick(autoId);
                return false;
            } else {
                setAlertMessage({
                    severity: res?.status,
                    children: res.message
                });
                fetchDataFromServer();
                return true;
            }
        });
    }
    const processRowUpdate = (newRow) => {

        return new Promise((resolve, reject) => {
            //console.log(newRow);
            validateField(newRow).then(async (response) => {
                setFieldError(null);
                const apiRes = await postDataToserver(newRow);
                if (apiRes) {
                    resolve(newRow)
                } else {
                    reject();
                }

            }).catch((error) => {
                //console.log(error);
                setFieldError(error);
                reject(error.message);
                setAlertMessage({
                    children: 'Validation Error, Highlighted fields with red colour are mandatory!',
                    severity: "error",
                });
            })
        })

    };

    const handleProcessRowUpdateError = useCallback((error) => {
        setAlertMessage({ children: error.message, severity: 'error' });
    }, []);

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    /**
     * updated on 06-oct-2023
     * handle column resizing
     */
    const apiRef = useGridApiRef();
    const autosizeOptions = {
        includeHeaders,
        includeOutliers,
        outliersFactor: Number.isNaN(parseFloat(outliersFactor))
            ? 1
            : parseFloat(outliersFactor),
        expand,
    }
    /**
     * ENd
     */

    const columns = [
        {
            field: 'actions',
            headerClassName: 'tableTH',
            type: 'actions',
            headerName: 'Actions',
            headerName: (
                <CustomColumnAutoResize apiRef={apiRef} autosizeOptions={autosizeOptions} />
            ),
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<DoneIcon titleAccess='Save Changes' title='save' />}
                            label="Save"
                            sx={{
                                //color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                            color={`success`}

                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon titleAccess='Cancel Changes' />}
                            label="Cancel"
                            //className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color={`error`}
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon titleAccess='Edit' />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon titleAccess='Delete' />}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            }
        },
        {
            type: "singleSelect",
            field: 'edi210',
            headerClassName: 'tableTH',
            headerName: 'EDI210',
            width: 60,
            editable: true,
            valueOptions: ['N', 'Y'],
            //renderEditCell: (params) => <CustomEditTextField {...params} error={fieldError && fieldError.hasOwnProperty("notes")} />
        },
        {
            type: "text",
            field: 'notes',
            headerClassName: 'tableTH',
            headerName: 'Rate Name',
            width: 130,
            editable: true,
            renderEditCell: (params) => <CustomEditTextField {...params} error={fieldError && fieldError.hasOwnProperty("notes")} />
        },
        {
            field: "GLDesc",
            headerClassName: 'tableTH',
            headerName: "GL Descr",
            type: "singleSelect",
            width: 130,
            editable: true,
            renderEditCell: (params) => (<GLCodeEditInputCell {...params} glCodeData={(params.row.edi210 === 'Y') ? rateBookReducer.GLCodeData : rateBookReducer.GLCodeData.filter(e => e.glLineAcc === queryParams.type)} error={fieldError && fieldError.hasOwnProperty("GLDesc")} />)
        },
        {
            field: "invoiceDesc",
            headerClassName: 'tableTH',
            headerName: "Invoice Descr",
            width: 130,
            editable: true,
            renderEditCell: (params) => <CustomEditTextField {...params} error={fieldError && fieldError.hasOwnProperty("invoiceDesc")} />
        },
        {
            field: "GLCode",
            headerClassName: 'tableTH',
            headerName: "GL Code",
            width: 50,
            editable: true,
            hide: true,
            //hideable: true,
        },
        {
            field: "originCity",
            headerClassName: 'tableTH',
            headerName: "Ramp/Port/CY",
            width: 130,
            editable: true,
            renderEditCell: (params) => (<RPCEditInputCellDropdown {...params} error={fieldError && fieldError.hasOwnProperty("originCity")} />)
        },
        {
            field: "originState",
            headerClassName: 'tableTH',
            headerName: "State",
            width: 130,
            editable: true,
            renderEditCell: (params) => <CustomEditTextField {...params} error={fieldError && fieldError.hasOwnProperty("originState")} />
        },
        {
            field: "destinationCity",
            headerClassName: 'tableTH',
            headerName: "Destination City",
            width: 130,
            editable: true,
            renderEditCell: (params) => (<CityEditInputCellDropdown {...params} error={fieldError && fieldError.hasOwnProperty("destinationCity")} />)
        },
        {
            field: "destinationState",
            headerClassName: 'tableTH',
            headerName: "Destination State",
            width: 130,
            editable: true,
            renderEditCell: (params) => (<StateEditInputCellDropdown {...params} error={fieldError && fieldError.hasOwnProperty("destinationState")} />)
        },
        {
            field: "totMiles",
            headerClassName: 'tableTH',
            headerName: "RT Miles",
            width: 130,
            editable: true,
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "receivableRates",
            headerClassName: 'tableTH',
            headerName: "Receivable Rate",
            width: 130,
            editable: true,
            renderCell: (params) => `$${params.value}`,
            renderEditCell: (params) => (<CustomEditTextField {...params} error={fieldError && fieldError.hasOwnProperty("receivableRates")} />)
        },
        {
            field: "billTo",
            headerClassName: 'tableTH',
            headerName: "Order By",
            width: 130,
            editable: true,
            renderEditCell: (params) => (<OrderByEditInputCellDropdown {...params} />)
        },
        {
            field: "payableRateContract",
            headerClassName: 'tableTH',
            headerName: (
                <CustomHeader firstLine="Payable Rate" secondLine="(Contractor)" error={fieldError && fieldError.hasOwnProperty("payableRateContract")} />
            ),
            width: 130,
            editable: true,
            renderCell: (params) => `$${params.value}`,
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "payableRatioContract",
            headerClassName: 'tableTH',
            //sortable: false,
            // headerName: "Payable Ratio(Contractor)",
            headerName: (
                <CustomHeader firstLine="Payable Ratio" secondLine="(Contractor)" />
            ),
            width: 130,
            cellClassName: "tableTD",
            editable: true,
            renderCell: (params) => formatRatioPercentage(params.value),
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "payableRateBroker",
            headerClassName: 'tableTH',
            //cellClassName: "non-editable tableTD",
            // headerName: "Payable Rate(Broker)",
            headerName: (
                <CustomHeader firstLine="Payable Rate" secondLine="(Broker)" error={fieldError && fieldError.hasOwnProperty("payableRateBroker")} />
            ),
            width: 130,
            editable: true,
            renderCell: (params) => `$${params.value}`,
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "payableRatioBroker",
            headerClassName: 'tableTH',
            //sortable: false,
            headerName: (
                <CustomHeader firstLine="Payable Ratio" secondLine="(Broker)" />
            ),
            width: 130,
            cellClassName: "tableTD",
            editable: true,
            renderCell: (params) => formatRatioPercentage(params.value),
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "marginContractor",
            // headerName: "Margin(Contractor)",
            //sortable: false,
            headerName: <CustomHeader firstLine="Margin" secondLine="(Contractor)" />,
            width: 130,
            cellClassName: "tableTD",
            headerClassName: 'tableTH',
            editable: true,
            renderCell: (params) => `$${params.value}`,
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "marginBroker",
            type: "number",
            // headerName: "Margin(Broker)",
            //sortable: false,
            headerName: <CustomHeader firstLine="Margin" secondLine="(Broker)" />,
            width: 130,
            cellClassName: "tableTD",
            headerClassName: 'tableTH',
            editable: true,
            renderCell: (params) => `$${params.value}`,
            renderEditCell: (params) => (<CustomEditTextField {...params} />)
        },
        {
            field: "revenuePerMile",
            type: "number",
            //sortable: false,
            // headerName: "Revenue Per Mile",
            headerName: <CustomHeader firstLine="Revenue" secondLine="Per Mile" />,
            cellClassName: "non-editable tableTD",
            headerClassName: 'tableTH',
            renderCell: (params) =>
                calRevenue(params.row.receivableRates, params.row.totMiles),
        },
        {
            field: "contractorCRM",
            type: "number",
            //sortable: false,
            headerName: "Contractor CPM",
            width: 130,
            cellClassName: "non-editable tableTD",
            headerClassName: 'tableTH',
            renderCell: (params) =>
                calRevenue(params.row.payableRateContract, params.row.totMiles),
        },
        {
            field: "brokerCPM",
            type: "number",
            //sortable: false,
            headerName: "Broker CPM",
            width: 130,
            cellClassName: "non-editable tableTD",
            headerClassName: 'tableTH',
            renderCell: (params) =>
                calRevenue(params.row.payableRateBroker, params.row.totMiles),
        }

    ];

    return (
        <Box
            sx={{
                height: window.innerHeight - Number(186),
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
                '& .tableTH': {
                    fontWeight: 700
                },
                '& .non-editable': {
                    backgroundColor: "#69696929 !important"
                },
            }}
        >
            <DataGrid
                autoHeight={false}
                loading={isLoading}
                rowCount={totalRows}
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                paginationMode="server"
                pagination={true}
                pageSizeOptions={[25, 50, 100]}
                slots={{
                    toolbar: EditToolbar,
                    pagination: CustomPagination,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
                onPaginationModelChange={(params) => {
                    //console.log('--------', params);
                    setQueryParams({ ...queryParams, pageSize: params.pageSize, pageNumber: params.page })
                }}

                sortingOrder={['desc', 'asc']}
                sortingMode="server"
                onSortModelChange={sortChange}
                initialState={{
                    pagination: { paginationModel: { pageSize: queryParams.pageSize, page: queryParams.pageNumber } },
                    columns: {
                        columnVisibilityModel: {
                            // Hide columns status and traderName, the other columns will remain visible
                            GLCode: false
                        },
                    },
                    pinnedColumns: { left: ['actions'] },
                    sortModel: [
                        {
                            field: 'notes',
                            sort: 'asc',
                        },
                    ],
                }}
                onCellKeyDown={(params, event) => {
                    if (event.key === 'Enter') {
                        event.defaultMuiPrevented = true;
                    }
                }}
                autosizeOptions={autosizeOptions}
                apiRef={apiRef}
            />

            <ConfirmationDialog
                isConfirmDialogOpen={confirmationAlert.show}
                title='Are you sure?'
                description="Pressing 'Yes' will delete selected record..."
                acceptTxt="Yes"
                rejectTxt="No"
                handleNo={() => setConfirmationAlert({ ...confirmationAlert, show: false })}
                handleYes={() => handleDeleteTrue()}
            />
        </Box >
    );
}

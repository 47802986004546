import { useRef, useLayoutEffect, useState } from 'react';
import {
    useGridApiContext,
    GridActionsCellItem
} from '@mui/x-data-grid-pro';
import {
    TextField
} from "@material-ui/core";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { Autocomplete, Stack } from "@mui/material";
import { calRatio, calMargin, calRate, calRevenue, calRateOnMarginChange } from './CommonFn';

import {
    cityLookup,
    stateLookup
} from "../../store/actions/rateBookActions";

import { pickupPointLookup } from "../../store/actions/orderActions";
import { useSelector, useDispatch } from "react-redux";
import { GET } from '../../utils/axios'

export const CustomHeader = ({ firstLine, secondLine }) => {
    return (
        <p style={{ lineHeight: "1.5" }}>
            {firstLine}
            <br />
            {secondLine}
        </p>
    );
}

// Column Auto Resize component
export const CustomColumnAutoResize = (props) => {
    const { apiRef, autosizeOptions } = props;
    return (
        <Stack
            spacing={1}
            direction="row"
            alignItems="center"
            // sx={{ mb: 1 }}
            // useFlexGap
            flexWrap="wrap"
        >
            Actions
            <GridActionsCellItem
                icon={<SettingsEthernetIcon titleAccess='Autosize columns' title='resize' />}
                onClick={() => apiRef.current.autosizeColumns(autosizeOptions)}
            />
        </Stack>
    );
}

// Custom GL Code Dropdown component
export const GLCodeEditInputCell = (props) => {
    const { id, value, field, glCodeData, error, hasFocus, fieldType } = props;
    // console.log(glCodeData);
    const apiRef = useGridApiContext();
    const getSelectedValue = glCodeData.filter((obj) => { return obj.glcode === props.row.GLCode })
    const ref = useRef();
    const [selectedValue, setSelectedValue] = useState(getSelectedValue[0] || { id: '', glcode: '', glDescription: '', glLineAcc: '' });
    useLayoutEffect(() => {
        if (hasFocus) {
            setSelectedValue({ id: '', glcode: '', glDescription: '', glLineAcc: '' });
            ref.current.focus();
        }
    }, [hasFocus]);
    return (
        <Autocomplete
            freeSolo={false}
            getOptionLabel={(glCodeData) => `${(glCodeData.glcode) ? glCodeData.glDescription + ' - ' + glCodeData.glcode : ''}`}
            options={glCodeData}
            renderInput={(params) => {
                return (
                    <TextField
                        name="GLCode"
                        placeholder={'GL Descr'}
                        {...params}
                        variant="standard"
                        style={{
                            width: 120,
                        }}
                        error={error}
                        inputRef={ref}
                    ></TextField>
                );
            }}
            componentsProps={{ popper: { style: { width: 400 } } }}
            onChange={(e, newValue) => {
                // console.log('-----', newValue)
                if (newValue !== null) {
                    apiRef.current.setEditCellValue({
                        id,
                        field,
                        value: newValue.glDescription,
                    });
                    apiRef.current.setEditCellValue({
                        id,
                        field: "GLCode",
                        value: newValue.glcode,
                    });

                    // disable payable fields
                    if (fieldType === 'A') {
                        apiRef.current.setEditCellValue({
                            id,
                            field: "payableRateContract",
                            value: (newValue.glPayRec === 'R') ? 0 : props.row.payableRateContract,
                        });
                        apiRef.current.setEditCellValue({
                            id,
                            field: "payableRatioContract",
                            value: (newValue.glPayRec === 'R') ? 0 : props.row.payableRatioContract,
                        });
                        apiRef.current.setEditCellValue({
                            id,
                            field: "payableRateBroker",
                            value: (newValue.glPayRec === 'R') ? 0 : props.row.payableRateBroker,
                        });
                        apiRef.current.setEditCellValue({
                            id,
                            field: "payableRatioBroker",
                            value: (newValue.glPayRec === 'R') ? 0 : props.row.payableRatioBroker,
                        });
                        apiRef.current.setEditCellValue({
                            id,
                            field: "marginContractor",
                            value: (newValue.glPayRec === 'R') ? 0 : props.row.marginContractor,
                        });
                        apiRef.current.setEditCellValue({
                            id,
                            field: "marginBroker",
                            value: (newValue.glPayRec === 'R') ? 0 : props.row.marginBroker,
                        });
                    }
                } else if (newValue === null) {
                    apiRef.current.setEditCellValue({
                        id,
                        field,
                        value: '',
                    });
                    apiRef.current.setEditCellValue({
                        id,
                        field: "GLCode",
                        value: 0,
                    });
                }
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${(option.glcode) ? option.glDescription + ' - ' + option.glcode : ''}`}
                    </li>
                )
            }}
            onBlur={() => setSelectedValue(getSelectedValue[0] || { id: '', glcode: '', glDescription: '', glLineAcc: '' })}
            value={selectedValue}
        //isOptionEqualToValue={(option, value) => option.glcode === value}
        //defaultValue={glCodeData[0].glcode}
        />
    );
};

// Ramp/Port/CY Dropdown component
export const RPCEditInputCellDropdown = (props) => {
    const { id, value, field, error, hasFocus } = props;
    const apiRef2 = useGridApiContext();
    const orderReducer = useSelector(({ orderReducer }) => orderReducer)
    const dispatch = useDispatch();

    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const handlePickupPointLookup = (event, value, reason) => {
        if (value && reason !== 'reset') {
            dispatch(pickupPointLookup({ code: "code", value: value }))
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    let selectedItem = orderReducer.pickupPointLookupRes.filter((obj) => (obj.code !== "" && obj.code.toLowerCase() === props.row[field].toLowerCase()))

    return (
        <Autocomplete
            freeSolo={false}
            options={orderReducer.pickupPointLookupRes}
            getOptionLabel={(option) => `${option.code || ''} ${option.name || ''}`}
            size="small"
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder={'Ramp/Port/CY'}
                        style={{
                            width: 120,
                        }}
                        error={error}
                        inputRef={ref}
                        onFocus={event => {
                            event.target.select();
                        }}
                    ></TextField>
                );
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                    </li>
                )
            }}

            onInputChange={handlePickupPointLookup}
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: newValue.code,
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'originState',
                        value: newValue?.state,
                    });
                } else if (newValue === null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: "",
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'originState',
                        value: "",
                    });
                }
            }}
            componentsProps={{ popper: { style: { width: 400 } } }}
            value={selectedItem[0]}
        />
    );
}

// Custom CITY dropdown
export const CityEditInputCellDropdown = (props) => {
    const { id, value, field, error, hasFocus } = props;
    const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
    const apiRef2 = useGridApiContext();
    const dispatch = useDispatch();
    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    let getSelectedValue;
    if (props.row[field] !== "") {
        getSelectedValue = [{ id: "", city: props.row[field] }];
    } else {
        getSelectedValue = rateBookReducer.cityData.filter((obj) => { return obj.city.toLowerCase() === props.row[field].toLowerCase() })
    }

    const handleCityLookup = (event, value, reason) => {
        if (value && reason !== 'reset') {
            dispatch(cityLookup(value));
            apiRef2.current.setEditCellValue({
                id,
                field,
                value: value,
            });
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }
    return (
        <Autocomplete
            freeSolo={true}
            options={rateBookReducer.cityData}
            getOptionLabel={(option) => (option.city ? `${option.city}` : '')}
            size="small"
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        variant="standard"
                        //placeholder={field}
                        style={{
                            width: 120,
                        }}
                        error={error}
                        inputRef={ref}
                        onFocus={event => {
                            event.target.select();
                        }}
                    //inputProps={{ maxLength: 20 }}
                    ></TextField>
                );
            }}
            onInputChange={handleCityLookup}
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: newValue.city,
                    });
                } else if (newValue === null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: "",
                    });
                }
            }}
            value={getSelectedValue[0] || { id: "", city: "" }}
        />
    );
}

// Custom state dropdown
export const StateEditInputCellDropdown = (props) => {
    const { id, value, field, error, hasFocus } = props;
    const rateBookReducer = useSelector((rateBookReducer) => rateBookReducer.rateBookReducer);
    const dispatch = useDispatch();
    const apiRef2 = useGridApiContext();
    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    let getSelectedValue;
    if (props.row[field] !== "") {
        getSelectedValue = [{ id: "", state: '', statePrefix: props.row[field] }];
    } else {
        getSelectedValue = rateBookReducer.stateData.filter((obj) => { return obj.state.toLowerCase() === props.row[field].toLowerCase() })
    }

    const handleStateLookup = (event, value, reason) => {
        if (value && reason !== 'reset') {
            dispatch(stateLookup(value))
            apiRef2.current.setEditCellValue({
                id,
                field,
                value: value.slice(0, 2).toUpperCase(),
            });
        }
        else {
            console.log('Reason is reset not making an api call')
        }
    }

    return (
        <Autocomplete
            freeSolo={true}
            options={rateBookReducer.stateData}
            getOptionLabel={(option) => `${option.state || ''} ${option.statePrefix || ''}`}
            size="small"
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${option.state} - ${option.statePrefix}`}
                    </li>
                )
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        variant="standard"
                        //placeholder={field}
                        style={{
                            width: 120,
                        }}
                        error={error}
                        inputRef={ref}
                        onFocus={event => {
                            event.target.select();
                        }}
                    //inputProps={{ maxLength: 2 }}
                    ></TextField>
                );
            }}
            onInputChange={handleStateLookup}
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: newValue.statePrefix,
                    });
                } else if (newValue === null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: "",
                    });
                }
            }}
            value={getSelectedValue[0]}
        />
    );
}

// Custom Destination City/State Dropdown component
export const EditInputCellDropdown = (props) => {
    const { id, value, field, cityData, stateData, error, hasFocus } = props;
    const apiRef2 = useGridApiContext();
    let opts;
    let getSelectedValue;
    if (field === "originCity" || field === "destinationCity") {
        opts = cityData;
        getSelectedValue = cityData.filter((obj) => { return obj.name.toLowerCase() === props.row[field].toLowerCase() })
    } else {
        opts = stateData;
        getSelectedValue = stateData.filter((obj) => { return obj.name.toLowerCase() === props.row[field].toLowerCase() })
    }

    const ref = useRef();
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    return (
        <Autocomplete
            freeSolo={true}
            options={opts}

            getOptionLabel={(option) => (option.name ? `${option.name}` : '')}
            size="small"
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        variant="standard"
                        //placeholder={field}
                        style={{
                            width: 120,
                        }}
                        error={error}
                        inputRef={ref}
                        onFocus={event => {
                            event.target.select();
                        }}
                    ></TextField>
                );
            }}
            onChange={(e, newValue) => {
                if (newValue !== null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: newValue.name,
                    });

                }
            }}
            value={getSelectedValue[0]}
            //isOptionEqualToValue={(option, value) => option.name.toLowerCase() === value.toLowerCase()}
            defaultValue={opts[0].name}
        />
    );
};

// Custom order by lookup
export const OrderByEditInputCellDropdown = (props) => {
    const { id, value, field, error, hasFocus } = props;
    const [orderByOptions, setOrderByOptions] = useState([]);
    let selectedDynamicItem = { code: value || '', name: '' };
    const [selectedItem, setSelectedValue] = useState(selectedDynamicItem)
    const apiRef2 = useGridApiContext();
    const ref = useRef();

    useLayoutEffect(() => {
        if (hasFocus) {
            setSelectedValue({ code: '', name: '' });
            ref.current.focus();
        }
    }, [hasFocus]);

    const orderByLookupTimeout = useRef(null)
    const handleOrderByLookup = (event, value, reason) => {
        if (value && reason !== 'reset') {
            if (orderByLookupTimeout.current)
                clearTimeout(orderByLookupTimeout.current)

            const timer = setTimeout(async () => {
                // dispatch(orderByLookUp({ code: "searchVal", value: value }))
                let url = `/orders/getOrderByLookUpData?searchVal=${value}`
                let res = await GET(url)
                if (res.status !== 'error') {
                    setOrderByOptions(res.data)
                }
            }, 1000)

            orderByLookupTimeout.current = timer
        }
    }

    return (
        <Autocomplete
            freeSolo={false}
            options={orderByOptions}
            getOptionLabel={(option) => option ? `${option.code} ${option.name}` : ""}
            size="small"
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        variant="standard"
                        placeholder={'Order By'}
                        style={{
                            width: 120,
                        }}
                        error={error}
                        inputRef={ref}
                        onFocus={event => {
                            event.target.select();
                        }}
                    ></TextField>
                );
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${option.code} - ${option.name} [${option.orderByLocation}, ${option.city}, ${option.state}]`}
                    </li>
                )
            }}

            onInputChange={handleOrderByLookup}
            onChange={(e, newValue) => {
                console.log("newvalue", newValue)
                if (newValue !== null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: newValue.code,
                    });
                } else if (newValue === null) {
                    apiRef2.current.setEditCellValue({
                        id,
                        field,
                        value: "",
                    });
                }
            }}
            componentsProps={{ popper: { style: { width: 400 } } }}
            value={selectedItem}
            onBlur={() => setSelectedValue(selectedDynamicItem || { code: '', name: '' })}
        />
    );
}

// Custom text field for the numbers/text
export const CustomEditTextField = (props) => {
    const { id, value, field, error, hasFocus, isCellEditable } = props;
    const apiRef2 = useGridApiContext();
    const ref = useRef();
    //console.log('isCellEditable--', isCellEditable);
    useLayoutEffect(() => {
        if (hasFocus) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const handleRecvChange = () => {
        apiRef2.current.setEditCellValue({
            id,
            field: 'payableRateContract',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'payableRatioContract',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'payableRateBroker',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'payableRatioBroker',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'marginContractor',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'marginBroker',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'revenuePerMile',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'contractorCRM',
            value: 0,
        });
        apiRef2.current.setEditCellValue({
            id,
            field: 'brokerCPM',
            value: 0,
        });
    }
    const handleChange = (e) => {

        const calcFields = ['receivableRates', 'payableRateContract', 'payableRatioContract', 'marginContractor', 'payableRateBroker', 'payableRatioBroker', 'marginBroker'];
        if (calcFields.includes(field) && props.row.receivableRates !== "") {
            switch (field) {
                case 'receivableRates':
                    //alert(value + '---' + e.target.value)
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRateContract',
                        value: props.row.payableRateContract,
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRatioContract',
                        value: calRatio(props.row.payableRateContract, e.target.value),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRateBroker',
                        value: props.row.payableRateBroker,
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRatioBroker',
                        value: calRatio(props.row.payableRateBroker, e.target.value),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'marginContractor',
                        value: calMargin(e.target.value, props.row.payableRateContract),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'marginBroker',
                        value: calMargin(e.target.value, props.row.payableRateBroker),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'revenuePerMile',
                        value: calRevenue(e.target.value, props.row.totMiles),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'contractorCRM',
                        value: calRevenue(props.row.payableRateContract, props.row.totMiles),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'brokerCPM',
                        value: calRevenue(props.row.payableRateBroker, props.row.totMiles),
                    });
                    break;
                case 'payableRateContract':
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRatioContract',
                        value: calRatio(e.target.value, props.row.receivableRates),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'marginContractor',
                        value: calMargin(props.row.receivableRates, e.target.value),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'revenuePerMile',
                        value: calRevenue(props.row.receivableRates, props.row.totMiles),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'contractorCRM',
                        value: calRevenue(e.target.value, props.row.totMiles),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'brokerCPM',
                        value: calRevenue(props.row.payableRateBroker, props.row.totMiles),
                    });
                    break;
                case 'payableRatioContract':
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRateContract',
                        value: calRate(e.target.value, props.row.receivableRates),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'marginContractor',
                        value: calMargin(props.row.receivableRates, calRate(e.target.value, props.row.receivableRates)),
                    });
                    break;
                case 'marginContractor':
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRateContract',
                        value: calRateOnMarginChange(props.row.receivableRates, e.target.value),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRatioContract',
                        value: calRatio(calRateOnMarginChange(props.row.receivableRates, e.target.value), props.row.receivableRates),
                    });
                    break;
                case 'payableRateBroker':
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRatioBroker',
                        value: calRatio(e.target.value, props.row.receivableRates),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'marginBroker',
                        value: calMargin(props.row.receivableRates, e.target.value),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'revenuePerMile',
                        value: calRevenue(props.row.receivableRates, props.row.totMiles),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'contractorCRM',
                        value: calRevenue(props.row.payableRateContract, props.row.totMiles),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'brokerCPM',
                        value: calRevenue(e.target.value, props.row.totMiles),
                    });
                    break;
                case 'payableRatioBroker':
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRateBroker',
                        value: calRate(e.target.value, props.row.receivableRates),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'marginBroker',
                        value: calMargin(props.row.receivableRates, calRate(e.target.value, props.row.receivableRates)),
                    });
                    break;
                case 'marginBroker':
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRateBroker',
                        value: calRateOnMarginChange(props.row.receivableRates, e.target.value),
                    });
                    apiRef2.current.setEditCellValue({
                        id,
                        field: 'payableRatioBroker',
                        value: calRatio(calRateOnMarginChange(props.row.receivableRates, e.target.value), props.row.receivableRates),
                    });
                    break;
                default:
                    break;
            }
        }
    }
    return (
        <TextField
            type={['totMiles', 'receivableRates', 'payableRateContract', 'payableRateBroker', 'payableRatioContract', 'billTo', 'payableRatioBroker', 'marginBroker', 'marginContractor'].includes(field) ? 'number' : 'text'}
            variant="standard"
            style={{
                width: 120,
            }}
            onBlur={(e) => handleChange(e)}
            onChange={(e) => {
                if (field === 'receivableRates' && Number(e.target.value) !== Number(value)) {
                    handleRecvChange()
                }
                apiRef2.current.setEditCellValue({
                    id,
                    field,
                    value: e.target.value
                });
            }}
            value={(value === 0) ? '' : value}
            error={error}
            inputRef={ref}
            disabled={isCellEditable ? true : false}
            onFocus={event => {
                event.target.select();
            }}
        ></TextField>
    );
}
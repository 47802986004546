import React, { useContext } from "react";
import { TextField, Paper, Autocomplete, IconButton, Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import './Styles.css'
import { shipperLookup } from "../../../store/actions/orderActions";
import { POST, PUT } from "../../../utils/axios";
import { useParams } from "react-router-dom";
import orderContext from '../orderContext';
import { PhoneNumberMask } from '../../../components/TextFieldFormat';
import CachedIcon from '@mui/icons-material/Cached';


export default function Shipper(props) {

    const { billingControlRef, isTemplateScreen, selectedOrderType, disableFull ,isEdiScreen} = props
    const dispatch = useDispatch();
    let params = useParams();

    const [shipperState, setShipperState] = useState(billingControlRef.current.shipper)
    const [shipperPrevState, setShipperPrevState] = useState({})
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackContent, setSnackContent] = useState('')
    const [shipperSelect, setShipperSelect] = useState(billingControlRef.current.shipper.shipper ? { code: billingControlRef.current.shipper.shipper, name: billingControlRef.current.shipper.name } : billingControlRef.current.shipper.name?{ code: "MANUAL", name: billingControlRef.current.shipper.name }:null)
    const shipperOptions = useSelector(({ orderReducer }) => orderReducer.shipperLookupRes)
    const [addEditShipper, setAddEditShipper] = useState(null);
    const [addEditShipperMode, setAddEditShipperMode] = useState(false);
    const [isTextField, setIsTextField] = useState(false);

    const { setIsStateChanged } = useContext(orderContext);
    const ordersLocation = useSelector(
        ({ orderReducer }) => orderReducer.ordersLocation
    );

    params= params?.locationNumber?params:{locationNumber: ordersLocation.locationNumber, controlNumber: ''}

    const handleFieldChanges = () => {
        /**
         * 28-09-2023
         * update global context that field has changed
         */
        console.log('shipper fields are changes')
        setIsStateChanged(true);
        /** END */
    }

    const handleClose = (event) => {
        setSnackOpen(false);
    };

    const clearAutocomplete = () => {
        setShipperSelect(null);
        setAddEditShipperMode(false);
        setShipperState({
            ...shipperState,
            shipper: "",
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            fax: "",
            yard: "",
            note1: "",
            note2: "",
            note3: "",
        });
        billingControlRef.current = {
            ...billingControlRef.current,
            shipper: {
                ...shipperState,
                shipper: "",
                name: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                fax: "",
                yard: "",
                note1: "",
                note2: "",
                note3: "",
            }
        }
        handleFieldChanges();
    }
    const handleShipperChange = (event, value, reason) => {
        console.log('Selected Shipper', value)
        if (reason === "clear") {
            clearAutocomplete();
            return false;
        }
        setShipperSelect(value)
        setAddEditShipperMode(true);

        setShipperState({
            ...shipperState,
            shipper: value.code,
            name: value.name,
            address: value.address,
            city: value.city,
            state: value.state,
            zip: value.zip,
            phone: value.phone,
            fax: value.fax,
            yard: value.yard,
            note1: value.note1,
            note2: value.note2,
            note3: value.note3
        })
        billingControlRef.current = {
            ...billingControlRef.current,
            shipper: {
                ...shipperState,
                shipper: value.code,
                name: value.name,
                address: value.address,
                city: value.city,
                state: value.state,
                zip: value.zip,
                phone: value.phone,
                fax: value.fax,
                yard: value.yard,
                note1: value.note1,
                note2: value.note2,
                note3: value.note3
            }
        }
        handleFieldChanges();
    }

    const updateBillingControlRef = (value) => {
        billingControlRef.current = {
            ...billingControlRef.current,
            shipper: {
              code:value.code,
                pickupNumber: value.pickupNumber,
                shipper: value.shipper,
                name: value.name,
                address: value.address,
                city: value.city,
                state: value.state,
                zip: value.zip,
                phone: value.phone,
                fax: value.fax,
            }
        }
        handleFieldChanges();
    }

    const handleAddEditShipperClick = (event) => {
        console.log('Add Edit Clicked Current', addEditShipper)

        if (addEditShipperMode) {
            setAddEditShipper(shipperSelect.code)
            return false;
        }
        // Save current Shipper Values to revert
        setShipperPrevState({ ...shipperState })
        setShipperState({
            shipper: '',
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            fax: '',
            yard: '',
            note1: '',
            note2: '',
            note3: ''
        })

        if (shipperSelect !== null && shipperSelect !== "")
            setAddEditShipper(shipperSelect.code)
        else if ((shipperSelect === null || shipperSelect === "") && addEditShipper !== 'Add')
            setAddEditShipper("Add")
        else if (addEditShipper)
            setAddEditShipper(null)
        console.log('Add Edit Clicked', addEditShipper)
    }

    const handleAddShipper = (event) => {
        console.log('Adding Shipper', shipperState)
        setSnackOpen(true)
        setSnackContent({ severity: "info", message: "Adding new Shipper/Consignee ..." })

        const newShipper = {
            company: '1',
            code: shipperState.shipper,
            yard: shipperState.yard,
            name: shipperState.name,
            address: shipperState.address,
            city: shipperState.city,
            state: shipperState.state,
            zip: shipperState.zip,
            phone: shipperState.phone,
            fax: shipperState.fax,
            note1: shipperState.note1,
            note2: shipperState.note2,
            note3: shipperState.note3
        }

        updateBillingControlRef(newShipper);

        if (addEditShipperMode) {
            PUT(`/shippers/${params.locationNumber}`, newShipper)
                .then(res => {
                    if (res && res.httpCode === 200 && res.status === "success") {

                        setAddEditShipper(null);
                        setAddEditShipperMode(true);

                        setSnackOpen(true)
                        setSnackContent({ severity: "success", message: `${newShipper.code} Consignee/Shipper added` })
                    }
                    else {
                        setSnackOpen(true)
                        setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper: ${res.message}` })
                    }
                })
                .catch(error => {
                    setSnackOpen(true)
                    setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper` })
                })
        } else {
            POST(`/shippers/${params.locationNumber}`, newShipper)
                .then(res => {
                    if (res && res.httpCode === 200 && res.status === "success") {

                        setShipperSelect({
                            code: shipperState.shipper,
                            name: shipperState.name
                        });
                        setAddEditShipper(null);
                        setAddEditShipperMode(true);

                        setSnackOpen(true)
                        setSnackContent({ severity: "success", message: `${newShipper.code} Consignee/Shipper added` })
                    }
                    else {
                        setSnackOpen(true)
                        setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper: ${res.message}` })
                    }
                })
                .catch(error => {
                    console.log(error)
                    setSnackOpen(true)
                    setSnackContent({ severity: "error", message: `Error Adding Consignee/Shipper` })
                })
        }

    }

    // Lookup handlers
    const shipperLookupTimeout = useRef(null)

    const handleShipperLookup = (event, value, reason) => {
        console.log(value, reason)
        if (value && reason !== 'reset') {
            if (shipperLookupTimeout.current)
                clearTimeout(shipperLookupTimeout.current)

            const timer = setTimeout(() => {
                dispatch(shipperLookup({ locationNumber: params.locationNumber, searchKey: "searchVal", searchValue: value }))
            }, 1000)

            shipperLookupTimeout.current = timer
        }
        else {
            if (value === "") {
                setAddEditShipperMode(false);
            }
            console.log('Reason is reset not making an api call..')
        }
    }

    const resetShipper = () => {
        if (addEditShipperMode) {
            setAddEditShipper(null);
            setShipperState(shipperState);
            setAddEditShipperMode(true);
            setShipperSelect({ code: shipperState.shipper, name: shipperState.name })
        } else if (!addEditShipperMode) {
            setAddEditShipper(null);
            setShipperState({ ...shipperPrevState });
            setAddEditShipperMode(false);
            setShipperSelect(null)
        }

    }

    const handleChangeShipperLookupField = () => {
      setIsTextField(!isTextField);
      setShipperState({
        ...shipperState,
        code: "MANUAL",
      });

      updateBillingControlRef({
        ...shipperState,
        code: "MANUAL",
      });
  };
    return (
      (isTemplateScreen ? (
        <div className="mainContainer width_100">
          <Divider textAlign="left" className="dividersty">
            <Chip
              label={
                selectedOrderType === "INBOUND" || selectedOrderType === "IMPORT"
                  ? "Shipper"
                  : selectedOrderType === "OUTBOUND" ||
                    selectedOrderType === "EXPORT"
                  ? "Consignee"
                  : ""
              }
              size="small"
              variant="outlined"
            />
          </Divider>
          <Paper className="paperstyl" variant="outlined">
            {!addEditShipper && (
              <table className="width_100">
                <tbody className="width_100">
                  {/* <tr className="width_100">

                    <td className="width_50">
                      <div className="width_100" style={{ display: "flex" }}>
                        <Autocomplete
                          disablePortal
                          //disableClearable
                          forcePopupIcon={false}
                          id="shipper-select"
                          size="small"
                          value={shipperSelect}
                          options={shipperOptions}
                          getOptionLabel={(option) =>
                            `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`
                          }
                          style={{ flex: 1, textTransform: "uppercase" }}
                          renderOption={(props, option) => {
                            return (
                              <li
                                {...props}
                                key={props["data-option-index"]}
                                style={{ borderBottom: "1px solid #E0E0E0" }}
                              >
                                {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                              </li>
                            );
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.code === value.code
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={params.code}
                              label={
                                selectedOrderType === "Inbound" ||
                                selectedOrderType === "Import"
                                  ? "Shipper"
                                  : selectedOrderType === "Outbound" ||
                                    selectedOrderType === "Export"
                                  ? "Consignee"
                                  : ""
                              }
                            />
                          )}
                          onInputChange={handleShipperLookup}
                          onChange={handleShipperChange}
                          disabled={disableFull}
                          componentsProps={{
                            paper: {
                              sx: {
                                width: 500,
                              },
                            },
                          }}
                        />
                        <IconButton
                          onClick={handleAddEditShipperClick}
                          disabled={disableFull}
                        >
                          {addEditShipperMode ? <EditIcon /> : <AddIcon />}
                        </IconButton>
                      </div>
                    </td>
                    <td className="width_50">
                      <TextField
                        label={"Pickup Number"}
                        size="small"
                        className="width_100"
                        value={shipperState.pickupNumber || ""}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                          maxLength: 10,
                        }}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            pickupNumber: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            pickupNumber: event.target.value,
                          });
                        }}
                        disabled={disableFull}
                      />
                    </td>
                  </tr> */}
  <tr className="width_100">
  <td className="width_100">
    <div className="width_100" style={{ display: 'flex', alignItems: 'center', padding: 0, width: '100%' }}>
      {isTextField ? (
        <TextField
          value={shipperState?.code || ""}
          onChange={(event) => {
            setShipperState({
              ...shipperState,
              code: event.target.value,
            });
            updateBillingControlRef({
              ...shipperState,
              code:event.target.value,
            });
          }}
          label={"CODE"}
          size="small"
          className="width_100"
          inputProps={{
            style: { textTransform: "uppercase" },
            maxLength: 10,
          }}
          style={{ flex: '1 1 30%', marginRight: 5 }}
          disabled={disableFull}
        />
      ) : (
        <Autocomplete
          disablePortal
          forcePopupIcon={false}
          id="shipper-select"
          size="small"
          value={shipperSelect}
          options={shipperOptions}
          getOptionLabel={(option) =>
            `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`
          }
          style={{ flex: 1, textTransform: "uppercase" }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={props["data-option-index"]}
                style={{ borderBottom: "1px solid #E0E0E0" }}
              >
                {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) =>
            option.code === value.code
          }
          renderInput={(params) => (
            <TextField
              {...params}
              value={params.code?params.code:"MANUAL"}
              label={
                selectedOrderType === "Inbound" ||
                selectedOrderType === "Import"
                  ? "Shipper"
                  : selectedOrderType === "Outbound" ||
                    selectedOrderType === "Export"
                  ? "Consignee"
                  : ""
              }
              // style={{
              //   background: checkNonMatchingFields(
              //     nonMatchingFields,
              //     `stops[${stopState.stop - 1}].name`
              //   )
              //     ? "#EDE21196"
              //     : null,
              // }}
            />
          )}
          onInputChange={handleShipperLookup}
          onChange={handleShipperChange}
          componentsProps={{
            paper: {
              sx: {
                width: 500,
              },
            },
          }}
          style={{ flex: '1 1 30%', marginRight: 5 }}
          disabled={disableFull}
        />
      )}
      <IconButton
        onClick={handleAddEditShipperClick}
        style={{ flex: '1 1 10%', marginRight: 5 }}
        disabled={disableFull}
      >
        {addEditShipperMode || shipperSelect?.code ? <EditIcon /> : <AddIcon />}
      </IconButton>
      {isEdiScreen && (
        <IconButton onClick={handleChangeShipperLookupField}
          style={{ flex: '1 1 10%', marginRight: 5 }}
        >
          <CachedIcon />
        </IconButton>
      )}
      <TextField
        label="Name"
        size="small"
        className="width_100"
        inputProps={{ style: { textTransform: "uppercase" } }}
        value={shipperState.name || ""}

        onChange={(event) => {
          setShipperState({
            ...shipperState,
            name: event.target.value,
          });
          updateBillingControlRef({
            ...shipperState,
            name: event.target.value,
          });
        }}
        style={{ flex: '1 1 60%' }}
        disabled={!isTextField}
      />
    </div>
  </td>
  {/* <td className="width_50">
    <TextField
      label={"Pickup Number"}
      size="small"
      className="width_100"
      value={shipperState.pickupNumber || ""}
      inputProps={{
        style: { textTransform: "uppercase" },
        maxLength: 10,
      }}
      onChange={(event) => {
        setShipperState({
          ...shipperState,
          pickupNumber: event.target.value,
        });
        updateBillingControlRef({
          ...shipperState,
          pickupNumber: event.target.value,
        });
      }}
      disabled={disableFull}
    />
    </td> */}
  </tr>
                  <tr className="width_100">
                    {/* <td className="width_50">
                      <TextField
                        label="Name"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.name || ""}

                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            name: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            name: event.target.value,
                          });
                        }}
                        disabled={!isTextField}

                      />
                    </td> */}
                    <td className="width_100">
                      <TextField
                        label="Address"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.address || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            address: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            address: event.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="width_100">
                    <td className="width_100">
                      <div className="width_100" style={{ display: 'flex', alignItems: 'center', padding: 0, width: '100%' }}>
                        <TextField
                          label="City"
                          size="small"
                          className="width_100"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={shipperState.city || ""}
                          style={{ flex: '1 1 50%', marginRight: 5 }}
                          disabled={!isTextField}
                          onChange={(event) => {
                            setShipperState({
                              ...shipperState,
                            city: event.target.value,
                            });
                            updateBillingControlRef({
                              ...shipperState,
                              city: event.target.value,
                            });

                          }}
                        />
                        <TextField
                          label="State"
                          size="small"
                          className="width_100"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={shipperState.state || ""}
                          style={{ flex: '1 1 20%', marginRight: 5 }}
                          disabled={!isTextField}
                          onChange={(event) => {
                            setShipperState({
                              ...shipperState,
                              state: event.target.value,
                            });
                            updateBillingControlRef({
                              ...shipperState,
                              state: event.target.value,
                            });
                          }}
                        />
                        <TextField
                          label="Zip"
                          size="small"
                          className="width_100"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={shipperState.zip || ""}
                          style={{ flex: '1 1 30%' }}
                          disabled={!isTextField}
                          onChange={(event) => {
                            setShipperState({
                              ...shipperState,
                              zip: event.target.value,
                            });
                            updateBillingControlRef({
                              ...shipperState,
                              zip: event.target.value,
                            });
                          }}
                        />
                      </div>
                    </td>
                    {/* <td className="width_50">
                      <TextField
                        label="State"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.state || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            state: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            state: event.target.value,
                          });
                        }}
                      />
                    </td> */}
                  </tr>
                  <tr className="width_100">
                    <td className="width_100">
                      <TextField
                        label="Phone"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.phone || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            phone: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            phone:event.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  {/* <tr className="width_100">
                    <td className="width_100">
                      <TextField
                        label="Fax"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.fax || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            fax: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            fax: event.target.value,
                          });
                        }}
                      />
                    </td>
                    <td></td>
                  </tr> */}
                </tbody>
              </table>
            )}
            {
            addEditShipper &&
              <table className="width_100">
                  <tbody className="width_100">
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Shipper/Cons Code"
                                  size="small"
                                  value={shipperState.shipper || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 7 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, shipper: event.target.value })
                                  }}
                                  disabled={disableFull || addEditShipperMode}
                              />
                          </td>
                          <td>
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Shipper/Cons Name"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.name || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, name: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="Address"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.address || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, address: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="City"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.city || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, city: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="State"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.state || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 2 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, state: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Zip"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.zip || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, zip: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <PhoneNumberMask
                                  label="Phone"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.phone || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, phone: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />

                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Fax"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.fax || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, fax: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="Yard"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.yard || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 1 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, yard: event.target.value })
                                  }}
                                  disabled={disableFull}
                                  select
                                  SelectProps={{
                                      native: true,
                                  }}
                              >
                                  <option selected value={`N`}>N</option>
                                  <option value={`Y`}>Y</option >
                              </TextField>
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Notes 1"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.note1 || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, note1: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="Notes 2"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.note2 || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, note2: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Notes 3"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.note3 || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, note3: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td>
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <Button onClick={handleAddShipper} disabled={disableFull}>
                                  {addEditShipperMode ? "Update" : "Add"}
                              </Button>
                              <Button onClick={() => resetShipper()}>
                                  Back
                              </Button>
                          </td>
                          <td>
                          </td>
                      </tr>
                  </tbody>
              </table>
            }
          </Paper>
          <Snackbar
            open={snackOpen}
            style={{ marginTop: "5%" }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={snackContent.severity}
              sx={{ width: "100%" }}
            >
              {snackContent.message}
            </Alert>
          </Snackbar>
        </div>
      ) :(
        <div className="mainContainer width_100" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Divider style={{ flex: 1, marginRight: '8px' }}
                    >
              <Chip
                label={
                  selectedOrderType === "INBOUND" || selectedOrderType === "IMPORT"
                    ? "Shipper"
                    : selectedOrderType === "OUTBOUND" ||
                      selectedOrderType === "EXPORT"
                    ? "Consignee"
                    : ""
                }
                size="small"
                variant="outlined"
              />
            </Divider>
          </div>
          <Paper className="paperstyl1" variant="outlined" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            {!addEditShipper && (
              <table className="width_100">
                <tbody className="width_100">
                  {/* <tr className="width_100">

                    <td className="width_50">
                      <div className="width_100" style={{ display: "flex" }}>
                        <Autocomplete
                          disablePortal
                          //disableClearable
                          forcePopupIcon={false}
                          id="shipper-select"
                          size="small"
                          value={shipperSelect}
                          options={shipperOptions}
                          getOptionLabel={(option) =>
                            `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`
                          }
                          style={{ flex: 1, textTransform: "uppercase" }}
                          renderOption={(props, option) => {
                            return (
                              <li
                                {...props}
                                key={props["data-option-index"]}
                                style={{ borderBottom: "1px solid #E0E0E0" }}
                              >
                                {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
                              </li>
                            );
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.code === value.code
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={params.code}
                              label={
                                selectedOrderType === "Inbound" ||
                                selectedOrderType === "Import"
                                  ? "Shipper"
                                  : selectedOrderType === "Outbound" ||
                                    selectedOrderType === "Export"
                                  ? "Consignee"
                                  : ""
                              }
                            />
                          )}
                          onInputChange={handleShipperLookup}
                          onChange={handleShipperChange}
                          disabled={disableFull}
                          componentsProps={{
                            paper: {
                              sx: {
                                width: 500,
                              },
                            },
                          }}
                        />
                        <IconButton
                          onClick={handleAddEditShipperClick}
                          disabled={disableFull}
                        >
                          {addEditShipperMode ? <EditIcon /> : <AddIcon />}
                        </IconButton>
                      </div>
                    </td>
                    <td className="width_50">
                      <TextField
                        label={"Pickup Number"}
                        size="small"
                        className="width_100"
                        value={shipperState.pickupNumber || ""}
                        inputProps={{
                          style: { textTransform: "uppercase" },
                          maxLength: 10,
                        }}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            pickupNumber: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            pickupNumber: event.target.value,
                          });
                        }}
                        disabled={disableFull}
                      />
                    </td>
                  </tr> */}
  <tr className="width_100">
  <td className="width_100">
    <div className="width_100" style={{ display: 'flex', alignItems: 'center', padding: 0, width: '100%' }}>
      {isTextField ? (
        <TextField
          value={shipperState?.code || ""}
          onChange={(event) => {
            setShipperState({
              ...shipperState,
              code: event.target.value,
            });
            updateBillingControlRef({
              ...shipperState,
              code:event.target.value,
            });
          }}
          label={"CODE"}
          size="small"
          className="width_100"
          inputProps={{
            style: { textTransform: "uppercase" },
            maxLength: 10,
          }}
          style={{ flex: '1 1 30%', marginRight: 5 }}
          disabled={disableFull}
        />
      ) : (
        <Autocomplete
          disablePortal
          forcePopupIcon={false}
          id="shipper-select"
          size="small"
          value={shipperSelect}
          options={shipperOptions}
          getOptionLabel={(option) =>
            `${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`
          }
          style={{ flex: 1, textTransform: "uppercase" }}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={props["data-option-index"]}
                style={{ borderBottom: "1px solid #E0E0E0" }}
              >
                {`${option.code} - ${option.name} [${option.address}, ${option.city}, ${option.state}]`}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) =>
            option.code === value.code
          }
          renderInput={(params) => (
            <TextField
              {...params}
              value={params.code?params.code:"MANUAL"}
              label={
                selectedOrderType === "Inbound" ||
                selectedOrderType === "Import"
                  ? "Shipper"
                  : selectedOrderType === "Outbound" ||
                    selectedOrderType === "Export"
                  ? "Consignee"
                  : ""
              }
              // style={{
              //   background: checkNonMatchingFields(
              //     nonMatchingFields,
              //     `stops[${stopState.stop - 1}].name`
              //   )
              //     ? "#EDE21196"
              //     : null,
              // }}
            />
          )}
          onInputChange={handleShipperLookup}
          onChange={handleShipperChange}
          componentsProps={{
            paper: {
              sx: {
                width: 500,
              },
            },
          }}
          style={{ flex: '1 1 30%', marginRight: 5 }}
          disabled={disableFull}
        />
      )}
      <IconButton
        onClick={handleAddEditShipperClick}
        style={{ flex: '1 1 10%', marginRight: 5 }}
        disabled={disableFull}
      >
        {addEditShipperMode || shipperSelect?.code ? <EditIcon /> : <AddIcon />}
      </IconButton>
      {isEdiScreen && (
        <IconButton onClick={handleChangeShipperLookupField}
          style={{ flex: '1 1 10%', marginRight: 5 }}
        >
          <CachedIcon />
        </IconButton>
      )}
      <TextField
        label="Name"
        size="small"
        className="width_100"
        inputProps={{ style: { textTransform: "uppercase" } }}
        value={shipperState.name || ""}

        onChange={(event) => {
          setShipperState({
            ...shipperState,
            name: event.target.value,
          });
          updateBillingControlRef({
            ...shipperState,
            name: event.target.value,
          });
        }}
        style={{ flex: '1 1 60%' }}
        disabled={!isTextField}
      />
    </div>
  </td>
  {/* <td className="width_50">
    <TextField
      label={"Pickup Number"}
      size="small"
      className="width_100"
      value={shipperState.pickupNumber || ""}
      inputProps={{
        style: { textTransform: "uppercase" },
        maxLength: 10,
      }}
      onChange={(event) => {
        setShipperState({
          ...shipperState,
          pickupNumber: event.target.value,
        });
        updateBillingControlRef({
          ...shipperState,
          pickupNumber: event.target.value,
        });
      }}
      disabled={disableFull}
    />
    </td> */}
  </tr>
                  <tr className="width_100">
                    {/* <td className="width_50">
                      <TextField
                        label="Name"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.name || ""}

                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            name: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            name: event.target.value,
                          });
                        }}
                        disabled={!isTextField}

                      />
                    </td> */}
                    <td className="width_100">
                      <TextField
                        label="Address"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.address || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            address: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            address: event.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  <tr className="width_100">
                    <td className="width_100">
                      <div className="width_100" style={{ display: 'flex', alignItems: 'center', padding: 0, width: '100%' }}>
                        <TextField
                          label="City"
                          size="small"
                          className="width_100"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={shipperState.city || ""}
                          style={{ flex: '1 1 50%', marginRight: 5 }}
                          disabled={!isTextField}
                          onChange={(event) => {
                            setShipperState({
                              ...shipperState,
                            city: event.target.value,
                            });
                            updateBillingControlRef({
                              ...shipperState,
                              city: event.target.value,
                            });

                          }}
                        />
                        <TextField
                          label="State"
                          size="small"
                          className="width_100"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={shipperState.state || ""}
                          style={{ flex: '1 1 20%', marginRight: 5 }}
                          disabled={!isTextField}
                          onChange={(event) => {
                            setShipperState({
                              ...shipperState,
                              state: event.target.value,
                            });
                            updateBillingControlRef({
                              ...shipperState,
                              state: event.target.value,
                            });
                          }}
                        />
                        <TextField
                          label="Zip"
                          size="small"
                          className="width_100"
                          inputProps={{ style: { textTransform: "uppercase" } }}
                          value={shipperState.zip || ""}
                          style={{ flex: '1 1 30%' }}
                          disabled={!isTextField}
                          onChange={(event) => {
                            setShipperState({
                              ...shipperState,
                              zip: event.target.value,
                            });
                            updateBillingControlRef({
                              ...shipperState,
                              zip: event.target.value,
                            });
                          }}
                        />
                      </div>
                    </td>
                    {/* <td className="width_50">
                      <TextField
                        label="State"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.state || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            state: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            state: event.target.value,
                          });
                        }}
                      />
                    </td> */}
                  </tr>
                  <tr className="width_100">
                    <td className="width_100">
                      <TextField
                        label="Phone"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.phone || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            phone: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            phone:event.target.value,
                          });
                        }}
                      />
                    </td>
                  </tr>
                  {/* <tr className="width_100">
                    <td className="width_100">
                      <TextField
                        label="Fax"
                        size="small"
                        className="width_100"
                        inputProps={{ style: { textTransform: "uppercase" } }}
                        value={shipperState.fax || ""}
                        disabled={!isTextField}
                        onChange={(event) => {
                          setShipperState({
                            ...shipperState,
                            fax: event.target.value,
                          });
                          updateBillingControlRef({
                            ...shipperState,
                            fax: event.target.value,
                          });
                        }}
                      />
                    </td>
                    <td></td>
                  </tr> */}
                </tbody>
              </table>
            )}
            {
            addEditShipper &&
              <table className="width_100">
                  <tbody className="width_100">
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Shipper/Cons Code"
                                  size="small"
                                  value={shipperState.shipper || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 7 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, shipper: event.target.value })
                                  }}
                                  disabled={disableFull || addEditShipperMode}
                              />
                          </td>
                          <td>
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Shipper/Cons Name"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.name || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, name: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="Address"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.address || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, address: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="City"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.city || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, city: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="State"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.state || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 2 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, state: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Zip"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.zip || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 10 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, zip: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <PhoneNumberMask
                                  label="Phone"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.phone || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, phone: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />

                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Fax"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.fax || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 13 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, fax: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="Yard"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.yard || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 1 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, yard: event.target.value })
                                  }}
                                  disabled={disableFull}
                                  select
                                  SelectProps={{
                                      native: true,
                                  }}
                              >
                                  <option selected value={`N`}>N</option>
                                  <option value={`Y`}>Y</option >
                              </TextField>
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Notes 1"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.note1 || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, note1: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td className="width_50">
                              <TextField
                                  label="Notes 2"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.note2 || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, note2: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <TextField
                                  label="Notes 3"
                                  size="small"
                                  className="width_100"
                                  value={shipperState.note3 || ''}
                                  inputProps={{ style: { textTransform: "uppercase" }, maxLength: 25 }}
                                  onChange={event => {
                                      setShipperState({ ...shipperState, note3: event.target.value })
                                  }}
                                  disabled={disableFull}
                              />
                          </td>
                          <td>
                          </td>
                      </tr>
                      <tr className="width_100">
                          <td className="width_50">
                              <Button onClick={handleAddShipper} disabled={disableFull}>
                                  {addEditShipperMode ? "Update" : "Add"}
                              </Button>
                              <Button onClick={() => resetShipper()}>
                                  Back
                              </Button>
                          </td>
                          <td>
                          </td>
                      </tr>
                  </tbody>
              </table>
            }
          </Paper>
          <Snackbar
            open={snackOpen}
            style={{ marginTop: "5%" }}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={snackContent.severity}
              sx={{ width: "100%" }}
            >
              {snackContent.message}
            </Alert>
          </Snackbar>
        </div>
      ))
    );
}
import React, { useContext, useState, useRef, useEffect } from "react";
import { TextField, Paper, Autocomplete } from "@mui/material";
import { GET } from '../../utils/axios'

export default function OrderByLookup(props) {
    const { handleOnChangeValue } = props;
    const [orderByOptions, setOrderByOptions] = useState([])
    const [orderByCodeSelect, setOrderByCodeSelect] = useState(null)

    // Lookup handlers
    const orderByLookupTimeout = useRef(null)
    const handleInputChange = (event, value, reason) => {
        if (value && reason !== 'reset') {
            if (orderByLookupTimeout.current)
                clearTimeout(orderByLookupTimeout.current)

            const timer = setTimeout(async () => {
                let url = `/orders/getOrderByLookUpData?searchVal=${value}`
                let res = await GET(url)
                if (res && res.status !== 'error') {
                    setOrderByOptions(res.data);
                }
            }, 1000)

            orderByLookupTimeout.current = timer
        }
        else {
            console.log('Reason is reset not making an api call')
        }

    }

    const handleOnChange = (event, value, reason) => {
       
        if (reason === "clear") {
            handleOnChangeValue(null)
            setOrderByCodeSelect(null)
            return false;
        }
        setOrderByCodeSelect(value)
        handleOnChangeValue(value)
    }

    return (
        <Autocomplete
            disablePortal
            forcePopupIcon={false}
            id="order-by-select"
            size="small"
            value={orderByCodeSelect}
            className="width_100"
            options={orderByOptions}
            getOptionLabel={(option) => option ? `${option.code} - ${option.name}` : ""}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={props['data-option-index']} style={{ borderBottom: '1px solid #E0E0E0' }}>
                        {`${option.code} - ${option.name} [${option.orderByLocation}, ${option.city}, ${option.state}]`}
                    </li>
                )
            }}
            isOptionEqualToValue={(option, value) => option.code === value.code || option === ""}
            renderInput={(params) =>
                <TextField
                    {...params}
                    value={params.code}
                    label="Order By"
                    sx={{
                        input: {
                            background: "#F8F0E3",
                            fontSize: '14px',
                            textTransform: 'uppercase'
                        },
                        width: 140
                    }}
                />
            }
            onInputChange={handleInputChange}
            onChange={handleOnChange}
            componentsProps={{
                paper: {
                    sx: {
                        width: 500
                    }
                }
            }}
            //disabled={disableFull}
        />
    )
}
import moment from 'moment';

export const ratesChargesObjsForServer = (obj) => {
  const res = {
    lineNumber: obj.lineNumber,
    description: obj.description || "",
    notes: obj.notes || "",
    glDescription: obj.glDescription || "",
    glCode: obj.glCode || "",
    originName: obj.originName || "",
    originCity: obj.originCity || "",
    originPrefix: obj.originPrefix || "",
    destinationCity: obj.destinationCity || "",
    destinationStatePrefix: obj.destinationStatePrefix || "",
    accountLine: obj.accountLine || "",
    unitRate: obj.unitRate || "",
    units: obj.units || "",
    amount: obj.amount || "",
    transmitted: obj.transmitted || "",
    truckNumber: obj.truckNumber || "",
    parentLineNumber: obj.parentLineNumber || "",
    splitLineNumber: obj.splitLineNumber || "",
    loadType: obj.loadType || "",
    subPer: obj.subPer || "",
    payRec: obj.payRec || "",
    payType: obj.payType || "",
    payableRecievable: obj.payableReceivable || "",
    processedDate: (obj.processedDate !== '') ? getDateDMY(obj.processedDate) : '',
    department: getRCDepartment(obj.glCode),
    recAgainstSplitLine: obj.recAgainstSplitLine || 0,
    splitType: obj.splitType || '',
    commonSplitLine: obj.commonSplitLine || 0,
  }
  return res
}

export const getDateDMY = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return dateVal;
  } else {
    let date = dateVal.toString().substring(1)
    date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
    return date;
  }
}

export const getRCDepartment = (glCode) => {
  const glCodeArr = [2026000, 2035000, 2175000, 2177000];
  if (glCodeArr.includes(glCode)) {
    return 0;
  }
  return 310;
}

export const recAgainstLineNumber = (obj) => {
  if (typeof obj?.lineNumber !== 'undefined' && obj?.lineNumber !== 0) {
    return obj.lineNumber;
  }
  return 0;
}

const orderTypeOptions = [
  {
    id: 1,
    value: "INBOUND",
    value2: "DOMINB"
  },
  {
    id: 2,
    value: "OUTBOUND",
    value2: "DOMOUT"
  },
  {
    id: 3,
    value: "IMPORT",
    value2: ""
  },
  {
    id: 4,
    value: "EXPORT",
    value2: ""
  },
];

const getDate = (dateVal) => {
  if (dateVal === 0 || !dateVal)
    return ""
  let date = dateVal.toString().substring(1)
  date = `20${date.substring(0, 2)}-${date.substring(2, 4)}-${date.substring(4)}`
  return date;
}
const convertDateToCentury=(dateVal)=>{
  if (dateVal === 0 || !dateVal)
  return ""
  if (moment(dateVal, 'YYYY-MM-DD', true).isValid()) {
    return "1"+moment(dateVal).format('YYMMDD');
  }

}


    const convertTimeFormat = (timeString) => {

      const parsedTime = moment(`2000-01-01T${timeString}`, 'YYYY-MM-DDTHH:mm');
  
      // Format the time as HHMM
      const formattedTime = parsedTime.format('HHmm');

      return formattedTime;
    };

const getTime = (timeVal) => {
  if (!timeVal || timeVal === 0)
    return ""
  if (timeVal.toString().length === 3)
    timeVal = `0${timeVal.toString().substring(0, 1)}:${timeVal.toString().substring(1)}`
  else
    timeVal = `${timeVal.toString().substring(0, 2)}:${timeVal.toString().substring(2)}`
  return timeVal;
}


export const mapOrderToClient = (item) => {

  let billingControl = {
    orderBy: {
      orderBy: item?.shipperAgentCode || "",
      name: item?.shipperAgent || "",
      location: item?.shipperOrAgentLoc || "",
      phone: "",
      fax: item?.shipperAgentFax || "",
      email: "",
      contact: "",
      billTo: item?.customerNumber || "",
      reference: item?.billingReference || "",
      billToStatus: item?.status || "",
      billingDate: getDate(item?.billingDate) || "",
      isBillingDateLocked: item?.isBillingDateLocked || false,
      orderContactSeq: item?.dispositionCOrderByContactSeq || ''
    },
    equipmentDetails: {
      unitNum: item?.unitNumber || "",
      chassis: item?.chassisNumber || "",
      equipmentSize: item?.equipSize || "",
      release: item?.releaseNumber || "",
      
      ssl: item?.ssl || "",
      booking: item?.billingBooking || "",
      voyage: item?.voyageNumber || "",
      vessel: item?.vessel || "",
      destinationPort: item?.dispositionCDestinationPort || "",
      equipmentType: item?.equipmentType || '',
      secondaryUnit: item?.secondaryUnit || '',
      secondaryChassis: item?.secondaryChassis || '',
      chassisProvider: item?.chassisProvider || '',
      chassisRelease: item?.chassisRelease || '',
      hazMat: item?.hazMat || 'N',
      tanker: item?.tanker || 'N',
      overWeight: item?.overWeight || 'N',
      reefer: item?.reefer || 'N',
      residential: item?.residential || 'N',
    },

    customerNotes: {
      internalNotes: item?.internalNotes || ""
    },

    // Shipper value or consignee value - defaul to shipper
    shipper: {
      shipper: item?.shipperOrConsShipper || "",
      name: item?.shipper || "",
      pickupNumber: item?.billingShipperPO || "",
      address: item?.shipperAddress || "",
      city: item?.shipperCity || "",
      state: item?.shipperState || "",
      zip: item?.shipperZip || "",
      phone: item?.shipperPhone || "",
      fax: item?.shipperFax || "",
    },
    pickup: {
      pickupPoint: item?.poruPoint || "",
      pickupDate: getDate(item?.poruDate) || "",
      pickupTime: getTime(item?.poruTime) || "",
      disposition: item?.disposition || "",
      returnDate: getDate(item?.returnDate) || "",
      returnTime: getTime(item?.returnTime) || "",
      chassisPickup: item?.dispositionCChassisPickupPoint || "",
      chassisPickupDate: '',
      chassisPickupTime: '',
      chasisDisposition: item?.dispositionCChassisDisposition || "",
      chassisReturnDate: '',
      chassisReturnTime: '',
      notifyDate: getDate(item?.notifyDate) || "",
      notifyTime: getTime(item?.notifyTime) || "",
      lastFreeDay: getDate(item?.cuttOffDate) || "",
      lastFreeTime: getTime(item?.cutOffTime) || "",
      lastPerDiemDays: getDate(item?.pickDate) || "",
      etaDate: getDate(item?.etaDate) || "",
      etaTime: getTime(item?.etaTime) || ""
    },
    // driverNotes: {
    //   driverNotes: item?.remarks1,
    //   driverNotes2: item?.remarks2,
    // },
      
      driverNotes: item?.driverNotes,
      driverNotes2: item?.driverNotes2,
  

    orderType: orderTypeOptions.filter(option => item?.orderType === option?.value || item?.orderType === option?.value2)[0]?.value,
    orderCreateDate:item?.orderCreateDate,
  }

  // Order type is OUTBOUND OR EXPORT
  if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
    // Shipper becomes consignee in above object
    billingControl.shipper = {
      shipper: item?.shipperOrConsignee,
      name: item?.consignee,
      pickupNumber: item?.billingConsigneePO,
      address: item?.consigneeAddress,
      city: item?.consigneeCity,
      state: item?.consigneeState,
      zip: item?.consigneeZip,
      phone: item?.consigneePhone,
      fax: item?.consigneeFax,
    }
  }

  let stops = (item?.stops) ? item?.stops?.map(stop => {
    return {
      stop: stop?.lineNumber,
      newStop:stop?.lineNumber,
      consignee: stop?.code,
      pickupNumber: stop?.pickupNumber,
      name: stop?.name,
      address: stop?.address,
      city: stop?.city,
      state: stop?.state,
      zip: stop?.zip,
      Phone: stop?.phone,
      fax: stop?.fax,
      shipperBillOfLadding: stop?.shipperBillOfLadding,
      appointmentDate: getDate(stop?.apptDate),
      appointmentTime:getTime(stop.apptTime), //stop?.apptTime, 
      totalPieces: stop?.pieces,
      totalWeight: stop?.weight,
      commodity: stop?.commodity,
      doorSeal: stop?.doorSeal,
      etaDate: getDate(stop?.etaDate),
      etaTime: getTime(stop?.etaTime),
      arriveDate: getDate(stop?.arriveDate),
      arriveTime: getTime(stop?.arriveTime),
      spotDate: getDate(stop?.spotDate),
      spotTime: getTime(stop?.spotTime),
      unloadDate: getDate(stop?.unloadDate),
      unloadTime: getTime(stop?.unloadTime),
      departDate: getDate(stop?.departDate),
      departTime: getTime(stop?.departTime),
      availDate: getDate(stop?.availDate),
      availTime: getTime(stop?.availTime),
      arriveTruck: stop?.arriveTruck,
      arriveDriver: stop?.arriveDriver,
      departTruck: stop?.departTruck,
      departDriver: stop?.departDriver,
      arriveTruckStatus: stop?.arriveTruckStatus,
      departTruckStatus: stop?.departTruckStatus,
      arriveDriverStatus: stop?.arriveDriverStatus,
      departDriverStatus: stop?.departDriverStatus,
      driverNotes: stop?.driverNotes,
      driverNotes2: stop?.driverNotes2,
      isServerRecord: true
    }
  }) : []
  let orderNotes = (item?.orderNotes) ? item?.orderNotes?.map(note=> {
    return {
      userId: note?.userId || "",
      note: note?.note || "",
      time: note?.time || "",
    }
  }) : []

 
  let linehaul = (item?.rates) ? item?.rates?.linehaul : [];
  let accessorial = (item?.rates) ? item?.rates?.accessorial : [];

  return { billingControl, stops, linehaul, accessorial,orderNotes }
}
export const mapOrderToServer = (item) => {
  try {
    let billingControl = {
      orderBy: {
        shipperAgentCode: item.billingControl.orderBy.orderBy || "",
        shipperAgent: item.billingControl.orderBy.name || "",
        shipperOrAgentLoc: item.billingControl.orderBy.location || "",
        shipperAgentPhone: item.billingControl.orderBy.phone || "",
        shipperAgentFax: item.billingControl.orderBy.fax || "",
        email: item.billingControl.orderBy.email || "",
        contact: item.billingControl.orderBy.contact || "",
        customerNumber: item.billingControl.orderBy.billTo || "",
        billingReference: item.billingControl.orderBy.reference || "",
        status: item.billingControl.orderBy.billToStatus || "",
        billingDate:"",
        dispositionCOrderByContactSeq: item.billingControl.orderBy.orderContactSeq || 0
      },
      equipmentDetails: {
        unitNumber: item.billingControl.equipmentDetails.unitNum || "",
        chassisNumber: item.billingControl.equipmentDetails.chassis || "",
        equipSize: item.billingControl.equipmentDetails.equipmentSize || "",
        realeaseNumber: item.billingControl.equipmentDetails.release || "",
        ssl: item.billingControl.equipmentDetails.ssl || "",
        billingBooking: item.billingControl.equipmentDetails.booking || "",
        voyageNumber: item.billingControl.equipmentDetails.voyage || "",
        vessel: item.billingControl.equipmentDetails.vessel || "",
        dispositionCDestinationPort: item.billingControl.equipmentDetails.destinationPort || "",
        equipmentType: item.billingControl.equipmentDetails.equipmentType || '',
        secondaryUnit: item.billingControl.equipmentDetails.secondaryUnit || '',
        secondaryChassis: item.billingControl.equipmentDetails.secondaryChassis || '',
        chassisProvider: item.billingControl.equipmentDetails.chassisProvider || '',
        chassisRelease: item.billingControl.equipmentDetails.chassisRelease || '',
        hazMat: item.billingControl.equipmentDetails.hazMat || 'N',
        tanker: item.billingControl.equipmentDetails.tanker || 'N',
        overWeight: item.billingControl.equipmentDetails.overWeight || 'N',
        reefer: item.billingControl.equipmentDetails.reefer || 'N',
        residential: item.billingControl.equipmentDetails.residential || 'N',
      },
      customerNotes: {
        internalNotes: item.billingControl.customerNotes.internalNotes
      },
      pickup: {
        poruPoint: item.billingControl.pickup.pickupPoint || "",
        poruDate:"",
        poruTime:"",
        disposition: item.billingControl.pickup.disposition || "",
        returnDate:"",
        returnTime:"",
        dispositionCChassisPickupPoint: item.billingControl.pickup.chassisPickup || "",
        chassisPickupDate: '',
        chassisPickupTime: '',
        dispositionCChassisDisposition: item.billingControl.pickup.chassisDisposition || "",
        chassisReturnDate:'',
        chassisReturnTime:'',
        notifyDate:"",
        notifyTime:"",
        cuttOffDate:"",
        cutOffTime:"",
        pickDate:"",
        etaDate:"",
        etaTime:""
      },
      // Shipper value or consignee value - defaul to shipper
      shipper: {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        shipper: item.billingControl.shipper.name || "",
        billingShipperPO: item.billingControl.shipper.pickupNumber || "",
        shipperAddress: item.billingControl.shipper.address || "",
        shipperCity: item.billingControl.shipper.city || "",
        shipperState: item.billingControl.shipper.state || "",
        shipperZip: item.billingControl.shipper.zip || "",
        shipperPhone: item.billingControl.shipper.phone || "",
        shipperFax: item.billingControl.shipper.fax || "",
      },
      customerNotes: {
        internalNotes: item.billingControl.customerNotes.internalNotes || "",
      },
      driverNotes: {
        driverNotes: item.billingControl.driverNotes.driverNotes || "",
        driverNotes2: item.billingControl.driverNotes.driverNotes2 || "",
      },
      orderType: item.billingControl.orderType || "",
    }


    let stops = item.stops.map(stop => {
      return {
        lineNumber: stop.stop || "",
        code: stop.consignee || "",
        pickupNumber: stop.pickupNumber || "",
        name: stop.name || "",
        address: stop.address || "",
        city: stop.city || "",
        state: stop.state || "",
        zip: stop.zip || "",
        phone: stop.Phone || "",
        fax: stop.fax || "",
        contact: stop.contact || "",
        contactEmail: stop.contactEmail || "",
        poPU: stop.contactEmail || "",
        shipperBillOfLadding: stop.shipperBillOfLadding || "",
        apptDate: stop.appointmentDate || "",
        apptTime: stop.appointmentTime || "",
        pieces: stop.totalPieces || "",
        weight: stop.totalWeight || "",
        commodity: stop.commodity || "",
        doorSeal: stop.doorSeal || "",
        arriveDate: stop.arriveDate || "",
        arriveTime: stop.arriveTime || "",
        spotDate: stop.spotDate || "",
        spotTime: stop.spotTime || "",
        unloadDate: stop.unloadDate || "",
        unloadTime: stop.unloadTime || "",
        departDate: stop.departDate || "",
        departTime: stop.departTime || "",
        availDate: stop.availDate || "",
        availTime: stop.availTime || "",
        etaDate: stop.etaDate || "",
        etaTime: stop.etaTime || "",
        arriveTruck: stop.arriveTruck || "",
        arriveDriver: stop.arriveDriver || "",
        departTruck: stop.departTruck || "",
        departDriver: stop.departDriver || "",
        arriveTruckStatus: stop.arriveTruckStatus || "",
        departTruckStatus: stop.departTruckStatus || "",
        arriveDriverStatus: stop.arriveDriverStatus || "",
        // departDriverStatus: stop.departDriverStatus || "",
        tempPulp: stop.tempPulp || "",
        internalNotes: stop.internalNotes || "",
        driverNotes: stop.driverNotes || "",
        driverNotes2: stop.driverNotes2 || "",
      }
    })

    // Order type is OUTBOUND OR EXPORT
    if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
      // Shipper becomes the first Stop

      billingControl.shipper = {
        shipperOrConsShipper: stops[0]?.code || "",
        shipper: stops[0]?.name || "",
        billingShipperPO: stops[0]?.pickupNumber || "",
        shipperAddress: stops[0]?.address || "",
        shipperCity: stops[0]?.city || "",
        shipperState: stops[0]?.state || "",
        shipperZip: stops[0]?.zip || "",
        shipperPhone: stops[0]?.phone || "",
        shipperFax: stops[0]?.fax || "",
      }
      // Consignee Becomes the Shipper
      billingControl.consignee = {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        consignee: item.billingControl.shipper.name || "",
        billingConsigneePO: item.billingControl.shipper.pickupNumber || "",
        consigneeAddress: item.billingControl.shipper.address || "",
        consigneeCity: item.billingControl.shipper.city || "",
        consigneeState: item.billingControl.shipper.state || "",
        consigneeZip: item.billingControl.shipper.zip || "",
        consigneePhone: item.billingControl.shipper.phone || "",
        consigneeFax: item.billingControl.shipper.fax || "",
      }
      billingControl.orderType = billingControl.orderType === "OUTBOUND" ? "DOMOUT" : "EXPORT"
    }
    else if (billingControl.orderType === "INBOUND" || billingControl.orderType === "IMPORT") {
      // Consignee is First Stop
      billingControl.consignee = {
        shipperOrConsShipper: stops[0]?.code || "",
        consignee: stops[0]?.name || "",
        billingConsigneePO: stops[0]?.pickupNumber || "",
        consigneeAddress: stops[0]?.address || "",
        consigneeCity: stops[0]?.city || "",
        consigneeState: stops[0]?.state || "",
        consigneeZip: stops[0]?.zip || "",
        consigneePhone: stops[0]?.phone || "",
        consigneeFax: stops[0]?.fax || "",
      }

      // Shipper is Shipper Already Assigned by Default
      billingControl.orderType = billingControl.orderType === "INBOUND" ? "DOMINB" : "IMPORT"
    }


    let ratesChargesarray = [];
    console.log('rates charge saving', item.ratecharges);
    item.ratecharges.map((item, indx) => {
      let rowIndex = Number(indx) + 1;
      if (item.payable.payableReceivable === 'P') {
        const { subItems: _, splitItems: __, ...newObj } = item?.payable;

        ratesChargesarray.push(ratesChargesObjsForServer(newObj));

        if (Object.keys(item?.payable).length !== 0) {
          item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.map(subitem => {
            //if (subitem?.splitItems?.length === 0) {
            ratesChargesarray.push(ratesChargesObjsForServer(subitem));
            //}
            subitem?.splitItems?.map(splititem => {
              ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
            })
          })

          item?.payable?.splitItems?.map(splitItems => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
          })

        }
      }

      if (typeof item.payable.payableReceivable === 'undefined' && item.payable?.splitItems?.length > 0) {

        item?.payable?.splitItems?.map(splitItems => {
          ratesChargesarray.push(ratesChargesObjsForServer({ ...splitItems, recAgainstSplitLine: recAgainstLineNumber(item.receivable), splitType: 'P', commonSplitLine: rowIndex }));
        })

        if (item?.payable?.subItems?.length > 0 && item?.payable?.subItems?.[0]?.splitItems?.length > 0) {
          item.payable?.subItems?.[0]?.splitItems?.map(splititem => {
            ratesChargesarray.push(ratesChargesObjsForServer({ ...splititem, recAgainstSplitLine: recAgainstLineNumber(item.receivable?.subItems?.[0]), splitType: 'C', commonSplitLine: rowIndex }));
          })
        }

      }

      if (item.receivable.payableReceivable === 'R') {

        const { subItems: _, splitItems: __, ...newObj } = item?.receivable;
        ratesChargesarray.push(ratesChargesObjsForServer(newObj));

        if (Object.keys(item?.receivable).length !== 0) {

          item?.receivable?.subItems?.length > 0 && item?.receivable?.subItems?.map(subitem => {
            ratesChargesarray.push(ratesChargesObjsForServer(subitem));
          })

        }
      }
    });

    const ratesCharges = ratesChargesarray.sort((a, b) => a.lineNumber - b.lineNumber).filter(item => item.lineNumber !== '');
    // return { ...billingControl, stops }
    return { ...billingControl, stops, ratesCharges }

  }
  catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

export const mapOrderToTemplateServer = (item) => {
 
  try {
    let billingControl = {
      orderBy: {
        shipperAgentCode: item?.billingControl?.orderBy?.orderBy || "",
        shipperAgent: item?.billingControl?.orderBy?.name || "",
        shipperOrAgentLoc: item?.billingControl?.orderBy?.location || "",
        shipperAgentPhone: item.billingControl.orderBy.phone || "",
        shipperAgentFax: item.billingControl.orderBy.fax || "",
        email: item.billingControl.orderBy.email || "",
        contact: item.billingControl.orderBy.contact || "",
        customerNumber: item.billingControl.orderBy.billTo || "",
        billingReference: item.billingControl.orderBy.reference || "",
        status: item.billingControl.orderBy.billToStatus || "",
        billingDate: item.billingControl.orderBy.billingDate || "",
        dispositionCOrderByContactSeq: item.billingControl.orderBy.orderContactSeq || 0
      },
      equipmentDetails: {
        unitNumber: item.billingControl.equipmentDetails.unitNum || "",
        chassisNumber: item.billingControl.equipmentDetails.chassis || "",
        equipSize: item.billingControl.equipmentDetails.equipmentSize || "",
        realeaseNumber: item.billingControl.equipmentDetails.release || "",
        ssl: item.billingControl.equipmentDetails.ssl || "",
        billingBooking: item.billingControl.equipmentDetails.booking || "",
        voyageNumber: item.billingControl.equipmentDetails.voyage || "",
        vessel: item.billingControl.equipmentDetails.vessel || "",
        dispositionCDestinationPort: item.billingControl.equipmentDetails.destinationPort || "",
        equipmentType: item.billingControl.equipmentDetails.equipmentType || '',
        secondaryUnit: item.billingControl.equipmentDetails.secondaryUnit || '',
        secondaryChassis: item.billingControl.equipmentDetails.secondaryChassis || '',
        chassisProvider: item.billingControl.equipmentDetails.chassisProvider || '',
        chassisRelease: item.billingControl.equipmentDetails.chassisRelease || '',
        hazMat: item.billingControl.equipmentDetails.hazMat || 'N',
        tanker: item.billingControl.equipmentDetails.tanker || 'N',
        overWeight: item.billingControl.equipmentDetails.overWeight || 'N',
        reefer: item.billingControl.equipmentDetails.reefer || 'N',
        residential: item.billingControl.equipmentDetails.residential || 'N',
      },
      customerNotes: {
        internalNotes: item.billingControl.customerNotes.internalNotes
      },
      pickup: {
        poruPoint: item.billingControl.pickup.pickupPoint || "",
        poruDate: item.billingControl.pickup.pickupDate || "",
        poruTime: item.billingControl.pickup.pickupTime || "",
        disposition: item.billingControl.pickup.disposition || "",
        returnDate: item.billingControl.pickup.returnDate || "",
        returnTime: item.billingControl.pickup.returnTime || "",
        dispositionCChassisPickupPoint: item.billingControl.pickup.chassisPickup || "",
        chassisPickupDate: '',
        chassisPickupTime: '',
        dispositionCChassisDisposition: item.billingControl.pickup.chassisDisposition || "",
        chassisReturnDate: '',
        chassisReturnTime: '',
        notifyDate: item.billingControl.pickup.notifyDate || "",
        notifyTime: item.billingControl.pickup.notifyTime || "",
        cuttOffDate: item.billingControl.pickup.lastFreeDay || "",
        cutOffTime: item.billingControl.pickup.lastFreeTime || "",
        pickDate: item.billingControl.pickup.lastPerDiemDays || "",
        etaDate: item.billingControl.pickup.etaDate || "",
        etaTime: item.billingControl.pickup.etaTime || ""
      },
      // Shipper value or consignee value - defaul to shipper
      shipper: {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        shipper: item.billingControl.shipper.name || "",
        billingShipperPO: item.billingControl.shipper.pickupNumber || "",
        shipperAddress: item.billingControl.shipper.address || "",
        shipperCity: item.billingControl.shipper.city || "",
        shipperState: item.billingControl.shipper.state || "",
        shipperZip: item.billingControl.shipper.zip || "",
        shipperPhone: item.billingControl.shipper.phone || "",
        shipperFax: item.billingControl.shipper.fax || "",
      },
      // customerNotes: {
      //   internalNotes: item.billingControl.customerNotes.internalNotes || "",
      // },
      driverNotes: {
        driverNotes: item?.billingControl?.driverNotes?.driverNotes || "",
        driverNotes2: item?.billingControl?.driverNotes?.driverNotes2 || "",
      },
      orderType: item.billingControl.orderType || "",
    }


    let stops = item.stops.map(stop => {
      return {
        lineNumber: stop.newStop || "",
        code: stop.consignee || "",
        pickupNumber: stop.pickupNumber || "",
        name: stop.name || "",
        address: stop.address || "",
        city: stop.city || "",
        state: stop.state || "",
        zip: stop.zip || "",
        phone: stop.Phone || "",
        fax: stop.fax || "",
        contact: stop.contact || "",
        contactEmail: stop.contactEmail || "",
        poPU: stop.contactEmail || "",
        shipperBillOfLadding: stop.shipperBillOfLadding || "",
        apptDate: convertDateToCentury( stop?.appointmentDate) || "",
        apptTime:stop?.appointmentTime? convertTimeFormat(stop?.appointmentTime):"" || "",
        pieces: stop.totalPieces || "",
        weight: stop.totalWeight || "",
        commodity: stop.commodity || "",
        doorSeal: stop.doorSeal || "",
        arriveDate: convertDateToCentury(stop.arriveDate) || "",
        arriveTime: convertTimeFormat(stop.arriveTime)|| "",
        spotDate: convertDateToCentury(stop.spotDate) || "",
        spotTime: convertTimeFormat(stop.spotTime) || "",
        unloadDate: convertDateToCentury(stop.unloadDate) || "",
        unloadTime: convertTimeFormat(stop.unloadTime) || "",
        departDate: convertDateToCentury(stop.departDate) || "",
        departTime: convertTimeFormat(stop.departTime) || "",
        availDate: convertDateToCentury(stop.availDate) || "",
        availTime: convertTimeFormat(stop.availTime) || "",
        etaDate: convertDateToCentury(stop.etaDate) || "",
        etaTime: convertTimeFormat(stop.etaTime) || "",
        arriveTruck: stop.arriveTruck || "",
        // arriveDriver: stop.arriveDriver || "",
        departTruck: stop.departTruck || "",
        // departDriver: stop.departDriver || "",
        arriveTruckStatus: stop.arriveTruckStatus || "",
        // departTruckStatus: stop.departTruckStatus || "",
        arriveDriverStatus: stop.arriveDriverStatus || "",
        // departDriverStatus: stop.departDriverStatus || "",
        tempPulp: stop.tempPulp || "",
        internalNotes: stop.internalNotes || "",
        driverNotes: stop.driverNotes || "",
        driverNotes2: stop.driverNotes2 || "",
      }
    }).sort((a, b) => a.lineNumber - b.lineNumber);

    // Order type is OUTBOUND OR EXPORT
    if (billingControl.orderType === "OUTBOUND" || billingControl.orderType === "EXPORT") {
      // Shipper becomes the first Stop

      billingControl.shipper = {
        shipperOrConsShipper: stops[0]?.code || "",
        shipper: stops[0]?.name || "",
        billingShipperPO: stops[0]?.pickupNumber || "",
        shipperAddress: stops[0]?.address || "",
        shipperCity: stops[0]?.city || "",
        shipperState: stops[0]?.state || "",
        shipperZip: stops[0]?.zip || "",
        shipperPhone: stops[0]?.phone || "",
        shipperFax: stops[0]?.fax || "",
      }
      // Consignee Becomes the Shipper
      billingControl.consignee = {
        shipperOrConsShipper: item.billingControl.shipper.shipper || "",
        consignee: item.billingControl.shipper.name || "",
        billingConsigneePO: item.billingControl.shipper.pickupNumber || "",
        consigneeAddress: item.billingControl.shipper.address || "",
        consigneeCity: item.billingControl.shipper.city || "",
        consigneeState: item.billingControl.shipper.state || "",
        consigneeZip: item.billingControl.shipper.zip || "",
        consigneePhone: item.billingControl.shipper.phone || "",
        consigneeFax: item.billingControl.shipper.fax || "",
      }
      billingControl.orderType = billingControl.orderType === "OUTBOUND" ? "DOMOUT" : "EXPORT"
    }
    else if (billingControl.orderType === "INBOUND" || billingControl.orderType === "IMPORT") {
      // Consignee is First Stop
      billingControl.consignee = {
        shipperOrConsShipper: stops[0]?.code || "",
        consignee: stops[0]?.name || "",
        billingConsigneePO: stops[0]?.pickupNumber || "",
        consigneeAddress: stops[0]?.address || "",
        consigneeCity: stops[0]?.city || "",
        consigneeState: stops[0]?.state || "",
        consigneeZip: stops[0]?.zip || "",
        consigneePhone: stops[0]?.phone || "",
        consigneeFax: stops[0]?.fax || "",
      }

      // Shipper is Shipper Already Assigned by Default
      billingControl.orderType = billingControl.orderType === "INBOUND" ? "DOMINB" : "IMPORT"
    }


    
    const ratesCharges=item.ratecharges
    return { ...billingControl, stops, ratesCharges }

  }
  catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}
const dateFormatter =(tenderDate) => {

};

export const maptoTemplateTable = (templateRes) => {
 
  try {
    let template = templateRes?.map((item,index) => {
      return {
        id: index,
        controlNumber:item?._source?.controlNumber,
        tamplatename: item?._source?.templateName,
        orderby: item?._source?.shipperAgent,
        stop: item?._source?.stops[0]?.name?item?._source?.stops[0]?.name:"",
        type: item?._source?.orderType,
        poruPoint:item?._source?.poruPoint,
        stop_city_state:(item?._source?.stops[0]?.city?item?._source?.stops[0]?.city:"")+","+(item?._source?.stops[0]?.state?item?._source?.stops[0]?.state:""),
        // stop_city:item?._source?.stops[0]?.city?item?._source?.stops[0]?.city:"",
        disposition:item?._source?.disposition,
        shipperOrConsShipper:item?._source?.shipperOrConsShipper
      }
    })
    return template;

  } catch (e) {
    console.log('Error occurred while mapping', e)
    return false;
  }
}

export const mapTemplateToServer = (item,reqParams,templateName) => {
  console.log("item",item)


  let postObj = {
      templateName: templateName || "",
      orderCreateDate: "",
      locationNumber: reqParams?.locationNumber || "",
      controlNumber: reqParams?.controlNumber || "",
      sequenceNumber: "",
      unitNumber: item?.equipmentDetails?.unitNumber || "",
        //new
        ssl: item?.equipmentDetails?.ssl || "",
        equipmentType:item?.equipmentDetails?.equipmentType|| "",
        secondaryChassis:item?.equipmentDetails?.secondaryChassis|| "",
        chassisProvider:item?.equipmentDetails?.chassisProvider|| "",
        tanker:item?.equipmentDetails?.tanker|| "",
        overWeight:item?.equipmentDetails?.overWeight|| "",
        reefer:item?.equipmentDetails?.reefer|| "",
        residential:item?.equipmentDetails?.residential|| "",
        internalNotes:item?.customerNotes?.internalNotes|| "",
        chassisPickupDate:convertDateToCentury(item?.pickup?.poruDate) || "",
        chassisPickupTime:convertTimeFormat(item?.pickup?.chassisPickupTime) || "",
        chassisReturnDate:convertDateToCentury(item?.pickup?.poruDate) || "",
        chassisReturnTime: convertTimeFormat(item?.pickup?.chassisReturnTime) || "",
        driverNotes: {
        driverNotes:item?.driverNotes?.driverNotes  || "",
        driverNotes2: item?.driverNotes?.driverNotes2 || "",
      },
        //2times
    // shipperOrConsShipper:item?.consignee?.shipperOrConsShipper || "",
    releaseNumber: item?.equipmentDetails?.realeaseNumber || "", 
    pickupNumber: item?.shipper?.pickupNumber || "", 
        //end
   
      chassisNumber: item?.equipmentDetails?.chassisNumber || "",
      shipperAgentCode: item?.orderBy?.shipperAgentCode || "",
      shipperAgent: item?.orderBy?.shipperAgent || "",
      shipperOrAgentLoc: item?.orderBy?.shipperOrAgentLoc || "",
      shipperPhone: item?.shipper?.shipperPhone || "",
      shipperFax: item?.shipper?.shipperFax || "",
      // releaseNumber: "",
      referenceNumber: "",
      customerNumber: item?.orderBy?.customerNumber || "",
      shipperOrConsShipper: item?.shipper?.shipperOrConsShipper || "",
      shipper: item?.shipper?.shipper || "",
      shipperPONumber: "",
      shipperAddress: item?.shipper?.shipperAddress || "",
      shipperCity: item?.shipper?.shipperCity || "",
      shipperState: item?.shipper?.shipperState || "",
      shipperZip: item?.shipper?.shipperZip || "",
      shipperAgentPhone: item?.orderBy?.shipperAgentPhone || "",
      shipperAgentFax: item?.orderBy?.shipperAgentFax || "",
      status: item?.orderBy?.status || "",
      hazMat: item?.equipmentDetails?.hazMat || "",
      shipperOrConsignee: "",
      consignee: item?.consignee?.consignee || "",
      consigneeAddress: item?.consignee?.consigneeAddress || "",
      consigneeCity: item?.consignee?.consigneeCity || "",
      consigneeState: item?.consignee?.consigneeState || "",
      consigneeZip: item?.consignee?.consigneeZip || "",
      consigneePhone: item?.consignee?.consigneePhone || "",
      consigneeFax: item?.consignee?.consigneeFax || "",
      createdDate: "",
      changeUser: "",
      changedDate: "",
      createUser: "",
      preNoteDate: "",
      preNoteTime: "",
      consigneePONumber: "",
      notifyDate: convertDateToCentury(item?.pickup?.notifyDate) || "",
      notifyTime: convertTimeFormat(item?.pickup?.notifyTime) || "",
      pickDate: convertDateToCentury(item?.pickup?.pickDate) || "",
      poruDate: convertDateToCentury(item?.pickup?.poruDate) || "",
      poruTime: convertTimeFormat(item?.pickup?.poruTime) || "",
      poruPoint: item?.pickup?.poruPoint || "",
      returnDate: convertDateToCentury(item?.pickup?.returnDate) || "",
      returnTime: convertTimeFormat(item?.pickup?.returnTime) || "",
      vessel: item?.equipmentDetails?.vessel || "",
      voyageNumber: item?.equipmentDetails?.voyageNumber || "",
      equipSize: item?.equipmentDetails?.equipSize || "",
      remarks1: "",
      remarks2: "",
      disposition: item?.pickup?.disposition || "",
      cuttOffDate: convertDateToCentury(item?.pickup?.cuttOffDate) || "",
      cutOffTime: convertTimeFormat(item?.pickup?.cutOffTime) || "",
      etaDate: convertDateToCentury(item?.pickup?.etaDate) || "",
      etaTime: convertTimeFormat(item?.pickup?.etaTime) || "",
      secondaryUnit: item?.equipmentDetails?.secondaryUnit || "",
      stops: item?.stops || [],
      orderSequenceNumber: "",
      orderType: item?.orderType || "",
      billingExtSeq: "",
      billingRelease: " ",
      billingReference: item?.orderBy?.billingReference || "",
      billingBooking: item?.equipmentDetails?.billingBooking || "",
      billingShipperPO: item?.shipper?.billingShipperPO || "",
      billingConsigneePO: item?.consignee?.billingConsigneePO || "",
      billingDate: convertDateToCentury(item?.orderBy?.billingDate) || "",
      rates:item?.ratesCharges || [] ,
      dispositionCSeq: "",
      dispositionCChassisPickupPoint: item?.pickup?.dispositionCChassisPickupPoint || "",
      dispositionCChassisDisposition: item?.pickup?.dispositionCChassisDisposition || "",
      dispositionCDestinationPort: item?.equipmentDetails?.dispositionCDestinationPort || "",
      dispositionCOrderByContactSeq: item?.orderBy?.dispositionCOrderByContactSeq || 0
    }


    
  


  return postObj;

}


export const mapOrderToTemplateToServer = (item,reqParams,templateName) => {

  let postObj = {
      templateName: templateName || "",
      orderCreateDate: "",
      locationNumber: reqParams?.locationNumber || "",
      controlNumber: reqParams?.controlNumber || "",
      sequenceNumber: "",
      unitNumber: "",
       
        ssl: item?.equipmentDetails?.ssl || "",
        equipmentType:item?.equipmentDetails?.equipmentType|| "",
        secondaryChassis:"",
        chassisProvider:"",
        tanker:item?.equipmentDetails?.tanker|| "",
        overWeight:item?.equipmentDetails?.overWeight|| "",
        reefer:item?.equipmentDetails?.reefer|| "",
        residential:item?.equipmentDetails?.residential|| "",
        internalNotes:item?.customerNotes?.internalNotes|| "",
        chassisPickupDate:"",
        chassisPickupTime:"",
        chassisReturnDate:"",
        chassisReturnTime:"",
        driverNotes: {
        driverNotes:item?.driverNotes?.driverNotes  || "",
        driverNotes2: item?.driverNotes?.driverNotes2 || "",
      },
    releaseNumber:"", 
    pickupNumber: item?.shipper?.pickupNumber || "", 
      chassisNumber:"",
      shipperAgentCode: item?.orderBy?.shipperAgentCode || "",
      shipperAgent: item?.orderBy?.shipperAgent || "",
      shipperOrAgentLoc: item?.orderBy?.shipperOrAgentLoc || "",
      shipperPhone: item?.shipper?.shipperPhone || "",
      shipperFax: item?.shipper?.shipperFax || "",
      // releaseNumber: "",
      referenceNumber: "",
      customerNumber: item?.orderBy?.customerNumber || "",
      shipperOrConsShipper: item?.shipper?.shipperOrConsShipper || "",
      shipper: item?.shipper?.shipper || "",
      shipperPONumber: "",
      shipperAddress: item?.shipper?.shipperAddress || "",
      shipperCity: item?.shipper?.shipperCity || "",
      shipperState: item?.shipper?.shipperState || "",
      shipperZip: item?.shipper?.shipperZip || "",
      shipperAgentPhone: item?.orderBy?.shipperAgentPhone || "",
      shipperAgentFax: item?.orderBy?.shipperAgentFax || "",
      status: item?.orderBy?.status || "",
      hazMat: "",
      shipperOrConsignee: "",
      consignee: item?.consignee?.consignee || "",
      consigneeAddress: item?.consignee?.consigneeAddress || "",
      consigneeCity: item?.consignee?.consigneeCity || "",
      consigneeState: item?.consignee?.consigneeState || "",
      consigneeZip: item?.consignee?.consigneeZip || "",
      consigneePhone: item?.consignee?.consigneePhone || "",
      consigneeFax: item?.consignee?.consigneeFax || "",
      createdDate: "",
      changeUser: "",
      changedDate: "",
      createUser: "",
      preNoteDate: "",
      preNoteTime: "",
      consigneePONumber: "",
      notifyDate:"",
      notifyTime:"",
      pickDate:"",
      poruDate:"",
      poruTime:"",
      poruPoint: item?.pickup?.poruPoint || "",
      returnDate:"",
      returnTime:"",
      vessel:"",
      voyageNumber: "",
      equipSize: item?.equipmentDetails?.equipSize || "",
      remarks1: "",
      remarks2: "",
      disposition: item?.pickup?.disposition || "",
      cuttOffDate: convertDateToCentury(item?.pickup?.cuttOffDate) || "",
      cutOffTime: convertTimeFormat(item?.pickup?.cutOffTime) || "",
      etaDate:"",
      etaTime:"",
      secondaryUnit:"",
      // stops: item?.stops || [],
      stops: item.stops?.map(stop => ({
        ...stop,
        apptDate: "",
        apptTime:"",
        arriveDate: "",
        arriveDriverStatus:"",
        arriveTime:"",
        arriveTruck:"",
        arriveTruckStatus:"",
        availDate:"",
        availTime:"",
        commodity:"",
        departDate: "",
        departTime:"",
        departTruck:"",
        doorSeal:"",
        etaDate:"",
        etaTime:"",
        pickupNumber:"",
        pieces: "",
        poPU: "",
        shipperBillOfLadding:"",
        spotDate:"",
        spotTime:"",
        tempPulp:"",
        unloadDate:"",
        unloadTime:"",
        weight: "",

      })) || [],
      orderSequenceNumber: "",
      orderType: item?.orderType || "",
      billingExtSeq: "",
      billingRelease: " ",
      billingReference:"",
      billingBooking: item?.equipmentDetails?.billingBooking || "",
      billingShipperPO: item?.shipper?.billingShipperPO || "",
      billingConsigneePO: item?.consignee?.billingConsigneePO || "",
      billingDate:"",
      rates:item?.ratesCharges || [] ,
      dispositionCSeq: "",
      dispositionCChassisPickupPoint: item?.pickup?.dispositionCChassisPickupPoint || "",
      dispositionCChassisDisposition: item?.pickup?.dispositionCChassisDisposition || "",
      dispositionCDestinationPort:"",
      dispositionCOrderByContactSeq: item?.orderBy?.dispositionCOrderByContactSeq || 0
    }


    
  


  return postObj;

}
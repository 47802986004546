import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = props => {
    const { component: Component, ...restProps } = props;
    const loggedIn = useSelector(({userReducer}) => userReducer.loggedIn);

    return <Route {...restProps} render={props => (loggedIn ? <Component {...props} /> : <Redirect to={`/login`} />)} />;
};

export default PrivateRoute;
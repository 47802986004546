import React, { useState } from 'react';
import {
    Stack,
    Typography,
    FormControlLabel,
    Checkbox,
    TextField,
    InputAdornment,
    IconButton,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Button,
    Box
} from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OrderByLookup from '../../../components/OrderByLookup';
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import {
    formatedDateRangeFunc,
    formatDateRange,
} from "../../../utils/global-functions";


const BillingFilterSection = (props) => {
    const {onApplyFilters, onClearFilters, activeTab, printDocument} = props;
    
    const [dateType, setDateType] = useState('');
    const [sortByControl, setSortByControl] = useState(false);
    const [revenuePercentage, setRevenuePercentage] = useState('');
    const [excludeFSC, setExcludeFSC] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [billTo, setBillTo] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startingPercentage, setStartingPercentage] = useState('');
    const [endingPercentage, setEndingPercentage] = useState('');

    // date picker states
    const [isPickerOpen, setIsPickerOpen] = useState(false);
    const [showApplyButton, setShowApplyButton] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");

    const handleApply = () => {
        if(dateType !== '' && (dateRange[0] === null || dateRange[1] === null)){
            alert('Please select valid date range!')
            return false;
        }
        onApplyFilters({
            dateType,
            sortByControl,
            revenuePercentage,
            excludeFSC,
            orderBy,
            billTo,
            dateRange,
            startingPercentage,
            endingPercentage,
        });
    };

    const handleClear = () => {
        setDateType('');
        setSortByControl(false);
        setRevenuePercentage('');
        setExcludeFSC(false);
        setOrderBy('');
        setBillTo('');
        setDateRange([null, null]);
        setStartingPercentage('');
        setEndingPercentage('');
        onClearFilters();
        setSelectedDate("");
    };

    const handleClosePicker = () => {
        setIsPickerOpen(false); // Close picker
    };
    const handleAccept = (index, dateValue) => {
        const { formattedStartDate, formattedEndDate } = formatDateRange(dateValue);
        const formatDate = formattedStartDate + "|" + formattedEndDate;        
        setDateRange([formattedStartDate, formattedEndDate])
        setSelectedDate(formatDate);
        handleClosePicker();
        setShowApplyButton(true);
    };

    return (

        <Stack >
            <Box
                sx={{
                    padding: 2,
                    // position: 'sticky',
                    // top: 0,
                    // zIndex: 1000, // Ensures it stays on top of other content
                    // backgroundColor: 'white', // Add background color so it doesn't overlap with the content behind
                    // padding: '16px', // Adjust padding as needed
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)', // Add shadow for visual separation
                }}
            >
                <Stack direction="column">
                    {/* First Row: Type and Date Type */}
                    <Stack direction="row" spacing={2}>
                        {/* Date Type */}
                        <Stack direction="column">
                            <FormControl sx={{ minWidth: 150 }} size="small">
                                <InputLabel id="demo-select-small-label">Date Type</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={dateType}
                                    label='Date Type'
                                    size="small"
                                    onChange={(e) => setDateType(e.target.value)}
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="billingDate">Billing Date</MenuItem>
                                    <MenuItem value="returnDate">Return Date</MenuItem>
                                    <MenuItem value="apptDate">Appt Date</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>

                        {/* Date Range */}
                        <Stack direction="column" style={{ position: 'relative'}}>
                            <TextField
                                disabled={!dateType?true:false}
                                autoComplete="off"
                                label="Date Range"
                                disablePortal
                                size="small"
                                variant="outlined"
                                id="combo-box-demo"
                                value={formatedDateRangeFunc(selectedDate) || ""}
                                onClick={() => setIsPickerOpen(true)}
                                readOnly // Makes the field read-only, only clickable
                                style={{ cursor: "pointer", width: "80%" }} // Styling for better UI
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="clear date"
                                                onClick={(event) => {
                                                    event.stopPropagation(); // Prevent calendar from opening
                                                    setSelectedDate(null); // Clear the date
                                                }}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {isPickerOpen && (
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "95%",
                                        //   left: "10%",
                                        width: "max-content",
                                        zIndex: 1000,
                                        background: "#fff",
                                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                                    }}
                                >
                                    <CustomDateRangePicker
                                        indexValue={0}
                                        defaultDateRangeValue={selectedDate ? selectedDate : ""}
                                        onDateSelect={handleAccept}
                                        handleCloseDatePicker={handleClosePicker}
                                        isRestrict={1}
                                    />
                                </div>
                            )}
                        </Stack>
                        {/* Revenue Percentage  */}
                        <Stack direction="column">
                            <FormControl sx={{ minWidth: 130 }} size="small">
                                <InputLabel id="demo-select-small-label">Revenue %</InputLabel>
                                <Select
                                    label="Revenue Percentage"
                                    value={revenuePercentage}
                                    onChange={(e) => setRevenuePercentage(e.target.value)}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value="ptPercent">PT %</MenuItem>
                                    <MenuItem value="totalPercent">Total %</MenuItem>
                                </Select>
                            </FormControl>
                        </Stack>
                        {/* Starting % */}
                        <Stack direction="column">
                            <TextField
                                label='Min %'
                                value={startingPercentage}
                                onChange={(e) => setStartingPercentage(e.target.value)}
                                size="small"
                                variant="outlined"
                                sx={{
                                    width: 100
                                }}
                                disabled={!revenuePercentage?true:false}
                            />
                        </Stack>

                        {/* Ending % */}
                        <Stack direction="column">
                            <TextField
                                value={endingPercentage}
                                onChange={(e) => setEndingPercentage(e.target.value)}
                                size="small"
                                variant="outlined"
                                fullWidth
                                label="Max %"
                                sx={{
                                    width: 100
                                }}
                                disabled={!revenuePercentage?true:false}
                            />
                        </Stack>
                        
                        {/* Order By */}
                        <Stack direction="column" display={activeTab !== 2?'block':'none'}>
                            <OrderByLookup 
                                handleOnChangeValue={(val) => { 
                                    if(val !== null){
                                        setOrderBy(val.code);  
                                        setBillTo(val.billTo) 
                                    }else{
                                        setOrderBy('');  
                                        setBillTo('') 
                                    }
                                    
                                    } 
                                }
                            />
                            {/* <TextField
                                label='Order By'
                                value={orderBy}
                                onChange={(e) => setOrderBy(e.target.value)}
                                size="small"
                                variant="outlined"
                                placeholder="Search order bys"
                                sx={{
                                    width: 120
                                }}
                            /> */}
                        </Stack>
                        {/* Bill To */}
                        <Stack direction="column" display={activeTab !== 2?'block':'none'}>
                            <TextField
                                label='Bill To'
                                value={billTo}
                                onChange={(e) => setBillTo(e.target.value)}
                                size="small"
                                variant="outlined"
                                placeholder="Search bill tos"
                                sx={{
                                    width: 120
                                }}
                            />
                        </Stack>
                        {/* Sort by Control # */}
                        {/* <Stack direction="column" alignItems="center" display={activeTab !== 2?'block':'none'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={sortByControl}
                                        onChange={(e) => setSortByControl(e.target.checked)}
                                        size="small"
                                    />
                                }
                                label="Sort by Control #"
                            />
                        </Stack> */}
                        
                        {/* Exclude FSC */}
                        <Stack direction="row" alignItems="center">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={excludeFSC}
                                        onChange={(e) => setExcludeFSC(e.target.checked)}
                                        size="small"
                                    />
                                }
                                label="Exclude FSC"
                            />
                        </Stack>
                        {/* Action Buttons */}
                        <Stack direction="row" spacing={2} justifyContent="flex-end" >
                            <Button 
                            variant="contained" 
                            onClick={handleApply}
                            disabled={(dateType === '' && orderBy==='' && billTo === '')?true:false}
                            >Apply</Button>
                            <Button 
                            variant="outlined" 
                            onClick={handleClear}
                            disabled={(dateType === '' && orderBy==='' && billTo === '')?true:false}
                            >Clear</Button>
                            {/* {activeTab === 1?(
                                <IconButton
                                    aria-label="clear vendor selection"
                                    onClick={printDocument} // Trigger the generatePDF function when clicked
                                    //disabled={showApplyButton}
                                    size="small"
                                    
                                > 
                                <PictureAsPdfIcon  />
                            </IconButton>
                            ):null} */}
                            
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
        </Stack>

    );
};

export default BillingFilterSection;

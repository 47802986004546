import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { Paper, Button } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@material-ui/core";
import { TextField, Autocomplete } from "@mui/material";
import "./Styles.css";

import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import Loader from "../../../components/Loader/Lodaer";

import { StyledSceneContent } from "../../../components/styledComponents";

import {
  deleteShipper,
  saveShipper,
  saveShipperHours,
  getShipperData,
  searchShipper,
} from "../../../store/actions/shipperActions";

import {getSingleMaintenanceData,saveMaintenanceData,deleteMaintenancePickUpDispo} from "../../../store/actions/maintenanceActions";
// import { getGLCodes } from "../../../store/actions/rateBookActions";
import { getGLCodes } from "../../../store/actions/rateBookActions";
import { useHistory } from "react-router-dom";
import { OrdersWrapper } from "../../../components/styledComponents";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
//new
import ContactTable from "./components/allPickupDispositionForms/ContactTable";
import PickupDispoHoursTable from "./components/allPickupDispositionForms/PickupDispoHoursTable";
import PickupDispoForm from "./components/allPickupDispositionForms/PickupDispoForm";
import { getShipperContactData } from "../../../store/actions/shipperActions";
import { getShipperHoursData } from "../../../store/actions/shipperActions";
import { maptoClientContactTable } from "./components/commonFn";
import {
  maptoClientHoursTable,
  mapShipperFormToClient,
  mapShipperFormToServer,
  mapShipperFormDataToClient,
  mapShipperContactToServer,
  convert_hours_data
} from "./components/commonFn";

export default function PickupDispositionFormContainer(props) {
  const {
    selectedRow,
    addNewPickUpDispo,
    pickUpDispositionTableApiCall,
    formKey,
    setAddNewPickUpDispo,
    setSelectedRow,
    onClose,
    isOrderScreen,
    shipperSelect,
    shipperOptions,
    stopState,
    newShipper,
    handleAddPickupDispo,
    isEditScreen,
    onEditShipper,
    //new
    editDataSendToModal
  } = props;

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackContent, setSnackContent] = useState("");

  const [editShipper, setEditShipper] = useState(isEditScreen?true:false);

  const [isLoading, setIsLoading] = useState(
    params.controlNumber === "new" ? false : true
  );
  const contactRowsRef = useRef([]);
  const hoursRowsRef = useRef([]);
  const shipperFormRef = useRef([]);

  
  const open = Boolean(anchorEl);

  const ordersLocation = useSelector(
    ({ orderReducer }) => orderReducer.ordersLocation
  );


// eslint-disable-next-line react-hooks/exhaustive-deps
useEffect(() => {
  console.log("selectedRow || addNewPickUpDispo", selectedRow, addNewPickUpDispo);
  setIsLoading(true);
  shipperFormRef.current = [];
  contactRowsRef.current = [];
  hoursRowsRef.current = [];

  const fetchData = async () => {
    console.log("editDataSendToModal?.code",editDataSendToModal?.code)
    const code= selectedRow ||editDataSendToModal?.code||""
      try {
        console.log("editDataSendToModal",editDataSendToModal)
        const res = await dispatch(getSingleMaintenanceData({
                  locationNumber: ordersLocation?.locationNumber,
                  code:code
              }));
              console.log("--->res",res)
              setFormValues(res);
         
      } catch (error) {
          console.error("Error fetching data:", error);
      } finally {
          setIsLoading(false);
      }
  };

  fetchData();
}, [formKey, selectedRow, addNewPickUpDispo, shipperSelect]);
const setFormValues = (mapShipperForm) => {
  console.log("mapShipperForm",mapShipperForm)
  if (mapShipperForm) {
      shipperFormRef.current = mapShipperForm.shipperForm;
      contactRowsRef.current = mapShipperForm.shipperContacts || [];
      hoursRowsRef.current = mapShipperForm.shipperHour;
  } else {
      // If mapShipperForm is not provided or invalid, reset form values
      shipperFormRef.current = [];
      contactRowsRef.current = [];
      hoursRowsRef.current = [];
  }
};

  const handleCancelClick = () => {
    // setAddNewPickUpDispo(false)
    if(isOrderScreen){
      onClose()}else{
        setAddNewPickUpDispo(false);
  
      }
    // setEditShipper(false);
  
  };

  const handleCancelEdit = () => {
    if(isOrderScreen){
      onClose()}else{
        setAddNewPickUpDispo(false);
  
      }
          
            setEditShipper(false);
            
  };

  const handleSave = async (type = "new") => {

let shipperCode=shipperFormRef.current.code
    if (shipperCode) {
      setSnackOpen(true);
      setSnackContent({
        severity: "info",
        message: `Pickup/Disposition Data ${type === "new" ? "Saving" : "Updating"} ...`,
      });
      console.log("shipperFormRef.current",shipperFormRef.current)
      console.log("hoursRowsRef.current",hoursRowsRef.current)
      console.log("contactRowsRef.current",contactRowsRef.current)
      const postShipperFormData = mapShipperFormToServer(shipperFormRef.current,contactRowsRef.current);
      const converted_data = convert_hours_data(hoursRowsRef.current.receivingHours, hoursRowsRef.current.shippingHours,shipperCode)
      const mapShipContactToServer=mapShipperContactToServer(contactRowsRef.current)
const postObj={...postShipperFormData,...converted_data,contacts:mapShipContactToServer}
console.log("postobj",postObj)
// const postObj={...postShipperFormData,...converted_data}
saveAPICall(postObj, type)

    }

    else {
        setSnackOpen(true)
        if(!shipperCode){

        setSnackContent({ severity: "error", message: "shipperCode is mandatory!" })
        }else{
            setSnackContent({ severity: "error", message: "something went wrong!" })
        }
    }
  };
  const saveAPICall = (postObj, type) => {
    dispatch(saveMaintenanceData(ordersLocation.locationNumber, postObj, type))
    .then(
      (res) => {
        console.log("---res-shipperForm", res);
        if (
          res?.httpCode === 200 &&
          res?.message === "Record has been updated successfully!"
        ) {
          setSnackOpen(true);
          setSnackContent({
            severity: "success",
            message:
              type === "new"
                ? `New PickUp/Disposition has created`
                : ` PickUp/Disposition has updated`,
          });
          
          // pickUpDispositionTableApiCall();
          // setAddNewPickUpDispo(false)
          if(isOrderScreen){
            setEditShipper(false)
            handleAddPickupDispo(postObj,contactRowsRef.current)
           onClose()
           
          }else{
            pickUpDispositionTableApiCall();
            setSelectedRow(postObj?.code)
            setAddNewPickUpDispo(false)
            setEditShipper(false)
            onEditShipper(false)
          }
         

         
        } else {
          if (
            res?.httpCode === 500 &&
            res?.message 
          ) {
            setSnackOpen(true);
            setSnackContent({
              severity: "error",
              message: res?.message
            });
          }else{


          setSnackOpen(true);
          setSnackContent({
            severity: "error",
            message: res?.message
               
          });
        }
        }
      }
    );
  };
  

  const handleDeleteClick = async() => {
            let confirmAlert = window.confirm(`Are you sure you want to delete ?`);
            if (confirmAlert) {
              const code= selectedRow ||editDataSendToModal?.code||""
              try {
                const res = await dispatch(deleteMaintenancePickUpDispo(code));
                      console.log("--->res",res)
                      if (res?.httpCode === 200 && res?.message === 'Record has been deleted successfully!') {
                                  setEditShipper(false)
                                  setSelectedRow("")
                                  pickUpDispositionTableApiCall();
                                  
                                } 
    
                 
              } catch (error) {
                  console.error("Error fetching data:", error);
              } finally {
                  setIsLoading(false);
              }
            }
              
        
  };
  const handleClose = (event) => {
            setSnackOpen(false);
  };

  const handleContactData = (contactData, isAddContact) => {
    console.log("data", contactData, isAddContact);
  };
  
//  console.log("shipperFormRef.current",shipperFormRef.current)
  return (
    <Paper
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "40px",
          backgroundColor: "#E4EEFA",
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", width: "50%" }}>
          {editDataSendToModal?.type?<label style={{ fontSize: "14px", color: "#091DA3" }}>
          {editDataSendToModal?.type} {editDataSendToModal?.field} Point
          </label>:<label style={{ fontSize: "14px", color: "#091DA3" }}>
          Equip Pickup Disposition Point
          </label>}
        </div>
        {addNewPickUpDispo || editShipper ? (
          <div>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={<SaveIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                handleSave(editShipper ? "edit" : "new");
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={editShipper ? handleCancelEdit : handleCancelClick}
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div>
            {/* <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                marginRight: "10px",
                color: "#000000",
              }}
              startIcon={
                <DeleteIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={handleDeleteClick}
              disabled={true}
            >
              Delete
            </Button> */}
            <Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
              }}
              startIcon={<EditIcon style={{ width: "14px", height: "14px" }} />}
              onClick={() => {
                setEditShipper(true);
                onEditShipper(true)
              }}
            >
              Edit
            </Button>
            {/* {isOrderScreen?(<Button
              variant="outlined"
              style={{
                width: "80px",
                height: "30px",
                fontSize: "10px",
                color: "#000000",
                marginLeft:10
              }}
              startIcon={
                <ClearIcon style={{ width: "14px", height: "14px" }} />
              }
              onClick={ handleCancelClick}
            >
              Cancel
            </Button>):null} */}
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div style={editShipper || addNewPickUpDispo? {}: {pointerEvents: "none", opacity: "0.8" }}>
        <StyledSceneContent>
          <OrdersWrapper >
            <Grid container>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <PickupDispoForm shipperFormRef={shipperFormRef}/>
              </Grid>

              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <div style={{ height: "100%" }}>
                  <Grid container style={{ height: "100%" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <ContactTable
                        contactRowsRef={contactRowsRef}
                        onContactData={handleContactData}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      style={{ marginTop: "auto" }}
                    >
                      <PickupDispoHoursTable hoursRowsRef={hoursRowsRef}   />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </OrdersWrapper>
        </StyledSceneContent>
        </div>
      )}
      <Snackbar
        open={snackOpen}
        style={{ marginTop: "5%" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={snackContent.severity}
          sx={{ width: "100%" }}
        >
          {snackContent.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

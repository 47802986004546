import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Tender from './../../components/Tender/Tender';
import { ListWrapper, TenderDetailsWrapper, H2 } from './../../components/styledComponents/index';
import Box from './../../components/Box/Box';
import TableView from '../../components/Table/TableView';

import { useHistory } from 'react-router';
import { useParams } from "react-router-dom";
import Loader from './../../components/Loader/Lodaer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { selectLoadReq } from './../../store/actions/loadsActions';
import { capitalize } from './../../utils/text';
import parsePhoneNumber from 'libphonenumber-js';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { putLoadUpdate } from '../../store/actions/loadsActions';
                            

const getMoreDetails = (details) => {
    if (!details) return [];
    return [
        {
            options: [
                {
                    description: 'chassis',
                    label: details['chassis'] || '-'
                },
                {
                    description: 'reference',
                    label: details['reference'] || '-'
                },
            ]
        },
        {
            options: [
                {
                    description: 'release',
                    label: details['release'] || '-'
                },
                {
                    description: 'booking',
                    label: details['booking'] || '-'
                },
            ]
        },
        {
            options: [
                {
                    description: 'vessel',
                    label: details['vessel'] || '-'
                },
                {
                    description: 'weight',
                    label: details['weight'] || '-'
                },
            ]
        },
    ]
}

const getOrderDetails = (details) => {
    if (!details) return [];
    return [
        {
            options: [
                {
                    description: 'name',
                    label: capitalize(details['name'] || '-')
                },
                {
                    description: 'phone',
                    label: parsePhoneNumber(details['phone'] || '-', 'US')?.formatNational?.()
                },
            ]
        },
        {
            options: [
                {
                    description: 'location',
                    label: details?.location?.city ? capitalize(details['location']?.city) + ', ' + details['location']?.state?.toUpperCase?.() : '-'
                },
            ]
        },
        {
            options: [
                {
                    description: 'contact name',
                    label: details['contact']?.name || '-'
                },
                {
                    description: 'contact phone',
                    label: parsePhoneNumber(details['contact']?.phone || '-', 'US')?.formatNational?.()
                },
            ]
        },
    ]
}

const getShipperDetails = (details) => {
    if (!details) return [];
    return [
        {
            options: [
                {
                    description: 'name',
                    label: capitalize(details['name'] || '-')
                },
                {
                    description: 'phone',
                    label: parsePhoneNumber(details['phone'] || '-', 'US')?.formatNational?.()
                },
            ]
        },
        {
            options: [
                {
                    description: 'location',
                    label: capitalize(details['location']?.address1) + ', ' + capitalize(details['location']?.city) + ', ' + details['location']?.state?.toUpperCase?.()
                },
               
            ]
        },
    ]
}

const getPickupDetails = (details) => {
    if (!details) return [];
    return [
        {
            options: [
                {
                    description: 'Contact',
                    label: capitalize(details['contact'] || '-')
                }
            ]
        },
        {
            options: [
                {
                    description: 'location',
                    label: capitalize(details.address1) + ', ' + capitalize(details.city) + ', ' + details.state?.toUpperCase?.() + ' - ' + details.zip
                },
               
            ]
        },
    ]
}

const getDispositionDetails = (details) => {
    if (!details) return [];
    return [
        {
            options: [
                {
                    description: 'location',
                    label: capitalize(details.address1) + ', ' + capitalize(details.city) + ', ' + details.state?.toUpperCase?.() + ' - ' + details.zip
                },
               
            ]
        },
    ]
}

const getRatesChargesDetails = (details) => {
    if ( details === undefined || details.length === 0 ) { 
        return [];
    }
    
    const myArr = [];
    const columns = [
        {
            id:"AccountNumber",
            label:"Account Number",
            minWidth: 90,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        }, 
        {
            id:"AccountTypeCode",
            label:"Account Type Code",
            minWidth: 90,
            align: 'right',
        }, 
        {
            id:"Amount",
            label:"Amount",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"AssociatedStop",
            label:"Associated Stop",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"ChargesTypeCode",
            label:"Charges Type Code",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Code",
            label:"Code",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Department",
            label:"Department",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Desciption",
            label:"Description",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Line",
            label:"Line",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"PaperworkCompletedCode",
            label:"Paper work Completed Code",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"PaperworkRequiredCode",
            label:"Paper work Required Code",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Processed",
            label:"Processed",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"RateAmount",
            label:"Rate Amount",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Transmitted",
            label:"Transmitted",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Truck",
            label:"Truck",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Units",
            label:"Units",
            minWidth: 90,
            align: 'right',
        },
        {
            id:"Vendor",
            label:"Vendor",
            minWidth: 90,
            align: 'right',
        }
    ];
    const rows = [];
    details.map((obj, ind) => {
        rows.push({
            Line:obj.Line,
            Desciption:obj.Desciption,
            Amount:obj.Amount,
            Truck:obj.Truck,
            history:{
                AccountNumber: obj.AccountNumber,
                AccountTypeCode:obj.AccountTypeCode,
                AssociatedStop:obj.AssociatedStop,
                ChargesTypeCode:obj.ChargesTypeCode,
                Code:obj.Code,
                Department:obj.Department,
                PaperworkCompletedCode:obj.PaperworkCompletedCode,
                PaperworkRequiredCode:obj.PaperworkRequiredCode,
                Processed:obj.Processed,
                RateAmount:obj.RateAmount,
                Transmitted:obj.Transmitted,
                Units:obj.Units,
                Vendor:obj.Vendor
            },
        });
    });
    myArr['columns'] = columns;
    myArr['rows'] = rows;
    return myArr;
}
export default function TenderDetails() {
    const params = useParams();
    const load = useSelector(({ loadsReducer }) => loadsReducer.selectedLoad);
    const loadDetails = useSelector(({ loadsReducer }) => loadsReducer.loadDetails);
    const isLoading = useSelector(({ loadsReducer }) => loadsReducer.isLoading);
    const dispatch = useDispatch();
    const history = useHistory();

    const [updateParams, setUpdateParams] = useState({
        appointmentDate: 0,
        appointmentTime: 0
    })

    const [highlighter,sethighlighter] = useState(false);

    useEffect(() => {
        if (!load) {
            dispatch(selectLoadReq(params.controlNum, params.locationNum));
        }
    }, [load, history, params, dispatch]);

    return (
        <TenderDetailsWrapper>
          
            {(isLoading || !loadDetails) ? <Loader /> : <ListWrapper>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ cursor: 'pointer', width: 'fit-content', marginRight: '10px' }} onClick={() => history.goBack()}>
                        <ArrowBackIcon />
                    </div>
                    <H2>Details <span style={{ fontWeight: 'normal' }}>{loadDetails.controlNumber}</span></H2>
                </div>
                <div>
                    <Card>
                      <CardContent>
                        <Typography variant="h5" component="h5">
                          Edit
                        </Typography>
                        <form noValidate autoComplete="off">
                            <TextField
                                label="Appointment Date"
                                defaultValue={(load?.stops[0]?.appointmentDate)?load?.stops[0]?.appointmentDate:loadDetails?.stops[0]?.appointmentDate}
                                onChange={e => { setUpdateParams({appointmentDate: e.target.value, appointmentTime: updateParams.appointmentTime}); 
                                        sethighlighter(true)
                                        setTimeout(() => {
                                            sethighlighter(false)
                                        }, 2000)
                                }}
                            />
                            <TextField
                                label="Appointment Time"
                                defaultValue={(load?.stops[0]?.appointmentTime)?load?.stops[0]?.appointmentTime:loadDetails?.stops[0]?.appointmentTime}
                                onChange={e => { setUpdateParams({appointmentTime: e.target.value, appointmentDate: updateParams.appointmentDate}); 
                                sethighlighter(true)
                                setTimeout(() => {
                                    sethighlighter(false)
                                  }, 2000)
                            }}
                            />
                        </form>
                      </CardContent>
                      <CardActions>
                        <Button style={( highlighter === true )?{
                            background:'red', color:"white",
                            "-moz-transition": "all 0.5s ease-in",
                            "-o-transition": "all 0.5s ease-in",
                            "-webkit-transition": "all 0.5s ease-in",
                            transition: "all 0.5s ease-in",
                            
                        }:{}}
                            active onClick={() => putLoadUpdate(load.locationNumber, load.controlNumber, updateParams) }>Save</Button>
                      </CardActions>
                    </Card>
                </div>
                {loadDetails && <Tender load={loadDetails} />}
                {loadDetails && <>
                    <Box items={getMoreDetails(loadDetails?.details)} title={'More Info'} />
                    <Box items={getOrderDetails(loadDetails?.orderer)} title={'Order By'} />
                    <Box items={getShipperDetails(loadDetails?.shipper)} title={'Shipper'} />
                    <Box items={getPickupDetails(loadDetails?.pickup)} title={'Pickup'} />
                    <Box items={getDispositionDetails(loadDetails?.disposition)} title={'Disposition'} />
                    <TableView items={getRatesChargesDetails(loadDetails?.charges)} container={loadDetails.container} reference={loadDetails.reference} title={'Rates & Charges'} />
                </>}
            </ListWrapper>}
        </TenderDetailsWrapper>
    )
}

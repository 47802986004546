import React from 'react';
import { CircularProgress } from '@material-ui/core';
const Loader = ({ label }) => {
    return (
        <div className='loader_container'>
            <div className='loader'>
                <CircularProgress role='status' size={15}>
                    <span className='sr-only'>{label}</span>
                </CircularProgress>
            </div>
            {label}
        </div>
    );
};

export default Loader;

import { truckdriverTypes } from "../types/truckdriverTypes";
const initialState = {
    truckdriverData: []
};

export const truckdriverReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case truckdriverTypes.TRUCKDRIVER_DATA:
      return {
        ...state,
        truckdriverData: payload
      }

    default:
      return state;
  }
}
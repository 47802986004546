import { getUserFromCookie } from '../../utils/cookie';
import { UserTypes } from '../types/userTypes';

const user = getUserFromCookie();

const initialState = {
    loggedIn: user ? true : false,
    user,
    isLoading: false,
    screenWidthSize: null
};

export const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case UserTypes.LOGIN: 
            return {
                ...state,
                loggedIn: payload,
                screenWidthSize: 1024 < window.screen.availWidth ? true : false
            }
        case UserTypes.LOGOUT:
            return {
                ...state,
                loggedIn: false
            }
        default:
            return state;
    }
};

